export const fontFamily = {
  font100: 'Poppins_100Thin',
  font200: 'Poppins_200ExtraLight',
  font300: 'Poppins_300Light',
  font400: 'Poppins_400Regular',
  font500: 'Poppins_500Medium',
  font600: 'Poppins_600SemiBold',
  font700: 'Poppins_700Bold',
  font800: 'Poppins_800ExtraBold',
  font900: 'Poppins_900Black'
};
