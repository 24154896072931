import React, { Component } from 'react';
import i18n from 'i18n-js';
import styles from '../style/popupmodel';
import { View, Text, Dimensions, TouchableOpacity } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
export default class AlertBoxPermission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
      closeAlert: true
    };
  }
  updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentWillUnmount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  getValueOfTop() {
    if (this.state.windowWidth <= 600) {
      if (this.props.top) {
        return '3%'
      }
      else {
        return '16%'
      }
    }
    else {
      if (this.props.top) {
        return '8px'
      }
      else {
        return '104px'
      }
    }
  }

  render() {
    return (
      <>
        <View style={styles.alertContainer}>
          <LinearGradient
            locations={[0, 1]}
            colors={['rgb(207,68,55)', 'rgb(122,40,40)']}
            style={[styles.alertPermission, { top: this.getValueOfTop() }]}
          >
            <Text
              style={{
                marginLeft: 15,
                fontSize: 16,
                color: '#fff',
                width: '80%'
              }}
            >
              {this.props.name}
            </Text>
          </LinearGradient>
        </View>
      </>
    );
  }
}
