import React, { useEffect, useRef } from 'react';
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Dimensions,
  ActivityIndicator
} from 'react-native';
import styles from '../../style/scanpack';
import i18n from 'i18n-js';
import globalStyles from '../../style/global';
import { fontFamily } from '../../helpers/fontFamily';
import promptStyle from '../../style/scanpack';
import '../scanpack/addBar.css';
import { LinearGradient } from 'expo-linear-gradient';
import StyledInput from '../../commons/Inputs/stylesInput';

export default function Alert(props) {
  const addNoteFocus = useRef(null);
  const windowWidth = window.innerWidth;
  const msg = !props.msg ? 'Add a Note' : props.msg;

  useEffect(() => {
    addNoteFocus.current?.focus();
  },[]);
  return (
    <View style={[styles.responseView]}
      testID='alertContainer'
      onStartShouldSetResponder={() => props.showInput? props.closeAlert() : ()=>{}}
    >
      <View style={[windowWidth >= 900 ? styles.typeScanContainer : "", windowWidth <= 900 ? styles.recordSerialMobile : "",{marginBottom: '9%'}]}>
        <LinearGradient
          locations={[0, 1]}
          start={{ x: 0, y: 1 }}
          end={{ x: 0, y: 0 }}
          colors={['#d47d02', '#ffb636']}
          style={[
            styles.typeScanBox,
            {
              backgroundColor: '#d3dfea',
              paddingHorizontal: 10,
              paddingVertical: 5,
              width: windowWidth >= 900 ? '50%' : '100%',
              marginTop: "2%",
              borderColor: '#e8971a',
              borderWidth: 3,
              height: 'fit-content',
              boxShadow: '2px 2px 10px #363636'
            }
          ]}
        >
          {props.heading && 
           <View
            style={
              ([globalStyles.flexDirectionRow],
                { marginTop: 10, alignItems: 'center' })
            }
          >
            <Text 
            testID='HeadingText'
            style={[
                styles.scanCountText,
                { fontFamily: fontFamily.font400,fontSize:windowWidth >= 900 ? 20:16,marginBottom: '5%' }
              ]}>
              {i18n.t(props.heading)}
            </Text>
          </View>
          }

          <View
            style={
              ([globalStyles.flexDirectionRow],
                { marginTop: 10, alignItems: 'center' })
            }
          >
           <Text
              testID='messageText'
              style={[
                styles.scanCountText,
                { fontFamily: fontFamily.font400, fontSize:windowWidth >= 900? 20 : 16,textAlign: 'justify',width: '90%' }
              ]}
            >
            {msg}
            </Text>

            {props.showIcon && <View
              style={{
                position: 'absolute',
                right: 0,
                top: -10
              }}
            >
              <TouchableOpacity 
              onPress={props.closeAlert}
              >
                <i
                  className="icon-cancel"
                  style={{ fontSize: '26px', color: '#4b4132' }}
                ></i>
              </TouchableOpacity>
            </View>
            }

          </View>
          <View
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: 20,
              marginBottom: props.showButtons ? 2 : 10
            }}
          >
            {!props.showInput && 
              <StyledInput
                value={props.notes_fromPacker}
                onChangeText={props.noteUpdate}
                autoFocus={true}
                onSubmitEditing={
                  props.notes_fromPacker !== '' ? props.submitAlert : ()=>{}
                }
                returnKeyType='done'
                ref={addNoteFocus}
                blurOnSubmit={true}
                style={[promptStyle.promptTextIn, styles.typeScanCountInput, { border: 'none',marginBottom: '1%',width: '90%',marginTop: '1%',height: 38 }]}
                className="borderInput"
                inputStyle={{height: '100%',fontSize: 20,padding: 10}}
                useInputStyle= {false}
                placeholder={props.placeholder}
                placeholderTextColor={props.placeholderTextColor}
              />
            }
            {props.loading && <View style={{flex: 1, justifyContent: 'center'}}>
              <ActivityIndicator size="large" color="#336599" />
            </View> }

            {props.showButtons && 
            <View
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '2%',
              width: '100%'
            }}
            >
              <View style={{ flexDirection: 'row' }}>
                <TouchableOpacity
                  onPress={props.notes_fromPacker !== '' ? props.submitAlert : ''}
                  style={[
                    styles.modalButton,
                    {
                      marginLeft: windowWidth >= 900 ? '-50%' : '-50%'
                    }
                  ]}
                >
                  <Text>
                    Save
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={props.closeAlert}
                  style={[
                      styles.modalButton,
                      { marginLeft: windowWidth >= 900 ? '84%' : '100%' }
                    ]}
                >
                  <Text>
                    Cancel
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
            }

          </View>
        </LinearGradient>
      </View>
    </View>
  );
}
