import React, { useState, useRef } from 'react';
import { Text, ScrollView, View, Animated } from 'react-native';

const CustomScrollBar = ({ instructionCheck }) => {
  const [completeScrollBarHeight, setCompleteScrollBarHeight] = useState(1);
  const [visibleScrollBarHeight, setVisibleScrollBarHeight] = useState(0);

  const scrollIndicator = useRef(new Animated.Value(0)).current;

  const scrollIndicatorSize =
    completeScrollBarHeight > visibleScrollBarHeight
      ? (visibleScrollBarHeight * visibleScrollBarHeight) /
      completeScrollBarHeight
      : visibleScrollBarHeight;

  const difference =
    visibleScrollBarHeight > scrollIndicatorSize
      ? visibleScrollBarHeight - scrollIndicatorSize
      : 1;

  const scrollIndicatorPosition = Animated.multiply(
    scrollIndicator,
    visibleScrollBarHeight / completeScrollBarHeight
  ).interpolate({
    inputRange: [0, difference],
    outputRange: [0, difference],
    extrapolate: 'clamp'
  });
  return (
    <>
      <ScrollView
        contentContainerStyle={{ padding: "16px 22px 12px",gap: "10px",display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flexStart" }}
        showsVerticalScrollIndicator={false}
        scrollEventThrottle={16}
        onContentSizeChange={(_, height) => {
          setCompleteScrollBarHeight(height);
        }}
        onLayout={({
          nativeEvent: {
            layout: { height }
          }
        }) => {
          setVisibleScrollBarHeight(height);
        }}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { y: scrollIndicator } } }],
          { useNativeDriver: false }
        )}
      >
        <Text
          style={{
            fontFamily: 'Poppins_400Regular',
            padding: 5,
            fontSize: '20px',
            marginLeft: '1%',
            minHeight:20,
            lineHeight: '30px',
            color: "#000000",
            flex: "none",
            alignSelf: "stretch",
            flexGrow: "0",
            
          }}
        >
          {instructionCheck}{' '}
        </Text>
      </ScrollView>
      <View style={{
        height: '100%',
        width: 6,
        backgroundColor: 'transparent',
        borderRadius: 8
      }}>
        {difference == 1 ? <></> :
          <Animated.View
            style={{
              width: 6,
              borderRadius: 8,
              backgroundColor: '#A9A9A9',
              height: scrollIndicatorSize,
              transform: [{ translateY: scrollIndicatorPosition }]
            }}
          />}
      </View>
    </>);
}
export default CustomScrollBar;