import React, { Component } from 'react';
import i18n from 'i18n-js';
import styles from '../style/popupmodel';
import { View, Text, Dimensions, TouchableOpacity } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
export default class AlertBoxPermission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: Dimensions.get('window').width,
      closeAlert: true
    };
  }
  updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentWillUnmount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  getValueOfTop () {
    if(this.state.windowWidth <= 600 ){
       if(this.props.top){
        return '3%'
       }
       else {
        return '16%'
       }
    }
    else {
      if(this.props.top){
        return '8px'
      }
      else {
        return '104px'
      }
    }
  }

  render() {
    return (
      <>
        {this.props.permissionState && (
          <View style={styles.alertContainer}>
            <LinearGradient
              testID='permissionError'
              locations={[0, 1]}
              colors={['rgb(207,68,55)', 'rgb(122,40,40)']}
              style={[styles.alertPermission, { top: this.getValueOfTop()}]}
            >
              <Text
                style={{
                  marginLeft: 15,
                  fontSize: 16,
                  color: '#fff',
                  width: '80%'
                }}
              >
                {i18n.t('product.alertBoxPermission')}
              </Text>
              <TouchableOpacity
                style={{
                  height: 28,
                  borderRadius: 1,
                  padding: 5,
                  borderRadius: 10,
                  position: 'absolute',
                  right: '0%',
                  top: this.state.windowWidth <= 600 ? '12%' : '-8%'
                }}
                testID= 'cancelIcon'
                onPress={() => {
                  this.props.onCloseAlert();
                }}
              >
                <i
                  className="icon-cancel"
                  style={{ fontSize: '22px', color: 'white' }}
                ></i>
              </TouchableOpacity>
            </LinearGradient>
          </View>
        )}
      </>
    );
  }
}
