import {LinearGradient} from 'expo-linear-gradient';
import React, {useLayoutEffect, useState} from 'react';
import {FlatList, ScrollView, TouchableOpacity} from 'react-native';
import {Text, View, Modal, Dimensions} from 'react-native';
import i18n from 'i18n-js';

const PrinterShow = ({dataPrinter, handlePrinterSelection, closePrompt}) => {
  const [windowWidth, setWindowWidth] = useState(
    Dimensions.get('window').width
  );
  useLayoutEffect(() => {
    function updateSize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return (
    <>
      <Modal visible={true} animationType="slide" transparent={true}>
        <View style={{display: 'flex', alignItems: 'center',marginTop:windowWidth >= 900 ? "100px" :"75px"}}>
          <LinearGradient
            colors={['#FFB538', '#CF7C08']}
            start={[-0.5, -1]}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '3px',
              gap: 4,
              width: windowWidth >= 1076 ? '1076px' : '90%',
              borderRadius: '15px',
              padding: 20
            }}>
            <View
              style={{
                position: 'absolute',
                right: 0,
                top: 0
              }}
            >
              <TouchableOpacity 
              onPress={()=>{closePrompt()}}
              >
                <i
                  className="icon-cancel"
                  style={{ fontSize: '26px', color: '#4b4132' }}
                ></i>
              </TouchableOpacity>
            </View>
            <Text
              style={{
                alignItems: 'center',
                fontFamily: 'Poppins_300Light',
                fontSize: '20px',
                textAlign:"center",
                paddingTop:"20px",
                paddingBottom:"20px"
              }}>
              {i18n.t('directprinting.selectprinter')}
            </Text>
            <LinearGradient
              start={[-0.5, -1]}
              colors={['#2D445F', '#2D445F']}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '13px',
                gap: '7px',
                padding: 13,
                borderRadius: 10,
                width: windowWidth >= 400 ?'333px':"280px",
                height: '121px'
              }}>
              <ScrollView showsVerticalScrollIndicator={false}>
                <FlatList
                  data={dataPrinter}
                  keyExtractor={item => item}
                  renderItem={({item, index}) => (
                    <TouchableOpacity
                      onPress={() => handlePrinterSelection(item, index)}
                      testID="selectPrinter"
                      >
                      <Text
                        style={{
                          fontFamily: 'Poppins_600SemiBold',
                          fontSize: '18px',
                          color: '#ffffff',
                          textAlign: 'center',
                          width: windowWidth >= 400 ?'333px':"280px",
                          backgroundColor: index == 0 ? '#4b5f76' : ''
                        }}>
                        {item}
                      </Text>
                    </TouchableOpacity>
                  )}
                />
              </ScrollView>
            </LinearGradient>
          </LinearGradient>
        </View>
      </Modal>
    </>
  );
};

export default PrinterShow;
