import React, {Component} from 'react';
import {
  View,
  Text,
  TextInput,
  ActivityIndicator,
  ScrollView,
  TouchableOpacity,
  Image
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import {connect} from 'react-redux';
import globalStyles from '../../style/global';
import styles from '../../style/scanpack';
import cross from '../../../assets/close_black.png';
import shared_barcode from '../../../assets/shared_barcode.png';
import SuggestedAliasDesktop from '../../../assets/suggestedAliasDesktop.svg';
import SuggestedAliasMobile from '../../../assets/suggestedAliasMobile.svg';
import placeholder from '../../../assets/placeholder.jpg';
import { LinearGradient } from 'expo-linear-gradient';
import AliasHeader from './aliasHeader';
import AliasButtons from './aliasButtons';
import ItemData from './itemData';
import CustomScrollBar from '../../commons/customScrollBar';

class ProductAlias extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aliasImageUrl: '',
    };
  }
  componentDidUpdate(prevProps) {
    if(this.props.updateProduct !== prevProps.updateProduct){
      this.getProductImage()
    }
  }
  async getProductImage ()  {
    if (this.props.updateProduct?.after_alias_product_data?.id) {
      const access_token = await AsyncStorage.getItem('access_token');
      const url = await AsyncStorage.getItem('url');
      axios({
        method: 'get',
        url: `${url}/products/${this.props.updateProduct?.alias_product_data?.id}.json`,
        headers: {Authorization: `Bearer ${access_token}`}
      })
        .then(data => {
          if(data.data.product){
            this.setState({
              aliasImageUrl: data.data.product?.images[0]?.image
            })
          }
        })
      }
  }
  getHeight () {
    if(this.props.windowWidth > 900){
      return (window.innerHeight + window.innerWidth) * 0.28
    }
    else if(this.props.windowWidth> 500){
      return 600
    }
    else {
      return (2*window.innerWidth) * 0.9
    }
  }
  render() {
    let productName = this.props.updateProduct?.alias_product_data?.name
    let productSkUs = this.props.updateProduct?.after_alias_product_data?.sku
    let productBarcode = this.props.updateProduct?.after_alias_product_data?.barcode
    let imageUrl = this.props.updateProduct?.params?.images[0]?.image
    return (
      <React.Fragment>
        {this.props.alias &&
          this.props.Product &&
          this.props.updateProduct.current_product_data &&
          this.props.updateProduct.alias_product_data.barcode && (
            <View
             style={styles.responseView}
            >
              <View style={styles.typeScanContainer}>
                <LinearGradient
                  testID='aliasContainer'
                  locations={[0, 1]}
                  colors={['#465667', '#4D6175']}
                  style={[styles.aliasBox,{
                    width: this.props.windowWidth > 900 ? '70%' : '100%',
                    height: this.props.windowWidth > 900 ? '70%' : '100%'
                  }]}
                  >
                    <View
                    style={{display:'block',marginBottom: '1%', alignSelf: "flex-end", marginRight: '6px'}}
                    >
                      <TouchableOpacity
                        testID='errorCancelIcon'
                        onPress={()=>{this.props.assignUniquebarcode('fromButton')}}
                      >
                        <i
                          className="icon-cancel"
                          style={{
                            fontSize: '26px',
                            color: '#ffff',
                            marginTop: -2,
                          }}
                        ></i>
                      </TouchableOpacity>
                    </View>
                  <CustomScrollBar>
                    <AliasHeader 
                    width= {this.props.windowWidth}
                    message1= {'Duplicate barcode identified. An alias may be required.'}
                    message2= {'You have tried to add a barcode that already exists on another item. Aliasing is often used in this situation to combine multiple SKUs in the same item record.'}
                    message3={'If both of these SKUs, refer to the same physical product, we can add the new sku to the existing item.'}
                    />
                    <AliasButtons 
                    message1={'No Thanks. These Items are different'}
                    message2={'Proceed With Aliasing'}
                    function_one={this.props.shareBarcodeMethod}
                    function_two={this.props.proceedAliasing}
                    />
                    <View
                    style={styles.aliasBoxCommonStyle2}
                    >
                      <View
                        style={{width: this.props.windowWidth > 900 ? '80%' : '100%'}}
                      >
                        <Text 
                        style={{color: '#FFFFFF',textAlign:'center'}}
                        >
                          Not to sound ominous but.... This can not be undone.</Text>
                        <View
                        style={{display:'block',marginTop: '2%'}}
                        >
                          <i
                            className=" icon-info-circled"
                            style={{ color: 'white', fontSize: '16px' }}
                          ></i>
                          <Text style={{color: '#FFFFFF',textAlign:'center'}}>Scroll down for more info. If you are seeing this often you may want to use a CSV to add multiple SKUs to the same item.</Text>
                        </View>
                      </View>
                    </View>

                    <View
                    style={styles.aliasBoxCommonStyle2}
                    >
                      <LinearGradient
                        locations={[0,1]}
                        colors={['#142130', '#304454']}
                        style={[styles.aliasResultMainContainer,{
                          width: this.props.windowWidth > 900 ? '80%' : '100%'
                        }]}
                      >
                        <View
                          style={[styles.aliasResultContainer,{
                            flexDirection:this.props.windowWidth > 900 ? 'row' : 'column'
                          }]}
                        >
                          <View
                          style={{display: 'flex',flexDirection: 'column',
                          width: this.props.windowWidth > 900 ? '70%' : '100%',
                        }}
                          >
                            <Text
                            style={styles.aliasResultText}
                            >
                              After aliasing you will have one item with the following product data:
                            </Text>
                            <Text 
                            style={styles.aliasResultText}
                            >
                              Name:{'\n'}{productName}
                            </Text>
                            <Text 
                            style={styles.aliasResultText}
                            >
                              SKUs:{'\n'}{productSkUs}
                            </Text>
                            <Text 
                            style={styles.aliasResultText}
                            >
                              Barcodes:{'\n'}{productBarcode}
                            </Text>
                          </View>
                          <View 
                          style={styles.aliasResultImageContainer}
                          >
                            <Image 
                            style={{
                              height:  this.props.windowWidth > 900 ? 180 : 150, width: 160,borderRadius: 15,resizeMode: 'contain'}}
                            defaultSource={placeholder} 
                            source={{ uri:imageUrl}} 
                            />
                          </View>
                        </View>
                      </LinearGradient>
                    </View>

                    <View
                    style={styles.aliasBoxCommonStyle2}
                    >
                        <Image
                          style={{
                            height: this.getHeight(),
                            width: this.props.windowWidth > 900 ? '80%' : '100%',
                            resizeMode: 'contain'

                          }}
                          source={this.props.windowWidth > 700 ? SuggestedAliasDesktop : SuggestedAliasMobile }
                        />
                      {/* </View> */}
                    </View>
                  </CustomScrollBar>
                </LinearGradient>
              </View>
            </View>
          )}
        {this.props.shareBarcodeShow &&
          this.props.Product &&
          this.props.updateProduct &&
          this.props.updateProduct.current_product_data && (
            <View
             style={styles.responseView}
            >
              <View style={styles.typeScanContainer}>
                <LinearGradient
                  locations={[0, 1]}
                  colors={['#465667', '#4D6175']}
                  style={[styles.aliasBox,{
                    width: this.props.windowWidth > 900 ? '70%' : '100%',
                    height: this.props.windowWidth > 900 ? '70%' : '100%'
                  }]}
                >
                  <CustomScrollBar>
                    <AliasHeader 
                    width={this.props.windowWidth}
                    message1={'Permit a Shared Barcode to be used across multiple items?'}
                    message2={'Since the items are different it is recommended to assign unique barcodes so that Groovepacker will be able to distinguish between them.'}
                    message3={'There are some special cases when you might decide to have the same barcode used for two or more unique items. (scroll down for examples) This is not a recommended practice since GroovePacker and other apps that use the barcode will not be able to tell them apart.'}
                    />

                    <AliasButtons 
                    message1={'Yes, I want to permit these separate items to have the same barcode'}
                    message2={'No I will assign unique barcode to this item'}
                    function_one={this.props.sameBarcode}
                    function_two={()=>{this.props.assignUniquebarcode('fromButton')}}
                    />
                    <View
                    style={styles.aliasBoxCommonStyle2}
                    >
                      <LinearGradient
                        locations={[0,1]}
                        colors={['#142130', '#304454']}
                        style={[styles.aliasResultMainContainer,{
                          width: this.props.windowWidth > 900 ? '80%' : '100%'
                        }]}
                      >
                        <View
                          style={styles.aliasBoxCommonStyle}
                        >
                          <Text
                          style={{color:'#FFFFFF',marginTop: 10,marginBottom:10}}
                          >
                          One item already has this barcode associated:
                          </Text>
                          <ItemData 
                          name={this.props.updateProduct?.alias_product_data?.name}
                          sku={this.props.updateProduct?.alias_product_data?.sku}
                          barcode={this.props.updateProduct?.alias_product_data?.barcode}
                          imageUrl={this.state.aliasImageUrl}
                          width={this.props.windowWidth}
                          placeholder={placeholder}
                          />
                          
                          <i
                            className='icon-down-circled'
                            style={{ color: '#CF7C08', fontSize: 40, marginTop: 10,marginBottom:10 }}
                          ></i>
                          <Text
                          style={{color:'#FFFFFF',marginTop: 10,marginBottom:10}}
                          >
                          The same barcode will be added to a different product record.
                          </Text>
                          <ItemData 
                          name={this.props.updateProduct?.current_product_data?.name}
                          sku={this.props.updateProduct?.current_product_data?.sku}
                          barcode={this.props.updateProduct?.alias_product_data?.barcode}
                          imageUrl={imageUrl}
                          width={this.props.windowWidth}
                          placeholder={placeholder}
                          color = {'#FFF973'}
                          />
                          
                        </View>
                        <Text
                        style={{color:'#FFFFFF',marginTop: 10,marginBottom:10}}
                        >
                        <i
                          className='icon-lightbulb'
                          style={{ color: '#EFDA1D', fontSize: 25 }}
                        ></i>
                        Example case:  A manufacturer has used the same barcode on 3 variants of an item. Perhaps the item is actually identical but the packaging differs slightly. It is not practical to re-barcode items since their would be no cost incurred if the wrong variant was shipped.
                        </Text>
                        <Text
                        style={{color:'#FFFFFF',marginTop: 10,marginBottom:10}}
                        >
                        Another scenario would be a regular item and a "tester" version. Both share the same barcode and are the same item, but they have differing SKUs for inventory tracking purposes. Here it would be possible to alias the items but by keeping them separate it is possible to show the packer instructions that are specific to one of the SKUs.
                        </Text>
                      </LinearGradient>
                    </View>
                  </CustomScrollBar>
                </LinearGradient>
              </View>
            </View>
          )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProductAlias);
