import React from 'react';
import { TextInput } from 'react-native';
const ProductInput = (props) => {
  const { value, editable, onChangeText, style,id } = props
  return (
    <TextInput
      testID={id}
      value={value}
      onChangeText={(text, name) => onChangeText(text, name)}
      editable={editable}
      style={style}
    />
  )
}
export default ProductInput