import React, {Component} from 'react';
import {
  View,
  TextInput,
  TouchableOpacity,
  Image,
  FlatList,
  SafeAreaView
} from 'react-native';
import {connect} from 'react-redux';
import {GetAllProduct, GetSearchProduct} from '../../actions/productAction';
import {AddOrderItems} from '../../actions/orderActions';
import styles from '../../style/orderdetail';
import {Tooltip, Text} from 'react-native-elements';
import {LinearGradient} from 'expo-linear-gradient';
import {fontFamily} from '../../helpers/fontFamily';
import {FontAwesome} from '@expo/vector-icons';
import SaveItemToOrder from './save_item_to_order';
import OrderItemList from '../../commons/itemsList';
import CommonStyles from '../../style/commonStyles';

let addItemSuccess = true;
let update = true;
let updateSearch = false;

class addItemInBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      getAllItem: {
        filter: 'all',
        sort: '',
        order: '',
        is_kit: -1,
        limit: 20,
        offset: 0
      },
      searchData: {
        search: '',
        sort: '',
        order: 'DESC',
        is_kit: -1,
        limit: 20,
        offset: 0
      },
      allProduct: [],
      selected: [],
      searchProduct: [],
      existingItems: [],
      orderID: '',
      windowWidth: 0,
      checkWidth: 0,
      searchTxt: '',
      start: 0,
      end: 10,
      quickScanToolTip: false
    };
    window.addEventListener('resize', this.update);
    this.tooltipRef = React.createRef();
    this.searchField = React.createRef();
  }
  update = () => {
    this.setState({
      windowWidth: window.innerWidth
    });
  };
  componentDidMount() {
    debugger
    this.props.hideSaveFun();
    this.update();
    let orderID =
      this.props?.orderDetail &&
      this.props?.orderDetail?.items &&
      this.props?.orderDetail?.items[0]?.iteminfo?.order_id;
      
      if (orderID > 0) {
        let existingItems = [];
        this.props?.items?.items.map((item, index) => {
          existingItems.push(item.productinfo.id);
        });
        update = true;
        this.setState({existingItems});
        this.props.GetAllProduct(this.state.getAllItem);
    }
    if (this.props?.items?.items?.length) {
      let sorted = this.props.items.items.sort(
        (a, b) =>
          Date.parse(a.iteminfo?.updated_at) -
          Date.parse(b.iteminfo?.updated_at)
      );
      this.setState({items: sorted});
    }
  }

  componentDidUpdate() {
    this.searchField.current.focus()
    if (update && this.state.allProduct !== this.props.allProduct) {
      debugger
      let updated = [];
      let indexLength = 0;
      if (this.props.allProduct && this.props.allProduct.length > 0) {
        let existing = this.state.existingItems;
        this.props.allProduct.filter((arrayItem, index) => {
          existing.includes(arrayItem.id) ? null : updated.push(arrayItem);
          indexLength = index;
        });
        if (
          this.props.allProduct &&
          this.props.allProduct.length === indexLength + 1
        ) {
          this.setState({allProduct: updated});
          update = false;
        }
      }
    }
    if (addItemSuccess && this.props.addOrderItems) {
      let id = this.props?.items?.items[0]?.iteminfo?.order_id;
      this.props.navigation.navigate('OrderDetail', {
        data: id,
        from: 'UpdateOrder'
      });
      addItemSuccess = false;
    }
    if (
      updateSearch === false &&
      this.props.searchProduct &&
      this.state.searchProduct !== this.props.searchProduct
    ) {
      let temp = this.state.searchData;
      this.setState({
        allProduct: this.props.searchProduct,
        searchProduct: this.props.searchProduct,
        temp
      });
      update = false;
      updateSearch = true;
    }
  }

  submitItems() {
    let orderID =
      this.props?.items &&
      this.props?.items?.items &&
      this.props?.items?.items[0]?.iteminfo.order_id;
    if (orderID && this.state.selected.length > 0) {
      let data = {
        productids: this.state.selected,
        qty: 1,
        on_ex: 'on GPX'
      };

      this.props.AddOrderItems(orderID, data);
      addItemSuccess = true;
    }
  }

  itemSelect(item) {
    let selected = this.state.selected;
    var check = selected.includes(item.id);
    if (check) {
      const index = selected.findIndex(selected => selected === item.id);
      selected.splice(index, 1);
    } else {
      selected.push(item.id);
    }
    this.setState({selected});
  }

  searchItem() {
    let search = this.state.searchData;
    if (search.search !== '') {
      this.props.GetSearchProduct(search);
      updateSearch = false;
    }
  }

  handleChange(text) {
    let searchData = this.state.searchData;
    searchData['search'] = text;
    this.setState({searchData});
  }

  componentWillUnmount() {
    this.props.hideSaveFun();
    update = true;
    this.setState({allProduct: [], searchProduct: []});
  }
  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  render() {
    console.log("first",this.state.allProduct)
    return (
      <View>
        <SaveItemToOrder
        onPress={() => {
              {
                this.state.selected.length
                  ? this.submitItems()
                  : this.props?.onchange();
              }
            }}
         onCancel={() => this.props?.onchange()}
        />
        {this.state.windowWidth <= 380 ? (
          <View
            style={CommonStyles.toOrderLinkView}
          >
            <View
              style={{
                textAlign: 'center',
                justifyContent: 'center'
              }}
            >
              <Text
                style={{
                  fontSize: 21,
                  fontFamily: 'Poppins_600SemiBold',
                  color: 'white'
                }}
              >
                Search
                <i
                  className="icon-search"
                  style={{
                    color: 'white',
                    flexDirection: 'row',
                    resizeMode: 'contain',
                    marginTop: '2%',
                    fontSize: '19px'
                  }}
                ></i>
                or Scan
                <i
                  className="icon-barcode"
                  style={{color: 'white', marginTop: '2%', fontSize: '19px'}}
                ></i>
                to Add items
              </Text>
            </View>
          </View>
        ) : (
          <View
            style={CommonStyles.addItemContainer}
          >
            <Text
              style={{
                fontSize: 21,
                fontFamily: 'Poppins_600SemiBold',
                color: 'white'
              }}
            >
              Search
              <i
                className="icon-search"
                style={{
                  color: 'white',
                  flexDirection: 'row',
                  resizeMode: 'contain',
                  marginTop: '2%',
                  fontSize: '19px'
                }}
              ></i>
              or Scan
              <i
                className="icon-barcode"
                style={{color: 'white', marginTop: '2%', fontSize: '19px'}}
              ></i>
              to Add items
            </Text>
          </View>
        )}
        <View
          style={CommonStyles.orderListStyle}
        >
          <View style={{width: '90%', marginLeft: 50, flexDirection: 'row'}}>
            <TextInput
              placeholder="Type order to search"
              name="searchOrder"
              autoFocus={true}
              ref={this.searchField}
              value={this.state.searchData && this.state.searchData.search}
              onChangeText={text => {
                this.handleChange(text), this.setState({searchTxt: text});
              }}
              onSubmitEditing={e => this.searchItem(e)}
              style={CommonStyles.typeOrderInput}
            />
            <TouchableOpacity
              testID='searchItem'
              onPress={() => this.searchItem(this.state.searchTxt)}
              style={{
                flexDirection: 'row',
                marginLeft: 10,
                marginTop: 5.5
              }}
            >
              <i
                className="icon-search"
                style={{
                  color: 'rgb(172,187,199)',
                  marginLeft: -50,
                  fontSize: '19px'
                }}
              ></i>
            </TouchableOpacity>
            <TouchableOpacity
              testID='quickScanToolTipBtn'
              onPress={() =>
                this.setState({
                  quickScanToolTip: !this.state.quickScanToolTip
                })
              }
              style={{
                flexDirection: 'row',
                marginLeft: 10
              }}
            >
              <i
                className="icon-info-circled"
                style={{
                  color: 'rgb(172,187,199)',
                  marginTop: '41%',
                  fontSize: '17px'
                }}
              ></i>
            </TouchableOpacity>
          </View>
        </View>
        {this.state.quickScanToolTip && (
          <LinearGradient
          testID='quickScanToolTip'
            locations={[0, 1]}
            colors={['#8a9daf', '#d7e1e9']}
            style={CommonStyles.quickScanToolTip2}
          >
            <Text
              style={CommonStyles.quickScanTextOne}
            >
              Multiple items can be added to an order at once. After searching
              for an item by it's name, sku or barcode use the checkbox to
              select it. Additional selections can be made the same way. Once
              all required items are selected click "Save & Close" to add them
              to the order.
            </Text>
          </LinearGradient>
        )}

        {this.state.windowWidth >= 900 ? (
          <View
            style={[CommonStyles.orderListMobile, {paddingLeft: 10, marginHorizontal: 0}]}
          >
            <View style={[styles.itemContainer]}>
              <View
                style={[CommonStyles.itemDetailHeading, {width: '100%'}]}
              >
                <View style={[styles.addItemView, {width: '5%'}]}>
                  <Image
                    style={{
                      width: 35,
                      height: 35,
                      resizeMode: 'contain'
                    }}
                    source={require('../../../assets/tick01.png')}
                  />
                </View>
                <View style={{width: '95%', flexDirection: 'row'}}>
                  <View style={[styles.addItemView, {width: '25%'}]}>
                    <Text style={styles.gridHeader2}>SKUuuuuu</Text>
                  </View>

                  <View style={[styles.addItemView, {width: '25%'}]}>
                    <Text style={[styles.gridHeader2]}>Product Name</Text>
                  </View>

                  <View
                    style={[
                      styles.addItemView,
                      {
                        width: '15%'
                      }
                    ]}
                  >
                    <Text style={styles.gridHeader2}>QOH</Text>
                  </View>

                  <View style={[styles.addItemView, {width: '15%'}]}>
                    <Text style={styles.gridHeader2}>Status</Text>
                  </View>
                  <View style={[styles.addItemView, {width: '25%'}]}>
                    <Text style={styles.gridHeader2}>Barcode</Text>
                  </View>
                </View>
              </View>
            </View>

            {this.state.allProduct.length > 0 && (
              <View>
                {this.state.allProduct &&
                  this.state.allProduct.length > 0 &&
                  this.state.allProduct.map((item, index) => {
                    return (
                      <TouchableOpacity
                        testID='selectItem'
                        key={index}
                        onPress={() => {
                          this.itemSelect(item);
                        }}
                        delayLongPress={1000}
                      >
                        <View style={[styles.itemContainer]}>
                          <View
                            style={[CommonStyles.itemDetailHeading, {alignItems: 'center'}]}
                          >
                            {this.state.selected.includes(item.id) ? (
                              <FontAwesome
                                name="dot-circle-o"
                                style={{resizeMode: 'contain'}}
                                size={32}
                                color="rgba(250, 250, 250, .4)"
                              />
                            ) : (
                              <FontAwesome
                                name="circle-o"
                                style={{resizeMode: 'contain'}}
                                size={32}
                                color="rgba(250, 250, 250, .4)"
                              />
                            )}

                            <View
                              style={CommonStyles.circleStyle}
                            >
                              <View
                                style={[styles.addItemView, {width: '25%'}]}
                              >
                                <Text
                                  style={[styles.itemName2, {fontSize: 16}]}
                                >
                                  {item.sku}
                                </Text>
                              </View>

                              <View
                                style={[styles.addItemView, {width: '25%'}]}
                              >
                                <Text
                                  style={[styles.itemName2, {fontSize: 16}]}
                                >
                                  {item.name}
                                </Text>
                              </View>

                              <View
                                style={[
                                  styles.addItemView,
                                  {
                                    width: '15%'
                                  }
                                ]}
                              >
                                <Text
                                  style={[styles.itemName2, {fontSize: 16}]}
                                >
                                  {item.qty_on_hand}
                                </Text>
                              </View>

                              <View
                                style={[styles.addItemView, {width: '15%'}]}
                              >
                                {item.status === 'active' ? (
                                  <Text
                                    style={[
                                      styles.statusActive,
                                      {fontSize: 16}
                                    ]}
                                  >
                                    {this.Capitalize(item.status)}
                                  </Text>
                                ) : (
                                  <Text
                                    style={[styles.statusNew, {fontSize: 16}]}
                                  >
                                    {this.Capitalize(item.status)}
                                  </Text>
                                )}
                              </View>

                              <View
                                style={[styles.addItemView, {width: '25%'}]}
                              >
                                <Text
                                  style={[styles.itemName2, {fontSize: 16}]}
                                >
                                  {item.barcode}
                                </Text>
                              </View>
                            </View>
                          </View>
                        </View>
                      </TouchableOpacity>
                    );
                  })}
              </View>
            )}
          </View>
        ) : (
          <View
            style={[CommonStyles.orderListMobile]}
          >
            <View style={[styles.itemContainer]}>
              <View
                style={CommonStyles.addItemToOrderContainer}
              >
                <View style={[styles.addItemView, {width: '5%'}]}>
                  <Image
                    style={{
                      width: 30,
                      height: 30,
                      resizeMode: 'contain'
                    }}
                    source={require('../../../assets/tick01.png')}
                  />
                </View>
                <View
                  style={CommonStyles.addItemHeader}
                >
                  <View style={[styles.addItemView, {width: '25%'}]}>
                    <Text style={[styles.gridHeader2, {fontSize: 16}]}>
                      SKU
                    </Text>
                  </View>

                  <View style={[styles.addItemView, {width: '35%'}]}>
                    <Text
                      style={[
                        styles.gridHeader2,
                        {fontSize: 16, lineHeight: 13}
                      ]}
                    >
                      Product Name
                    </Text>
                  </View>

                  <View
                    style={[
                      styles.addItemView,
                      {
                        width: '10%'
                      }
                    ]}
                  >
                    <Text style={[styles.gridHeader2, {fontSize: 16}]}>
                      QOH
                    </Text>
                  </View>

                  <View style={[styles.addItemView, {width: '15%'}]}>
                    <Text style={[styles.gridHeader2, {fontSize: 16}]}>
                      Status
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <OrderItemList
              orderList= {this.state.allProduct}
              selected= {this.state.selected}
              onPress={(item) => this.itemSelect(item)}
              c1 = 'sku'
              c2 = 'name'
              c3 = 'qty_on_hand'
            />
          </View>
        )}
        {this.state.allProduct?.length > 20 ?
        <SaveItemToOrder
        onPress={() => {
              {
                this.state.selected.length
                  ? this.submitItems()
                  : this.props?.onchange();
              }
            }}
         onCancel={() => this.props?.onchange()}
        />:<></>}
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    allProduct: state.product.allProduct,
    addOrderItems: state.order.addOrderItems,
    searchProduct: state.product.searchProduct
  };
};

const mapDispatchToProps = {
  GetAllProduct,
  AddOrderItems,
  GetSearchProduct
};

export default connect(mapStateToProps, mapDispatchToProps)(addItemInBox);

// export default addItemInBox
