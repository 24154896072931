import {
  PRODUCT_DETAIL,
  PRODUCT_DETAIL_FAIL,
  UPDATE_PRODUCT_ALIAS,
  GET_ALL_PRODUCT,
  GET_SEARCH_PRODUCT,
  CLEAR_SEARCH_PRODUCT,
  UPDATE_PRODUCT_INFO,
  UPDATE_PRODUCT_INFO_FAIL,
  UPDATE_PRODUCT_LIST,
  UPDATE_PRODUCT_LIST_FAIL,
  GET_KIT_PRODUCT,
  ADD_IMAGE,
  ADD_HTML_IMAGE,
  UPDATE_PRODUCT_ALIAS_FAIL,
  ADD_IMAGE_FAIL,
  GET_KIT_PRODUCT_FAIL,
  GET_ALL_PRODUCT_FAIL,
  GET_SEARCH_PRODUCT_FAIL,
  IS_LOADING,
  IS_PRODUCT_CREATED,
  IS_PRODUCT_BARCODE_ADDED,
  CREATE_PRODUCT,
  ADD_PDF,
  GET_PDFS,
  IS_PRODUCT_UPDATED,
  IS_PRODUCT_LOADING
} from '../constants';

const initialState = {
  isProductCreated: true,
  isProductBarCodeAdded: true
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_DETAIL:
      return {...state, product: action.payload};
    case IS_LOADING:
      return {...state, isLoading: action.payload};
    case GET_KIT_PRODUCT:
      return {...state, productskit: action.payload};
    case GET_KIT_PRODUCT_FAIL:
      return {...state, productskit: action.payload};
    case PRODUCT_DETAIL_FAIL:
      return {...state, product: action?.payload};
    case GET_ALL_PRODUCT:
      return {...state, allProduct: action.payload};
    case GET_ALL_PRODUCT_FAIL:
      return {...state, allProduct: action.payload};
    case GET_SEARCH_PRODUCT:
      return {...state, searchProduct: action.payload};
    case CLEAR_SEARCH_PRODUCT:
      return {...state, searchProduct: undefined,product: undefined};
    case GET_SEARCH_PRODUCT_FAIL:
      return {...state, searchProduct: action.payload};
    case UPDATE_PRODUCT_INFO:
      return {...state, updateProduct: action.payload, product: action.payload.data.params};
    case UPDATE_PRODUCT_ALIAS:
      return {...state, updateProductAlias: action.payload};
    case UPDATE_PRODUCT_ALIAS_FAIL:
      return {...state, updateProductAlias: action.payload};
    case UPDATE_PRODUCT_INFO_FAIL:
      return {...state, updateProduct: action.payload, error: true};
    case UPDATE_PRODUCT_LIST:
      return {...state, updateProductList: action.payload};
    case UPDATE_PRODUCT_LIST_FAIL:
      return {...state, updateProductList: action.payload};
    case ADD_IMAGE:
      return {...state, AddImage: action.payload};
    case ADD_HTML_IMAGE:
      return {...state, addHtmlImage: action.payload};
    case ADD_IMAGE_FAIL:
      return {...state, AddImage: action.payload};
    case IS_PRODUCT_CREATED:
      return {...state, isProductCreated: action.payload, productId: undefined};
    case IS_PRODUCT_BARCODE_ADDED:
      return {...state, isProductBarCodeAdded: action.payload};
    case CREATE_PRODUCT:
      return {...state, isProductCreated: false, productId: action.payload};
    case ADD_PDF:
      return {...state, isPdfAdded: action.payload};
    case GET_PDFS: 
      return {...state, pdfsList: action.payload};
    case IS_PRODUCT_UPDATED:
      return {...state, isProductUpdated: action.payload};
    case IS_PRODUCT_LOADING:
      return {...state, isProductLoaded: action.payload};
    default:
      return state;
  }
};

export default productReducer;
