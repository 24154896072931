import React, { useEffect, useState } from 'react';
import {View,TouchableOpacity} from 'react-native';
import OrderButton from '../../commons/orderStatusButton';
import { fetchData, handlePrinterSelection, setQz } from '../../../qzservice';
import PrinterShow from './printerShow';
import PaperSettings from './paperSettings';
import AsyncStorage from '@react-native-async-storage/async-storage';

export default function PaperSettingsContainer({
  isPrinter,
  removeItem,
  switchLabel,
  pdfSize,
  handleEdit,
  isShipping,
  isReceiver
}) {
  const colors = {firstColor: '#FFB538', secondColor: '#CF7C08', textColor: 'black'};
  const [modalVisible, setModalVisible] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  const [printers, setPrinters] = useState([]);
  const [printer, setPrinter] = useState('');

  const [paperHeight, setPaperHeight] = useState(6);
  const [paperWeight, setPaperWeight] = useState(4);
  const [dpiValue, setDpiValue] = useState();

  const setDimensions=(dimensions) => {
    setPaperWeight(dimensions?.width);
    setPaperHeight(dimensions?.height);
    setDpiValue(dimensions?.dpi);
    setShowBtn(true);
  }

  const fetchPrinterInfo = async () => {
    const printerName = await isPrinter;
    const dimensionsJSON = await AsyncStorage.getItem(switchLabel+"_Dimensions");
    const dimensions = JSON.parse(dimensionsJSON);
    setDimensions(dimensions)
    if (printerName) {
      const updatedName = printerName.replace(/"/g, '');
      setPrinter(updatedName);
    }
    else{
      setShowBtn(false);
    }
  };

  useEffect(() => {
    fetchPrinterInfo();
  }, []);

  useEffect(() => {
    if(pdfSize !== undefined){
      let printSize = pdfSize?.split('x')
      setPaperWeight(printSize[0].trim());
      setPaperHeight(printSize[1].trim());
      savePrinterDimensions(printSize[0].trim(), printSize[1].trim())
    }
  }, [pdfSize]);

  const handleChange = (value, name) => {
    switch (name) {
      case 'paperHeight':
        setPaperHeight(value);
        savePrinterDimensions(paperWeight, value, dpiValue);
        break;
      case 'paperWeight':
        setPaperWeight(value);
        savePrinterDimensions(value,paperHeight , dpiValue);
        break;
      case 'dpiValue':
        setDpiValue(value);
        savePrinterDimensions(paperWeight, paperHeight, value);
        break;
      default:
        break;
    }
  };

  const handlePrinterSelect =async () =>{
    let data = await fetchData();
    if(data.printersList.length > 0){
      setPrinters(JSON.parse(JSON.stringify(data.printersList)))
      setModalVisible(true)
    }
  }

  const savePrinterDimensions = async (weight= paperWeight,height= paperHeight,dpi=dpiValue) => {
    try {
      const printerKey = `${switchLabel+'_Dimensions'}`;
      const defaultDimensions = {width: 4, height: 6, dpi: 0};
      const newDimensions = {width: weight, height: height, dpi: dpi };
      const dimensions = {
        width: newDimensions.width || defaultDimensions.width,
        height: newDimensions.height || defaultDimensions.height,
        dpi: newDimensions.dpi || defaultDimensions.dpi,
      };
  
      await AsyncStorage.setItem(printerKey, JSON.stringify(dimensions));
    } catch (error) {
      console.error('Error saving printer dimensions:', error);
    }
  };
  return (
    <>
    {modalVisible && (
      <PrinterShow
      dataPrinter={printers}
      handlePrinterSelection={async printer => {
        setPrinter(printer)
        let printerState = await setQz(
          printer,
          switchLabel
        );
        setModalVisible(false)
        setShowBtn(true)
      }}
      closePrompt={() => {
        setModalVisible(false)
      }}
    />
    )}
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 5,
        paddingVertical: 10
      }}>
      {showBtn ?
      <>
      <TouchableOpacity testID='removeBtn' onPress={() => {
        setShowBtn(false)
        AsyncStorage.removeItem(printer)
        AsyncStorage?.removeItem(removeItem)
      }}>
        <i className="icon-cancel" style={{
          fontSize: 16,
          fontWeight: 300,
          color: '#e3dcdc'
        }}></i>
      </TouchableOpacity>
      <view style={{
        backgroundColor: '#e3dcdc',
        borderRadius: '25px',
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 5,
        paddingBottom: 5,
        fontFamily: 'Poppins_300Light'
      }}>
        {printer}
      </view>
      </> : 
       <OrderButton 
        firstColor ={colors.firstColor}
        secondColor = {colors.secondColor}
        label ={'Select Printer'}
        handleOnPress = {()=>{handlePrinterSelect()}}
        marginLeft = {-1}
       />
      }
    </View>
    <PaperSettings
        paperHeight={paperHeight}
        paperWeight={paperWeight}
        dpiValue={dpiValue}
        handleEdit={() => {handleEdit()}}
        isShipping={isShipping}
        isReceiver={isReceiver}
        handleChange={(value, name) => {
          handleChange(value, name)
       }}
        handleSubmit={() => {savePrinterDimensions()}}
      />
    </>
  );
}
