import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  QtyContainer: {
    margin: 10,
    marginTop: '2%',
    backgroundColor: '#rgba(104, 135, 156, 0.3)',
    borderWidth: 1,
    borderRadius: 10,
    border: 'none'
  },
  QtyContainerTypeOne: {
    margin: 10,
    backgroundColor: '#rgba(104, 135, 156, 0.3)',
    borderWidth: 1,
    borderRadius: 10,
    border: 'none'
  },
  costContainer:{
    margin: 10, 
    flexDirection: 'row'
  },
  costSubContainer:{
    flexDirection: 'column',
    width: '49%',
  },
  catContainer: {
    flexDirection: 'row',
    marginLeft: 1,
    marginTop: '7%'
  },
  catContainerTypeOne:{
    flexDirection: 'row',
    marginLeft: 5,
    marginTop: '12%',
    width: 120
  },
  catSubContainer: {
    flexDirection: 'row',
    width: 'auto',
    marginRight: 4
  },
  catSubContainerOne: {
    flexDirection: 'row',
    width: 'auto',
    marginRight: 10
  },
  crossButton:{
    position: 'absolute',
    top: '17%',
    right: '-2%'
  },
  crossButtonTypeOne:{
    position: 'absolute',
    top: '19%',
    right: '-4px'
  },
  alignItemsTypeOne:{
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  alignItemsTypeTwo:{
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2%'
  },
  imagesContainer:{
    flexDirection: 'row',
    marginLeft: 400,
    width: '40%',
    marginTop: '-60px'
  },
  addImageContainer:{
    flexDirection: 'row',
    height: 100,
    width: 190,
    marginRight: -200
  },
  imageStyle:{
    width: 80,
    height: 80,
    marginLeft: 30,
    borderWidth: 1,
    borderRadius: 8,
    borderBottomWidth: 0
  },
  textStyle:{
    width: '20%',
    color: '#cdcdcd',
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 18,
    right: '1%',
    top: '25%',
    position: 'absolute'
  },
  textStyleTypeOne:{
    color: 'white',
    fontFamily: 'Poppins_600SemiBold',
    textAlign: 'center',
    marginBottom: -15
  },
  textStyleTypeTwo:{
    color: 'white',
    marginLeft: -71,
    marginTop: 88,
    fontFamily: 'Poppins_600SemiBold'
  },
  textStyleTypeThree:{
    color: 'white',
    marginTop: '-18px',
    marginLeft: 25,
    fontFamily: 'Poppins_300Light',
    fontSize: 13
  },
  textStyleTypeFour:{
    color: 'white',
    marginLeft: 10,
    fontFamily: 'Poppins_300Light',
    fontSize: 13
  },
  textStyleTypeFive:{
    color: '#cdcdcd',
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 18
  },
  textInputStyle:{
    margin: 15,
    borderWidth: 1,
    border: 'none',
    marginTop: 3,
    height: 38,
    borderRadius: 10,
    padding: 10,
    backgroundColor: '#cdcdcd',
    color: '#1d3045',
    fontFamily: 'Poppins_500Medium',
    fontSize: 15
  },
  textInputStyleTypeOne:{
    borderWidth: 1,
    border: 'none',
    height: 31,
    borderRadius: 10,
    padding: 10,
    backgroundColor: '#cdcdcd',
    color: '#1d3045',
    width: '100%',
    fontFamily: 'Poppins_500Medium',
    fontSize: 15
  },
  iconStyle:{
    color: 'white', 
    height: 17, 
    width: 17
  },
  texAndIconContainer:{
    flexDirection: 'row',
    marginTop: 18,
    marginLeft: 25
  },
  texAndIconContainerTwo:{
    flexDirection: 'row',
    marginTop: 10,
    marginLeft: 25
  },
  iconContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 34,
    marginTop: '3%'
  },
  scanQty:{
    position: 'absolute',
    right: -60,
    marginTop: '4%'
  },
  skuContainer:{
    marginLeft: 14,
    marginRight: 10,
    width: '99%'
  },
  removeImageText:{
    color: 'white',
    marginLeft: '-118%',
    marginTop: '160%',
    fontFamily: 'Poppins_600SemiBold'
  },
  addImageText:{
    color: 'white',
    fontFamily: 'Poppins_600SemiBold',
    marginBottom: '-30px'
  }

})

export default styles;