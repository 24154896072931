import React from 'react';
import { TouchableOpacity, View, Text, StyleSheet, Dimensions } from 'react-native';
import { useDispatch } from 'react-redux';
import {FontAwesome} from '@expo/vector-icons';
import { MailOutOfStockReport, removeItem } from '../../helpers/suggestedMenuFunctions'; // Update the path accordingly

const { width: screenWidth } = Dimensions.get('window');

const SuggestedMenuMobile = (props) => {
  const dispatch = useDispatch();

  return (
    <>
        <View style={[styles.buttonContainer, { width: "auto", height: '58%' }]}>
          <View style={styles.shade} />
          <TouchableOpacity testID='OutOfStockReport' style={styles.button} onPress={() => {
            props.updateShowMenu()
            MailOutOfStockReport(props.order)
          }}>
            <Text style={styles.buttonText}>Report Out of Stock</Text>
            <i
                class="icon-comment"
                fontSize={24}
                style={{ color: '#fff' }}
            />
          </TouchableOpacity>
          <TouchableOpacity testID='RemoveItem' style={styles.button} onPress={() => {
            props.updateIsRemove();
            props.updateShowMenu();
            removeItem(props.order, dispatch)
          }}>
            <Text style={styles.buttonText}>Remove</Text>
            <FontAwesome name="bomb" size={20} color={'#fff'}></FontAwesome>
          </TouchableOpacity>
          <TouchableOpacity testID='CloseButton' style={[styles.button, { top: '70%', justifyContent: 'center' }]} onPress={props.updateShowMenu}>
            <Text style={styles.buttonText}>Close</Text>
          </TouchableOpacity>
        </View>
    </>
  );
};

const styles = StyleSheet.create({
  iconContainer: {
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    elevation: 5,
  },
  shade: {
    borderRadius: '7px',
    position: 'absolute',
    top: 1,
    bottom: '-72%',
    left: 1,
    right: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // semi-transparent background
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '80%',
    padding: 15,
    marginVertical: 10,
    backgroundColor: 'rgb(61, 72, 81)',
    borderRadius: 5,
    alignItems: 'center',
  },
  buttonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
  },
});

export default SuggestedMenuMobile;
