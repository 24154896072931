import React from "react";
import { View, Text, StyleSheet, } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { fontFamily } from "../../helpers/fontFamily";
const TollTip = ({ message,right, top, iconRight, testID }) => {
  return (
    <LinearGradient
      testID={testID}
      locations={[0, 1]}
      colors={['#8a9daf', '#d7e1e9']}
      style={[styles.container,{right: right, top: top}]}
    >
      <Text
        style={styles.Text}
      >
        {message}
      </Text>
      <View
        style={[styles.tollTipIcon,{right: iconRight}]}
      />
    </LinearGradient>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
    position: 'absolute',
    zIndex: 99,
    borderRadius: 5,
    width: 300,
    alignSelf: 'flex-end'
  },
  Text: {
    fontSize: 20,
    color: '#030303'
  },
  tollTipIcon: {
    position: 'absolute',
    top: -10,
    borderLeftWidth: 10,
    borderLeftColor: 'transparent',
    borderRightWidth: 10,
    borderRightColor: 'transparent',
    borderBottomWidth: 10,
    borderBottomColor: '#8d9fb1'
  }
})

export default TollTip;