import React from 'react';
import {AntDesign, Entypo, FontAwesome} from '@expo/vector-icons';
import {TouchableOpacity, View, Text} from 'react-native';
import styles from './styles';
import {LinearGradient} from 'expo-linear-gradient';
import {fontFamily} from '../../helpers/fontFamily';

const ProductList = ({products, onSelectItem, navigation}) => {
  return (
    <LinearGradient
      colors={[
        '#000',
        'rgba(37, 60, 87,1)',
        'rgba(92, 119, 143,1)',
        'rgba(37, 60, 87,1)',
        '#000'
      ]}
      start={[-0.5, -1]}
      end={[1, 1]}
      locations={[0, 0.2, 0.5, 0.8, 0.9]}
      style={{
        flex: 1,
        backgroundColor: 'rgba(92, 119, 143,1)'
      }}
    >
      <View style={styles.container}>
        <TouchableOpacity onPress={navigation.goBack}>
          <AntDesign
            name="arrowleft"
            size={30}
            color="#778fa5"
            style={{fontWeight: 'bold'}}
          />
        </TouchableOpacity>
        <View
          style={{
            backgroundColor: 'rgba(15, 39, 58, 0.4)',
            marginBottom: 15,
            paddingHorizontal: 15,
            borderRadius: 15,
            paddingBottom: 50,
            minHeight: 200
          }}
        >
          <HeaderList />
          {products.map(item => (
            <ItemRow
              key={item.id}
              sku={item.sku}
              name={item.name}
              qoh={item.qty_on_hand}
              status={item.status}
              barcode={item.barcode}
              onPress={() => onSelectItem(item.id)}
            />
          ))}
        </View>
      </View>
    </LinearGradient>
  );
};
const HeaderList = () => {
  return (
    <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
      <View style={{width: '5%'}}>
        <Entypo name="check" size={24} color="#778fa5" />
      </View>
      <View style={{width: '19%'}}>
        <Text adjustsFontSizeToFit style={styles.headerText}>
          SKU
        </Text>
      </View>
      <View style={{width: '19%'}}>
        <Text adjustsFontSizeToFit style={styles.headerText}>
          Product Name
        </Text>
      </View>
      <View style={{width: '19%'}}>
        <Text adjustsFontSizeToFit style={styles.headerText}>
          QOH
        </Text>
      </View>
      <View style={{width: '19%'}}>
        <Text style={styles.headerText}>Status</Text>
      </View>
      <View style={{width: '19%'}}>
        <Text adjustsFontSizeToFit style={styles.headerText}>
          Barcode
        </Text>
      </View>
    </View>
  );
};
const ItemRow = ({sku, name, qoh, status, barcode, onPress}) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderBottomWidth: 0.5,
        borderBottomColor: 'white',
        paddingHorizontal: 5
      }}
    >
      <View style={{width: '5%', paddingVertical: 3, justifyContent: 'center'}}>
        <FontAwesome
          name="circle-o"
          style={{resizeMode: 'contain'}}
          size={24}
          color="rgba(250, 250, 250, .4)"
        />
      </View>
      <View
        style={{width: '19%', justifyContent: 'center', paddingVertical: 3}}
      >
        <Text adjustsFontSizeToFit style={styles.itemText}>
          {sku}
        </Text>
      </View>
      <View
        style={{width: '19%', justifyContent: 'center', paddingVertical: 3}}
      >
        <Text adjustsFontSizeToFit style={styles.itemText}>
          {name}
        </Text>
      </View>
      <View
        style={{width: '19%', justifyContent: 'center', paddingVertical: 3}}
      >
        <Text adjustsFontSizeToFit style={styles.itemText}>
          {qoh}
        </Text>
      </View>
      <View
        style={{width: '19%', justifyContent: 'center', paddingVertical: 3}}
      >
        <Text
          adjustsFontSizeToFit
          style={[
            styles.itemText,
            {
              color: status === 'active' ? '#7bfac3' : '#eb53fa',
              fontFamily: fontFamily.font600
            }
          ]}
        >
          {status.toUpperCase()}
        </Text>
      </View>
      <View
        style={{width: '19%', justifyContent: 'center', paddingVertical: 3}}
      >
        <Text adjustsFontSizeToFit style={styles.itemText}>
          {barcode}
        </Text>
      </View>
    </TouchableOpacity>
  );
};
export default ProductList;
