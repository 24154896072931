import React from 'react';
import { View, Text } from 'react-native';
import ProductInput from '../../commons/Inputs/productInfoInput';
import styles from '../../style/productdetail';
const CommonFunction = ({
  onChangeText,
  editable,
  value,
  label,
  right,
  textLeft
}) => {
  let marginLeft = textLeft  ? 17 : 14;
  let testID = textLeft ? 'infoInput' : 'categoryInput';
  return (
    <View style={[styles.viewGap,{marginLeft: right ? marginLeft : ''}]} >
      <Text
        style={[styles.productInfoText,{marginLeft: textLeft ? 17 : ''}]}
      >
        {label}
      </Text>
      <ProductInput
        style={[styles.productInfoInput, { width: '100%' }]}
        onChangeText={(text) => { onChangeText(text) }}
        editable={editable}
        value={value}
        id = {testID}
      />
    </View>
  )
}
export default CommonFunction;

export const navigateIfNeeded = (props, state) => {
  if (
    props?.route?.params?.data?.from === 'productDetailPage' &&
    state.productSearchData.length === 0 &&
    props.isProductCreated
  ) {
    props.navigation.navigate('ScanPack');
  }
};