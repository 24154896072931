import AsyncStorage from '@react-native-async-storage/async-storage';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useFocusEffect} from '@react-navigation/core';
import {
  GetProductDetail,
  GetSearchProduct,
  SetIsProductBarCodeAdded,
  CreateProduct
} from '../../actions/productAction';
import scanFailSound from '../../../assets/sounds/scan_fail_shorter.mp3';
import notReady from '../../../assets/sounds/not_ready.mp3';
import { GetBothSettings } from '../../actions/userAction';
import { processBarCode } from '../scanpack/scanBarcodeFunctions';

const alertSound = new Audio(notReady);
const tooltipText = [
  {
    id: 'header-info-1',
    description:
      'The Workflow setting allows you to choose which product fields will be updated while receiving inventory so you can update many products quickly. Here is a description of each workflow:'
  },
  {
    id: 'tooltip-receive-1',
    description:
      'Enter Quantity Received \nAfter scanning the product you’ll type in the total quantity received. The quantity you enter will be added to the existing QOH (quantity on hand) and the total will be your new QOH.'
  },
  {
    id: 'tooltip-receive-2',
    description:
      'Enter Location\nAfter scanning the product you can type or scan a location for the item. This allows you to quickly save a location for items you are receiving.'
  },
  {
    id: 'tooltip-receive-3',
    description:
      'Enter Quantity Received, Then Enter Location\nThis option combines the first two options allowing you to first type in a quantity and then enter a location for each item.'
  },
  {
    id: 'tooltip-receive-4',
    description:
      'Scan Each Piece Received\nThis option allows you to scan each piece received and use the scanner to count the total pieces received. When you are done scanning every piece you can save the total which will be added to the existing QOH to give the new QOH.'
  },
  {
    id: 'tooltip-receive-5',
    description:
      'Scan Each Piece Received, Then Enter Location\nThis option allows you to scan each piece as in the option above. After the new QOH is saved you’ll have an opportunity to enter a location for the item.'
  }
];
const useReceiveScan = ({navigation}) => {
  const dispatch = useDispatch();
  const store = useSelector(state => state.product);
  const settingcheck = useSelector(
    state => state?.user?.bothSettings?.data?.data?.scanpack_setting
  );
  const user = useSelector(state => state.user.userInfo);
  const isUnMounted = useSelector(state => state.menu.isUnMounted);
  const [isWorkflowContainerVisible, setWorkflowContainerVisible] =
    useState(false);
  const [selectedWorkFlow, setSelectedWorkflow] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [oldBarCode, setOldBarCode] = useState('');
  const [showModal, setShowModal] = useState('');
  const searchRef = useRef();
  const [isInvalidBarcode, setIsInvalidBarcode] = useState(false);
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  useEffect(() => {
    let mounted = true;
    dispatch(GetBothSettings());
    (async () => {
      const receiveWorkflow = await AsyncStorage.getItem('receive_workflow');
      if (receiveWorkflow)
        mounted && setSelectedWorkflow(JSON.parse(receiveWorkflow));
      else mounted && setSelectedWorkflow(workflowOptions[0]);
    })();
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (searchTerm) {
      setIsInvalidBarcode(false);
      if (store.searchProduct) {
        if (store.searchProduct.length === 1) {
          dispatch(GetProductDetail(store.searchProduct[0].id));
        } else if (store.searchProduct.length > 1) {
          navigation.navigate('ProductListReceive', {selectedWorkFlow});
        } else {
          setIsInvalidBarcode(true);
          setOldBarCode(searchTerm);
          if (user.role.add_edit_products) {
            setShowModal(true);
          }
          setSearchTerm('');
          alertSound.play();
        }
      } else {
        setIsInvalidBarcode(true);
        setOldBarCode(searchTerm);
        if (user.role.add_edit_products) {
          setShowModal(true);
        }
        setSearchTerm('');
        alertSound.play();
      }
    }
  }, [store.searchProduct]);

  useEffect(() => {
    if (searchTerm)
      if (store.product) {
        setSearchTerm('');
        navigation.navigate(selectedWorkFlow.screen, {
          steps: selectedWorkFlow.steps
        });
        setShowModal(false);
      }
  }, [store.product]);

  useEffect(() => {
    if (selectedWorkFlow) {
      AsyncStorage.setItem(
        'receive_workflow',
        JSON.stringify(selectedWorkFlow)
      );
    }
  }, [selectedWorkFlow]);

  useFocusEffect(() => {
    searchRef.current.focus();
  });

  const setFocus = () => {
    searchRef.current.focus();
  };

  useEffect(() => {
    setFocus();
  }, [isUnMounted]);

  const workflowOptions = useMemo(() => [
    {
      label: 'Enter Quantity Received',
      steps: ['quantity'],
      screen: 'ProductReceive'
    },
    {
      label: 'Enter Location',
      steps: ['location'],
      screen: 'ProductReceive'
    },
    {
      label: 'Enter Quantity Received, Then Enter Location',
      steps: ['quantity', 'location'],
      screen: 'ProductReceive'
    },
    {
      label: 'Scan Each Piece Received',
      steps: ['count'],
      screen: 'CountReceive'
    },
    {
      label: 'Scan Each Piece Received, Then Enter Location',
      steps: ['count', 'location'],
      screen: 'CountReceive'
    }
  ]);
  const searchProduct = () => {
    let updateBarcode  
    if (settingcheck?.escape_string_enabled) {
      const { barCodeWithoutSpaces } = processBarCode(searchTerm, settingcheck);
      updateBarcode = barCodeWithoutSpaces
    }    
    else {
      updateBarcode = searchTerm
    }
    if (updateBarcode) {
      const data = {
        search: updateBarcode,
        sort: '',
        order: 'DESC',
        is_kit: 0,
        limit: 20,
        offset: 0
      };
      dispatch(GetSearchProduct(data));
    }
  };

  const toggleTooltip = () => {
    setWorkflowContainerVisible(false);
    setTooltipVisible(!isTooltipVisible);
  };

  const goBack = useCallback(() => {
    navigation.navigate('ScanReceive');
  }, []);

  const goToNewProductWithBarcode = () => {
    dispatch(CreateProduct());
    const page = 'ScanReceive';
    navigation.navigate('ProductDetail', {
      page,
      barCode: oldBarCode,
      oncomeBack: goBack
    });
    setShowModal(false);
    dispatch(SetIsProductBarCodeAdded(true));
  };

  return {
    workflowOptions,
    isWorkflowContainerVisible,
    setWorkflowContainerVisible,
    selectedWorkFlow,
    setSelectedWorkflow,
    searchProduct,
    searchTerm,
    setSearchTerm,
    oldBarCode,
    showModal,
    setFocus,
    setShowModal,
    goToNewProductWithBarcode,
    searchRef,
    isInvalidBarcode,
    toggleTooltip,
    isTooltipVisible,
    setTooltipVisible,
    tooltipText
  };
};
export default useReceiveScan;
