import React,{useState,forwardRef, useEffect} from 'react';
import { TextInput,Platform } from 'react-native';

const Input = (props,ref) => {
  const [show, setShow] = useState(false);
  const [barCode, setBarCode] = useState('')
  let onSubmitCalled = false;
  const handleSubmit = () => {
      onSubmitCalled = true
      props.onChangeText(barCode);
      props.onSubmitEditing();
      setBarCode('');
      props.onChangeValue('');
  }
  const handleKeyPress = (e) => {
    if(e.key === 'Enter'){
        if(window.navigator.platform === 'iPhone' || Platform.OS === 'ios') {
          if(barCode === '' || barCode === '*'){
            handleSubmit()
          }
        }
        else {
          handleSubmit()
        }
    }
  }
  useEffect(()=>{
    setBarCode('')
  },[props.isClicked,props.isPassButtonPressed])
  return(
    <TextInput
    //  name = {props.name}
     ref={ref}
     value={barCode}
     onChangeText={(text)=>{
      setBarCode(text);
      props.onChangeValue(text);
     }}
     onSubmitEditing={()=>{!onSubmitCalled && handleSubmit()}}
     onFocus={()=>{
      props.onFocus();
      setShow(!show)
     }}
     autoFocus={true}
     placeholder={props.placeholder}
     placeholderTextColor={props.placeholderTextColor}
     onBlur={props.onBlur}
     autoCapitalize='none'
     onKeyPress={handleKeyPress}
     style={props.style}
    />
  )
}
export default forwardRef(Input);