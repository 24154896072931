import React, { Component, useRef } from 'react';
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Platform,
  Dimensions,
} from 'react-native';
import styles from '../../style/scanpack';
import promptStyle from '../../style/scanpack';
import '../scanpack/addBar.css';
import { LinearGradient } from 'expo-linear-gradient';
import StyledInput from '../../commons/Inputs/stylesInput';

export default class TypeScanAlert extends Component {
  constructor(props) {
    super(props);
    this.typeScanFocus = React.createRef();
    this.state = {
      windowWidth: Dimensions.get('window').width
    };
  }
  updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentWillUnmount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentDidUpdate() {
    this.typeScanFocus.current.focus();
  }

  render() {
    return (
      <View style={[styles.responseView]} dataSet={{ componentName:'typeInCountPrompt'}}>
        <View style={[this.state.windowWidth >= 900 ? styles.typeScanContainer : "", styles.typeInCountNew, this.state.windowWidth <= 900 ? styles.recordSerialMobile : ""]}>
          <LinearGradient
            testID='typeScanAlertContainer'
            locations={[0, 1]}
            start={{ x: 0, y: 1 }}
            end={{ x: 0, y: 0 }}
            colors={['#d47d02', '#ffb636']}
            style={[
              styles.typeRecordBox,
              {
                top: this.state.windowWidth >= 900 ? '4%' : '0%',
                width: this.state.windowWidth >= 900 ? '50%' : '97%',
                right: this.state.windowWidth >= 900 ? "" : "-1%",
                backgroundColor: '#b7b7b7',
                padding: 18,
                borderColor: '#e8971a',
                borderWidth: 3,
                boxShadow: '2px 2px 10px #363636'
              }
            ]}>
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 5
              }}
            >
              <Text style={styles.scanCountText}>Type-In Count</Text>
              <TouchableOpacity
                testID='cancelIcon'
                style={{ position: 'absolute', right: 2, top: -5 }}
                onPress={this.props.closeAlert}
              >
                <i
                  className="icon-cancel"
                  style={{ fontSize: '34px', marginLeft: '10%' }}
                ></i>
              </TouchableOpacity>
            </View>
            {/* </View> */}
            <View style={{ display: 'flex', alignItems: 'center', marginTop: 15 }}>
              <StyledInput
                className="borderInput"
                style={[promptStyle.promptTextIn, styles.typeScanCountInput, { border: 'none' }]}
                value={this.props.typeScanCount}
                onChangeText={this.props.typeScanCountUpdate}
                autoFocus={true}
                ref={this.typeScanFocus}
                onSubmitEditing={
                  this.props.typeScanCount !== '' ? this.props.submitTypeScan : ''
                }
                returnKeyType={Platform.OS === 'android' ? 'done' : 'next'}
              // style={styles.typeScanCountInput}
                inputStyle={{height: '100%',fontSize: 20,padding: 10}}
                useInputStyle= {false}
              />
            </View>
            <View style={{ alignItems: 'center', marginTop: 32 }}>
              <TouchableOpacity
                testID='enterButton'
                onPress={
                  this.props.typeScanCount !== '' ? this.props.submitTypeScan : ''
                }
                style={styles.scanCountSubmitBtnBox}
              >
                <Text style={styles.scanCountSubmitBtn}>Enter</Text>
              </TouchableOpacity>
            </View>
          </LinearGradient>
        </View>
      </View>
    );
  }
}
