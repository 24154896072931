import Dexie from 'dexie';

// Name of Index DB
let db_name = 'ReactExpoDB';
if (process.env.NODE_ENV === 'test') {
  db_name = 'ReactExpoDBTest';
}

// Configuring Index DB
export const db = new Dexie(db_name);

db.version(1).stores({
  orders:
    '++id, tenant, user_id, order_id, order_data, flag, created_at, updated_at'
});

export const createOrUpdateOrder = async attributes => {
  try {
    let {order_id, tenant, user_id} = attributes || {};
    let order = await db.orders
      .where({order_id: order_id, tenant: tenant, user_id: user_id})
      .first();

    if (order) {
      await db.orders.update(order.id, {...attributes, updated_at: new Date()});
    } else {
      await db.orders.add({
        ...attributes,
        created_at: new Date(),
        updated_at: new Date()
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const fetchOrderWithOrderID = async id => {
  let order = await db.orders.where('order_id').equals(id).first();
  return order;
};

export const fetchOrders = async () => {
  const orders = await db.orders.toArray();
  return orders;
};

export const fetchCompletedOrdersLocally = async (user_id, tenant) => {
  const orders = await db.orders
    .where({tenant: tenant, user_id: user_id, flag: 0})
    .toArray();
  return orders;
};

export const updateOrdersFlag = async (
  tenant,
  user_id,
  order_id,
  attributes
) => {
  await db.orders
    .where({order_id: order_id, tenant: tenant, user_id: user_id})
    .modify(attributes);
};

export const updateOrderAsFlagZero = async () => {
  await db.orders.toCollection().modify({flag: 0});
};

export const deleteOrder = async id => {
  await db.orders.delete(id);
};
