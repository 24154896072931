// CustomText.js

import React from 'react';
import { Text } from 'react-native';
import colors from './commonColors'; // Update the path based on your project structure
import styles from "../style/orderdetail";

const CustomText = ({ status, item }) => {
  const statusColor = colors[item.status];
  const Capitalize = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };

  return (
    <Text style={[styles.statusNew, { color: statusColor, fontSize: 16 }]}>
      {Capitalize(status)}
    </Text>
  );
};

export default CustomText;
