import React from "react";
import { View, Text, StyleSheet, } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { fontFamily } from "../../helpers/fontFamily";
const TollTip = ({ message,isMobile }) => {
  return (
    <LinearGradient
      testID='ToolTip'
      locations={[0, 1]}
      colors={['#8a9daf', '#d7e1e9']}
      style={[styles.container,isMobile && {right: '-23%'}]}
    >
      <Text
        style={styles.Text}
      >
        {message}
      </Text>
      <View
        style={[styles.tollTipIcon,isMobile && {left: '90%'}]}
      />
    </LinearGradient>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
    position: 'absolute',
    zIndex: 99,
    top: 40,
    borderRadius: 5,
    width: 300,
  },
  Text: {
    fontFamily: fontFamily.font400,
    fontSize: 14,
    color: '#030303'
  },
  tollTipIcon: {
    position: 'absolute',
    left: '3%',
    top: -10,
    borderLeftWidth: 10,
    borderLeftColor: 'transparent',
    borderRightWidth: 10,
    borderRightColor: 'transparent',
    borderBottomWidth: 10,
    borderBottomColor: '#8d9fb1'
  }
})

export default TollTip;