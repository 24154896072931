import React from 'react';
import {
    View,
    Text
} from 'react-native';
import styles from '../../style/scanpack';
const AliasHeader = ({width,message1, message2, message3}) =>{
    return (
        <View
         style={styles.aliasSubBox}
        >
            <View
            style={[styles.aliasHeadingContainer,width < 900 ? styles.aliasHeadingPadding: '',{
                width: width > 900 ? '80%' : '100%'
            },
            ]}
            >
                <Text
                 style={styles.aliasTextHeading}
                >
                    {message1}
                </Text>
                <Text
                 style={[styles.aliasText,{
                    width: width > 900 ? '90%' : '100%'
                 }]}
                >
                    {message2}
                </Text>
                <Text
                 style={[styles.aliasText,{
                    width: width > 900 ? '90%' : '100%'
                 }]}
                >
                    {message3}
                </Text>
            </View>
        </View>
    )
}
export default AliasHeader;