import React from 'react';
import PassButton from '../../commons/passButton'; // Import the PassButton component
import ScanAllButton from '../../commons/scanAllButton';
import { checkUnScannedSku } from './scanBarcodeFunctions';

const ScanButtonContainer  = ({
    Order,
    popInternet,
    scanAllItem,
    windowWidth,
    setType,
    checkCount,
    scanAllOption,
    typeScanState,
    handlePassButton,
    isBarcodeEnter,
    setIsBarcodeEnter
  }) => {

  const shouldRenderPassButton =
    isBarcodeEnter === false ||
    scanAllOption === false ||
    checkUnScannedSku(Order, typeScanState) ||
    checkCount();

  const handleOnPress = (item, isShiftKey) =>{
    handlePassButton(item, isShiftKey);
  }

  const checkButtonDisabled = (Order) => {
    if (Order?.unscanned_items[0]?.child_items.length > 0) {
      return Order.unscanned_items[0].child_items[0].click_scan_enabled === 'on';
    }
    return Order?.unscanned_items[0]?.click_scan_enabled === 'on';
  };
  

  return (
    <>
      {(shouldRenderPassButton) ? (
        <PassButton
          orderItem={Order.unscanned_items[0]}
          order={Order}
          onPress={(item, isShiftKey = false) => {
            popInternet === true && (
              handleOnPress(item, isShiftKey)
            );
          }}
          onLongPress={scanAllItem}
          windowWidth={windowWidth}
        />
      ) : (
        <ScanAllButton
          onPress={() => {
            setType('scan_all_items');
            scanAllItem();
            checkButtonDisabled(Order) && setIsBarcodeEnter();
          }}
          order={Order}
          windowWidth={windowWidth}
          orderItem={Order.unscanned_items[0]}
        />
      )}
    </>
  )
};

export default ScanButtonContainer;
