import React, {useState} from 'react';
import {StyleSheet, Text, View, TouchableOpacity} from 'react-native';
import {LinearGradient} from 'expo-linear-gradient';
import {AntDesign} from '@expo/vector-icons';

const Tooltip = ({children, tooltipText, position = 'right', shipping = 'false', confirmation = 'false', ssWeight = 'false'}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <TouchableOpacity
      testID='toolTip'
      onMouseEnter={() => {
        setShowTooltip(true);
      }}
      onMouseLeave={() => {
        setShowTooltip(false);
      }}
      style={styles.btn}>
      <Text style={styles.btnText}>{children}</Text>

      {showTooltip && (
        <View
          testID='tooltipText'
          style={
            {...styles[position], position: 'absolute', 
              top: shipping === 'true' && position === 'top' && confirmation === 'false' ? '-90px' : 
               position ==='right' ? '0px' : 
               confirmation === 'true' ? '-94px' : '-50px'  }
          }>
          <AntDesign
            name={position === 'top' ? 'caretdown' : 'caretleft'}
            size={20}
            color="#8098ad"
            style={{
              zIndex: 1,
              alignSelf: position === 'top' ? 'end' : 'none',
              marginTop: position === 'top' ? '-10px' : '0px'
            }}
          />
          <LinearGradient
            locations={[1, 0]}
            colors={['#8098ad', '#5e7991']}
            style={{borderRadius: 5, marginLeft: -8, zIndex: 2,
              width: shipping === 'true' && confirmation === 'false' ? 300 : 
              shipping === 'true'? 225 : 150}}>
            <Text style={[styles.customTooltip,
              {lineHeight: ssWeight === 'true' ? '14px' : '17px'}]}>{tooltipText}</Text>
          </LinearGradient>
        </View>
      )}
    </TouchableOpacity>
  );
};

export default Tooltip;

const styles = StyleSheet.create({
  btnText: {
    fontSize: 13,
    paddingLeft: 1,
    paddingRight: 1,
    color: '#fff',
    textAlign: 'center',
    marginRight: 5
  },
  btn: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 2,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 5,
    borderRadius: 5,
    position: 'relative',
    zIndex: 100
  },
  customTooltip: {
    paddingHorizontal: 7,
    paddingVertical: 5,
    fontSize: 13,
    color: 'white'
  },
  top: {
    top: '-50px',
    left: 'auto',
    right:'44%',
    flexDirection:'column-reverse',
    alignContent: 'center',
    alignItems: 'center'
  },
  right: {
    top: 0,
    left: '88%',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center'
  }
});
