import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  alertContainer: {
    flex: 1,
    position: 'absolute',
    alignItems: 'center',
    top: 10,
    zIndex: 9999,
    width: '100%'
  },
  alertBox: {
    backgroundColor: '#ccc',
    opacity: 1,
    width: '95%',
    marginTop: 10,
    padding: 10,
    // borderRadius: 30,
    borderWidth: 2,
    borderColor: '#0003'
  },
  alerBoxPopup: {
    flex: 1,
    position: 'fixed',
    alignItems: 'center',
    top: "20%",
    zIndex: 9999,
    width: '100%'
  },
  alertBoxTracking: {
    backgroundColor: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 50,
    padding: 5,
    flex: 6,
    lineHeight: 20,
    margin: 10,
    // borderWidth: 1,
    borderRadius: 8
  },
  alertBoxShipping: {
    backgroundColor: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
    padding: 5,
    flex: 6,
    lineHeight: 20,
    margin: 10,
    // borderWidth: 1,
    borderRadius: 8
  },
  alertPermission: {
    flexDirection: 'row',
    position: 'fixed',
    backgroundColor: '#b7b7b7',
    padding: 5,
    borderRadius: 10,
    width: '90%',
    shadowColor: '#000'
  },
  alertMultiBox:{
    flexDirection: 'row',
    position: 'fixed',
    backgroundColor: '#b7b7b7',
    padding: 5,
    borderRadius: 10,
    width: '50%',
    shadowColor: '#000'
  },
  promptModel: {
    alignItems: 'center',
    justifyContent: 'center',
    top: '23%',
    padding: 5,
    borderColor: '#e8971a',
    boxShadow: '2px 2px 10px #363636',
    borderWidth: 3,
    borderRadius: 10
  },
  promptButton: {
    borderColor: 'black',
    borderWidth: 1,
    height: 23,
    width: 67,
    marginTop: '1%',
    borderRadius: 5,
    textAlign: 'center',
    justifyContent: 'center',
    marginBottom: 10,
  },
  promptTextIn: {
    margin: 15,
    borderWidth: 1,
    border: "none",
    // border: 'none',
    marginTop: '3px !important',
    height: '31px',
    borderRadius: 10,
    padding: 10,
    color: '#3e3c37',
    fontFamily: 'Poppins_500Medium',
    fontSize: 15,
    width: '120%'
  },
  alertText: {
    flex: 8,
    color: '#000',
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: 16
  },
  alertClose: {
    flex: 2,
    color: '#000',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 16
  },
  rescanOrderMainView:{
    backgroundColor: '#00000066',
    height: '100%',
    width: '100%',
    position: 'absolute',
    zIndex: 1,
  },
  linearGradMainView:{
    height:"205px",
    overflow: "auto",
    width: '60%',
    position: "absolute",
    top: '10%',
    left: '0',
    right: '0',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: '15px',
    padding: '10px',
    textAlign: 'center',
  },
  linearGradMainViewMobile:{
    backgroundColor: "rgb(255, 165, 0)",
    overflow: "auto",
    width: '85%',
    position: "absolute",
    top: '10%',
    height:"290px",
    left: '0',
    right: '0',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: '15px',
    padding: '10px',
    textAlign: 'center',
  },
  alreadyScanHead: {
    fontSize: 24,
    lineHeight: '36px',
    fontFamily: 'Poppins_300Light', 
    paddingBottom: 5
  },
  alreadyScanSubHead: {
    fontSize: 20,
    lineHeight: '30px',
    fontFamily: 'Poppins_300Light',
    paddingBottom: 5
  },
  buttonTextOrderRescan:{
    fontFamily:'Poppins_300Light',
    fontSize:"16px"
  },
  buttonBox: {
    display: 'inlineBlock',
    alignSelf: 'center',
    color: '#000000',
    backgroundColor: '#ffffff40',
    paddingVertical: '3px',
    paddingHorizontal: '10px',
    border: '1px solid #000',
    borderRadius: '15px',
    textAlign: 'center',
    fontSize: '18px',
    lineHeight: '27px',
    fontFamily: 'Poppins_300Light',
  },
  buttonMainView:{
    flexDirection: 'row',
    marginTop: '29px',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '57px'
  },
  buttonMainViewMobile:{
    flexDirection: 'column',
    marginTop: '29px',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '12px'
  }
});

export default styles;
