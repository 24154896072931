import React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import styles from "../../style/scanpack";

const ActionBarButtons = ({
  width,
  onPressRestartOrder,
  restartButtonDesign,
  onPressGetOrderData,
  reloadButtonDesign,
  onPressAddNote,
}) => {
  return (
    <LinearGradient
      testID="actionBarContainer"
      style={[
        styles.actionButtonInnerView,
        {
          backgroundColor: "#707070",
          borderRadius: width > 900 ? 12 : 5,
          height: 50
        },
      ]}
      start={{ x: 0, y: 1 }}
      end={{ x: 0, y: 0 }}
      colors={["#142130", "#304454"]}
    >
      <Button
        onPress={onPressRestartOrder}
        windowWidth={width}
        firstLabel={"Restart"}
        secondLabel={"Order"}
        id={'restartOrder'}
        style={restartButtonDesign}
        icon={"icon-ccw"}
        dataSet={'restartButton'}
      />
      <Button
        onPress={onPressGetOrderData}
        windowWidth={width}
        firstLabel={"Reload"}
        secondLabel={"Order"}
        id={'getOrderData'}
        style={reloadButtonDesign}
        icon={"icon-arrows-cw"}
        dataSet={'reloadButton'}
      />
      <Button
        onPress={onPressAddNote}
        windowWidth={width}
        firstLabel={"Add"}
        secondLabel={"Note"}
        id={'addNote'}
        style={restartButtonDesign}
        icon={"icon-doc"}
        dataSet={'addNoteButton'}
      />
    </LinearGradient>
  );
};
export default ActionBarButtons;

const Button = ({
  onPress,
  windowWidth,
  firstLabel,
  secondLabel,
  style,
  icon,
  dataSet,
  id,
}) => {
  return (
    <TouchableOpacity 
      testID={id}
      style={style} 
      onPress={onPress}
    >
      <View
        dataSet={{componentName: dataSet}}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          margin: 5,
        }}
      >
        <i
          className={icon}
          style={{
            color: "white",
            fontSize: "27px",
          }}
        ></i>
        {windowWidth > 300 && (
          <View
            style={{
              flexDirection: "column",
              alignItems: 'flex-start',
              marginLeft: windowWidth > 900 ? 3 : 0,
            }}
          >
            <Text
              style={[
                styles.actionButtonText,
                windowWidth > 900
                  ? styles.actionButtonTextDesktop
                  : styles.actionButtonTextMobile,
              ]}
            >
              {firstLabel}
            </Text>
            <Text
              style={[
                styles.actionButtonText,
                windowWidth > 900
                  ? styles.actionButtonLabelDesktop
                  : styles.actionButtonTextMobile,
              ]}
            >
              {secondLabel}
            </Text>
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
};