import React from 'react';
import { View, Text, Linking, TouchableHighlight } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import i18n from 'i18n-js';
import styles from '../../style/directPrinting';

const ToolTip = ({ top, testID, left, windowWidth, message, isLink, bodyTop = 0 }) =>  {
  const openLink = () => {
    Linking.openURL('https://groovepacker.freshdesk.com/a/solutions/articles/6000245428')
  }
  return (
    <LinearGradient
      testID={testID}
      locations={[0, 1]}
      colors={['#8a9daf', '#d7e1e9']}
      style={{
        padding: 10,
        position: 'absolute',
        borderRadius: 5,
        width: windowWidth >= 900 ? 270 : 260,
        alignSelf: 'flex-start',
        zIndex: 99,
        top: windowWidth >= 900 ? 0:  bodyTop,
        left: windowWidth >= 900 ? left : 35,
      }}>
      {isLink ?
        <Text>
          {i18n.t('directprinting.packingsliptolltipOne')}
          <TouchableHighlight testID='linkText' onPress={openLink}>
            <Text style={{ color: 'blue', textDecorationLine: 'underline' }}>
              {i18n.t('directprinting.packingsliptolltipLinkText')}
            </Text>
          </TouchableHighlight>
          {i18n.t('directprinting.packingsliptolltipTwo')}
        </Text> :
        <Text style={styles.tooltipText}>
          {message}
        </Text>
      }
      <View
        style={{
          position: 'absolute',
          right: windowWidth >= 900 ? -15 : '',
          left: windowWidth >= 900 ? '' : -15,
          top: windowWidth >= 900 ? 10 : top,
          transform:
            windowWidth >= 900
              ? 'rotate(90deg)'
              : 'rotate(270deg)',
          borderLeftWidth: 10,
          borderLeftColor: 'transparent',
          borderRightWidth: 10,
          borderRightColor: 'transparent',
          borderBottomWidth: 10,
          borderBottomColor: '#8d9fb1'
        }}
      />
    </LinearGradient>
  )
}

export default ToolTip
