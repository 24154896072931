import * as ImagePicker from 'expo-image-picker';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  addImage,
  GetProductDetail,
  UpdateProductInfo
} from '../../actions/productAction';

const qunatityLabel1 = 'Current QOH';
const qunatityLabel2 = 'QTY to Recieve';
const quantityMessage = 'QTY to receive will be added to existing QOH.';
const useReceiveProduct = ({navigation, route}) => {
  const {steps} = route.params;
  const store = useSelector(state => state.product);
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const [qtyReceive, setReceiveQty] = useState('');
  const [recevingNotes, setReceivingNotes] = useState(
    store.product?.basicinfo.product_receiving_instructions ?? ''
  );
  const [locationPrimary, setLocationPrimary] = useState(
    store.product?.inventory_warehouses[0].info.location_primary
  );
  const [locationPrimaryQty, setLocationPrimaryQty] = useState(
    store.product?.inventory_warehouses[0].info.location_primary_qty
  );
  const [locationSecondary, setLocationSecondary] = useState(
    store.product?.inventory_warehouses[0].info.location_secondary
  );
  const [locationSecondaryQty, setLocationSecondaryQty] = useState(
    store.product?.inventory_warehouses[0].info.location_secondary_qty
  );
  const [locationTertiary, setLocationTertiary] = useState(
    store.product?.inventory_warehouses[0].info.location_tertiary
  );
  const [locationTertiaryQty, setLocationTertiaryQty] = useState(
    store.product?.inventory_warehouses[0].info.location_tertiary_qty
  );
  const qtyRef = useRef();
  const locationRef = useRef();

  useEffect(() => {
    if (store.AddImage) {
      dispatch(GetProductDetail(store.product.basicinfo.id));
    }
  }, [store.AddImage]);

  useEffect(() => {
    if (steps.includes('quantity')) {
      qtyRef.current.focus();
    } else if (steps.includes('location')) {
      locationRef.current.focus();
    }
  }, []);

  const pickImage = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1
    });

    dispatch(addImage(store.product.basicinfo.id, result.uri));
  };

  const saveQuantity = useCallback(() => {
    if (!qtyReceive) return;
    const product = Object.assign({}, store.product);
    product.inventory_warehouses[0].info.quantity_on_hand =
      (product.inventory_warehouses[0].info.quantity_on_hand ?? 0) +
      parseInt(qtyReceive);
    dispatch(UpdateProductInfo(product.basicinfo.id, product));
  }, [qtyReceive]);
  const saveProductInfo = useCallback(() => {
    const product = Object.assign({}, store.product);

    product.inventory_warehouses[0].info.location_primary = locationPrimary;
    product.inventory_warehouses[0].info.location_primary_qty = parseInt(
      locationPrimaryQty ?? 0
    );
    product.inventory_warehouses[0].info.location_secondary = locationSecondary;
    product.inventory_warehouses[0].info.location_secondary_qty = parseInt(
      locationSecondaryQty ?? 0
    );
    product.inventory_warehouses[0].info.location_tertiary = locationTertiary;
    product.inventory_warehouses[0].info.location_tertiary_qty = parseInt(
      locationTertiaryQty ?? 0
    );
    product.basicinfo.product_receiving_instructions = recevingNotes;

    dispatch(UpdateProductInfo(product.basicinfo.id, product));
  }, [
    locationPrimary,
    locationPrimaryQty,
    locationSecondary,
    locationSecondaryQty,
    locationTertiary,
    locationTertiaryQty,
    recevingNotes
  ]);

  const goBack = useCallback(() => {
    navigation.navigate('ScanReceive');
  }, []);
  const onPressProductDetail = useCallback(() => {
    navigation.navigate('ProductDetail', {
      productInfo: store.product.basicinfo.id,
      userData: user.userInfo,
      page: 'ReceiveProduct',
      oncomeBack: goBack
    });
  }, []);
  return {
    navigation,
    user,
    steps,
    qunatityLabel1,
    qunatityLabel2,
    quantityMessage,
    qtyReceive,
    setReceiveQty,
    product: store.product,
    qtyRef,
    locationRef,
    saveQuantity,
    locationPrimary,
    setLocationPrimary,
    locationPrimaryQty,
    setLocationPrimaryQty,
    locationSecondary,
    setLocationSecondary,
    locationSecondaryQty,
    setLocationSecondaryQty,
    locationTertiary,
    setLocationTertiary,
    locationTertiaryQty,
    setLocationTertiaryQty,
    pickImage,
    recevingNotes,
    setReceivingNotes,
    saveProductInfo,
    goBack,
    onPressProductDetail
  };
};
export default useReceiveProduct;
