import {IS_OPEN, IS_SWAP, IS_HAPTICS_MODE, IS_LIST_MODE, IS_UNMOUNT,OPEN_NOTIFICATION} from '../constants';

const initialState = {
  isOpen: false,
  isHaptics: false,
  isSwap: false,
  isUnMounted: false,
  showNotification: false,
  isCodeFound: false,
  isListMode: false
};

const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case IS_OPEN:
      return {...state, isOpen: action.payload};
    case IS_SWAP:
      return {...state, isSwap: action.payload};
    case IS_HAPTICS_MODE:
      return {...state, isHaptics: action.payload};
    case IS_LIST_MODE:
      return {...state, isListMode: action.payload};
    case IS_UNMOUNT:
      return {...state, isUnMounted: action.payload};
    case OPEN_NOTIFICATION:
      return {...state, showNotification: action.payload.value, isCodeFound: action.payload.codeFound};
    default:
      return state;
  }
};

export default menuReducer;
