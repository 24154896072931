import { LinearGradient } from 'expo-linear-gradient';
import React from 'react';
import { Dimensions, ActivityIndicator } from 'react-native';
import {View, Text, TouchableOpacity} from 'react-native';
import promptStyle from '../../src/style/popupmodel';
import styles from '../../src/style/scanpack';

const Prompt = ({msg,onPressCancel , onPressOk, loading}) => {
  const windowWidth = Dimensions.get('window').width
   return(
    <View style={[styles.responseView]}>
      <View style={styles.typeScanContainer}>
        <LinearGradient
          testID='prompt'
          locations={[0, 1]}
          start={{ x: 0, y: 1 }}
          end={{ x: 0, y: 0 }}
          colors={['#d47d02', '#ffb636']}
          style={[promptStyle.promptModel, { width: windowWidth >= 900 ? '50%' : '97%', top: '5%' }]}
        >
          <View style={{flexDirection: 'row'}}>
            <Text
              style={[
                styles.addBarcodeText,
                { marginTop: windowWidth <= 900 ? '0%' : '0' , width: windowWidth < 900 ? '85%': '100%' }
              ]}
            >
              {msg}
            </Text>
          </View>
          <View
            style={{
              position: 'absolute',
              right: 0,
              top: windowWidth <= 900 ? 5 : 10
            }}
          >
            <TouchableOpacity onPress={onPressCancel}>
              <i
                className="icon-cancel"
                style={{ fontSize: '26px', color: '#4b4132' }}
              ></i>
            </TouchableOpacity>
          </View>
          <View
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '2%',
              width: '100%'
            }}
          >
            <View style={{ flexDirection: 'row' }}>
              <TouchableOpacity
                testID='okButton'
                onPress={onPressOk}
                style={[
                  styles.modalButton,
                  {
                    marginLeft: windowWidth >= 900 ? '-50%' : '-50%'
                  }
                ]}
              >
                <Text>Ok</Text>
              </TouchableOpacity>
              <TouchableOpacity
               testID='cancelButton'
                onPress={onPressCancel}
                style={[
                  styles.modalButton,
                  { marginLeft: windowWidth >= 900 ? '84%' : '100%' }
                ]}
              >
                <Text>Cancel</Text>
              </TouchableOpacity>
            </View>
          </View>
          {loading &&
          <View style={{ justifyContent: 'center', flex: 1 }}>
            <ActivityIndicator size="large" color="white" />
            <View>
              <Text
                style={{ textAlign: 'center', color: 'white', fontSize: 16 }}
              >
                Loading
              </Text>
            </View>
          </View>
        }
        </LinearGradient>
      </View>
    </View>
   )
}
export default Prompt;