import React, {Component} from 'react';
import {
  View,
  Text,
  TextInput,
  ScrollView,
  Dimensions,
} from 'react-native';
import moment from 'moment';
import styles from '../../style/orderdetail';
import globalStyles from '../../style/global';
import {TouchableOpacity} from 'react-native-gesture-handler';
import DateTimePicker from 'react-datetime-picker';
import datestyle from 'react-datetime-picker/dist/DateTimePicker.css';
import i18n from 'i18n-js';
import AlertBoxPermission from '../../commons/alertBoxPermission';
import InformationDetailMobileView from './information_detail_mobile_view';
import OrderInfoHeader from '../scanpack/order-info-header';
import CommonStyles from '../../style/commonStyles';
let order_no = true;
let scanning_com = true;
let order_date = false;
let tracking_number = true;
let cust_order_data1 = true;
let cust_order_data2 = true;
let first_name = true;
let last_name = true;
let email_cust = true;
let company_cust = true;
let city_cust = true;
let state_cust = true;
let address1 = true;
let address2 = true;
let postal_code = true;
let country_cust = true;
export default class InformationDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: Dimensions.get('window').width,
      lessMore: false,
      value: new Date(),
      pickedDate: new Date(),
      startDate: '',
      permissionState: false
    };
  }
  updateDimensions = () => {
    this.setState({windowWidth: window.innerWidth});
  };
  contentLess = () => {
    this.setState({lessMore: !this.state.lessMore});
  };

  permissionCheck = () => {
    if (this.props.show === true) {
      this.setState({permissionState: true});
      order_no = false;
      scanning_com = false;
      order_date = true;
      tracking_number = false;
      cust_order_data1 = false;
      cust_order_data2 = false;
      first_name = false;
      last_name = false;
      email_cust = false;
      company_cust = false;
      city_cust = false;
      state_cust = false;
      address1 = false;
      address2 = false;
      postal_code = false;
      country_cust = false;
    }
  };
  onCloseAlert = () => {
    if (this.props.show === true) {
      this.setState({permissionState: false});
      order_no = true;
      scanning_com = true;
      order_date = false;
      tracking_number = true;
      cust_order_data1 = true;
      cust_order_data2 = true;
      first_name = true;
      last_name = true;
      email_cust = true;
      company_cust = true;
      city_cust = true;
      state_cust = true;
      address1 = true;
      address2 = true;
      postal_code = true;
      country_cust = true;
    }
  };
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.setState({
      pickedDate: new Date(this.props.basicinfo.order_placed_time)
    });
  }
  componentWillUnmount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  setDate = date => {
    this.setState({pickedDate: date});
    this.props.handleChange('order_placed_time', date);
  };

  render() {
    let basicinfo = this.props && this.props.basicinfo;
    return (
      <>
        <AlertBoxPermission
          permissionState={this.state.permissionState}
          onCloseAlert={() => this.onCloseAlert()}
        />
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            marginBottom: '5%'
          }}
          showsVerticalScrollIndicator={false}
        >
          <View
            testID='informationContainer'
            style={CommonStyles.packingCamContainer}
          />

          <View
            style={{
              flex: 1,
              paddingLeft: 20,
              paddingRight: 20
            }}
          >
            <OrderInfoHeader
              status={basicinfo.status}
              onSave={this.props.updateBasicInfo}
              updateOrderStatus={this.props.updateOrderStatus}
              onCancel={this.props.back}
              show={this.props.show}
              id={basicinfo}
            />
            {this.state.windowWidth >= 900 ? (
              <>
                {basicinfo && (
                  <>
                    <View
                      style={CommonStyles.orderInfoStyle}
                    >
                      <View style={[globalStyles.m_10, {flexDirection: 'row'}]}>
                        <View style={{width: '50%'}}>
                          <Text
                            style={[globalStyles.py_10, CommonStyles.InfoDetailTextStyle]}
                          >
                            {i18n.t('order.order_number')}
                          </Text>
                          <TextInput
                            testID='orderNumber'
                            editable={order_no}
                            style={[styles.roundedBox, globalStyles.inputBoxWidth, CommonStyles.infoDetailInputStyle, {width: '90%'}]}
                            onChangeText={text => {
                              this.props.show === true
                                ? this.permissionCheck()
                                : this.props.handleChange('increment_id', text);
                            }}
                            value={basicinfo.increment_id}
                          />
                        </View>
                        <View style={{width: '50%'}}>
                          <Text
                            style={[globalStyles.py_10, CommonStyles.InfoDetailTextStyle]}
                          >
                            {i18n.t('order.scanning_completed')}
                          </Text>
                          <TextInput
                            testID='scanningCom'
                            editable={scanning_com}
                            style={[styles.roundedBox, globalStyles.inputBoxWidth, globalStyles.bgWhite, CommonStyles.infoDetailInputStyle, {width: '95%'}]}
                            onChangeText={text => {
                              this.props.show === true
                                ? this.permissionCheck()
                                : this.props.handleChange(
                                    'scan_start_time',
                                    text
                                  );
                            }}
                            value={moment(basicinfo.scan_start_time).format(
                              'MMMM Do YYYY, h:mm:ss a'
                            )}
                          />
                        </View>
                      </View>                     
                      <View
                        style={[
                          globalStyles.m_10,
                          {
                            flexDirection: 'row',
                            zIndex: '9',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                          }
                        ]}
                      >
                        <View style={{width: '47%'}}>
                          <Text
                            style={[globalStyles.py_10, CommonStyles.InfoDetailTextStyle, {marginTop: 0}]}
                          >
                            {i18n.t('order.order_date')}
                          </Text>
                          <View
                            style={[styles.roundedBox, globalStyles.inputBoxWidth, globalStyles.bgWhite, CommonStyles.infoDetailInputStyle, {width: '95%', padding: '6px'}]}
                          >
                            <DateTimePicker
                              disabled={order_date}
                              style={{datestyle}}
                              onChange={date => {
                                this.props.show === true
                                  ? this.permissionCheck()
                                  : this.setDate(date);
                              }}
                              value={this.state.pickedDate}
                              amPmAriaLabel={'Select AM/PM'}
                              format="MM-dd-yyyy h:mm aa"
                            />
                          </View>
                        </View>
                        <View style={{width: '50%'}}>
                          <Text
                            style={[globalStyles.py_10, CommonStyles.InfoDetailTextStyle, {marginTop: 0}]}
                          >
                            {i18n.t('order.tracking_number')}
                          </Text>
                          <TextInput
                            testID='trackingNumber'
                            editable={tracking_number}
                            style={[styles.roundedBox, globalStyles.inputBoxWidth, globalStyles.bgWhite, CommonStyles.infoDetailInputStyle, {width: '95%'}]}
                            onChangeText={text => {
                              this.props.show === true
                                ? this.permissionCheck()
                                : this.props.handleChange('tracking_num', text);
                            }}
                            value={basicinfo.tracking_num}
                          />
                        </View>
                      </View>                     
                      <View style={[globalStyles.m_10, {flexDirection: 'row'}]}>
                        <View style={{width: '50%'}}>
                          <Text
                            style={[globalStyles.py_10, CommonStyles.InfoDetailTextStyle, {marginTop: 0}]}
                          >
                            {i18n.t('order.customer_order_data1')}
                          </Text>
                          <TextInput
                            testID='customerOrderData1'
                            editable={cust_order_data1}
                            style={[styles.roundedBox, globalStyles.inputBoxWidth, globalStyles.bgWhite, CommonStyles.infoDetailInputStyle, {width: '90%'}]}
                            onChangeText={text => {
                              this.props.show === true
                                ? this.permissionCheck()
                                : this.props.handleChange(
                                    'custom_field_one',
                                    text
                                  );
                            }}
                            value={basicinfo.custom_field_one}
                          />
                        </View>
                        <View style={{width: '50%'}}>
                          <Text
                            style={[globalStyles.py_10, CommonStyles.InfoDetailTextStyle, {marginTop: 0}]}
                          >
                            {i18n.t('order.customer_order_data2')}
                          </Text>
                          <TextInput
                            testID='customerOrderData2'
                            editable={cust_order_data2}
                            style={[styles.roundedBox, globalStyles.inputBoxWidth, globalStyles.bgWhite, CommonStyles.infoDetailInputStyle, {width: '95%'}]}
                            onChangeText={text => {
                              this.props.show === true
                                ? this.permissionCheck()
                                : this.props.handleChange(
                                    'custom_field_two',
                                    text
                                  );
                            }}
                            value={basicinfo.custom_field_two}
                          />
                        </View>
                      </View>
                      <Text
                        style={[CommonStyles.textStyleOne,{marginTop: 10, color: '#dbdada'}]}
                      >
                        Customer Details
                      </Text>
                      <View
                        style={[
                          globalStyles.m_10,
                          {flexDirection: 'row', marginTop: 20}
                        ]}
                      >
                        <View style={{width: '50%'}}>
                          <Text
                            style={[globalStyles.py_10, CommonStyles.InfoDetailTextStyle, {marginTop: 0}]}
                          >
                            {i18n.t('order.first_name')}
                          </Text>
                          <TextInput
                            testID='firstName'
                            editable={first_name}
                            style={[styles.roundedBox, globalStyles.inputBoxWidth, globalStyles.bgWhite, CommonStyles.infoDetailInputStyle, {width: '90%'}]}
                            onChangeText={text => {
                              this.props.show === true
                                ? this.permissionCheck()
                                : this.props.handleChange('firstname', text);
                            }}
                            value={basicinfo.firstname}
                          />
                        </View>
                        <View style={{width: '50%'}}>
                          <Text
                            style={[globalStyles.py_10, CommonStyles.InfoDetailTextStyle, {marginTop: 0}]}
                          >
                            {i18n.t('order.last_name')}
                          </Text>
                          <TextInput
                            testID='lastName'
                            editable={last_name}
                            style={[styles.roundedBox, globalStyles.inputBoxWidth, globalStyles.bgWhite, CommonStyles.infoDetailInputStyle, {width: '95%'}]}
                            onChangeText={text => {
                              this.props.show === true
                                ? this.permissionCheck()
                                : this.props.handleChange('lastname', text);
                            }}
                            value={basicinfo.lastname}
                          />
                        </View>
                      </View>
                      <View style={[globalStyles.m_10, {flexDirection: 'row'}]}>
                        <View style={{width: '50%'}}>
                          <Text
                            style={[globalStyles.py_10, CommonStyles.InfoDetailTextStyle, {marginTop: 0}]}
                          >
                            {i18n.t('order.email')}
                          </Text>
                          <TextInput
                            testID='emailCustomer'
                            editable={email_cust}
                            style={[styles.roundedBox, globalStyles.inputBoxWidth, globalStyles.bgWhite, CommonStyles.infoDetailInputStyle, {width: '90%'}]}
                            onChangeText={text => {
                              this.props.show === true
                                ? this.permissionCheck()
                                : this.props.handleChange('email', text);
                            }}
                            value={basicinfo.email}
                          />
                        </View>
                        <View style={{width: '50%'}}>
                          <Text
                            style={[globalStyles.py_10, CommonStyles.InfoDetailTextStyle, {marginTop: 0}]}
                          >
                            {i18n.t('order.company')}
                          </Text>
                          <TextInput
                            testID='companyCustomer'
                            editable={company_cust}
                            style={[styles.roundedBox, globalStyles.inputBoxWidth, globalStyles.bgWhite, CommonStyles.infoDetailInputStyle, {width: '95%'}]}
                            onChangeText={text => {
                              this.props.show === true
                                ? this.permissionCheck()
                                : this.props.handleChange('company', text);
                            }}
                            value={basicinfo.company}
                          />
                        </View>
                      </View>                    
                      <View style={[globalStyles.m_10, {flexDirection: 'row'}]}>
                        <View style={{width: '50%'}}>
                          <Text
                            style={[globalStyles.py_10, CommonStyles.InfoDetailTextStyle, {marginTop: 0}]}
                          >
                            {i18n.t('order.city')}
                          </Text>
                          <TextInput
                            testID='cityCustomer'
                            editable={city_cust}
                            style={[styles.roundedBox, globalStyles.inputBoxWidth, globalStyles.bgWhite, CommonStyles.infoDetailInputStyle, {width: '90%'}]}
                            onChangeText={text => {
                              this.props.show === true
                                ? this.permissionCheck()
                                : this.props.handleChange('city', text);
                            }}
                            value={basicinfo.city}
                          />
                        </View>
                        <View style={{width: '50%'}}>
                          <Text
                            style={[globalStyles.py_10, CommonStyles.InfoDetailTextStyle, {marginTop: 0}]}
                          >
                            {i18n.t('order.state')}
                          </Text>
                          <TextInput
                            testID='stateCustomer'
                            editable={state_cust}
                            style={[styles.roundedBox, globalStyles.inputBoxWidth, globalStyles.bgWhite, CommonStyles.infoDetailInputStyle, {width: '95%'}]}
                            onChangeText={text => {
                              this.props.show === true
                                ? this.permissionCheck()
                                : this.props.handleChange('state', text);
                            }}
                            value={basicinfo.state}
                          />
                        </View>
                      </View>                     
                      <View style={[globalStyles.m_10, {flexDirection: 'row'}]}>
                        <View style={{width: '50%'}}>
                          <Text
                            style={[globalStyles.py_10, CommonStyles.InfoDetailTextStyle, {marginTop: 0}]}
                          >
                            {i18n.t('order.address_1')}
                          </Text>
                          <TextInput
                            testID='address1'
                            editable={address1}
                            style={[styles.roundedBox, globalStyles.inputBoxWidth, globalStyles.bgWhite, CommonStyles.infoDetailInputStyle, {width: '90%'}]}
                            onChangeText={text => {
                              this.props.show === true
                                ? this.permissionCheck()
                                : this.props.handleChange('address_1', text);
                            }}
                            value={basicinfo.address_1}
                          />
                        </View>
                        <View style={{width: '50%'}}>
                          <Text
                            style={[globalStyles.py_10, CommonStyles.InfoDetailTextStyle, {marginTop: 0}]}
                          >
                            {i18n.t('order.address_2')}
                          </Text>
                          <TextInput
                            testID='address2'
                            editable={address2}
                            style={[styles.roundedBox, globalStyles.inputBoxWidth, globalStyles.bgWhite, CommonStyles.infoDetailInputStyle, {width: '95%'}]}
                            onChangeText={text => {
                              this.props.show === true
                                ? this.permissionCheck()
                                : this.props.handleChange('address_2', text);
                            }}
                            value={basicinfo.address_2}
                          />
                        </View>
                      </View>
                      <View style={[globalStyles.m_10, {flexDirection: 'row'}]}>
                        <View style={{width: '50%'}}>
                          <Text
                            style={[globalStyles.py_10, CommonStyles.InfoDetailTextStyle, {marginTop: 0}]}
                          >
                            {i18n.t('order.postal_code')}
                          </Text>
                          <TextInput
                            testID='postalCode'
                            editable={postal_code}
                            style={[styles.roundedBox, globalStyles.inputBoxWidth, globalStyles.bgWhite, CommonStyles.infoDetailInputStyle, {width: '90%'}]}
                            onChangeText={text => {
                              this.props.show === true
                                ? this.permissionCheck()
                                : this.props.handleChange('postcode', text);
                            }}
                            value={basicinfo.postcode}
                          />
                        </View>
                        <View style={{width: '50%'}}>
                          <Text
                            style={[globalStyles.py_10, CommonStyles.InfoDetailTextStyle, {marginTop: 0}]}
                          >
                            {i18n.t('order.country')}
                          </Text>
                          <TextInput
                            testID='countryCustomer'
                            editable={country_cust}
                            style={[styles.roundedBox, globalStyles.inputBoxWidth, globalStyles.bgWhite, CommonStyles.infoDetailInputStyle, {width: '95%'}]}
                            onChangeText={text => {
                              this.props.show === true
                                ? this.permissionCheck()
                                : this.props.handleChange('country', text);
                            }}
                            value={basicinfo.country}
                          />
                        </View>
                      </View>
                    </View>
                  </>
                )}
              </>
            ) : (
              //For Mobile View
              <InformationDetailMobileView
                {...this.props}
                setDate={date => this.setDate(date)}
                contentLess={() => {
                  this.contentLess();
                }}
                lessMore={this.state.lessMore}
                pickedDate={this.state.pickedDate}
                order_no={order_no}
                scanning_com={scanning_com}
                order_date={order_date}
                tracking_number={tracking_number}
                cust_order_data1={cust_order_data1}
                cust_order_data2={cust_order_data2}
                first_name={first_name}
                last_name={last_name}
                email_cust={email_cust}
                company_cust={company_cust}
                city_cust={city_cust}
                state_cust={state_cust}
                address1={address1}
                address2={address2}
                postal_code={postal_code}
                country_cust={country_cust}
                permissionCheck={() => this.permissionCheck()}
                onCloseAlert={() => this.onCloseAlert()}
              ></InformationDetailMobileView>
            )}
          </View>
        </ScrollView>
      </>
    );
  }
}
