import React, { useEffect, useState } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  Dimensions
} from 'react-native';
import styles from '../../style/popupmodel';
import alertPrompt from "../../style/popupmodel"
import { LinearGradient } from 'expo-linear-gradient';

export default function PopUpModel(props) {
  const [windowWidth, setWindowWidth] = useState(null);
  useEffect(() => {
    setWindowWidth(Dimensions.get('window').width);
  }, []);

  //  console.log("real window",windowWidth)
  return (
    <>
      <View style={[windowWidth >= 900 ? styles.alertContainer : alertPrompt.alerBoxPopup]}>
        <LinearGradient
          testID='notification'
          locations={[0, 1]}
          colors={['rgb(207,68,55)', 'rgb(122,40,40)']}
          style={[alertPrompt.alertPermission]}
        >
          <Text
            testID='message'
            style={{
              marginLeft: 15,
              fontSize: 16,
              color: '#fff',
              width: '80%'
            }}
          >
            {props.message}
          </Text>
          <TouchableOpacity
            testID='closeButton'
            onPress={props.closeAlert}
            style={{
              position: 'absolute',
              right: windowWidth >=900 ? 10 : 0,
              top :windowWidth >=900 ? 1 :"-1%",
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%'
            }}
          >
            <i
              className="icon-cancel"
              style={{ fontSize: '22px', color: 'white' }}
            ></i>
            {/* <Image source={closeButton ? closeButton : ""}
            style={{ height: 20, width: 20, }} /> */}
          </TouchableOpacity>
        </LinearGradient>
      </View>
    </>
  );
}
