import { LinearGradient } from 'expo-linear-gradient';
import React, { Component } from 'react';
import i18n from 'i18n-js';
import { Dimensions, Text, TouchableOpacity, View } from 'react-native';
import globalStyles from '../../style/global';
import styles from '../../style/scanpack';
import promptStyle from '../../style/popupmodel'
export default class RemoveImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: Dimensions.get('window').width
    };
    this.trackingFocus = React.createRef();
  }
  updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentWillUnmount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  render() {
    return (
      <>
        {this.props.ModalShow === true ? (
          <View style={{ width: '100%', zIndex: 99 }}>
            <View
              style={{
                textAlign: 'center',
                justifyContent: 'center',
                width: '100%',
                zIndex: 999,
                position: 'fixed'
              }}
            >
              <LinearGradient
                locations={[0, 1]}
                start={{ x: 0, y: 1 }}
                end={{ x: 0, y: 0 }}
                colors={['#d47d02', '#ffb636']}
                style={[promptStyle.promptModel, { width: this.state.windowWidth >= 600 ? '50%' : '81%', left: this.state.windowWidth >= 600 ? '25%' : '5%', }]}
              >
                <View
                  style={[
                    globalStyles.flexDirectionRow,
                    { width: this.state.windowWidth >= 900 ? '100%' : '76%' }
                  ]}
                >
                  <Text style={[styles.scanRecordCountText]}>
                    {i18n.t('product.images_remove')}
                  </Text>
                </View>
                <View style={{ flexDirection: 'row', marginTop: '2%' }}>
                  <TouchableOpacity
                    testID='CloseModal'
                    style={[promptStyle.promptButton, { marginLeft: '-50%' }]}
                    onPress={() => {
                      this.props.closeModelImg();
                    }}
                  >
                    <Text>Cancel</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    testID='RemoveImageConfirm'
                    style={[promptStyle.promptButton, { marginLeft: "100%" }]}
                    onPress={() => this.props.imageRemove()}
                  >
                    <Text>Confirm</Text>
                  </TouchableOpacity>
                </View>
              </LinearGradient>
            </View>
          </View>
        ) : (
          <></>
        )}
      </>
    );
  }
}
