import React, { Component } from 'react';
import { View, Text, ActivityIndicator, TouchableOpacity, ScrollView , Vibration} from 'react-native';
import { connect } from 'react-redux';
import { GetProductDetail, UpdateProductInfo, updateProductList, SetIsProductCreated, updateProductAlias } from '../../actions/productAction';
import { SearchScanpackOrder } from '../../actions/scanpackAction';
import { GetBothSettings, GetGeneralSetting, UserInfo } from '../../actions/userAction';
import { SetItem } from '../../actions/updateAsyncAction';
import ProductInfo from './product_info';
import ScanpackOptions from './scanpack_options';
import InventoryKitOptions from './inventory_kit_options';
import PopUpModel from '../scanpack/pop_up_model';
import styles from '../../style/productdetail';
import InerternetConnectionAlert from '../internetConnectionAlert';
import ProductAlias from '../Alias/productAlias';
import { LinearGradient } from 'expo-linear-gradient';
import InterNetChecks from '../internateConnectionCheck';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios'

import OrderButton from '../../commons/orderStatusButton';
import DropDown from '../../commons/dropDown';
import i18n from 'i18n-js';
import Alert from '../scanpack/alert';
import * as Clipboard from 'expo-clipboard';
let updateProductInfoSuccess = false;
let orderID = 0;
let buttonClick = false;
let aliasValid = false;
let saveClose = false;
let updateProductAliasFlag = false;
let lessMoreObj = false;
let lessMoreObjCat = false;
let setFocus = true;

const states = [
    {label: "Active", value: 'active'},
    {label: "Inactive", value: 'inactive'},
    {label: "New", value: 'new'}
  ];

  const colors = [
    {firstColor: '#1CBB77', secondColor: '#7BFAC3', textColor: 'black'},
    {firstColor: '#979797', secondColor: '#D7D7D7', textColor: 'black'},
    {firstColor: '#FFB538', secondColor: '#CF7C08', textColor: 'black'},
  ];
class ProductDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productInfoActive: true,
      scanPackOptionsActive: false,
      inventoryKitOptionsActive: false,
      productActivityLogActive: false,
      Product: [],
      loading: false,
      alias: false,
      shareBarcodeShow: false,
      updateBarcodeLocal: false,
      index: 0,
      showBarcodePopup:false,
      showDropDown: false,
      removefromLocal: '',
      loadingText: 'Loading',
      from: '',
      productInfoCommon: '',
      popInternet: true,
      pickerSelectedOptions: [
        {
          label: 'Enable',
          value: 'on'
        },
        {
          label: 'Disable',
          value: 'off'
        },
        {
          label: 'On with confirmation',
          value: 'on_with_confirmation'
        }
      ],
      pickerSelectedRecordOptions: [
        {
          label: 'Record 1',
          value: 'record_1'
        },
        {
          label: 'Record 2',
          value: 'record_2'
        },
        {
          label: 'Disable',
          value: 'off'
        }
      ],
      localBarcodes: [],
      popUpModelShow: false,
      windowWidth: 0,
      hideSaveCloseInv: true,
      lessMoreClose: true,
      productPermissionPopup: false,
      shipmentOrder: {
        input: '',
        state: 'scanpack.rfo',
        id: null,
        box_id: null,
        store_order_id: null,
        app: 'app'
      },
      saveAndCloseClicked: false,   
    };
    window.addEventListener('resize', this.update);
  }

  setIndex = (Product) => {
    let {basicinfo} = Product
    if(basicinfo?.status === 'active'){
      this.setState({index: 0})
    }
    else if(basicinfo?.status === 'inactive'){
      this.setState({index: 1})
    }
    else{
      this.setState({index: 2})
    }
  }

  copySkuToClipboard = async (text) => {
    await Clipboard.setStringAsync(`${text}`);
  };

  checkPermission = () => {
    if(!this.state.productPermissionPopup){
      return true;
    }
    this.alertBox(i18n.t('product.alertBoxPermission'), true, false);
    return false;
  }

  componentDidMount() {
    const sI = setInterval(this.test, 2000);
    this.apiCall('Loading...');
    this.update();
    const { userData } = this.props.route.params;
    if (userData) {
      const { role } = userData;
      if (role && role.add_edit_products === false) {
        this.setState({ productPermissionPopup: true });
      }
      else {
        this.props.UserInfo()
      }
    }
  }
  update = () => {
    this.setState({
      windowWidth: window.innerWidth
    });
  };

 async componentDidUpdate(prevProps, prevState) {
  const { saveAndCloseClicked } = this.state;
    const { product, isProductCreated, productId, userInfo, GetProductDetail, SetIsProductCreated, updateProduct, updateProductAliasSuccess, navigation, route, } = this.props;
    if (
      product &&
      product && this.state.Product !== product &&
      this.state.Product.show_alias_popup !== true
    ) {
      setTimeout(() => {
        this.setState({
          Product: this.props.product,
          loading: false,
          localBarcodes: this.props.product.barcodes
        });
        this.setIndex(product);
      }, 600);
    }

    if (!isProductCreated) {
      if (productId) {
        userInfo.role.add_edit_products === false &&
          this.setState({ productPermissionPopup: true });
      }
      GetProductDetail(this.props.productId);
      SetIsProductCreated()
    }
    if (
      aliasValid && updateProduct &&
      updateProduct.data &&
      updateProduct.data.show_alias_popup &&
      updateProduct.time > this.state.updateProductApiCall
    ) {
      setTimeout(
        () =>
          this.setState({
            alias: true,
            Product: this.props.updateProduct,
            loading: false
          }),
        500
      );
      aliasValid = false;
    }

    if (
      updateProduct &&
      updateProduct.data &&
      updateProduct.data.scan_pack_product &&
      updateProduct.time > this.state.updateProductApiCall
    ) {
      if (saveClose === true) {
        const { page, oncomeBack, onComeBack, increment_id } = route.params;
        if (page == 'ProductSearch') {
          navigation.navigate('ProductSearch', {
            data: { from: 'productDetailPage' }
          });
        } else if (page == 'ReceiveProduct') {
          oncomeBack();
          return;
        } else if (page == 'ScanReceive') {
          oncomeBack();
          return;
        } else if (page == 'OrderDetail') {
          onComeBack();
          return;
        } else if (page == 'RecountProduct') {
          oncomeBack();
          return;
        } else {
          if(saveAndCloseClicked){
          this.setState({saveAndCloseClicked:false})
            const access_token = await AsyncStorage.getItem('access_token');
            const url = await AsyncStorage.getItem('url');
            let fetchListData = this.state.shipmentOrder;
            fetchListData.input = this.props.route?.params?.increment_id;
            axios({
              method: 'post',
              url: `${url}/scan_pack/scan_barcode.json`,
              headers: { Authorization: `Bearer ${access_token}` },
              data: fetchListData
            })
              .then(res => {
                navigation.navigate('ScanPackItem', {
                  data: {
                    from: 'productDetailPage',
                    time: new Date(),
                    increment_id: this.props?.route?.params?.increment_id,
                    order: res?.data.data?.order[0].scan_hash?.data?.order
                  }
                });
              })
              
          }
        }
      } else {
        if (updateProduct.data.show_alias_popup !== true) {
          if (updateProduct.data.status === false) {
            this.setState({
              Product: updateProduct.data.scan_pack_product,
              popUpModelShow: true,
              updateProductApiCall: updateProduct.time,
              loading: false
            });
            this.alertBox(updateProduct.data.message, true, false);
          } else {
            this.setState({
              Product: updateProduct.data.scan_pack_product,
              updateProductApiCall: updateProduct.time,
              loading: false
            });
          }
          this.apiCall('Title');

          updateProductInfoSuccess = false;
        }
      }
    }

    if (
      updateProductAliasFlag && updateProductAliasSuccess
    ) {
      if (updateProductAliasSuccess.status) {
        this.setState({
          loading: false,
          alias: false,
          Product: product
        });
        navigation.navigate('ScanPack', {
          data: {
            from: 'productDetailPage',
            time: new Date(),
            increment_id: route?.params?.productInfo
          }
        });
        updateProductAliasFlag = false;
      }
    }
  }

  hideSaveFun = () => {
    this.setState({ hideSaveCloseInv: !this.state.hideSaveCloseInv });
  };
  alertBox = (message, messageTypeSuccess) => {
    if (messageTypeSuccess === true) {
      this.setState({
        popUpModelShow: true,
        message: message,
        messageTypeSuccess: true
      });
    } else {
      this.setState({ popUpModelShow: true, message: message });
    }
    setTimeout(
      function () {
        this.setState({
          popUpModelShow: false,
          message: '',
          messageTypeSuccess: false
        });
      }.bind(this),
      4000
    );
  };

  closeAlert = () => {
    this.setState({
      popUpModelShow: false,
      message: ''
    });
  };

  handleProductStatus = (value, index) => {
    const {Product} =  this.state
    const {basicinfo} = Product
    if((basicinfo.status === 'inactive' || basicinfo.status === 'new' ) && value==='active' && 
    (Product.barcodes.length === 0 || Product.skus.length === 0)){
      value = 'new'
      index = 2
      this.setState({showBarcodePopup: true})
    }
    basicinfo.status = value
    this.setState({ Product: Product});
    this.setState({showDropDown: false})
    this.setState({index: index})
  };

  getStates = () => {
    const {Product} =  this.state
    if(Product.barcodes.length > 0){
      return states.filter(state => state.value !== 'new');
    }
    return states
  }

  handleOnPress = () => {
    if(this.checkPermission()){
      this.setState({showDropDown: !this.state.showDropDown})
    }
  };

  apiCall = title => {
    const { route, product, GetProductDetail, GetBothSettings, GetGeneralSetting } = this.props;
    this.setState({ loading: true, loaderTitle: title });
    let id = this.props?.route?.params?.productInfo;
    const barCode = route?.params?.barCode;
    orderID = this.props?.route?.params?.orderID;
    if (barCode && product?.basicinfo.id) {
      id = product?.basicinfo.id
    }
    if (id && !product) {
      GetProductDetail(id);
    }
    GetBothSettings();
    GetGeneralSetting();
  };

  changeState = (state) => {
    let productInfoActive = false;
    let scanPackOptionsActive = false;
    let inventoryKitOptionsActive = false;
    let productActivityLogActive = false;

    if (state === 'productInfoActive') {
      productInfoActive = true;
    } else if (state === 'scanPackOptionsActive') {
      scanPackOptionsActive = true;
      setFocus = false;
    } else if (state === 'inventoryKitOptionsActive') {
      inventoryKitOptionsActive = true;
      setFocus = false;
    } else if (state === 'productActivityLogActive') {
      productActivityLogActive = true;
      setFocus = false;
    }

    this.setState({
      productInfoActive,
      scanPackOptionsActive,
      inventoryKitOptionsActive,
      productActivityLogActive
    });
  };

  multiBarcode = (value, name, index) => {
    let basicinfo = this.state.Product.basicinfo;
    let Product = this.state.Product;
    if (name === 'is_skippable') {
      basicinfo[name] = value;
    }
    if (name === 'barcode' || name === 'packing_count') {
      if (name === 'barcode') {
        Product.barcodes[index].barcode = value;
      }
      if (name === 'packing_count') {
        Product.barcodes[index].packing_count = value;
      }
    }
    this.setState({ Product });
  };

  productInfoHandleChange = (value, name) => {
    const { Product } = this.state;
    const { basicinfo } = Product || {};
    if (basicinfo) {
      basicinfo[name] = value;
      if (name === 'is_kit') {
        let id = Product.basicinfo.id;
        let increment_id = this.props.route.params.orderID;
        this.props.UpdateProductInfo(id, Product, increment_id);
      }
    }
    this.setState({ Product });
    setFocus = false
  };

  addMultiBarcode = () => {
    let Product = this.props.product.barcodes;
    let newHash = {
      id: 'TEMP',
      barcode: '',
      packing_count: '1',
      product_id: this.props.product.basicinfo.id
    };
    Product.push(newHash);
    this.setState({ Product });
  };

  checkBarcodeSku = (Product) => {
    let {basicinfo} = Product
    if(Product.skus.length > 0 && Product.barcodes.length > 0 )
    {
      basicinfo.status = 'active'
    }
    else if(Product.barcodes.length === 0 || Product.skus.length === 0)
    {
      basicinfo.status = 'new'
    }
    return Product;
  }

  updateProductSBC = (details, type, remove) => {
    const { Product } = this.state;
    let data = this.state.Product;
    if (type === 'barcode') {
      Product.barcodes = details;
      this.checkBarcodeSku(Product)
      this.updateProduct('barcode');
    } else if (type === 'sku') {
      Product.skus = details;
      this.checkBarcodeSku(Product)
      this.updateProduct('sku');
    } else if (type === 'cats') {
      Product.cats = details;
      this.updateProduct('cats');
    }
    this.setState({ data });
    this.setState({ Product });
  };

  inventoryWarehousesHandleChange = (value, name) => {
    const { Product } = this.state;
    Product[name] = value;
    this.setState({ Product });
  };
  inventoryWarehousesHandleChange1 = (value, name) => {
    const { Product } = this.state;
    Product.inventory_warehouses[0].info[name] = value;
    this.setState({ Product });
  };

  updateProduct = from => {
    this.setState({saveAndCloseClicked:true, loading: true, loaderTitle: 'Saving Changes...' }, () => { });
    let increment_id = this.props.route.params.orderID;
    // let Product = this.state.Product;
    const { Product } = this.state;
    if (Product) {
      let id = Product?.basicinfo?.id;
      if (id) {
        Product['app'] = 'app';
        let data = Product;
        if (from === 'save&close') {
          Product.barcodes.length > 0 &&
            Product.barcodes.map(bar => {
              if (bar.id === 'TEMP') {
                bar['skip_check'] = true;
              }
            });
          Product.skus.length > 0 &&
            Product.skus.map(sku => {
              if (sku.id === 'TEMP') {
                sku['skip_check'] = true;
              }
            });
          Product.cats.length > 0 &&
            Product.cats.map(cat => {
              if (cat.id === 'TEMP') {
                cat['skip_check'] = true;
              }
            });
        }

        if (from === 'save') {
          Product.barcodes.length > 0 &&
            Product.barcodes.map(bar => {
              if (bar.id === 'TEMP') {
                bar['skip_check'] = true;
              }
            });
          Product.skus.length > 0 &&
            Product.skus.map(sku => {
              if (sku.id === 'TEMP') {
                sku['skip_check'] = true;
              }
            });
          Product.cats.length > 0 &&
            Product.cats.map(cat => {
              if (cat.id === 'TEMP') {
                cat['skip_check'] = true;
              }
            });
        }
        this.props.UpdateProductInfo(id, Product, increment_id);
        if (from === 'barcode') {
          setTimeout(
            () =>
              this.setState({
                loading: false,
                from: 'barcode',
                updateProductApiCall: new Date()
              }),
            300
          );
          updateProductInfoSuccess = false;
          aliasValid = true;
        } else {
          updateProductInfoSuccess = true;
        }
        if (from === 'save&close') {
          saveClose = true;
          this.setState({ updateProductApiCall: new Date() });
        } else {
          saveClose = false;
        }
        buttonClick = true;
      }
      this.setState({ updateProductApiCall: new Date() });
    }
  };

  componentWillUnmount() {
    this.setState({ data: {}, Product: {} });
    const { page } = this.props.route.params;
    if (!(page === 'ReceiveProduct' || page === 'RecountProduct')) {
      this.props.GetProductDetail(0);
    }
  }

  proceedAliasing = () => {
    let alias_product_data_id =
      this.props.updateProduct.data.alias_product_data.id;
    let current_product_data_id = [];
    current_product_data_id.push(
      this.props.updateProduct.data.current_product_data.id
    );
    let details = {
      product_alias_ids: current_product_data_id
    };
    this.props.updateProductAlias(alias_product_data_id, details);
    updateProductInfoSuccess = true;
    updateProductAliasFlag = true;
  };

  assignUniquebarcode = () => {
    let data = this.state.data;
    let lastItem = data.length;
    let removefromLocal = '';
    data &&
      data.barcodes.length > 0 &&
      data.barcodes.map(barcode => {
        if (barcode.id === 'TEMP') {
          data.barcodes.pop();
          removefromLocal = barcode.barcode;
        }
      });
    this.setState({
      data,
      shareBarcodeShow: false,
      alias: false,
      updateBarcodeLocal: true,
      removefromLocal
    });
  };

  sameBarcode = () => {
    let data = this.state.data;
    let lastItem = data.barcodes.length > 0 && data.barcodes.length - 1;
    let id = data.basicinfo.id;
    data &&
      data.barcodes.length > 0 &&
      data.barcodes.map((barcode, index) => {
        if (index === lastItem) {
          barcode['permit_same_barcode'] = true;
        }
      });
    this.props.UpdateProductInfo(id, data);
    this.setState({ data, shareBarcodeShow: false, alias: false });
  };
  drawerClose = () => {
    this.props.route.params.closeDrawer();
  };
  goBackPage = () => {
    const { page, oncomeBack, navigation } = this.props?.route?.params;
    if (page == 'ProductSearch') {
      oncomeBack();
    }
    if (page == 'ReceiveProduct') {
      oncomeBack();
      return;
    } else if (page == 'RecountProduct') {
      oncomeBack();
      return;
    }
    else if (page == 'ScanReceive') {
      oncomeBack();
      return;
    }
    this.props.navigation.goBack();
  };


  //Check Internate connectiion Here
  test = async () => {
    let num = await InterNetChecks();
    if (num !== true) {
      this.setState({ popInternet: false });
    } else {
      this.setState({ popInternet: true });
    }
  };
  updateState = () => {
    lessMoreObj = true;
  };
  updateStateSecond = () => {
    lessMoreObj = false;
  };
  updateStateCat = () => {
    lessMoreObjCat = true;
  };
  updateStateSecondCat = () => {
    lessMoreObjCat = false;
  };
  render() {
    let Product = this.state && this.props.product;
    let confProductInstruction = this.props?.bothSettings?.data?.data?.general_setting?.conf_code_product_instruction;
    return (
      <>
        {
          this.state.showBarcodePopup && (
            <Alert
              closeAlert={() => {
                this.setState({ showBarcodePopup: false });
              }}
              msg={i18n.t("product.zeroBarcodeText")}
              heading={"product.address_new_items"}
              showIcon={false}
              showButtons={false}
              showInput={true}
            />
          )
        }
        <View
          onStartShouldSetResponder={() => {this.drawerClose(); this.setState({ showDropDown: false });}}
          style={{ flex: 1, paddBottom: 15, backgroundColor: 'black' }}
        >
          {this.state.loading ? (
            <View style={{ justifyContent: 'center', flex: 1 }}>
              <ActivityIndicator size="large" color="white" />
              <View>
                <Text
                  style={{ textAlign: 'center', color: 'white', fontSize: 16 }}
                >
                  {this.state.loaderTitle}
                </Text>
              </View>
            </View>
          ) : (
            <LinearGradient
              colors={[
                '#000',
                '#000',
                '#5c778f',
                '#5c778f',
                '#253c57',
                '#253c57',
                '#000'
              ]}
              start={[-0.5, -1]}
              style={{
                flex: 1,
                paddingBottom: 15
              }}
            >
              {
                // Notification Pop Up for messages
                this.state.popUpModelShow && (
                  <PopUpModel
                    closeAlert={this.closeAlert.bind(this)}
                    message={this.state.message}
                    messageTypeSuccess={
                      this.state.messageTypeSuccess ? true : false
                    }
                  />
                )
              }
              <ProductAlias
                alias={this.state.alias}
                Product={this.state.Product}
                updateProduct={
                  this.props.updateProduct && this.props.updateProduct.data
                }
                assignUniquebarcode={() => this.assignUniquebarcode()}
                shareBarcodeMethod={() => {
                  this.setState({ shareBarcodeShow: true, alias: false });
                }}
                windowWidth={this.state.windowWidth}
                proceedAliasing={() => this.proceedAliasing()}
                shareBarcodeShow={this.state.shareBarcodeShow}
                sameBarcode={() => this.sameBarcode()}
              />
              <View style={{ height: '100%', width: '100%' }}>
                {this.props && Product && this.state.Product !== [] && (
                  <View style={{ width: '100%' }}>
                    <ScrollView
                      horizontal={true}
                      showsHorizontalScrollIndicator={false}
                      showsVerticalScrollIndicator={false}
                      style={{
                        width: '100%',
                        borderWidth: 1
                      }}
                    >
                      <LinearGradient
                        testID='menu'
                        colors={['#2E3D46', '#587284']}
                        style={{
                          width: this.state.windowWidth,
                          flexDirection: 'row',
                          paddingTop: 5,
                          paddingLeft: '2%',
                          minWidth: 710
                        }}
                      >
                        <TouchableOpacity
                          testID='productInfo'
                          onPress={state =>
                            this.changeState('productInfoActive')
                          }
                          style={[
                            {
                              backgroundColor: this.state.productInfoActive
                                ? '#000'
                                : ''
                            },
                            styles.tabBtn
                          ]}
                        >
                          <Text style={[styles.tabLabel]}>Product Info</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          testID='ScanPackOption'
                          onPress={() =>
                            this.changeState('scanPackOptionsActive')
                          }
                          style={[
                            {
                              backgroundColor: this.state.scanPackOptionsActive
                                ? '#000'
                                : ''
                            },
                            styles.tabBtn
                          ]}
                        >
                          <Text style={[styles.tabLabel]}>
                            Scan & Pack Options
                          </Text>
                        </TouchableOpacity>
                        {Product?.basicinfo?.is_kit == 1 ? (
                          <TouchableOpacity
                            testID='kitOptions'
                            onPress={state =>
                              this.changeState('inventoryKitOptionsActive')
                            }
                            style={[
                              {
                                backgroundColor: this.state
                                  .inventoryKitOptionsActive
                                  ? '#000'
                                  : ''
                              },
                              styles.tabBtn
                            ]}
                          >
                            <Text style={[styles.tabLabel]}>Kit Options</Text>
                          </TouchableOpacity>
                        ) : (
                          <></>
                        )}
                      </LinearGradient>
                    </ScrollView>
                    <View
                      style={{
                        backgroundColor: '#000',
                        height: 20,
                        width: '100%',
                        shadowColor: '#000',
                        shadowOffset: {
                          width: 1,
                          height: 10
                        },
                        shadowOpacity: 3,
                        shadowRadius: 10,
                        elevation: 5
                      }}
                    ></View>
                    <View style={{flexDirection:'row'}}>
                      <Text
                        numberOfLines={1}
                        style={{
                          fontSize: 22,
                          fontWeight: 'bold',
                          marginLeft: 25,
                          marginRight: 5,
                          width: 'auto',
                          fontFamily: 'Poppins_700Bold',
                          background:
                            '-webkit-linear-gradient(\n90deg\n, #4b657d, #768ea5 80%)',
                          WebkitTextFillColor: 'transparent',
                          webkitBackgroundClip: 'text'
                        }}>
                        {this.props.product?.skus?.[0]?.sku}
                      </Text>
                      <TouchableOpacity
                        testID='copySku'
                        style={{marginRight: this.state.windowWidth > 700 ?'69%':'38%'}}
                        onPress={()=>{
                          this.props.isHaptics && Vibration.vibrate(30)
                          this.copySkuToClipboard(this.props.product?.skus?.[0]?.sku)
                        }}
                      >
                        <i
                          className="icon-docs"
                          style={{
                            fontSize: 16,
                            fontWeight: 'bold',
                            marginTop: this.state.windowWidth > 900 ? '6px': '5px',
                            background:
                              '-webkit-linear-gradient(\n90deg\n, #4b657d, #768ea5 80%)',
                            WebkitTextFillColor: 'transparent',
                            webkitBackgroundClip: 'text'
                          }}>
                        </i>
                      </TouchableOpacity>
                    </View>
                    {this.state.hideSaveCloseInv &&
                      this.state.popInternet === true ? (
                      <View
                        style={{
                          // alignSelf: 'flex-start',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          paddingVertical: 10,
                          marginBottom: this.state.windowWidth > 500 && this.state.windowWidth < 900 ? '2rem' : '1rem',
                          flexDirection: 'row',
                          zIndex: 1,
                          position: 'relative'
                        }}
                      >
                        <View>
                          <View style={{position: 'relative'}}>
                            <OrderButton
                            label={states[this.state.index]?.label}
                            width="fit-content"
                            fontSize={this.state.windowWidth > 900 ? 14 : 12}
                            handleOnPress={this.handleOnPress}
                            firstColor={colors[this.state.index]?.firstColor}
                            secondColor={colors[this.state.index]?.secondColor}
                            textColor={colors[this.state.index]?.textColor}
                            marginLeft={18}
                          />
                            {this.state.showDropDown &&
                              <DropDown
                                width="fit-content"
                                pickerStates={this.getStates()}
                                fontSize={this.state.windowWidth > 900 ? 14 : 12}
                                handlePickerChange={this.handleProductStatus}
                                top={this.state.windowWidth > 900 ? 24 : 21}
                                marginLeft={this.state.windowWidth > 900 ? '14%' : '10%'}
                                alignSelf={'flex-start'}
                                colors={colors}
                              />}
                            </View>
                          </View>
                        <View
                          style={{ flexDirection: 'row' }}
                        >
                          <View>
                            <TouchableOpacity
                              testID='saveAndCloseButton'
                              style={[styles.saveCloseButton, { shadowColor: '#2bfac3', shadowOffset: { width: 0, height: 0.5 } }]}
                              onPress={() => {
                                this.updateProduct('save&close');
                                this.updateState();
                                this.updateStateCat();
                                this.props.isHaptics && Vibration.vibrate(30)
                              }}
                            >
                              <Text
                                style={{
                                  color: 'white',
                                  paddingHorizontal: 10,
                                  fontWeight: 'bold'
                                }}
                              >
                                Save & Close
                              </Text>
                            </TouchableOpacity>
                          </View>
                          <View>
                            <TouchableOpacity
                              testID='cancelBtn'
                              style={[styles.saveCloseButton, { shadowColor: '#e853fa', shadowOffset: { width: 0, height: 0.5 }, }]}
                              onPress={() => {
                                this.goBackPage();
                                this.props.isHaptics && Vibration.vibrate(30);
                              }}
                            >
                              <Text
                                style={{
                                  paddingHorizontal: 10,
                                  color: '#fff',
                                  fontWeight: 'bold'
                                }}
                              >
                                Cancel{' '}
                              </Text>
                            </TouchableOpacity>
                          </View>
                        </View>
                      </View>
                    ) : (
                      <View></View>
                    )}
                    {this.state.popInternet === false ? (
                      <View
                        style={{
                          backgroundColor: 'transparent',
                          position: 'absolute',
                          top: 0
                        }}
                      >
                        <InerternetConnectionAlert />
                      </View>
                    ) : null}

                    {this.state.productInfoActive && this.state.popInternet && (
                      <ProductInfo
                      {...this.props}
                        basicinfo={Product.basicinfo}
                        productInfoObj={lessMoreObj}
                        productInfoObjCat={lessMoreObjCat}
                        productInfoCommon={this.state.productInfoCommon}
                        closeDrawer={() => {
                          this.props.route.params.closeDrawer();
                        }}
                        inventoryWarehouses={Product.inventory_warehouses}
                        inventoryWarehousesHandleChange={(value, name) =>
                          this.inventoryWarehousesHandleChange(value, name)
                        }
                        inventoryWarehousesHandleChange1={(value, name) =>
                          this.inventoryWarehousesHandleChange1(value, name)
                        }
                        images={Product.images}
                        navigation={this.props.navigation}
                        productInfoHandleChange={(value, name) =>
                          this.productInfoHandleChange(value, name)
                        }
                        updateProductSBC={(details, type, remove) =>
                          this.updateProductSBC(details, type, remove)
                        }
                        updateProductFun={name => this.updateProduct(name)}
                        updateBarcodeLocal={this.state.updateBarcodeLocal}
                        removefromLocal={this.state.removefromLocal}
                        updateLocalBarcode={() =>
                          this.setState({ removefromLocal: '' })
                        }
                        updatedProduct={this.state.Product}
                        changeState={state => this.changeState(state)}
                        userRole={this.props.route.params.userData}
                        hideSaveFun={() => this.hideSaveFun()}
                        updateStateSecond={() => {
                          this.updateStateSecond();
                        }}
                        setFocus={setFocus}
                        updateStateSecondCat={() => {
                          this.updateStateSecondCat();
                        }}
                        show={this.state.productPermissionPopup}
                        {...this.props}
                      />
                    )}
                    {this.state.scanPackOptionsActive &&
                      this.state.popInternet && (
                        <ScanpackOptions
                          basicinfo={Product.basicinfo}
                          confProductInstruction={confProductInstruction}
                          productInfoHandleChange={(value, name) =>
                            this.productInfoHandleChange(value, name)
                          }
                          multiBarcode={(value, name, index) =>
                            this.multiBarcode(value, name, index)
                          }
                          navigation={this.props.navigation}
                          updateProductSBC={(details, type, remove) =>
                            this.updateProductSBC(details, type, remove)
                          }
                          addMultiBarcode={() => this.addMultiBarcode()}
                          pickerSelectedOptions={
                            this.state.pickerSelectedOptions
                          }
                          pickerSelectedRecordOptions={
                            this.state.pickerSelectedRecordOptions
                          }
                          updatedProduct={this.state.Product}
                          userRole={this.props.route.params.userData}
                          show={this.state.productPermissionPopup}
                          {...this.props}
                        />
                      )}
                    {this.state.inventoryKitOptionsActive &&
                      this.state.popInternet && (
                        <InventoryKitOptions
                          inventoryWarehouses={Product.inventory_warehouses}
                          {...this.props}
                          removeIds={this.state.removeIds}
                          removeItems={() => this.removeItems()}
                          navigation={this.props.navigation}
                          productInfoHandleChange={(value, name) =>
                            this.productInfoHandleChange(value, name)
                          }
                          inventoryWarehousesHandleChange={(value, name) =>
                            this.inventoryWarehousesHandleChange(value, name)
                          }
                          hideSaveFun={() => this.hideSaveFun()}
                          updateProductFun={name => this.updateProduct(name)}
                          changeState={state => this.changeState(state)}
                          userRole={this.props.route.params.userData}
                          show={this.state.productPermissionPopup}
                        />
                      )}
                  </View>
                )}
              </View>
            </LinearGradient>
          )}
        </View>{' '}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    product: state.product.product,
    updateProduct: state.product.updateProduct,
    searchOrder: state.scanpack,
    bothSettings: state.user.bothSettings,
    generalSettings: state.user.settings,
    userInfo: state.user.userInfo,
    productId: state.product.productId,
    isProductCreated: state.product.isProductCreated,
    updateProductAliasSuccess: state.product.updateProductAlias,
    updateProductListData: state.product.updateProductList,
    isHaptics: state.menu.isHaptics
  };
};

const mapDispatchToProps = {
  GetProductDetail,
  UpdateProductInfo,
  SetItem,
  UserInfo,
  SetIsProductCreated,
  GetBothSettings,
  GetGeneralSetting,
  SearchScanpackOrder,
  updateProductAlias,
  updateProductList
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);
