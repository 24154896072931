import React, {useState, useEffect, useRef} from 'react';
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Image,
  Dimensions,
  Platform
} from 'react-native';
import styles from '../style/shippinglabel.js';
import globalStyles from '../style/global';
import shortcutImage from '../../assets/shortcut-icon.png';
import {FontAwesome} from '@expo/vector-icons';
import Tooltip from './toolTip';
import Dropdown from './customSelect';
import { LinearGradient } from 'expo-linear-gradient';

const Dimension = ({
  dimensionRef,
  shortcutsInputs,
  setDimensionDataParent,
  activateEditButton,
  addShortcutOther,
  addShortcutDimension,
  dimensionDataParent,
  setSelectedDimensionData,
  selectedDimensionPreset,
  setSelectedDimensionPreset,
  selectedDimensionData,
  pickerDimensionUnit,
  setPickerDimensionUnit,
  labelShortcuts,
  permissions,
  savePresets,
  credentialId,
  encodeDimensions,
  decodeDimensions,
  pickerDimensionOpen,
  setPickerDimensionOpen,
  fetchUpdatedRates,
  changeInitialFormValues,
  heightRef,
  widthRef,
  lengthRef,
  confirmationRef,
  pickerWeightSelectedOption
}) => {
  const pickerSelectedOptionsHeight = [
    {
      label: 'Inches',
      value: 'inches'
    },
    {
      label: 'Centimeters',
      value: 'cm'
    }
  ];

  const windowWidth = Dimensions.get('window').width;

  const [editable, setEditable] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const initialRender = useRef(true);

  const handleTextchange = (label, value) => {
    changeInitialFormValues(label,value)
    
    setSelectedDimensionData({
      ...selectedDimensionData,
      [label]: value
    });
  };

  const saveItem = () => {
    if (
      !selectedDimensionData.length ||
      !selectedDimensionData.height ||
      !selectedDimensionData.width ||
      !selectedDimensionData.presetName
    ) {
    } else if (
      selectedDimensionData.length &&
      selectedDimensionData.width &&
      selectedDimensionData.height &&
      selectedDimensionData.presetName &&
      editable
    ) {
      setDimensionDataParent(dimensionDataParent);
      setEditable(false);
      setSelectedItem(null);
    } else {
      const currentDimensionData = selectedDimensionData;
      let totalPresets = {};
      let currentPresetShortcuts = {};
      for (const el of dimensionDataParent) {
        const {presetName, value} = encodeDimensions(el);
        totalPresets[presetName] = value;
        if (el.shortcutKey?.length)
          currentPresetShortcuts[presetName] = el.shortcutKey;
      }
      const {presetName, value} = encodeDimensions(currentDimensionData);
      totalPresets[presetName] = value;
      const payload = {
        credential_id: credentialId,
        presets: totalPresets
      };
      let arr = [];
      for (const prop in totalPresets) {
        let obj = decodeDimensions({
          presetName: prop,
          value: totalPresets[prop]
        });
        if (currentPresetShortcuts[prop]) {
          obj.shortcutKey = currentPresetShortcuts[prop];
        }
        arr.push(obj);
      }
      savePresets(payload);
      //setDimensionDataParent([...dimensionDataParent, currentDimensionData]);
      setDimensionDataParent(arr);
      //setSelectedDimensionData(selectedDimensionData);
      setSelectedDimensionPreset(currentDimensionData.presetName);
    }
  };

  const deleteItem = index => {
    const updateItem = dimensionDataParent.filter(el => {
      return index !== el.presetName;
    });
    let totalPresets = {};
    for (const el of updateItem) {
      const {presetName, value} = encodeDimensions(el);
      totalPresets[presetName] = value;
    }
    const payload = {
      credential_id: credentialId,
      presets: totalPresets
    };
    let arr = [];
    for (const prop in totalPresets) {
      let obj = decodeDimensions({
        presetName: prop,
        value: totalPresets[prop]
      });
      arr.push(obj);
    }
    savePresets(payload);
    setDimensionDataParent(updateItem);
  };

  const selectPreset = (presetName,e) => {
    const selectedDimensionData = dimensionDataParent.find(el => {
      return el.presetName === presetName;
    });
    setPickerDimensionUnit(selectedDimensionData.units);
    setSelectedDimensionData(selectedDimensionData);
    setSelectedDimensionPreset(selectedDimensionData.presetName);
    if(e.key === 'Enter'){
      fetchUpdatedRates()
    }
  };

  useEffect(() => {
    selectedDimensionPreset && setSelectedItem(selectedDimensionPreset);
  }, [selectedDimensionPreset]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    pickerWeightSelectedOption === 'ounces' ? setPickerDimensionUnit('inches') : setPickerDimensionUnit('cm') ;
  }, [pickerWeightSelectedOption]);

  return (
    <>
      <View style={{marginTop: 5, marginBottom: 20, zIndex: -5}}>
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'start'
          }}>
          <View style={{marginRight: '-17px'}}>
            <Text
              style={[
                globalStyles.py_10,
                styles.labelStyle,
                {
                  height: '64px',
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '0',
                  marginBottom: '0'
                }
              ]}>
              Dimension
            </Text>
          </View>
          <View>
            <Tooltip tooltipText={'Click to Add a Keyboard Shortcut'}>
              <TouchableOpacity onPress={() => addShortcutOther('dimension')}>
                <Text
                  style={[
                    styles.labelStyle,
                    {display: 'flex', alignItems: 'center'}
                  ]}>
                  (
                  {shortcutsInputs?.dimension ? (
                    shortcutsInputs.dimension
                  ) : labelShortcuts ? (
                    <Image
                      source={shortcutImage}
                      style={styles.shortcutIconShowSmall}
                    />
                  ) : (
                    ''
                  )}
                  )
                </Text>
              </TouchableOpacity>
            </Tooltip>
          </View>
        </View>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center'
          }}>
          <TextInput
            style={[
              styles.roundedBox,
              windowWidth >= 1080 ? styles.inputStyle : styles.inputStyleMobile,
              globalStyles.bgGrey,
              windowWidth >= 1080 ? {width: '29%'} : {width: '70%'}
            ]}
            ref={dimensionRef}
            onFocus={e => e.target.select()}
            keyboardType="numeric"
            value={selectedDimensionData?.length??""}
            onChangeText={value => handleTextchange('length', value)}
            placeholder="Length"
            onSubmitEditing={()=>fetchUpdatedRates()}
            placeholderTextColor="grey"
          />

          <TextInput
            style={[
              styles.roundedBox,
              styles.inputStyle,
              globalStyles.bgGrey,
              windowWidth >= 1080 ? {width: '29%'} : {width: '70%'}
            ]}
            ref={widthRef}
            keyboardType="numeric"
            onFocus={e => e.target.select()}
            value={selectedDimensionData?.width??""}
            onChangeText={value => handleTextchange('width', value)}
            placeholder="Width"
            onSubmitEditing={()=>fetchUpdatedRates()}
            placeholderTextColor="grey"
          />
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent:'flex-start',
            width: '100%',
            zIndex: 2147483647
          }}>
          <TextInput
          ref={heightRef}
            style={[
              styles.roundedBox,
              windowWidth >= 1080 ? styles.inputStyle : styles.inputStyleMobile,
              globalStyles.bgGrey,
              windowWidth >= 1080 ? {width: '29%'} : {width: windowWidth < 340 ? '67%' : '66%'}
            ]}
            keyboardType="numeric"
            onFocus={e => e.target.select()}
            value={selectedDimensionData?.height??""}
            onChangeText={value => handleTextchange('height', value)}
            placeholder="Height"
            onSubmitEditing={()=>fetchUpdatedRates()}
            placeholderTextColor="grey"
          />
          <LinearGradient
            locations={[0, 1]}
            colors={['rgba(144, 163, 180, 0.16)', 'rgba(181, 201, 221, 0.16)', 'rgba(220, 234, 252, 0.16)']}
            style={[
              styles.roundedBox,
              windowWidth >= 1080 ? styles.inputStyle : styles.inputStyleMobile,
              globalStyles.bgGrey,
              windowWidth >= 1080 ? {width: '29%'} : {width: windowWidth < 340 ? '43%' : windowWidth >= 768 ? '47.8%' : '45%'},
              {backgroundColor:'none'}
            ]}
          >
            <Text
              class="customSelect"
              id="weight"
              style={{
                cursor: 'pointer',
                borderColor: '#fff',
                color: '#fff',
                fontSize: 17,
                overflow: 'visible',
                textAlign: 'center',
                fontFamily: 'Poppins_600SemiBold',
              }}
            >
              {pickerDimensionUnit === 'cm' ? 'Centimeters' : 'Inches' }
            </Text>
          </LinearGradient>
        </View>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            width: windowWidth >= 1080 ?  windowWidth <= 1900 ? '64%' : '62%' : '100%' 
          }}>
          <TextInput
            style={[
              styles.roundedBox,

              windowWidth >= 1080 ? styles.inputStyle : styles.inputStyleMobile,
              globalStyles.bgGrey,
              windowWidth >= 1080 ? {width: '-webkit-fill-available'} : {width: '100%'}
            ]}
            onFocus={e => e.target.select()}
            value={selectedDimensionData?.presetName??""}
            onChangeText={value => handleTextchange('presetName', value)}
            placeholder="Preset Name"
            onSubmitEditing={()=>fetchUpdatedRates()}
            placeholderTextColor="grey"
          />
          {permissions.DIMENSIONS_PRESETS_WRITE && (
            <LinearGradient
            locations={[0, 1]}
            colors={['#D7D7D7', '#979797']}
            style={{borderRadius: 7, width: 100, height: 42, justifyContent: 'center', marginRight: '10px'}}
            >
              <TouchableOpacity style={[styles.saveContainer, {alignItems: 'center', paddingLeft: '4%'}]} onPress={saveItem} testID= 'saveItem'>
                <i
                  className=" icon-save-icon"
                  style={{width: 33, height: 28, color: '#45576C', fontSize: '25px', marginLeft: '-5px'}}
                ></i>
                <Text style={styles.save}>Save Preset</Text>
              </TouchableOpacity>
            </LinearGradient>
          )}
        </View>
      </View>

      {/* Dimension list section */}
      {dimensionDataParent &&
        dimensionDataParent.map(el => (
          <View style={styles.dimensionContainer} key={el.presetName}>
            <TouchableOpacity
              testID='selectPreset'
              style={
                selectedItem === el.presetName
                  ? styles.dimensionBoxSelected
                  : styles.dimensionBox
              }
              onPress={(e) => selectPreset(el.presetName,e)}>
              <Text style={styles.dimensionText}>
                {`${el.presetName} - ${el.length} x ${el.height} x ${el.width} (${el.units})`}
              </Text>
              <TouchableOpacity onPress={() => deleteItem(el.presetName)} testID = 'deleteItem'>
                <FontAwesome name="close" size={24} color="#374f69" />
              </TouchableOpacity>
            </TouchableOpacity>
            {permissions.SHORTCUTS_WRITE ? (
              <View>
                {activateEditButton && !el.shortcutKey && (
                  <Tooltip tooltipText={'Click to Add a Keyboard Shortcut'}>
                    <TouchableOpacity
                    testID='imageElement'
                      onPress={() => addShortcutDimension(el.presetName)}
                      style={styles.editBox}>
                      <Text style={styles.editButton}>
                        <Image
                          source={shortcutImage}
                          style={styles.shortcutIconShowSmall}
                        />
                      </Text>
                    </TouchableOpacity>
                  </Tooltip>
                )}
                {activateEditButton && el.shortcutKey?.length > 0 && (
                  <Tooltip tooltipText={'Click to Replace Keyboard Shortcut'}>
                    <TouchableOpacity
                      testID='addShortCut'
                      onPress={() => addShortcutDimension(el.presetName)}
                      style={styles.editBox}>
                      <Text style={styles.shortcutText}>
                        {el.shortcutKey?.join('+')}
                      </Text>
                    </TouchableOpacity>
                  </Tooltip>
                )}
                {!activateEditButton && !el.shortcutKey && (
                  <Tooltip tooltipText={'Click to Add a Keyboard Shortcut'}>
                    <TouchableOpacity
                      testID='imageElement'
                      onPress={() => addShortcutDimension(el.presetName)}
                      style={styles.editBox}>
                      <Text style={styles.editButton}>
                        <Image
                          source={shortcutImage}
                          style={styles.shortcutIconShowSmall}
                        />
                      </Text>
                    </TouchableOpacity>
                  </Tooltip>
                )}
                {!activateEditButton && el.shortcutKey?.length > 0 && (
                  <Tooltip tooltipText={'Click to Replace Keyboard Shortcut'}>
                    <TouchableOpacity
                      testID='addShortCut'
                      onPress={() => addShortcutDimension(el.presetName)}
                      style={styles.editBox}>
                      <Text style={styles.shortcutText}>
                        {el.shortcutKey?.join('+')}
                      </Text>
                    </TouchableOpacity>
                  </Tooltip>
                )}
              </View>
            ) : (
              <View>
                {activateEditButton && !el.shortcutKey && (
                  <Text style={styles.editButton}>
                    <Image
                      source={shortcutImage}
                      style={styles.shortcutIconShowSmall}
                    />
                  </Text>
                )}
                {activateEditButton && el.shortcutKey?.length > 0 && (
                  <Text style={styles.shortcutText}>
                    {el.shortcutKey?.join('+')}
                  </Text>
                )}
                {!activateEditButton && !el.shortcutKey && (
                  <Text style={styles.editButton}>
                    <Image
                      source={shortcutImage}
                      style={styles.shortcutIconShowSmall}
                    />
                  </Text>
                )}
                {!activateEditButton && el.shortcutKey?.length > 0 && (
                  <Text style={styles.shortcutText}>
                    {el.shortcutKey?.join('+')}
                  </Text>
                )}
              </View>
            )}

            {/* {!activateEditButton && el.shortcutKey?.length > 0 && (
              <View style={styles.shortcutBox}>
                <Text style={styles.shortcutText}>
                  {el.shortcutKey?.join('+')}
                </Text>
              </View>
            )} */}
          </View>
        ))}
    </>
  );
};

export default Dimension;
