import React, {Component} from 'react';
import {
  View,
  Text,
  TextInput,
  ScrollView,
  TouchableOpacity
} from 'react-native';

import styles from '../../style/orderdetail';
import globalStyles from '../../style/global';
import {LinearGradient} from 'expo-linear-gradient';
import Switch from 'react-switch';
import i18n from 'i18n-js';
import AlertBoxPermission from '../../commons/alertBoxPermission';
import OrderInfoHeader from '../scanpack/order-info-header';
import CommonStyles from '../../style/commonStyles';
let internal_notes = true;
let notes_to_packer = true;
let customer_comments_notes = true;
let tags_notes = true;
let notes_from_packer = true;
let confirmation_require = false;
export default class NotesDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerComments: false,
      notesInternal: false,
      notesToPacker: false,
      notesFromPacker: false,
      TagsSet: false,
      quickScanToolTip: false,
      quickScanToolTip1: false,
      quickScanToolTip2: false,
      quickScanToolTip3: false,
      quickScanToolTip4: false,
      quickScanToolTip5: false,
      switchtoggle: false,
      toolTipNum: 0,
      toolTipCheck: false,
      permissionState: false
    };
  }
  toolTipFun = tipno => {
    this.setState(prevState => {
      const newState = {...prevState, toolTipCheck: !prevState.toolTipCheck};
      switch (tipno) {
        case '0':
          newState.toolTipNum = tipno;
          newState.quickScanToolTip = !prevState.quickScanToolTip;
          break;
        case '1':
          newState.toolTipNum = tipno;
          newState.quickScanToolTip1 = !prevState.quickScanToolTip1;
          break;
        case '2':
          newState.toolTipNum = tipno;
          newState.quickScanToolTip2 = !prevState.quickScanToolTip2;
          break;
        case '3':
          newState.toolTipNum = tipno;
          newState.quickScanToolTip3 = !prevState.quickScanToolTip3;
          break;
        case '4':
          newState.toolTipNum = tipno;
          newState.quickScanToolTip4 = !prevState.quickScanToolTip4;
          break;
        case '5':
          newState.toolTipNum = tipno;
          newState.quickScanToolTip5 = !prevState.quickScanToolTip5;
          break;
        default:
          break;
      }

      return newState;
    });
  };

  switchToggle = () => {
    this.setState({switchtoggle: !this.state.switchtoggle});
    this.sendToserver();
  };
  sendToserver = () => {
    this.props.handleChange('note_confirmation', this.state.switchtoggle);
  };
  openTextField = field => {
    switch (field) {
      case '1':
        this.setState({notesInternal: true});
        break;
      case '2':
        this.setState({notesToPacker: true});
        break;
      case '3':
        this.setState({customerComments: true});
        break;
      case '4':
        this.setState({TagsSet: true});
        break;
      case '5':
        this.setState({notesFromPacker: true});
        break;
      default:
        break;
    }
  };
  permissionCheck = () => {
    if (this.props.show === true) {
      this.setState({permissionState: true});
      internal_notes = false;
      notes_to_packer = false;
      customer_comments_notes = false;
      tags_notes = false;
      notes_from_packer = false;
      confirmation_require = true;
    }
  };

  onCloseAlert = () => {
    if (this.props.show === true) {
      this.setState({permissionState: false});
      internal_notes = true;
      notes_to_packer = true;
      customer_comments_notes = true;
      tags_notes = true;
      notes_from_packer = true;
      confirmation_require = false;
    }
  };
  render() {
    let basicinfo = this.props && this.props.basicinfo;
    return (
      <>
        <AlertBoxPermission
          permissionState={this.state.permissionState}
          onCloseAlert={() => this.onCloseAlert()}
        />
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            marginBottom: '5%'
          }}
          showsVerticalScrollIndicator={false}>
          <View
            testID="notesContainer"
            style={CommonStyles.packingCamContainer}
          />
          <View
            testID="mainContainer"
            style={CommonStyles.activitiesExpFlex}
            onStartShouldSetResponder={() =>
              this.state.toolTipCheck ? (
                this.toolTipFun(this.state.toolTipNum)
              ) : (
                <></>
              )
            }>
            <OrderInfoHeader
              status={basicinfo.status}
              updateOrderStatus={this.props.updateOrderStatus}
              show={this.props.show}
              onSave={this.props.updateBasicInfo}
              onCancel={this.props.back}
              id={basicinfo}
            />
            <View style={CommonStyles.orderInfoStyle}>
              {basicinfo && (
                <View>
                  {this.state.quickScanToolTip1 && (
                    <LinearGradient
                      testID="internalNotesTooltip"
                      locations={[0, 1]}
                      colors={['#8a9daf', '#d7e1e9']}
                      style={[CommonStyles.notesToPackerTooltip, {top: 75}]}>
                      <Text style={{fontSize: 20, color: '#030303'}}>
                        {i18n.t('order.internal_notes_tooltip')}
                      </Text>
                      <View
                        style={[CommonStyles.noteViewStyle, {right: '44%'}]}
                      />
                    </LinearGradient>
                  )}
                  {this.state.quickScanToolTip2 && (
                    <LinearGradient
                      testID="notesToPackerTooltip"
                      locations={[0, 1]}
                      colors={['#8a9daf', '#d7e1e9']}
                      style={[CommonStyles.notesToPackerTooltip, {top: '27%'}]}>
                      <Text style={{fontSize: 20, color: '#030303'}}>
                        {i18n.t('order.notes_to_packer_tooltip')}
                      </Text>
                      <View
                        style={[CommonStyles.noteViewStyle, {right: '43%'}]}
                      />
                    </LinearGradient>
                  )}
                  {this.state.quickScanToolTip3 && (
                    <LinearGradient
                      testID="customerCommentsTooltip"
                      locations={[0, 1]}
                      colors={['#8a9daf', '#d7e1e9']}
                      style={[CommonStyles.notesToPackerTooltip, {top: '46%'}]}>
                      <Text style={{fontSize: 20, color: '#030303'}}>
                        {i18n.t('order.customer_comments_tooltip')}
                      </Text>
                      <View
                        style={[CommonStyles.noteViewStyle, {right: '21%'}]}
                      />
                    </LinearGradient>
                  )}

                  {this.state.quickScanToolTip4 && (
                    <LinearGradient
                      testID="tagsTooltip"
                      locations={[0, 1]}
                      colors={['#8a9daf', '#d7e1e9']}
                      style={[CommonStyles.notesToPackerTooltip, {top: '65%'}]}>
                      <Text style={{fontSize: 20, color: '#030303'}}>
                        {i18n.t('order.tags_tooltip')}
                      </Text>
                      <View
                        style={[CommonStyles.noteViewStyle, {right: '72%'}]}
                      />
                    </LinearGradient>
                  )}
                  {this.state.quickScanToolTip5 && (
                    <LinearGradient
                      testID="notesFromPackerTooltip"
                      locations={[0, 1]}
                      colors={['#8a9daf', '#d7e1e9']}
                      style={[CommonStyles.notesToPackerTooltip, {top: '85%'}]}>
                      <Text style={{fontSize: 20, color: '#030303'}}>
                        {i18n.t('order.notes_from_packer_tooltip')}
                      </Text>
                      <View
                        style={[CommonStyles.noteViewStyle, {right: '31%'}]}
                      />
                    </LinearGradient>
                  )}
                  <View>
                    <View style={CommonStyles.confirmationRequiredStyle}>
                      <Text style={CommonStyles.confirmationRequiredText}>
                        Confirmation Required
                      </Text>
                      <View style={{marginTop: 8, marginLeft: 8}}>
                        {this?.props?.bothSettings?.data?.data?.general_setting
                          ?.conf_req_on_notes_to_packer == 'optional' ? (
                          <Switch
                            offColor="#5c6e79"
                            onColor="#65baea"
                            disabled={confirmation_require}
                            offHandleColor="#969696"
                            onHandleColor="#fbfdfe"
                            handleDiameter={22}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={20}
                            width={40}
                            onChange={() => {
                              this.props.show === true
                                ? this.permissionCheck()
                                : this.switchToggle();
                            }}
                            checked={basicinfo?.note_confirmation}
                          />
                        ) : this?.props?.bothSettings?.data?.data
                            ?.general_setting?.conf_req_on_notes_to_packer ==
                          'always' ? (
                          <View
                            style={[
                              CommonStyles.alwaysTextStyle,
                              {backgroundColor: 'green'}
                            ]}>
                            <Text>Always</Text>
                          </View>
                        ) : this?.props?.bothSettings?.data?.data
                            ?.general_setting?.conf_req_on_notes_to_packer ==
                          'never' ? (
                          <View
                            style={[
                              CommonStyles.alwaysTextStyle,
                              {backgroundColor: 'grey'}
                            ]}>
                            <Text>Never</Text>
                          </View>
                        ) : (
                          <Switch
                            trackColor={{false: '#999991', true: '#000'}}
                            thumbColor={
                              basicinfo?.note_confirmation
                                ? '#951590'
                                : '#959595'
                            }
                            ios_backgroundColor="#767577"
                            style={{borderWidth: 0.5, borderRadius: 20}}
                            onValueChange={() => {
                              this.props.show === true
                                ? this.permissionCheck()
                                : this.switchToggle();
                            }}
                            value={basicinfo?.note_confirmation}
                          />
                        )}
                      </View>
                      <TouchableOpacity
                        testID="toolTip0"
                        onPress={() => this.toolTipFun('0')}>
                        <i
                          className="icon-info-circled"
                          style={{
                            color: 'rgb(172,187,199)',
                            fontSize: '17px',
                            marginTop: '38%',
                            marginLeft: '35%'
                          }}></i>
                      </TouchableOpacity>
                    </View>
                    {this.state.quickScanToolTip && (
                      <LinearGradient
                        testID="notesFromPacker"
                        locations={[0, 1]}
                        colors={['#8a9daf', '#d7e1e9']}
                        style={CommonStyles.notesFromPackerStyle}>
                        <Text style={{fontSize: 20, color: '#030303'}}>
                          {i18n.t('order.notes_from_packer')}
                        </Text>
                        <View
                          style={[CommonStyles.noteViewStyle, {right: '3%'}]}
                        />
                      </LinearGradient>
                    )}
                    <View style={globalStyles.m_10}>
                      <View style={{flexDirection: 'row'}}>
                        <Text style={styles.headerTextNew}>
                          {i18n.t('order.internal_notes')}
                        </Text>
                        <TouchableOpacity
                          testID="toolTip1"
                          onPress={() => this.toolTipFun('1')}>
                          <i
                            className="icon-info-circled"
                            style={{
                              color: 'rgb(172,187,199)',
                              fontSize: '17px',
                              marginTop: '21%',
                              marginLeft: '35%'
                            }}></i>
                        </TouchableOpacity>
                      </View>

                      {this.state.notesInternal == false &&
                      (basicinfo?.notes_internal == null
                        ? basicinfo?.notes_internal == null
                        : basicinfo?.notes_internal.trim() == '') ? (
                        <TouchableOpacity
                          testID="openTextField1"
                          onPress={() => {
                            this.openTextField('1');
                          }}
                          style={{
                            width: 70,
                            height: 70
                          }}>
                          <i
                            className="icon-plus"
                            style={{color: 'white', fontSize: '60px'}}></i>
                        </TouchableOpacity>
                      ) : (
                        <TextInput
                          testID="internalNotesInput"
                          multiline={true}
                          editable={internal_notes}
                          numberOfLines={4}
                          style={styles.inputBox}
                          onChangeText={text => {
                            this.props.show === true
                              ? this.permissionCheck()
                              : this.props.handleChange('notes_internal', text);
                          }}
                          value={basicinfo.notes_internal}
                        />
                      )}
                    </View>
                    <View style={globalStyles.m_10}>
                      <View style={{flexDirection: 'row'}}>
                        <Text style={styles.headerTextNew}>
                          {i18n.t('order.notes_to_packer')}
                        </Text>
                        <TouchableOpacity
                          testID="toolTip2"
                          onPress={() => this.toolTipFun('2')}>
                          <i
                            className="icon-info-circled"
                            style={{
                              color: 'rgb(172,187,199)',
                              fontSize: '17px',
                              marginTop: '21%'
                            }}></i>
                        </TouchableOpacity>
                      </View>

                      {this.state.notesToPacker == false &&
                      (basicinfo?.notes_toPacker == null
                        ? basicinfo?.notes_toPacker == null
                        : basicinfo?.notes_toPacker.trim() == '') ? (
                        <TouchableOpacity
                          testID="openTextField2"
                          onPress={() => {
                            this.openTextField('2');
                          }}
                          style={{
                            height: 70,
                            width: 70
                          }}>
                          <i
                            className="icon-plus"
                            style={{color: 'white', fontSize: '60px'}}></i>
                        </TouchableOpacity>
                      ) : (
                        <TextInput
                          testID="notesToPackerInput"
                          multiline={true}
                          editable={notes_to_packer}
                          numberOfLines={4}
                          style={styles.inputBox}
                          onChangeText={text => {
                            this.props.show === true
                              ? this.permissionCheck()
                              : this.props.handleChange('notes_toPacker', text);
                          }}
                          value={basicinfo.notes_toPacker}
                        />
                      )}
                    </View>
                    <View style={globalStyles.m_10}>
                      <View style={{flexDirection: 'row'}}>
                        <Text style={styles.headerTextNew}>
                          {i18n.t('order.customer_comments')}
                        </Text>
                        <TouchableOpacity
                          testID="toolTip3"
                          onPress={() => this.toolTipFun('3')}>
                          <i
                            className="icon-info-circled"
                            style={{
                              color: 'rgb(172,187,199)',
                              fontSize: '17px',
                              marginTop: '21%',
                              marginLeft: '35%'
                            }}></i>
                        </TouchableOpacity>
                      </View>

                      {this.state.customerComments == false &&
                      (basicinfo?.customer_comments == null
                        ? basicinfo?.customer_comments == null
                        : basicinfo?.customer_comments?.trim() == '') ? (
                        <TouchableOpacity
                          testID="openTextField3"
                          onPress={() => {
                            this.openTextField('3');
                          }}
                          style={{
                            width: 70,
                            height: 70
                          }}>
                          <i
                            className="icon-plus"
                            style={{color: 'white', fontSize: '60px'}}></i>
                        </TouchableOpacity>
                      ) : (
                        <TextInput
                          testID="customerCommentsInput"
                          editable={customer_comments_notes}
                          multiline={true}
                          numberOfLines={4}
                          style={styles.inputBox}
                          onChangeText={text => {
                            this.props.show === true
                              ? this.permissionCheck()
                              : this.props.handleChange(
                                  'customer_comments',
                                  text
                                );
                          }}
                          value={basicinfo.customer_comments}
                        />
                      )}
                    </View>
                  </View>
                  <View>
                    <View style={globalStyles.m_10}>
                      <View style={{flexDirection: 'row'}}>
                        <Text style={styles.headerTextNew}>
                          {i18n.t('order.tags')}
                        </Text>
                        <TouchableOpacity
                          testID="toolTip4"
                          onPress={() => this.toolTipFun('4')}>
                          <i
                            className="icon-info-circled"
                            style={{
                              color: 'rgb(172,187,199)',
                              fontSize: '17px',
                              marginTop: '21%',
                              marginLeft: '35%'
                            }}></i>
                        </TouchableOpacity>
                      </View>

                      {this.state.TagsSet == false &&
                      (basicinfo?.tags == null
                        ? basicinfo?.tags == null
                        : basicinfo?.tags?.trim() == '') ? (
                        <TouchableOpacity
                          testID="openTextField4"
                          onPress={() => {
                            this.openTextField('4');
                          }}
                          style={{
                            height: 70,
                            width: 70
                          }}>
                          <i
                            className="icon-plus"
                            style={{color: 'white', fontSize: '60px'}}></i>
                        </TouchableOpacity>
                      ) : (
                        <TextInput
                          testID="tagsInput"
                          multiline={true}
                          editable={tags_notes}
                          numberOfLines={4}
                          style={styles.inputBox}
                          onChangeText={text => {
                            this.props.show === true
                              ? this.permissionCheck()
                              : this.props.handleChange('tags', text);
                          }}
                          value={basicinfo.tags}
                        />
                      )}
                    </View>
                  </View>
                  <View>
                    <View style={globalStyles.m_10}>
                      <View style={{flexDirection: 'row'}}>
                        <Text style={styles.headerTextNew}>
                          {i18n.t('order.notes_from_packer')}
                        </Text>
                        <TouchableOpacity
                          testID="toolTip5"
                          onPress={() => this.toolTipFun('5')}>
                          <i
                            className="icon-info-circled"
                            style={{
                              color: 'rgb(172,187,199)',
                              fontSize: '17px',
                              marginTop: '21%',
                              marginLeft: '35%'
                            }}></i>
                        </TouchableOpacity>
                      </View>
                      {this.state.notesFromPacker == false &&
                      (basicinfo?.notes_fromPacker == null
                        ? basicinfo?.notes_fromPacker == null
                        : basicinfo?.notes_fromPacker?.trim() == '') ? (
                        <TouchableOpacity
                          testID="openTextField5"
                          onPress={() => {
                            this.openTextField('5');
                          }}
                          style={{
                            height: 70,
                            width: 70
                          }}>
                          <i
                            className="icon-plus"
                            style={{color: 'white', fontSize: '60px'}}></i>
                        </TouchableOpacity>
                      ) : (
                        <TextInput
                          testID="notesFromPackerInput"
                          multiline={true}
                          editable={notes_from_packer}
                          numberOfLines={4}
                          style={styles.inputBox}
                          onChangeText={text => {
                            this.props.show === true
                              ? this.permissionCheck()
                              : this.props.handleChange(
                                  'notes_fromPacker',
                                  text
                                );
                          }}
                          value={basicinfo.notes_fromPacker}
                        />
                      )}
                    </View>
                  </View>
                </View>
              )}
            </View>
          </View>
        </ScrollView>
      </>
    );
  }
}
