import React, {Component, useState} from 'react';
import {View, Text, StyleSheet, TouchableOpacity, Image} from 'react-native';
import {CountdownCircleTimer} from 'react-native-countdown-circle-timer';
import {Camera} from 'expo-camera';
import {connect} from 'react-redux';
import {LinearGradient} from 'expo-linear-gradient';
import CommonStyles from '../../style/commonStyles';
import {AddOrderImage, clearImageStates,emailCustomer} from '../../actions/orderActions';
import {SearchScanpackOrder} from '../../actions/scanpackAction';
import {fontFamily} from '../../helpers/fontFamily';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import {faCancel, faCheck, faRedo} from '@fortawesome/free-solid-svg-icons';

let isImageTaken = false

class PackingCam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uniqueId: Math.random(),
      photo: null,
      isPlaying: false
    };
    this.camera = this.props.route.params.cameraInstance;
    this.snap = this.snap.bind(this);
    this.onCameraReady = this.onCameraReady.bind(this);
    this.onRetake = this.onRetake.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.redirectFun = this.redirectFun.bind(this);
    this.handleCounterComplete = this.handleCounterComplete.bind(this);
  }

  async snap(isUpdate = false) {
    if (this.camera.ref.current) {
      let photo = await this.camera.ref.current.takePictureAsync();
      this.setState({
        photo,
        isPlaying: false
      });
      isImageTaken = isUpdate
      if(isUpdate){
        this.onConfirm(photo.uri)
      }
    }
  }

  handleCounterComplete() {
    if (this.props.route.params.cameraOption === 'Manual') {
      return;
    }
    this.snap();
  }
  onCameraReady() {
    this.setState({
      isPlaying: true
    });
  }
  onRetake() {
    this.setState({
      uniqueId: Math.random(),
      isPlaying: true,
      photo: null
    });
  }
  redirectFun() {
    // this.props.SearchScanpackOrder('', false);
    let page = '';
    if (
      (this.props.route.params.postScanning !== 'None' &&
        this.props.route.params.post_scanning_option_second !== 'None') ||
      (this.props.route.params.postScanning !== 'None' &&
        this.props.route.params.post_scanning_option_second === 'None')
    ) {
      page = 'PackingCam';
      this.props.navigation.navigate('ScanPackItem', {
        data: this.props.route.params.order,
        packingcamPage: page
      });
    }
    // else if(this.props.route.params.postScanning !== "None" &&  this.props.route.params.post_scanning_option_second === "None"){
    //   page = "PackingCam"
    //   this.props.navigation.navigate("ScanPackItem", { data : this.props.route.params.order, packingcamPage: page })
    // }
    else if (
      this.props.route.params.postScanning === 'Barcode' &&
      this.props.route.params.post_scanning_option_second == 'None'
    ) {
      this.props.navigation.navigate('ScanPack', {
        update: true,
        time: new Date()
      });
    } else {
      this.props.navigation.navigate('ScanPack', {
        update: true,
        time: new Date()
      });
    }
    if(this.props?.route?.params?.settingCheck?.callOrder?.data?.order[0]?.print_ss_label && this.props.route.params.postScanning === "None"){
      this.props.navigation.navigate('ShippingLabel',{
        time: new Date(),
        basicinfo:this.props.route.params?.settingCheck?.Order?.basicinfo,
        directPrintingSetting: this.props.route.params?.settingCheck?.bothSettings?.data?.data?.general_setting?.print_ss_shipping_labels,
        isolatedComponent: true,
        userRole: this.props?.route?.params?.userRole,
        navigation: this.props?.navigation || null,
      })
    }
    // this.props.navigation.navigate("ScanPack", { update: true, time: new Date(), page : page, order_in : this?.props?.route?.params?.order?.increment_id })
    // let page = "PackingCam"
  }
  handlePrintOrderBarcodePrint() {
    if(this.props?.route?.params?.isPrintOrderBarcode) {
      this.props?.route?.params?.multipleTime();
      this.props?.route?.params?.resetIsPrintOrderBarcode();
    }
  }
  onCancel() {
    this.handlePrintOrderBarcodePrint();
    if (this.props.shouldNavigate === false) this.props.hideModal();
    else setTimeout(() => this.redirectFun(), 1000);
  }
  onConfirm(photoUri = null) {
    const isManual = this.props.route.params.cameraOption === 'Manual';
    if (!isImageTaken && !this.state.photo && isManual) {
      this.snap(true);
      return;
    }

    const photoURL = photoUri || this.state.photo.uri

    // upload image here then navigate to scan pack most probably in componentwill
    let packingRoute = this.props?.route?.params
    let settingEmail = packingRoute?.settingCheck?.bothSettings?.data?.data?.scanpack_setting
    this.props.AddOrderImage(this.props.route.params.order.id, photoURL);
    this.handlePrintOrderBarcodePrint();
    if(this.props?.route?.name === "PackingCam" && settingEmail?.email_customer_option && packingRoute?.settingCheck?.Order?.basicinfo?.email?.length >=0){
      this.props.emailCustomer(packingRoute?.order?.id)
    }
    //added a changed to not wait for image upload when scanning order
    if(this.props.shouldNavigate === undefined){
      this.redirectFun();
    }
    // this.props.SearchScanpackOrder("", false)
    // this.props.navigation.navigate("ScanPack", { update: true, time: new Date() })
  }
  keyDownPressed = event => {
    event.preventDefault();
    if (event.key === 'Space' || event.code === 'Space') {
      if (!this.state.photo) {
        if (this.props.route.params.cameraOption !== 'do_not_take_image') {
          this.snap();
        }
      } else {
        this.onRetake();
      }
    } else if (event.key === 'Enter' || event.code === 'Enter') {
      this.onConfirm();
    } else if (event.key === 'Escape' || event.code === 'Escape') {
      this.onCancel();
    }
  };
  componentDidMount() {
    document.addEventListener('keydown', this.keyDownPressed, false);
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyDownPressed);
    this.props.clearImageStates();
  }
  componentDidUpdate() {
    if (this.props.imageUploaded) {
      if (this.props.shouldNavigate === false) this.props.hideModal();
      else setTimeout(() => this.redirectFun(), 1000);
    }
  }
  render() {
    const {photo, uniqueId, isPlaying} = this.state;
    const failedToUpload = this.props.imageUploaded === false;
    const {uploadingImage} = this.props;
    const DisplayCountDown = this.props.route.params?.cameraOption === 'Manual' ? false : true;
    return (
      <LinearGradient
        colors={[
          '#000',
          '#000',
          '#5c778f',
          '#5c778f',
          '#253c57',
          '#253c57',
          '#000'
        ]}
        style={{
          flex: 1,
          paddingBottom: 15
        }}
      >
        <View style={styles.container}>
          {photo ? (
            <Image source={photo} style={styles.camera} />
          ) : (
            <View style={styles.camera}>
              <View
               style={{height: '100%'}}
              >
                {this.camera &&
                React.cloneElement(this.camera, {
                  style: styles.camera,
                  onCameraReady: this.onCameraReady,
                })}
                <View
                  style={styles.boxContainerTwo}
                >
                  {DisplayCountDown && <CountdownCircleTimer
                    key={uniqueId}
                    onComplete={this.handleCounterComplete}
                    isPlaying={isPlaying}
                    duration={3}
                    colors={['#004777', '#F7B801', '#ffffff', '#ffffff']}
                    colorsTime={[7, 5, 2, 0]}
                    strokeWidth={0}
                  >
                    {({remainingTime}) => (
                      <Text
                        style={[CommonStyles.textStyle,{fontFamily: 'Poppins_600SemiBold',fontSize:"300px",color:"rgba(255, 255, 255, 0.5)"}]}
                      >
                        {remainingTime}
                      </Text>
                    )}
                  </CountdownCircleTimer>}
                </View>
              </View>
            </View>
          )}
          <LinearGradient
            start={{x: 0, y: 1}}
            end={{x: 0, y: 0}}
            colors={['#142130', '#304454']}
            style={{
              backgroundColor: '#707070'
            }}
          >
            <View style={styles.actions}>
              <Action
                onPress={this.onCancel}
                label={'Cancel'}
                icon={faCancel}
                disabled={photo === null || uploadingImage}
                tooltipText={'ESC to Cancel'}
              />
              <Divider />
              <Action
                onPress={this.onRetake}
                label={'Retake'}
                icon={faRedo}
                disabled={photo === null || uploadingImage}
                tooltipText={'SPACEBAR to Retake'}
              />
              <Divider />
              <Action
                onPress={this.onConfirm}
                label={'Confirm'}
                icon={faCheck}
                disabled={photo === null || uploadingImage}
                tooltipText={'ENTER to Confirm'}
              />
            </View>
            {failedToUpload && (
              <View style={{justifyContent: 'center', alignItems: 'center'}}>
                <Text style={{color: 'orange'}}>
                  Failed to upload image, try again
                </Text>
              </View>
            )}
            {uploadingImage && (
              <View style={{justifyContent: 'center', alignItems: 'center'}}>
                <Text style={{color: 'yellow'}}>Uploading...</Text>
              </View>
            )}
            {this.props.imageUploaded && (
              <View style={{justifyContent: 'center', alignItems: 'center'}}>
                <Text style={{color: 'green'}}>Upload complete</Text>
              </View>
            )}
          </LinearGradient>
        </View>
      </LinearGradient>
    );
  }
}

const Action = ({onPress, label, icon, disabled, tooltipText}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <TouchableOpacity
      testID='toolTip'
      onMouseEnter={() => {
        setShowTooltip(true);
      }}
      onMouseLeave={() => {
        setShowTooltip(false);
      }}
      disabled={disabled}
      style={styles.btn}
      onPress={onPress}
    >
      <Text style={styles.btnText}>{label}</Text>
      <FontAwesomeIcon
        style={CommonStyles.packingCamIcon}
        icon={icon}
      />

      {showTooltip && (
        <View style={{position: 'absolute', top: 35}}>
          <View
            style={CommonStyles.boxContainerThree}
          />
          <LinearGradient
            locations={[0, 1]}
            colors={['#8a9daf', '#d7e1e9']}
            style={{borderRadius: 5}}
          >
            <Text
              style={{fontFamily: fontFamily.font300, padding: 5}}
              numberOfLines={1}
            >
              {tooltipText}
            </Text>
          </LinearGradient>
        </View>
      )}
    </TouchableOpacity>
  );
};
const Divider = () => <View style={styles.divider} />;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  camera: {
    width: 400,
    height: 350
  },
  cameraContainer: {
    width: '100%',
    height: 350
  },
  boxContainerTwo: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    top: '50%',
    left: '40%',
    transform: [{translateX: -50}, {translateY: -50}]
  },
  actions: {
    width: 400,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row'
  },
  btn: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 5,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 10,
    borderRadius: 5
  },
  btnText: {
    fontFamily: fontFamily.font800,
    fontSize: 18,
    paddingLeft: 1,
    paddingRight: 1,
    color: '#fff',
    textAlign: 'center',
    marginRight: 5
  },
  divider: {
    alignSelf: 'center',
    width: 1,
    height: 20,
    backgroundColor: '#455766'
  }
});

const mapStateToProps = state => {
  return {
    searchOrder: state.scanpack,
    imageUploaded: state.order.addOrderImage,
    uploadingImage: state.order.uploadingImage
  };
};

const mapDispatchToProps = {
  AddOrderImage,
  clearImageStates,
  SearchScanpackOrder,
  emailCustomer
};

export default connect(mapStateToProps, mapDispatchToProps)(PackingCam);
