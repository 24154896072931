import React, {useEffect} from 'react';
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image,
  ViewBase
} from 'react-native';
import {AntDesign, FontAwesome} from '@expo/vector-icons';

const Panel = ({
  title,
  titleStyle,
  contentVisible,
  compressExpandCarrier,
  carrierVisible,
  showHideCarrier,
  headerContainerStyle,
  iconColor,
  children,
  activateEditButton,
  toggleCarrierDropdownVisibility,
  carrierDropdownVisibility,
  carrierDropdownVisibilityNormalMode,
  toggleCarrierDropdownVisibilityNormalMode
}) => {
  return activateEditButton ? (
    <View style={styles.shadowBox}>
      <View style={styles.screenContainer}>
        <View style={[headerContainerStyle, styles.header]}>
          <Text style={titleStyle}>{title}</Text>

          <TouchableOpacity
            testID='panelContainer'
            style={styles.marginHorizontal}
            onPress={showHideCarrier}>
            <FontAwesome
              name={carrierVisible ? 'eye' : 'eye-slash'}
              size={24}
              color={iconColor}
            />
          </TouchableOpacity>
          <TouchableOpacity
            testID='contractedCarrier'
            onPress={toggleCarrierDropdownVisibility}
            style={styles.marginHorizontal}>
            <FontAwesome
              name={carrierDropdownVisibility ? 'expand' : 'compress'}
              size={24}
              color={iconColor}
            />
          </TouchableOpacity>
        </View>
        <View style={{justifyContent: 'center'}}>
          <TouchableOpacity onPress={compressExpandCarrier}>
            <AntDesign
              name={contentVisible ? 'caretup' : 'caretdown'}
              size={24}
              color={iconColor}
            />
          </TouchableOpacity>
        </View>
      </View>

      {contentVisible && <View>{children}</View>}
    </View>
  ) : (
    <>
      {carrierVisible && (
        <View style={styles.shadowBox}>
          <View style={styles.screenContainer}>
            <View style={[headerContainerStyle, styles.header]}>
              <Text style={titleStyle}>{title}</Text>
            </View>
            <View style={{justifyContent: 'center'}}>
              <TouchableOpacity
               testID='panelContainer'
                onPress={toggleCarrierDropdownVisibilityNormalMode}>
                <AntDesign
                  name={
                    carrierDropdownVisibilityNormalMode
                      ? 'caretup'
                      : 'caretdown'
                  }
                  size={24}
                  color={iconColor}
                />
              </TouchableOpacity>
            </View>
          </View>
          {carrierDropdownVisibilityNormalMode && <View>{children}</View>}
        </View>
      )}
    </>
  );
};

export default Panel;

const styles = StyleSheet.create({
  screenContainer: {
    flexDirection: 'row'
  },
  header: {
    flex: 1,
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center'
  },
  marginHorizontal: {marginHorizontal: 10},
  shadowBox: {
    paddingHorizontal: '1%',
    paddingVertical: '1%',
    backgroundColor: 'rgba(104, 135, 156, 0.4)',
    borderRadius: 10,
    marginHorizontal: '1%',
    marginTop: 10,
    marginBottom: 10,
    shadowColor: '#50708b',
    shadowOffset: {width: 1, height: 1},
    shadowRadius: 10
  }
});
