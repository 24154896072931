import {StyleSheet} from 'react-native';
import {fontFamily} from '../helpers/fontFamily';

const styles = StyleSheet.create({
  drawerBackView: {
    flex: 1,
    zIndex: 15,
    // backgroundColor: "rgba(226, 42 , 42 , 0)",
    //  backgroundColor: "red",
    height: '100%',
    width: '31%'
  },
  iconsCommon: {
    marginLeft: -8,
    fontSize: '35px',
    color: '#768ea5'
  },
  iconText: {
    fontFamily: fontFamily.font700,
    fontSize: 16,
    marginLeft: 5,
    color: '#768ea5'
  },
  iconCommonSub: {
    fontSize: '24px',
    color: '#768ea5'
  },
  iconTextSub: {
    fontFamily: fontFamily.font400,
    fontSize: 16,
    marginLeft: 5,
    color: '#768ea5'
  },
  iconnewCommon: {
    marginLeft: -8,
    fontSize: '32px',
    color: '#768ea5'
  },
  iconnewCommonText:{
    fontFamily: fontFamily.font700,
    fontSize: 16,
    marginLeft: 8,
    marginTop: 6,
    color:"#768ea5"
  },
  drawerMainView: {
    // backgroundColor: "blue",

    backgroundColor: '#000',
    height: '100%',

    // width: "65%"
    width: '55%',
    paddingTop: 20
  },
  closeButtonContainer: {
    flexDirection: 'row',
    margin: 10
  },
  textStyle: {
    backgroundColor: 'white',
    color: 'black',
    margin: 5,
    paddingRight: 8,
    paddingLeft: 8,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 15
  },
  refreshButton: {
    marginTop: 5,
    marginBottom: 5
  },
  closeButton: {
    backgroundColor: 'white',
    textAlign: 'center',
    borderRadius: 30,
    padding: 2
  }
});
export default styles;
