import React from 'react';
import {Text, View} from 'react-native'
import { LinearGradient } from 'expo-linear-gradient';

const DisplayQuantityInBox = ({
  qty,
  boxName,
  isMobileView
}) => {
  return (
    <View
     testID='QtyBox'
     style={{
      zIndex: '1',
      marginTop: isMobileView ? '-83px':'-88px', 
      position: 'absolute'
     }}
    >
    <LinearGradient
    
    locations={[0, 0.04, 0.08]}
    start={{x: 0.9, y: 1}}
    colors={[
      '#3d4851',
      '#9EA4A8',
      '#ffffff'
    ]}
    style={{
      height: 'fit-content',
      width: 100,
      backgroundColor: '#000000',
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 0
      },
      shadowOpacity: 0.58,
      shadowRadius: 29,
      elevation: 3,
      borderTopLeftRadius: 5,
      marginTop: 5,
      opacity: 0.6,
      justifyContent: 'center',
      alignItems: 'center'
    }}
    >
    <Text
     style={{
      fontSize: isMobileView ? 14 : 22 , 
      fontFamily: 'Poppins_600SemiBold',
      paddingRight:'25%'
     }}
    >
      {qty}
      <Text
       style={{
        fontSize: 12,
        paddingLeft: 3,
        paddingRight: 4
       }}
      >
        in {boxName}
      </Text>
    </Text>
    </LinearGradient>
    </View>
  )
}
export default DisplayQuantityInBox;