import React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import styles from '../style/shippinglabel.js';
import { LinearGradient } from 'expo-linear-gradient';
const UpdateRatesButton = (props) => {
  const {
    windowWidth,
    carrierLoading,
    handlePoundsChange,
    fetchUpdatedRates,
    istop
  } = props;

  return (
    <TouchableOpacity
      disabled={carrierLoading}
      onPress={() => {
        handlePoundsChange();
        fetchUpdatedRates();
      }}>
      <LinearGradient
        colors={['#7BFAC3', '#1CBB77']}
        locations={[0.1, 0.9]}
        style={{
          borderRadius: 10,
          marginLeft: istop ? 10 : 7,
          textAlign: 'center',
          width: 162,
          top: istop && '10px'
        }}>
        <Text style={styles.editRatesButton}>
          {windowWidth >= 900 ? (
            <span style={{ fontSize: 22 }}>ENTER</span>
          ) : (
            <span style={{ fontSize: 22 }}>TAP</span>
          )}
          {'\n'}To update Rates
        </Text>
      </LinearGradient>
    </TouchableOpacity>
  );
};

export default UpdateRatesButton;
