import { LinearGradient } from 'expo-linear-gradient';
import React, { Component } from 'react';
import {
  View,
  Text,
  TextInput,
  Image,
  TouchableOpacity,
  ScrollView
} from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import {
  faArrowRightFromBracket,
  faArrowRightToBracket,
  faBriefcase,
  faShuffle
} from '@fortawesome/free-solid-svg-icons';
import i18n from 'i18n-js';
import styles from '../../style/productdetail';
import CommonStyles from './productStyles';
import RemoveImage from './removeImage';
import EditableInputMobile from '../../commons/Inputs/editableInputMobile';
import ProductInput from '../../commons/Inputs/productInfoInput';
import CommonFunction from './commonFunction';
import AddIconFunction from './addIconFunction';
class ProductInfoMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false
    }
  }
  getIcon = (show,top) => {
    if (show) {
      return <i
        className="icon-up-open"
        style={{
          color: '#cdcdcd',
          height: '26',
          width: '24',
          marginTop: top ? 2 : ''
        }}
      ></i>
    }
    else {
      return <i
        className="icon-down-open"
        style={{
          color: '#cdcdcd',
          height: '26',
          width: '24',
          marginTop: top ? 2 : ''
        }}
      ></i>
    }
  }
  render() {
    let { basicinfo, images, inventoryWarehouses } = this.props;
    let skus = this?.props?.product?.skus;
    let cats = this?.props?.product?.cats;
    return (
      <>
        {basicinfo && this.props.openPrompt === false && !this.props.showProductAliasPage ? (
          <>
            <View
              style={[
                this.props.windowWidth < 450
                  ? { marginLeft: '20px', marginTop: '-5px', zIndex: 999 }
                  : {
                    marginLeft: '30px',
                    marginTop: '-35px',
                    position: 'relative',
                    zIndex: 999
                  }
              ]}
            >
              {images != undefined &&
                images?.length > 0 &&
                images?.map((img, index) => {
                  return (
                    <>
                      <View style={{ zIndex: 999 }}>
                        <RemoveImage
                          ModalShow={this.props.ModalShow}
                          closeModelImg={this.props.modelShowStateFun}
                          imageRemove={this.props.imageRemove}
                        ></RemoveImage>
                      </View>
                    </>
                  );
                })}
              {basicinfo.is_kit == 1 ? (
                <Text
                  style={[styles.KitHeading, {display: 'none'}]}
                >
                  KIT:{this.props.product?.skus?.[0]?.sku}
                </Text>
              ) : (
                <Text
                  style={[styles.KitHeading, , {display: 'none'}]}
                >
                  {this.props.product?.skus?.[0]?.sku}
                </Text>
              )}
            </View>
            <View style={{ width: 'auto' }}>
              <TouchableOpacity testID='contentLessMobile' onPress={() => this.props.contentLessMobile()}>
                <View style={{ flexDirection: 'row', marginLeft: 23 }}>
                  <Text
                    style={CommonStyles.textStyleTypeFive}
                  >
                    {'Edit'}
                  </Text>
                  {this.getIcon(this.props.lessMoreMobile,true)}
                </View>
              </TouchableOpacity>
            </View>
            {this.props.lessMoreMobile && (
              <>
                <View style={{ flexDirection: 'column', marginBottom: 5 }}>
                  <TouchableOpacity
                    testID='aliasOfAnother'
                    style={CommonStyles.texAndIconContainer}
                    onPress={() => {
                      this.props.openAlias(true)
                    }}
                  >
                    <FontAwesomeIcon
                      style={CommonStyles.iconStyle}
                      icon={faArrowRightToBracket}
                    />
                    <Text
                      style={[CommonStyles.textStyleTypeFour,{marginLeft: 8}]}
                    >
                      {i18n.t('product.alias_of_another')}
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    testID='aliasesOfThisProduct'
                    style={CommonStyles.texAndIconContainerTwo}
                    onPress={() => {
                      this.props.openAlias(false)
                    }}
                  >
                    <FontAwesomeIcon
                      style={CommonStyles.iconStyle}
                      icon={faArrowRightFromBracket}
                    />
                    <Text
                      style={[CommonStyles.textStyleTypeFour,{marginLeft: 8}]}
                    >
                      {i18n.t('product.aliases_of_this_product')}
                    </Text>
                  </TouchableOpacity>
                  {basicinfo?.is_kit == 0 ? (
                    <View
                      style={CommonStyles.texAndIconContainerTwo}
                    >
                      <TouchableOpacity
                        testID='changeToKit'
                        onPress={() => {
                          this.props.show === true
                            ? this.props.permissionCheck()
                            : this.props.changeProductKit();
                        }}
                      >
                        <FontAwesomeIcon
                          style={CommonStyles.iconStyle}
                          icon={faBriefcase}
                        />
                        <Text
                          style={CommonStyles.textStyleTypeThree}
                        >
                          {i18n.t('product.product_to_kit')}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  ) : (
                    <View
                    style={CommonStyles.texAndIconContainerTwo}
                    >
                      <TouchableOpacity
                        testID='kitRemove'
                        onPress={() => {
                          this.props.show === true
                            ? this.props.permissionCheck()
                            : this.props.kitRemove();
                        }}
                      >
                        <FontAwesomeIcon
                          style={CommonStyles.iconStyle}
                          icon={faShuffle}
                        />
                        <Text
                          style={CommonStyles.textStyleTypeThree}
                        >
                          {i18n.t('product.kit_to_product')}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  )}
                </View>
              </>
            )}

            <View
              style={{
                flexDirection: 'row',
                marginLeft: this.props.windowWidth <= 750 ? 10 : 63
              }}
            >
              <View>
                <ScrollView
                  style={{
                    width:
                      this.props.windowWidth > 500 &&
                        this.props.windowWidth < 700
                        ? 200
                        : this.props.windowWidth >= 720
                          ? 550
                          : 220
                  }}
                  horizontal={true}
                  showsHorizontalScrollIndicator={false}
                  showsVerticalScrollIndicator={false}
                >
                  {images?.length > 0 &&
                    images?.map((img, index) => {
                      return (
                        <>
                          <View style={{}}>
                            <Image
                              style={{
                                width: 90,
                                height: 90,
                                borderWidth: 1,
                                // padding:2,
                                borderRadius: 8,
                                marginLeft:
                                  this.props.windowWidth >= 450 ? 5 : 4,
                                // borderColor: "#869db1",
                                borderBottomWidth: 0
                              }}
                              source={{ uri: img?.image ? img?.image : '' }}
                              key={index}
                            />
                          </View>
                          {/* } */}

                          <TouchableOpacity
                            testID='imageRemove'
                            onPress={() => {
                              this.props.show === true
                                ? this.props.permissionCheck()
                                : this.props.removeImageById(img.id);
                            }}
                          >
                            <Text
                              style={CommonStyles.removeImageText}
                            >
                              Remove
                            </Text>
                          </TouchableOpacity>
                        </>
                      );
                    })}
                </ScrollView>
              </View>
              <TouchableOpacity
                testID='pickImage'
                onPress={() => {
                  this.props.show === true
                    ? this.props.permissionCheck()
                    : this.props.pickImage();
                }}
              >
                <View
                  style={{
                    height: 60,
                    width: 70,
                    marginTop: 45,
                    marginLeft:
                      this.props.windowWidth < 400 &&
                        this.props.windowWidth <= 700
                        ? 70
                        : this.props.windowWidth >= 720
                          ? -11
                          : 90
                  }}
                >
                  <LinearGradient
                    colors={[
                      // "#000",
                      // "#000",
                      '#5c778f',
                      '#5c778f',
                      // "#253c57",
                      // "#253c57",
                      '#000'
                    ]}
                    start={[-0.5, -1]}
                    style={[styles.iconPlusMobileGradiant, { marginLeft: this.props.windowWidth <= 720 ? '-50px' : 70, }]}
                  >
                    <i
                      className="icon-plus"
                      style={{ color: 'white', fontSize: '62px' }}
                    ></i>

                    <Text
                      style={CommonStyles.addImageText}
                    >
                      Add Image
                    </Text>
                  </LinearGradient>
                </View>
              </TouchableOpacity>
            </View>
            <View
              style={[CommonStyles.QtyContainer,{marginTop: '4%'}]}
            >
              <View style={{ margin: 10, flexDirection: 'row' }}>
                <Text
                  style={[styles.productInfoText, { marginRight: '-60px', marginLeft: '8px', }]}
                >
                  {i18n.t('product.QOH')}
                </Text>
                <ProductInput
                  style={[styles.productInfoInput, { margin: 15, marginTop: '25px', }]}
                  onChangeText={(text, name) => {
                    this.props?.onChangeTextInfo(text, "quantity_on_hand")
                  }}
                  editable={this.props.qohFocus}
                  value={inventoryWarehouses[0]?.info?.quantity_on_hand}
                // keyboardType={'numeric'}
                />
                <Text
                  style={[styles.productInfoText, { marginLeft: '15px', marginRight: '-68px', }]}
                >
                  {i18n.t('product.location1')}
                </Text>
                <ProductInput
                  style={[styles.productInfoInput, { marginTop: "25px", marginLeft: "-18px", width: "50%" }]}
                  editable={this.props.locationPrimaryFocus}
                  onChangeText={(text, name) => {
                    this.props?.onChangeTextInfo(text, "location_primary")
                  }}
                  value={inventoryWarehouses[0]?.info?.location_primary}
                />
              </View>
              <TouchableOpacity testID='showProductDetail' onPress={() => this.props.contentLess()}>
                <View
                  style={CommonStyles.alignItemsTypeOne}
                >
                  <Text
                    style={CommonStyles.textStyleTypeFive}
                  >
                    {this.props.lessCountFalse ? 'less' : 'More'}
                  </Text>
                  {this.getIcon(this.props.lessCountFalse)}
                </View>
              </TouchableOpacity>
              {this.props.lessCountFalse && (
                <>
                  <View style={{ margin: 10, flexDirection: 'row' }}>
                    <CommonFunction
                      label={i18n.t('product.location1')}
                      onChangeText={(text) => { this.props.onChangeTextInfo(text, 'location_primary') }}
                      editable={this.props.locationPrimaryFocus}
                      value={inventoryWarehouses[0]?.info?.location_primary}
                      textLeft={true}
                    />
                    <CommonFunction
                      label={i18n.t('product.location_qty1')}
                      onChangeText={(text) => { this.props.onChangeTextInfo(text, 'location_primary_qty') }}
                      editable={this.props.locationPrimaryQtyFocus}
                      value={inventoryWarehouses[0]?.info?.location_primary_qty}
                      textLeft={true}
                      right={true}
                    />
                  </View>

                  <View style={{ margin: 10, flexDirection: 'row' }}>
                    <CommonFunction
                      label={i18n.t('product.location2')}
                      onChangeText={(text) => { this.props.onChangeTextInfo(text, 'location_secondary') }}
                      editable={this.props.locationSecondaryFocus}
                      value={inventoryWarehouses[0]?.info?.location_secondary}
                      textLeft={true}
                    />
        
                    <CommonFunction
                      label={i18n.t('product.location_qty2')}
                      onChangeText={(text) => { this.props.onChangeTextInfo(text, 'location_secondary_qty') }}
                      editable={this.props.locationSecondaryQtyFocus}
                      value={inventoryWarehouses[0]?.info?.location_secondary_qty}
                      textLeft={true}
                      right={true}
                    />
                  </View>

                  <View style={{ margin: 10, flexDirection: 'row' }}>
                    <CommonFunction
                      label={i18n.t('product.location3')}
                      onChangeText={(text) => { this.props.onChangeTextInfo(text, 'location_tertiary') }}
                      editable={this.props.locationTertiarytFocus}
                      value={inventoryWarehouses[0]?.info?.location_tertiary}
                      textLeft={true}
                    />
                    <CommonFunction
                      label={i18n.t('product.location_qty3')}
                      onChangeText={(text) => { this.props?.onChangeTextInfo(text, 'location_tertiary_qty') }}
                      editable={this.props?.locationTertiaryQtyFocus}
                      value={inventoryWarehouses[0]?.info?.location_tertiary_qty}
                      textLeft={true}
                      right={true}
                    />
                  </View>

                  <View style={{ margin: 10, flexDirection: 'row' }}>
                    <CommonFunction
                      label={i18n.t('product.inventory_alert_level')}
                      onChangeText={(text) => { this.props?.onChangeTextInfo(text, 'product_inv_alert_level') }}
                      editable={this.props?.inventoryAlertLevel}
                      value={inventoryWarehouses[0]?.info?.product_inv_alert_level}
                      textLeft={true}
                    />
                    <CommonFunction
                      label={i18n.t('product.inventory_target_level')}
                      onChangeText={(text) => { this.props?.onChangeTextInfo(text, 'product_inv_target_level') }}
                      editable={this.props?.inventoryTargetLevel}
                      value={inventoryWarehouses[0]?.info?.product_inv_target_level}
                      textLeft={true}
                      right={true}
                    />
                  </View>
                </>
              )}
            </View>
            <View
              style={CommonStyles.QtyContainerTypeOne}
            >
              <Text
                style={[styles.productInfoText, { marginLeft: '15px' }]}
              >
                {i18n.t('product.product_name')}
              </Text>
              <ProductInput
                style={[styles.productInfoInput, { width: '94%', margin: 15, marginTop: '3px', }]}
                editable={this.props.proName}
                onChangeText={(text, name) => {
                  this.props.show === true
                    ? this.permissionCheck()
                    : this.props.productInfoHandleChange(text, 'name');
                }}
                value={basicinfo.name}
              />
              <Text
                style={[styles.productInfoText, { marginLeft: '15px' }]}
              >
                {i18n.t('product.skus')}
              </Text>
              <View
                style={CommonStyles.skuContainer}
              >
                {skus.length > 0 &&
                  skus?.map((sku, index) => {
                    return (
                      sku !== '' && (
                        <EditableInputMobile
                          testID={"skuInput"}
                          onSubmitEditing={(text) => this.props.updateSkus(text, sku)}
                          onRemove={() => {
                            this.props.show === true
                              ? this.props.permissionCheck()
                              : this.props.removeMultiTag("sku", index);
                          }}
                          value={sku.sku}
                          index={index}
                          isAllowed={this.props.show}
                          checkPermission={this.permissionCheck}
                          id={"removeTagSku"}
                          marginLeft={true}
                          setIsEditing={(value) => {
                            this.setState({ isEditing: value });
                          }}
                        />
                      )
                    );
                  })}
              </View>
              <AddIconFunction
                onPress={() => { this.props.show ? this.props.permissionCheck() : this.props.openPromptSku() }}
                testID={'openPromptSku'}
                left={'28%'}
                isLeft ={true}
              />
              <View style={{ flexDirection: 'row' }}>
                <View style={{ width: '60%' }}>
                  <View style={{ flexDirection: 'row', width: ' 120%' }}>
                    <Text
                      style={[styles.productInfoText, { marginTop: '4%', marginLeft: '15px' }]}
                    >
                      {i18n.t('product.barcodes')}
                    </Text>
                    <View
                      style={CommonStyles.scanQty}
                    >
                      <Text style={[styles.productInfoText]}>
                        {i18n.t('product.scan_qty')}
                      </Text>
                    </View>
                  </View>
                  {
                    this.props.barcodenew.map((barcode, index) => {
                      return (
                        barcode !== '' && (
                          <>
                            <View
                              style={{
                                flexDirection: 'row',
                                alignItems: this.state.isEditing ? 'flex-end' : 'center',
                                marginBottom: '-15px',
                                width: '100%'
                              }}
                            >
                              <EditableInputMobile
                                testID={"barcodeInput"}
                                onSubmitEditing={(text) => this.props.updateBarcodes(text, barcode)}
                                onRemove={() => {
                                  this.props.show === true
                                    ? this.props.permissionCheck()
                                    : this.props.removeMultiTag("barcode", index);
                                }}
                                value={barcode.barcode}
                                index={index}
                                isAllowed={this.props.show}
                                checkPermission={this.permissionCheck}
                                id={"removeBarcode"}
                                setIsEditing={(value) => {
                                  this.setState({ isEditing: value });
                                }}
                              />
                              <View style={{ width: '30%', marginLeft: '12%', marginBottom: 15 }}>
                                <TextInput
                                  testID='barcodeQty'
                                  style={[CommonStyles.textInputStyleTypeOne,{marginTop: 13}]}
                                  value={
                                    barcode?.packing_count === null
                                      ? 0
                                      : barcode?.packing_count
                                  }
                                  onChangeText={qty => {
                                    this.props.show === true
                                      ? this.props.permissionCheck()
                                      : this.props.updateBarcodeQty(
                                        qty,
                                        barcode
                                      );
                                  }}
                                ></TextInput>
                              </View>
                              <TouchableOpacity
                                testID='submitProduct'
                                onPress={() => {
                                  this.props.show === true
                                    ? this.props.permissionCheck()
                                    : this.props.submitProduct(barcode.barcode);
                                }}
                              >
                                <i
                                  className="icon-print"
                                  style={{
                                    color: 'white',
                                    fontSize: '27px',
                                    height: '40',
                                    width: '40',
                                    marginBottom: this.state.isEditing ? 13 : 0,
                                    marginLeft: '36%'
                                  }}
                                ></i>
                              </TouchableOpacity>
                            </View>
                          </>
                        )
                      );
                    })
                  }
                </View>
              </View>
              <AddIconFunction
                onPress={() => { this.props.show ? this.props.permissionCheck() : this.props.openPromptBar() }}
                testID={'addNewBarcode'}
                left ={'-15%'}
                top={true}
                isLeft ={true}
              />
              <View>
                <TouchableOpacity testID='contentLess' onPress={() => this.props.contentLessd()}>
                  <View
                    style={CommonStyles.iconContainer}
                  >
                    <Text
                      style={CommonStyles.textStyleTypeFive}
                    >
                      {this.props.lessCountCat ? 'less' : 'More'}
                    </Text>
                    {this.getIcon(this.props.lessCountCat)}
                  </View>
                </TouchableOpacity>
                {this.props.lessCountCat && (
                  <>
                    <View
                      style={{
                        margin: 10
                      }}
                    >
                      <View>
                        <Text
                          style={CommonStyles.textStyleTypeFive}
                        >
                          Categories
                        </Text>
                        <TextInput
                          style={CommonStyles.textInputStyleTypeOne}
                          onChangeText={text => {
                            this.props.show === true
                              ? this.props.permissionCheck()
                              : this.props.categoriesMultiInputFun(text);
                          }}
                          value={
                            this.props.categoriesMultiInput
                          }
                          editable={this.props.categoriesFocus}
                          onSubmitEditing={() =>
                            this.props.updateMultiTag('cats')
                          }
                        ></TextInput>
                        <ScrollView
                          horizontal={true}
                          showsHorizontalScrollIndicator={false}
                          showsVerticalScrollIndicator={false}
                          style={{ width: '100%' }}
                        >
                          <View
                            style={CommonStyles.catContainerTypeOne}
                          >
                            {cats &&
                              cats.length > 0 &&
                              cats.map((cat, index) => {
                                let catLength = cat.category.length;
                                return (
                                  cats !== '' && (
                                    <View
                                      key={index}
                                      style={CommonStyles.catSubContainerOne}
                                    >
                                      <TextInput
                                        style={{
                                          border: 'none',
                                          height: '31px',
                                          borderRadius: 10,
                                          paddingTop: 10,
                                          paddingBottom: 10,
                                          paddingRight: 20,
                                          paddingLeft: 5,
                                          backgroundColor: '#cdcdcd',
                                          width: catLength <= 4 ? (catLength + 2) * 12 + 'px' : catLength <= 7 ? (catLength + 2) * 11 + 'px' : (catLength + 2) * 10 + 'px',
                                          color: '#1d3045',
                                          fontFamily: 'Poppins_500Medium',
                                          fontSize: 15
                                        }}
                                        value={cat.category}
                                        editable={false}
                                        onSubmitEditing={() =>
                                          this.props.update()
                                        }
                                      />
                                      <TouchableOpacity
                                        testID='removeTagCat'
                                        key={index}
                                        onPress={() => {
                                          this.props.show === true
                                            ? this.props.permissionCheck()
                                            : this.props.removeMultiTag(
                                              'cats',
                                              index
                                            );
                                        }}
                                        style={CommonStyles.crossButtonTypeOne}
                                      >
                                        <i
                                          className="icon-cancel"
                                          style={{
                                            fontSize: '22px',
                                            color: '#2b3d4b',
                                            marginTop: -3,
                                            marginLeft: -28
                                          }}
                                        ></i>
                                      </TouchableOpacity>
                                    </View>
                                  )
                                );
                              })}
                          </View>
                        </ScrollView>
                      </View>
                    </View>
                    <View style={{ margin: 10, flexDirection: 'row' }}>
                      <CommonFunction
                        label={i18n.t('product.fnsku')}
                        onChangeText={(text) => { this.props?.onChangeTextDetail(text, 'fnsku') }}
                        editable={this.props?.fnskuFocus}
                        value={basicinfo.fnsku == null ? '' : basicinfo.fnsku}
                      />
                      <CommonFunction
                        label={i18n.t('product.asin')}
                        onChangeText={(text) => { this.props?.onChangeTextDetail(text, 'asin') }}
                        editable={this.props?.asinFocus}
                        value={basicinfo.asin == null ? '' : basicinfo.asin}
                        right={true}
                      />
                    </View>

                    <View style={{ margin: 10, flexDirection: 'row' }}>
                      <CommonFunction
                        label={i18n.t('product.isbn')}
                        onChangeText={(text) => { this.props?.onChangeTextDetail(text, 'isbn') }}
                        editable={this.props?.asinFocus}
                        value={basicinfo.isbn == null ? '' : basicinfo.isbn}
                      />
                      <CommonFunction
                        label={i18n.t('product.ean')}
                        onChangeText={(text) => { this.props?.onChangeTextDetail(text, 'ean') }}
                        editable={this.props?.eanFocus}
                        value={basicinfo.ean == null ? '' : basicinfo.ean}
                        right={true}
                      />
                    </View>

                    <View style={{ margin: 10, flexDirection: 'row' }}>
                      <CommonFunction
                        label={i18n.t('product.supplier_sku')}
                        onChangeText={(text) => { this.props?.onChangeTextDetail(text, 'supplier_sku') }}
                        editable={this.props?.supplierFocus}
                        value={basicinfo.supplier_sku == null ? '' : basicinfo.supplier_sku}
                      />
                      <CommonFunction
                        label={i18n.t('product.avg_cost')}
                        onChangeText={(text) => { this.props?.onChangeTextDetail(text, 'avg_cost') }}
                        editable={this.props?.avgCostFocus}
                        value={basicinfo.avg_cost == null ? '' : basicinfo.avg_cost}
                        right={true}
                      />
                    </View>

                    <View style={{ margin: 10, flexDirection: 'row' }}>
                      <CommonFunction
                        label={i18n.t('product.count_group')}
                        onChangeText={(text) => { this.props?.onChangeTextDetail(text, 'count_group') }}
                        editable={this.props?.countGroupsFocus}
                        value={basicinfo.count_group == null ? '' : basicinfo.count_group}
                      />
                      <CommonFunction
                        label={i18n.t('product.product_weight')}
                        onChangeText={(text) => { this.props?.onChangeTextDetail(text, 'weight') }}
                        editable={this.props?.ProductWeightFocus}
                        value={basicinfo.weight == null ? '' : basicinfo.weight}
                        right={true}
                      />
                    </View>
                  </>
                )}
              </View>
            </View>
          </>
        ) : (
          <></>
        )
        }
      </>
    );
  }
}

export default ProductInfoMobile;
