import React from 'react';
import {TouchableOpacity} from 'react-native';
import * as Clipboard from 'expo-clipboard';

export const CopyToClipboard = ({value, color = '#aad9fc'}) => {

  const handleCopyToClipboard = async () => {
    try {
      await Clipboard.setStringAsync(value);
    } catch (error) {
      console.error('Failed to copy to clipboard:', error);
    }
  };

  return (
    <TouchableOpacity testID='copyToClipboard' onPress={handleCopyToClipboard}>
      <i
        className="icon-docs"
        style={{
          color: color,
          fontSize: 15,
          marginLeft: 5
        }}></i>
    </TouchableOpacity>
  );
};

