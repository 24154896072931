import {StyleSheet} from 'react-native';
import {fontFamily} from '../../helpers/fontFamily';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: '2%'
  },
  searchContainer: {
    maxWidth: '419px',
    maxHeight: '120px',
    backgroundColor: 'rgba(157,176,193,0.07058823529411765)',
    marginTop: 20,
    borderRadius: '7px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 20
  },
  searchInput: {
    flex: 1,
    margin: 5,
    borderRadius: 5,
    fontSize: 16,
    fontFamily: fontFamily.font700,
    padding: 5,
    paddingLeft: 15,
    backgroundColor: '#f4efef',
    color: '#17364b',
    borderColor: 'rgb(172,187,199)',
    borderWidth: 2
  },
  dropDownToggleStyle: {
    flex: 1,
    height: 30,
    backgroundColor: 'transparent',
    borderBottomWidth: 2,
    borderBottomColor: '#fff'
  },
  productTopContainer: {
    alignItems: 'flex-end',
    paddingRight: '10px',
    paddingTop: '10px'
  },
  productMiddleContainer: {
    flexDirection: 'row',
    marginTop: 50,
    padding: '2%'
  },
  productBottomContainer: {
    margin: '2%',
    marginTop: '50px'
  },
  button: {
    marginRight: 10,
    borderWidth: 1,
    borderColor: '#ffffff',
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
    height: 30,
    padding: 10,
    height: 30,
    shadowColor: '#2bfac3',
    shadowOffset: {width: 0, height: 0.5},
    shadowRadius: 8,
    elevation: 5,
    shadowOpacity: 0.5
  },
  buttonText: {
    color: '#ffffff',
    paddingHorizontal: 10,
    fontFamily: fontFamily.font300,
    fontSize: '14px'
  },
  textInput: {
    backgroundColor: '#f4efef',
    borderRadius: '7px',
    padding: 5,
    marginVertical: 5
  },
  inputRow: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  inputText: {
    fontSize: '16px',
    fontFamily: fontFamily.font600,
    color: '#ffffff',
    width: '110%'
  },
  message: {
    alignSelf: 'center',
    fontSize: '12px',
    fontFamily: fontFamily.font600,
    color: '#ffffff'
  },
  receivingNotesInput: {
    marginLeft: '50px',
    fontSize: '17px',
    fontFamily: fontFamily.font400,
    flex: 1,
    height: '100px',
    backgroundColor: 'rgba(244, 239, 239,0.7)',
    borderRadius: '7px',
    padding: 5,
    maxWidth: '508px'
  },
  image: {
    maxWidth: '180px',
    maxHeight: '180px',
    borderRadius: 8,
    borderBottomWidth: 0
  },
  text: {
    padding: 5,
    fontFamily: fontFamily.font400,
    color: '#ffffff',
    fontSize: 16,
    lineHeight: '36px',
    backgroundColor: 'rgba(72,90,114,0.5)'
  },
  headerText: {
    color: '#fff',
    fontFamily: fontFamily.font600,
    opacity: 0.5,
    textAlign: 'center',
    fontSize: 19
  },
  itemText: {
    color: '#fff',
    fontStyle: fontFamily.font800,
    textAlign: 'center',
    fontSize: 16
  }
});
export default styles;
