import { LinearGradient } from 'expo-linear-gradient';
import React,{forwardRef} from 'react';
import { TextInput,Dimensions, Platform } from 'react-native';

const StyledInput = (props,ref) => {
  const width = Dimensions.get('window').width;
  let onSubmitCalled = false;
  const useStyle = props.useInputStyle
  const handleKeyPress = (e) =>{
    if(e.key === 'Enter'){
      if(width < 900 && !(window.navigator.platform === 'iPhone' || Platform.OS === 'ios')){
        props.onSubmitEditing();
        onSubmitCalled = true;
      }
    }
  }
  return (
    <LinearGradient 
    locations={[0, 1]}
    start={{ x: 0, y: 1 }}
    end={{ x: 0, y: 0 }}
    colors={['#fce8c0', '#fad492']}
    style={[props.style,{padding: 0}]}
    >
      <TextInput 
      dataSet={{ componentName: props.name}}
      testID='inputElement'
      value={props.value}
      onChangeText={props.onChangeText}
      onSubmitEditing={()=>{
        !onSubmitCalled && props.onSubmitEditing()
      }}
      blurOnSubmit={false}
      placeholder={props.placeholder}
      placeholderTextColor={props.placeholderTextColor}
      ref={ref}
      autoCapitalize='none'
      returnKeyType= 'done'
      autoFocus={props.autoFocus}
      style={useStyle ? [props.style,props.inputStyle]: [props.inputStyle]}
      onKeyPress={handleKeyPress}
      />

    </LinearGradient>
  );
}
export default forwardRef(StyledInput);