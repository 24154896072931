import React, { useState, useRef } from 'react';
import { View, Text, TouchableOpacity, Image, Platform } from 'react-native';
import ToolTip from '../../commons/toolTip';
import globalStyles from '../../style/global';
import Dropdown from '../../commons/customSelect';
import styles from '../../style/shippinglabel.js';
import shortcutImage from '../../../assets/shortcut-icon.png';
import i18n from 'i18n-js';

const ShippingDropDown = (props) => {
    const {
      id,
      showTooltip,
      name,
      input,
      windowWidth,
      shortcutsInputs,
      labelShortcuts,
      pickerConfirmationSelectedOption,
      pickerSelectedOptionsConfirmation,
      setPickerConfirmationOpen,
      setPickerConfirmationSelectedOption,
      changeInitialFormValues,
      addShortcutOther,
      confirmationRef,
      zindex
    } = props;
    return (
        <View style={{ marginTop: 5 ,  width: windowWidth <= 425 && '81%',
        borderBottomColor: '#fff', borderBottomWidth: 2, marginLeft: '2%', zIndex: zindex}}> 
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'start',
          }}>
          <View style={{ marginRight: '-17px' }}>
            <Text
              style={[
                globalStyles.py_10,
                styles.labelStyle,
                {
                  height: '64px',
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '0',
                  marginBottom: '0',
                  fontSize: 20
                  // fontSize: windowWidth <= 425 && 16
                }
              ]}>
              {name}
            </Text>
          </View>
          <View>
            <ToolTip tooltipText={'Click to Add a Keyboard Shortcut'}>
              <TouchableOpacity
                testID='addShortCutBtn'
                onPress={() => addShortcutOther(input)}>
                <Text
                  style={[
                    styles.labelStyle,
                    { display: 'flex', alignItems: 'center' }
                  ]}>
                  (
                  {shortcutsInputs ? (
                    shortcutsInputs
                  ) : labelShortcuts ? (
                    <Image
                      source={shortcutImage}
                      style={styles.shortcutIconShowSmall}
                    />
                  ) : (
                    ''
                  )}
                  )
                </Text>
              </TouchableOpacity>
            </ToolTip>
          </View>
        </View>
        <View style={{flexDirection: 'row'}}>
        {Platform.OS === 'ios' || Platform.OS === 'android' ? (
          <View style={{ zIndex: 2147483647 }}>
            <Dropdown
              selectedValue={
                pickerConfirmationSelectedOption
              }
              data={pickerSelectedOptionsConfirmation}
              onSelect={value => {
                setPickerConfirmationOpen(value)
                changeInitialFormValues('confirmation', value);
              }}
              pickerOpen={pickerConfirmationOpen}
              setPickerOpen={setPickerConfirmationOpen}
            />
          </View>
        ) : (
          <View>
          <View style={styles.customSelectContainer}>
            <select
              class="confirmationSelect"
              id={id}
              style={{
                cursor: 'pointer',
                width: windowWidth <= 425 ? 191 : '154px',
                height: '35px',
                backgroundColor: 'transparent',
                borderStyle: 'none',
                borderBottomWidth: 2,
                borderColor: '#fff',
                color: '#fff',
                fontSize: 18,
                overflow: 'visible'
              }}
              value={pickerConfirmationSelectedOption}
              ref={confirmationRef}
              data-focusvisible-polyfill={true}
              onChange={event => {
                setPickerConfirmationSelectedOption(event.target.value)
              }}>
              {pickerSelectedOptionsConfirmation.map(el => {
                return (
                  <option
                    style={{
                      backgroundColor: '#dbdada',
                      color: '#000',
                      fontSize: 18
                    }}
                    value={el.value}>
                    {el.label}
                  </option>
                );
              })}
            </select>
          </View>
          </View>
        )}
        { showTooltip &&
          <ToolTip tooltipText={i18n.t('directprinting.tooltipResidential')} position={'top'} shipping={'true'} confirmation={'true'}>
              <i
                  className="icon-info-circled"
                  style={{
                  color: 'rgb(172,187,199)',
                  fontSize: '17px'
                  }}
              ></i>
          </ToolTip>
        }
        </View> 
      </View>
    );
  };

export default ShippingDropDown;
