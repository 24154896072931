import React, {Component} from 'react';
import i18n from 'i18n-js';
import styles from '../../style/popupmodel';
import {View, Text, Dimensions, TouchableOpacity} from 'react-native';
import {LinearGradient} from 'expo-linear-gradient';
export default class MultiBoxAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: Dimensions.get('window').width,
      closeAlert: true
    };
  }
  updateDimensions = () => {
    this.setState({windowWidth: window.innerWidth});
  };
  componentWillUnmount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  render() {
    return (
      <>
        <View style={styles.alertContainer}>
          <LinearGradient
            testID='multiBoxAlert'
            locations={[0, 1]}
            colors={['rgb(207,68,55)', 'rgb(122,40,40)']}
            style={[
              styles.alertMultiBox,
              {
                width: this.state.windowWidth <= 900 ? '97%' : '50%',
                top: this.state.windowWidth <= 900 ? '96px' : '130px'
              }
            ]}>
            <Text
              testID='alertMultiBoxMessage'
              style={{
                marginLeft: 15,
                fontSize: 16,
                color: '#fff',
                width: this.state.windowWidth <= 900 ? '90%' : '80%'
              }}>
              {i18n.t('product.alertBoxMultiBox')}
            </Text>
            <TouchableOpacity
              testID='alertMultiBoxClose'
              style={{
                height: 28,
                borderRadius: 1,
                padding: 5,
                borderRadius: 10,
                position: 'absolute',
                right: '0%',
                top: this.state.windowWidth <= 600 ? '12%' : '-8%'
              }}
              onPress={() => {
                this.props.onCloseAlertMultiBox();
              }}>
              <i
                className="icon-cancel"
                style={{fontSize: '22px', color: 'white'}}></i>
            </TouchableOpacity>
          </LinearGradient>
        </View>
      </>
    );
  }
}
