import axios from 'axios';
import {
  UPDATE_PRODUCT,
  SEARCH_SCANPACK_ORDER,
  SEARCH_SCANPACK_ORDER_FAIL,
  IMPORT_ORDER_SUCCESS,
  IMPORT_ORDER_FAIL,
  IMPORT_ORDER_STATUS_SUCCESS,
  IMPORT_ORDER_STATUS_FAIL,
  FULL_ORDER_SCAN_CHECK,
  DETECT_ORDERS_DISCREPANCIES,
  DASHBOARD_DATA,
} from '../constants';

import AsyncStorage from '@react-native-async-storage/async-storage';

export function SearchScanpackOrder(data, call) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    if (call === false) {
      dispatch({
        type: SEARCH_SCANPACK_ORDER_FAIL,
        payload: ''
      });
    } else {
      axios({
        method: 'post',
        url: `${url}/scan_pack/scan_barcode.json`,
        headers: {Authorization: `Bearer ${access_token}`},
        data: data
      })
        .then(res => {
          dispatch({
            type: SEARCH_SCANPACK_ORDER,
            payload: res?.data
          });
        })
        .catch(error => {
          // console.log("error1-->",error.response.status)
          if (error?.response?.status) {
            dispatch({
              type: SEARCH_SCANPACK_ORDER_FAIL,
              payload: error?.response?.status
            });
          }
        });
    }
  };
}

export function UpdateProduct(data) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'post',
      url: `${url}/products/update_product_list.json?id=${data.id}&var=${data.name}&value=${data.value}`,
      headers: {Authorization: `Bearer ${access_token}`}
      // data: data
    })
      .then(data => {
        dispatch({
          type: UPDATE_PRODUCT,
          payload: data.data.data
        });
      })
      .catch(error => {
        // console.log("error2-->",error.response.status)
        dispatch({
          type: UPDATE_PRODUCT_FAIL,
          payload: error.response.status
        });
      });
  };
}
export function ImportOrder() {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'get',
      url: `${url}/orders/import_all.json`,
      headers: {Authorization: `Bearer ${access_token}`}
      // data: data
    })
      .then(data => {
        // console.log("data import order---->",data.data)
        dispatch({
          type: IMPORT_ORDER_SUCCESS,
          payload: data.data
        });
        return dispatch(ImportOrderStatus());
      })
      .catch(error => {
        // console.log("error3-->",error.response.status)

        dispatch({
          type: IMPORT_ORDER_FAIL,
          payload: error.response.status
        });
      });
  };
}

export function ImportOrderStatus() {
  // console.log("----------status-------")
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'get',
      url: `${url}/home/import_status`,
      headers: {Authorization: `Bearer ${access_token}`}
    })
      .then(data => {
        // console.log("data import order status---????????->",data.data)
        dispatch({
          type: IMPORT_ORDER_STATUS_SUCCESS,
          payload: data.data
        });
      })
      .catch(error => {
        // console.log("error4-->",error.response.status)

        // console.log("----------data import status error->",error)
        dispatch({
          type: IMPORT_ORDER_STATUS_FAIL,
          payload: error?.response?.status
        });
      });
  };
}

export function FullOrderScan(orderID) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'post',
      url:`${url}/scan_pack/verify_order_scanning`,
      headers: {Authorization: `Bearer ${access_token}`},
      data:`id=${orderID}&app_url=${window.location.origin}`
    })
      .then(res => {
        console.log("check res",res);
        dispatch({
          type: FULL_ORDER_SCAN_CHECK,
        });
      })
      .catch(error => {
        dispatch({
          // type: REMOVE_PACKING_IMAGE_FAIL
        });
      });
  };
}

export function detectOrdersDiscrepancies(orders_data) {
  return async dispatch => {
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');
    axios({
      method: 'post',
      url:`${url}/scan_pack/detect_discrepancy`,
      headers: {Authorization: `Bearer ${access_token}`},
      data: {data: orders_data, app_url: window.location.origin}
    })
      .then(res => {
        dispatch({
          type: DETECT_ORDERS_DISCREPANCIES,
          payload: res?.data
        });
      })
      .catch(error => {
        console.log('error in discrepancy function => ', error);
      });
  };
}

export function SetDashboardData(data) {
  return dispatch => {
    dispatch({
      type: DASHBOARD_DATA,
      payload: data
    });
  };
}
