import React from 'react';
import { View, TouchableOpacity } from 'react-native';

const AddIconFunction = ({ onPress, testID,top,left,isLeft }) => {
  return (
    <View style={{ marginLeft: isLeft ? '36%': '45%', marginTop : top ? 20 : '', width: '0%' }}>
      <TouchableOpacity
        testID={testID}
        onPress={onPress}
      >
        <i
          className="icon-plus"
          style={{
            color: 'white',
            fontSize: '70px',
            marginLeft: left ? left : '-3%'
          }}
        ></i>
      </TouchableOpacity>
    </View>
  )
}
export default AddIconFunction;