import React, {Component} from 'react';
import {
  View,
  Text,
  TextInput,
  ActivityIndicator,
  Platform,
  ImageBackground,
  ScrollView
} from 'react-native';
import {connect} from 'react-redux';
import {SearchOrder,SetIsOrderCreated,ResetSearchOrder} from '../../actions/orderActions';
import PopUpModel from '../scanpack/pop_up_model';
import OrderSearchList from '../orders/order_search_list';
import {IS_LOADING} from '../../constants';
import CommonStyles from '../../style/commonStyles';
import { LinearGradient } from 'expo-linear-gradient';
import { navigateFromOrdersIfNeeded } from './commonFunctions';
class OrderSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowwidth: 0,
      data: {
        search: '',
        sort: '',
        order: 'DESC',
        is_kit: 0,
        limit: 20,
        offset: 0,
        app:'app'
      },
      productSearchData: [],
      popUpModelShow: false,
      cueOrderStatus: '',
      checkFocus: false,
      lastSearched: ''
    };
    this.inputFocus = React.createRef();
    window.addEventListener('resize', this.update);
  }
  componentDidMount() {
    this.setState({productSearchData: []});
    this.update();
  }
  update = () => {
    this.setState({
      windowwidth: window.innerWidth
    });
  };
  //According to the platform call the function
  getOrder = () => {
    if (Platform.OS === 'ios' || Platform.OS === 'android') {
      this.checkConnection('apiCall');
    } else {
      this.apiCall();
    }
  };
  /////////////Alert DialogBox open
  alertBox = (message, skip, messageTypeSuccess) => {
    if (messageTypeSuccess === true) {
      this.setState({
        popUpModelShow: true,
        message: message,
        messageTypeSuccess: true,
        loading: false
      });
    } else {
      this.setState({popUpModelShow: true, message: message});
    }
    setTimeout(
      function () {
        this.setState({
          popUpModelShow: false,
          message: '',
          messageTypeSuccess: false
        });
      }.bind(this),
      4000
    );
  };
  //Check the connection for the ios and android
  checkConnection = async (type, input) => {
    const connectionInfo = await NetInfo.fetch();
    if (connectionInfo.isConnected) {
      type === 'callShipmentOrder'
        ? this.callShipmentOrder(input)
        : this.apiCall();
    } else {
      this.setState({rfoTitle: 'Please check your internet connection'});
    }
  };
  apiCall = () => {
    this.props.GetOrderList(this.state.dataList);
    orderListSuccess = true;
  };

  componentDidUpdate(pP) {
    if(!this.props.isOrderCreated){
      let page = 'OrderSearch';
        this.props.navigation.navigate('OrderDetail', {
          item: {id:this.props.orderId},
          userData: this.props.route.params.userInfo,
          page: page,
          onComeBack: () => this.onComeBack()
        });
        this.props.SetIsOrderCreated()
    }
    if (
      this.props &&
      this.props.isLoading === false &&
      this.props.orderSearchList
    ) {
      this.props.setIsLoading(true);
      let orderlist = this.props?.orderSearchList;
      if (orderlist?.length == 1 && orderlist[0].ordernum.replace(/^#*/, '').toLowerCase() === this.state.lastSearched.toLowerCase()
      ) {
        let page = 'OrderSearch';
        this.props.navigation.navigate('OrderDetail', {
          item: orderlist[0],
          userData: this.props.route.params.userInfo,
          page: page,
          onComeBack: () => this.onComeBack()
        });
      }
      if (orderlist?.length > 0) {
        this.setState({productSearchData: orderlist});
      } else {
        this.alertBox(
          `Order with number ${this.state.lastSearched} cannot be found`,
          true,
          true
        );
      }
      let data = this.state.data;
      data['search'] = '';
      this.setState({loading: false, data: data});
    }
    this?.inputFocus?.current?.focus();
    navigateFromOrdersIfNeeded(this.props, this.state);
  }
  //////////// For Close Alert
  closeAlert = () => {
    this.setState({popUpModelShow: false});
  };

  redirect = async () => {
    await AsyncStorage.clear();
    this.props.navigation.navigate('SignIn', {
      status: 'Inactive',
      time: new Date()
    });
    this.setState({userInfo: this.props.userInfo});
  };
  //After the apis call update the status (scanned , order  not present)
  statusFunction = message => {
    let data = this.state.data;

    data['input'] = '';
    this.setState({rfoTitle: message, data});
  };

  //To set the focus
  setFocus = () => {
    this.inputFocus.focus();
  };
  handleOnSubmit = () => {
    this.state.data.search.charAt(0) === '#' &&
    this.handleChange(
      'searchOrder',
      this.state.data.search.slice(1)
    );
    if (this.state.data.search === '') return;
    this.setState({lastSearched: this.state.data.search})
    this.searchProduct();
  }

  //Redirect to the scanpackItem page with order details
  orderDetails = order => {
    if (order && order.scan_hash && order.scan_hash.data) {
      this.setState({showQuick: false});

      this.props.navigation.navigate('ScanPackItem', {
        data: order.scan_hash.data,
        userdata: this?.props?.userInfo
      });
      messageShow = false;
    }
  };
  //Check the connection for mobile device before call the api
  searchProduct = input => {
    this.setState({loading: true, loaderTitle: 'Loading...'});
    let data = this.state.data;
    this.props.SearchOrder(data);
  };
  //Api to call the search order
  callShipmentOrder = input => {
    let shipmentOrder = this.state.data;
    shipmentOrder['input'] = input;
    if (input !== '') {
      shipmentOrder.input = input;
      this.props.SearchScanpackOrder(shipmentOrder);
      searchOrderSuccess = true;
      let apiCallTime = new Date();
      this.setState({apiCallTime});
    }
  };
  //Handle the search field input
  handleChange = (name, value) => {
    let temp = this.state.data;
    temp['search'] = value;
    this.setState({data: temp});
  };
  drawerClose = () => {
    this.props.route.params.closeDrawer();
  };
  orderSearchBack = () => {
    this.setState({productSearchData: []});
  };
  onComeBack = () => {
    this.setState({checkFocus: true});
  };
  render() {
    return (
      <LinearGradient
      colors={[
        '#000',
        '#000',
        '#5c778f',
        '#5c778f',
        '#253c57',
        '#253c57',
        '#000'
      ]}
      start={[-0.5, -1]}
      style={{
        flex: 1,
      }}>
        {this?.state?.productSearchData &&
        this?.state?.productSearchData?.length < 2 ? (
          <View
            style={{flex: 1}}
            onStartShouldSetResponder={() => this.drawerClose()}
          >
            <ImageBackground
              style={{flex: 1}}
              source={require('././../../../assets/background.png')}
            >
              <ScrollView contentContainerStyle={{flex: 1}}>
                <ImageBackground
                  style={CommonStyles.orderSearchBackImg}
                  source={require('././../../../assets/scaning.png')}
                  resizeMode="contain"
                >
                  <Text
                    style={CommonStyles.orderSearchScan}
                  >
                    Scan
                  </Text>
                  <TextInput
                    name="searchOrder"
                    autoFocus={true}
                    ref={this.inputFocus}
                    value={this.state.data.search}
                    onChangeText={text => {
                      this.handleChange('searchOrder', text);
                    }}
                    onSubmitEditing={() => {this.handleOnSubmit()}}
                    style={CommonStyles.searchOrderInput}
                    onKeyPress={(e)=>{
                      if(e.key === 'Enter') {
                        this.handleOnSubmit()
                      }
                    }}
                  />
                  {
                    // Notification Pop Up for messages
                    this.state.popUpModelShow && (
                      <PopUpModel
                        closeAlert={this.closeAlert.bind(this)}
                        message={this.state.message}
                        messageTypeSuccess={
                          this.state.messageTypeSuccess ? true : false
                        }
                      />
                    )
                  }
                  {this.state.loading ? (
                    <View style={{flex: 1, justifyContent: 'center'}}>
                      {/* Activity Indicator Shown  */}
                      <ActivityIndicator size="large" color="#336599" />
                    </View>
                  ) : (
                    <>
                      {this.state.error ? (
                        <Text style={styles.text}>{this.state.error}</Text>
                      ) : null}
                    </>
                  )}
                  <Text
                    style={CommonStyles.searchOrderText}
                  >
                    Scan a Packing Slip or Search by Order Number
                  </Text>
                  <View style={{height: 40, width: '100%'}} />
                </ImageBackground>
              </ScrollView>
            </ImageBackground>
          </View>
        ) : (
           <OrderSearchList
            productSearchList={this.state.productSearchData}
            navigation={this.props.navigation}
            userInfo={this.props.route.params.userInfo}
            orderSearchBack={() => this.orderSearchBack()}
            onComeBack={() => this.onComeBack()}
            lastSearched={this.state.lastSearched}
            orderCount={this.props?.orderSearchListCount}
          />
        )}
      </LinearGradient>
    );
  }
}
const mapStateToProps = state => {
  return {
    orderSearchList: state.order.searchResult,
    orderSearchListCount: state.order.searchResultCount,
    orderId: state.order.orderId,
    isOrderCreated:state.order.isOrderCreated,
    isLoading: state.order.isLoading,
    ordersList: state.order.list
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setIsLoading: value =>
      dispatch({
        type: IS_LOADING,
        payload: value
      }),
    SearchOrder: data => dispatch(SearchOrder(data)),
    SetIsOrderCreated: () => dispatch(SetIsOrderCreated()),
    ResetSearchOrder: () => dispatch(ResetSearchOrder())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderSearch);
