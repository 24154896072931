import {StyleSheet} from 'react-native';
import { fontFamily } from '../helpers/fontFamily';

const styles = StyleSheet.create({
  contentContainerStyle: {
    flexGrow: 1,
    marginBottom: '5%'
  },
  workArea: {flexDirection: 'row', marginHorizontal: 20},
  header: {marginHorizontal: 10},
  mainContainer: {
    flex: 1
    // paddingLeft: 20,
    // paddingRight: 20
  },
  fetchEditContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  fetchEditContainerMobile: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5
  },
  container: {
    backgroundColor: '#000',
    height: 20,
    width: '100%',
    shadowColor: '#000',
    shadowOffset: {
      width: 1,
      height: 10
    },
    shadowOpacity: 3,
    shadowRadius: 10,
    elevation: 5,
    marginLeft: -10
  },
  leftPortion: {width: '40%'},
  rightPortion: {
    width: '60%',
    height: '10%'
  },
  unitsStyle:{
    width: 177, 
    height:18, 
    fontFamily: 'Poppins_700Bold', 
    weight: 600,
    fontSize: 12,
    lineHeight: 18,
    color: '#FFFFFF',
    marginLeft: '3%'
  },
  weightStyle:{
    width: 38, 
    height: 27,
    fontSize: 18,
    lineHeight: 27,
    color: '#FFFFFF'
  },
  weightStyleMobile:{
    width: 27, 
    height: 27,
    fontSize: 14,
    lineHeight: 27,
    color: '#FFFFFF',
    marginLeft: '1%'
  },
  customSelectStyle:{
    cursor: 'pointer',
    width: '154px',
    height: '35px',
    backgroundColor: 'transparent',
    borderStyle: 'none',
    color: '#fff',
    fontSize: 14,
    overflow: 'visible',
    marginTop: -5,
    lineHeight: 21,
  },
  roundedBox: {
    marginVertical: 3,
    marginHorizontal: 10,
    // borderWidth: 1,
    borderRadius: 10,
    padding: 10
  },

  labelStyle: {
    fontFamily: 'Poppins_500Medium',
    color: 'white',
    fontSize: 20,
    marginTop: 10,
    marginBottom: 10
  },

  labelStyleFocus: {
    fontFamily: 'Poppins_500Medium',
    color: 'rgb(90, 248, 189)',
    fontSize: 20,
    marginTop: 10,
    marginBottom: 10
  },
  inputStyle: {
    backgroundColor: '#dbdada',
    width: '60%',
    paddingVertical: 10,
    paddingHorizontal: 5,
    fontFamily: 'Poppins_500Medium',
    fontSize: 15
  },

  companyName: {
    color: 'white',
    fontFamily: 'Poppins_400Regular',
    fontSize: 24
  },
  saveContainer: {
    width: '15%',
    flexDirection: 'row',
    alignItems: 'center'
  },
  saveIcon: {
    paddingHorizontal: 5
  },
  save: {
    color: 'white',
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 18,
    lineHeight: '87%',
    color: '#45576C'
  },
  panelContentBoxError: {
    width: '100%',
    marginVertical: 5,
    marginHorizontal: 5,
    paddingRight: 7,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  panelContentBox: {
    width: '100%',
    marginVertical: 5,
    marginHorizontal: 5,
    flexDirection: 'row'
  },
  panelContentBoxMobile: {
    marginVertical: 5,
    marginHorizontal: 2,
    flexDirection: 'column'
  },
  panelRowBoxMobile: {
    flexDirection: 'row'
  },
  upsText: {
    color: '#7e8389',
    fontFamily: 'Poppins_400Regular',
    fontSize: 20
  },
  upsMobile: {
    color: '#7e8389',
    fontFamily: 'Poppins_400Regular',
    fontSize: 20
  },
  price: {
    color: 'white',
    backgroundColor: '#57718a',
    paddingVertical: 0,
    paddingHorizontal: 10,
    borderRadius: 30,
    fontFamily: 'Poppins_400Regular',
    fontSize: 24,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center'
  },
  priceNew: {
    color: '#738ea9',
    backgroundColor: 'rgba(87, 113, 138, 0.9)',
    paddingVertical: 0,
    paddingHorizontal: 10,
    borderRadius: 30,
    fontFamily: 'Poppins_400Regular',
    fontSize: 24,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center'
  },
  panelBox: {
    width: '70%',
    backgroundColor: '#dbdada',
    paddingHorizontal: 25,
    paddingVertical: 5,
    flexDirection: 'row',
    borderRadius: 10,
    justifyContent: 'space-between'
  },
  panelBoxSelected: {
    width: '70%',
    backgroundColor: '#dbdada',
    paddingHorizontal: 25,
    paddingVertical: 5,
    flexDirection: 'row',
    borderRadius: 10,
    justifyContent: 'space-between',
    borderColor: '#5af8bd',
    borderWidth: 3
  },
  panelBoxMobile: {
    width: '73%',
    backgroundColor: '#dbdada',
    paddingHorizontal: 25,
    paddingVertical: 5,
    flexDirection: 'row',
    borderRadius: 10,
    justifyContent: 'space-between'
  },
  panelBoxSelectedMobile: {
    width: '73%',
    backgroundColor: '#dbdada',
    paddingHorizontal: 25,
    paddingVertical: 5,
    flexDirection: 'row',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderRadius: 10,
    justifyContent: 'space-between',
    // borderColor: '#5af8bd',
    border: "5px solid #7bf1bd",
    borderWidth: 3
  },
  greenBoxTextMobile: {
    width: '73%',
    justifyContent: 'center',
    //borderRadius: '0px !important',
    borderRadius: 0,
    paddingTop: 3
  },
  greenBox: {
    fontFamily: 'Poppins_400Regular',
    // backgroundColor: '#5af8bd',
    borderRadius: 10,
    margin: 5,
    position: 'absolute',
    zIndex: 1,
    // paddingVertical: 0,
    // paddingHorizontal: 5,
    color: "#465667",
    fontWeight: 600,
    fontSize: 18,
    lineHeight: 25,
    textAlign: "center",
  },
  greenBoxMobile: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 10,
    lineHeight: 13,
    textAlign: 'center',
    color: '#7e8389',
    backgroundColor: '#5af8bd',
    padding: 1,
    borderRadius: 5,
    margin: 1,
    marginBottom: 0,
    zIndex: 100
  },
  greenBoxMobileNew: {
    fontFamily: 'Poppins_400Regular',
    fontSize: 14,
    lineHeight: 16,
    fontWeight: 400,
    textAlign: 'center',
    color: '#253A52',
    backgroundColor: '#5af8bd',
    padding: 1,
    borderRadius: 5,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    marginBottom: -1,
    zIndex: 100
  },
  greenBoxInvisible: {
    display: 'none'
  },
  caretStyle: {
    position: 'absolute',
    top: 13,
    right: -3,
    alignSelf: 'flex-end',
  },
  caretStyleInvisible: {
    display: 'none'
  },
  eyeContainer: {width: '21%', justifyContent: 'center'},
  eyeContainerMobile: {
    width: '5%',
    justifyContent: 'center',
    // borderRadius: '0px !important',
    borderRadius: 0,
    paddingTop: 3
  },
  shortcutBox: {width: '15%', justifyContent: 'center'},
  shortcutBoxMobile: {width: '27%', justifyContent: 'center'},
  editButton: {
    color: 'white',
    alignSelf: 'flex-end',
    fontSize: 14,
    fontWeight: 400,
    paddingHorizontal: 2,
    lineHeight: 20,
    fontFamily: 'Poppins_400Regular',
    marginBottom: 40,
  },
  editButtonMobile: {
    color: 'white',
    alignSelf: 'flex-end',
    fontSize: 14,
    fontWeight: 400,
    paddingHorizontal: 2,
    lineHeight: 20,
    fontFamily: 'Poppins_400Regular',
    marginBottom: 0,
  },
  editRatesButton: {
    color: '#465667',
    alignSelf: 'flex-end',
    fontSize: 18,
    fontWeight: 600,
    paddingVertical: 10,
    paddingHorizontal: 8,
    lineHeight: 20,
    fontFamily: 'Poppins_400Regular'
  },
  requireDataButton: {
    color: '#465667',
    alignSelf: 'flex-end',
    fontSize: 18,
    fontWeight: 600,
    width:"270px",
    paddingVertical: 10,
    paddingHorizontal: 8,
    lineHeight: 20,
    fontFamily: 'Poppins_400Regular'
  },
  editBox: {
    marginVertical: 10,
    marginHorizontal: 0
  },
  showEye: {
    alignSelf: 'flex-end',
    marginRight: 10
  },
  hideEye: {
    display: 'none'
  },
  shortcutIconShow: {
    alignSelf: 'center',
    height: 35,
    width: 35
  },
  shortcutIconShowMobile: {
    alignSelf: 'center',
    height: 22,
    width: 22
  },
  shortcutIconShowSmall: {
    alignSelf: 'center',
    height: 22,
    width: 22,
    marginHorizontal: 3
  },
  shortcutIconHide: {
    display: 'none'
  },
  shortcutTextHide: {display: 'none'},
  shortcutText: {
    paddingLeft: 2,
    alignSelf: 'center',
    fontFamily: 'Poppins_400Regular',
    fontSize: 14,
    color: 'white'
  },
  dimensionContainer: {flexDirection: 'row', marginVertical: 2},
  dimensionBox: {
    width: '62%',
    backgroundColor: '#dbdada',
    paddingHorizontal: 8,
    paddingVertical: 6,
    marginHorizontal: 10,
    borderRadius: 10,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  dimensionText: {
    color: '#7e8389',
    fontFamily: 'Poppins_400Regular',
    fontSize: 16
  },
  displayTooltip: {},
  hideToolTip: {
    display: 'none'
  },
  dimensionBoxSelected: {
    width: '62%',
    backgroundColor: '#dbdada',
    paddingHorizontal: 8,
    paddingVertical: 6,
    marginHorizontal: 10,
    borderRadius: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderColor: 'rgb(90, 248, 189)',
    borderWidth: 2
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  leftPortionMobile: {},
  rightPortionMobile: {
    position: 'unset',
  },
  workAreaMobile: {},
  inputStyleMobile: {
    backgroundColor: '#dbdada',
    width: '95%',
    paddingVertical: 10,
    paddingHorizontal: 5,
    fontFamily: 'Poppins_500Medium',
    fontSize: 15
  },
  mainContainerMobile: {
    flex: 1,
    paddingLeft: 5,
    paddingRight: 5
  },
  upsTextMobile: {
    color: '#7e8389',
    fontFamily: 'Poppins_400Regular',
    fontSize: 12
  },
  priceMobile: {
    color: 'white',
    backgroundColor: '#57718a',
    paddingVertical: 0,
    paddingHorizontal: 5,
    borderRadius: 30,
    fontFamily: 'Poppins_400Regular',
    fontSize: 12,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center'
  },
  priceMobileNew :{
    color: '#738ea9',
    backgroundColor: 'rgba(87, 113, 138, 0.9)',
    paddingVertical: 0,
    paddingHorizontal: 5,
    borderRadius: 30,
    fontFamily: 'Poppins_400Regular',
    fontSize: 12,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center'
  },
  shortcutTextMobile: {
    alignSelf: 'center',
    fontFamily: 'Poppins_400Regular',
    fontSize: 10,
    color: 'white'
  },
  orderHeaderContainer: {
    marginHorizontal: 32,
    zIndex: 1,
    marginBottom: 50
  },
  orderHeaderContainerMobile: {
    marginHorizontal: 35,
    zIndex: 1,
    marginBottom: 40
  },
  ratesErrorBox: {
    width: '95%',
    backgroundColor: '#dbdada',
    paddingHorizontal: 25,
    paddingVertical: 5,
    flexDirection: 'row',
    borderRadius: 10,
    justifyContent: 'center'
  },
  ratesErrorBoxMobile: {
    width: '95%',
    backgroundColor: '#dbdada',
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginBottom: 5,
    flexDirection: 'row',
    borderRadius: 10,
    justifyContent: 'center'
  },
  ratesErrorText: {
    color: '#7e8389',
    fontFamily: 'Poppins_400Regular',
    fontSize: 20
  },
  ratesErrorTextMobile: {
    color: '#7e8389',
    fontFamily: 'Poppins_400Regular',
    fontSize: 12
  },
  orderInfoHeaderContainer: {
    // marginHorizontal: 92,
    zIndex: 1
  },
  orderInfoHeaderContainerMobile: {
    marginHorizontal: 10,
    zIndex: 1,
    marginBottom: 15
  },
  errorAlertContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '35%',
    marginLeft: '34%',
    top: '-3%',
    zIndex: 1
  },
  errorAlertGradient: {
    flexDirection: 'row',
    backgroundColor: '#b7b7b7',
    boxShadow: 'rgba(0, 0, 0, 0.9) 0px 0px 3.8px',
    padding: 5,
    borderRadius: 10,
    width: '100%',
    marginLeft: '',
    marginRight: '',
    padding: 10,
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 10,
    shadowRadius: 10
  },
  errorAlertText: {
    color: '#cdcdcd',
    fontFamily: 'Poppins_800ExtraBold',
    fontSize: '16px'
  },
  errorAlertTouchable: {
    height: 28,
    borderRadius: 1,
    padding: 5,
    borderRadius: 10,
    position: 'absolute',
    right: '0%',
    top: '8%'
  },
  errorAlertContainerMobile: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '95%',
    marginHorizontal: '10px',
    top: '15px',
    zIndex: 1
  },
  customSelect: {
    appearance: 'none',
    backgroundColor: 'transparent',
    border: 'none',
    padding: '0 1em 0 0',
    margin: '0',
    width: '100%',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    cursor: 'inherit',
    lineHeight: 'inherit',

    background: 'red',
    border: '1px solid #ccc',
    fontSize: 18,
    padding: 10,
    width: '100%',
    height: '100%',
    cursor: 'pointer'
  },
  customSelectContainer: {
    marginHorizontal: 10,
    width: 154,
    height: 35
  },
  pickerStyle: {
    width: '29%',
    height: 35,
    background: 'transparent',
    borderTopWidth: 0,
    borderbottomcolor: 'rgba(255, 255, 255)',
    borderLeftWidth: 0,
    borderRightWidth: 0,
    color: '#fff',
    fontSize: 18,
    borderColor: 'white'
  },
  reprintWrapView: {
    backgroundColor: '#00000066',
    height: '100%',
    width: '100%',
    position: 'absolute',
    zIndex: 1,
  },
  reprintMainView:{
    // backgroundColor: "rgb(255, 165, 0)",
    maxHeight: '80%',
    minHeight: '50%',
    overflow: "auto",
    width: '60%',
    position: "absolute",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '15px',
    padding: '10px',
    textAlign: 'center',
  },
  reprintMainViewMobile:{
    backgroundColor: "rgb(255, 165, 0)",
    maxHeight: '80%',
    minHeight: '50%',
    overflow: "auto",
    width: '85%',
    position: "absolute",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '15px',
    padding: '10px',
    textAlign: 'center',
  },
  reprintOuterView: {
    width: '70%',
    marginVertical: '5px',
    marginHorizontal: 'auto',
    paddingVertical: 10
  },
  reprintOuterViewMobile: {
    width: '100%',
    marginHorizontal: 'auto',
    paddingVertical: 10
  },
  reprintInfoView: {
    borderRadius: '15px',
    flexDirection: 'row',
    paddingHorizontal: 18,
    paddingVertical: 11,
    gap: 9,
    marginBottom: 15,
    alignItems: 'center'
  },
  reprintInfoLtView: {
    fontSize: 16,
    textAlign: 'left',
    fontFamily: 'Poppins_300Light',
  },
  reprintInfoLtViewMobile: {
    fontSize: 14,
    textAlign: 'left',
    fontFamily: 'Poppins_300Light',
  },
  reprintInfoHead: {
    fontSize: 20,
    lineHeight: '28px',
    fontFamily: 'Poppins_300Light',
    paddingBottom: 5
  },
  reprintInfoHeadMobile: {
    fontSize: 16,
    lineHeight: '22px',
    fontFamily: 'Poppins_300Light',
    paddingBottom: 10
  },
  reprintInfoSubhead: {
    fontSize: 18,
    lineHeight: '28px',
    fontFamily: 'Poppins_300Light',
  },
  reprintInfoSubheadMobile: {
    fontSize: 14,
    lineHeight: '18px',
    fontFamily: 'Poppins_300Light',
  },
  reprintButtonView: {
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 57,
    paddingVertical: 13,
  },
  reprintButtonViewMobile: {
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 15,
    paddingVertical: 13,
  },
  reprintButton: {
    display: 'inlineBlock',
    alignSelf: 'center',
    color: '#000000',
    backgroundColor: '#ffffff40',
    paddingVertical: '3px',
    paddingHorizontal: '10px',
    border: '1px solid #000',
    borderRadius: '15px',
    minWidth: '100px',
    textAlign: 'center',
    fontSize: '18px',
    lineHeight: '27px',
    fontFamily: 'Poppins_300Light',
  },
  reprintButtonMobile: {
    width:"85%",
    display: 'inlineBlock',
    alignSelf: 'center',
    color: '#000000',
    backgroundColor: '#ffffff40',
    paddingVertical: '3px',
    paddingHorizontal: '10px',
    border: '1px solid #000',
    borderRadius: '15px',
    minWidth: '100px',
    textAlign: 'center',
    fontSize: '14px',
    lineHeight: '27px',
    fontFamily: 'Poppins_300Light',
  },
  previousStyleBtn: {
    flexDirection: 'row',
  },
  previousStyleBtnMobile: {
    position: 'absolute',
    top: -20,
    left: '50%',
    transform: [{translateX: '-50%'}],
    width: 'max-content'
  },
  previousStyleText: {
    backgroundColor: '#7BFAC3',
    paddingVertical: 3,
    paddingHorizontal: 10,
    borderRadius: 15,
    width: 'fit-content',
    fontFamily: 'Poppins_300Light',
  }
});

export default styles;
