import AsyncStorage from '@react-native-async-storage/async-storage';
import Axios from 'axios';
import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import { TouchableOpacity } from 'react-native';
import { View, StyleSheet, Picker, Text, Dimensions } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import {GetBothSettings, GetGeneralSetting} from '../../actions/userAction';
import { Entypo } from '@expo/vector-icons';
import CustomDropdown from './customDropdown';
import AddEditDropDown from '../../commons/dropDowns/orderTags/addEditDropDown';


function UpdateTagsDropDowns  ({ordersTags, loadTagsData, ordersTagsStatus, getStatus, prevlimit, prevSkip, filters, search, gridSelectionRef, selectedStates, FirstOrdersTagsStatus, ResetFirstOrdersTagsStatus, updateOrdersTagsStatus, unselectedOrderItems, GetTags, filterIncludedTags, resetSelectedTags}) {
  // console.log(">>>>>>>>>>>...", ordersTags);
  // console.log(">>>>>>>>>>>ordersTagsStatus", ordersTagsStatus);
  let url= '';
  const dispatch = useDispatch();
  const {bothSettings} = useSelector(state => state.user);
  const general_settings = bothSettings?.data?.data?.general_setting;
  const [openDropdown, setOpenDropdown] = useState(null);
  const [openEditDropdown, setOpenEditDropdown] = useState(false);

  const toggleDropdown = (dropdownKey) => {
    setOpenDropdown(openDropdown === dropdownKey ? null : dropdownKey);
  };

  useEffect(() => {
    console.log("FirstOrdersTagsStatus", FirstOrdersTagsStatus)
    dispatch(GetBothSettings());
    FirstOrdersTagsStatus && loadTagsData();
    FirstOrdersTagsStatus && ResetFirstOrdersTagsStatus();
  }, []);

  useEffect(() => {
    FirstOrdersTagsStatus && loadTagsData();
    FirstOrdersTagsStatus && ResetFirstOrdersTagsStatus();
  }, [gridSelectionRef]);

  const handleOutsideClick = () => {
    setOpenDropdown(null)
  };

  return (
    <View style={styles.container}>
      <View style={{minWidth: Dimensions.get('window').width < 1300 ?   Dimensions.get('window').width < 1300 ? '22%' : '23%' : '15.5%'}}/>
      <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        {openEditDropdown === false && <CustomDropdown
          options={ordersTags?.data}
          onSelect={()=>{}}
          title={"Add/Remove Tags"}
          isOpen={openDropdown === 'dropdown1'}
          toggleDropdown={() => {toggleDropdown('dropdown1')}}
          handleOutsideClick={()=> handleOutsideClick()}
          testID={'updateTagsBtn'}
          ordersTagsStatus = {ordersTagsStatus}
          getStatus = {getStatus}
          prevlimit= {prevlimit}
          prevSkip = {prevSkip}
          filters = {filters}
          search = {search}
          gridSelectionRef ={gridSelectionRef}
          selectedStates ={selectedStates}
          loadTagsData = {loadTagsData}
          updateOrdersTagsStatus = {updateOrdersTagsStatus}
          unselectedOrderItems = {unselectedOrderItems}
          setOpenEditDropdown = {() => {setOpenEditDropdown(true)}}
          GetTags={GetTags}
          filterIncludedTags ={filterIncludedTags}
          resetSelectedTags = {resetSelectedTags}
        />}
        {openEditDropdown && <AddEditDropDown options={ordersTags?.data} resetOpenEditDropdown = {() => {
          setOpenEditDropdown(false)}} />}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginLeft: '18%'
  },
  picker: {
    flex: 1,
    backgroundColor: 'transparent',
    border: 'none',
    color: 'white'
  },
  dropdownContainer: {
    position: 'relative',
    zIndex: 1,
    marginLeft: '14.6rem'
  },
  selectedOption: {
    paddingHorizontal: 6,
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5,
    border: 'none',
  },
  optionsContainer: {
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    borderRadius: 5,
    marginTop: 5,
    width: '12rem',
    backgroundColor: 'rgb(38, 43, 48)',
    // border: 'none',
    border: '1px solid #80808082'
  },
  option: {
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    color: 'white',
    border: 'none'
  },
});

export default UpdateTagsDropDowns;
