import React, {useEffect, useState} from 'react';
import {Text, TouchableOpacity} from 'react-native';

import { CopyToClipboard } from '../../commons/copyToClipBoard';

export const OrderNumberCell = ({
  value,
  item,
  redirectToProductDetail,
  redirectToScanPack
}) => {
  const [isCtrlPressed, setIsCtrlPressed] = useState(false);

  const handleKeyDown = event => {
    if (event.ctrlKey || event.metaKey) {
      setIsCtrlPressed(true);
    }
  };

  const handleKeyUp = event => {
    if (!event.ctrlKey && !event.metaKey) {
      setIsCtrlPressed(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false);
    document.addEventListener('keyup', handleKeyUp, false);

    return () => {
      document.removeEventListener('keydown', handleKeyDown, false);
      document.removeEventListener('keyup', handleKeyUp, false);
    };
  }, []);

  const handlePress = () => {
    if (isCtrlPressed) {
      redirectToScanPack(item);
    } else {
      redirectToProductDetail(item, false);
    }
  };

  return (
    <TouchableOpacity
      style={{flexDirection: 'row', alignItems: 'center'}}
      testID="redirectToProductDetail"
      onPress={handlePress}>
      <Text style={[{color: '#aad9fc'}]} numberOfLines={1}>
        {value}
      </Text>
      <CopyToClipboard value={value} />
    </TouchableOpacity>
  );
};
