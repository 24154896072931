import React from 'react';
import Scan from './scan';
import Product from './product';
import Count from './count';
import ProductList from './productList';
import useReceiveScan from './useReceiveScan';
import useRecountScan from './useRecountScan';
import useRecountProduct from './useRecountProduct';
import useReceiveProduct from './useReceiveProduct';
import useReceiveCount from './useReceiveCount';
import useRecountCount from './useRecountCount';
import useProductList from './useProductList';

export const ScanRecount = props => {
  const {...otherProps} = useRecountScan(props);
  return <Scan {...props} {...otherProps} />;
};
export const ScanReceive = props => {
  const {...otherProps} = useReceiveScan(props);
  return <Scan {...props} {...otherProps} />;
};

export const ProductRecount = props => {
  const {...otherProps} = useRecountProduct(props);
  return <Product {...props} {...otherProps} />;
};
export const ProductReceive = props => {
  const {...otherProps} = useReceiveProduct(props);
  return <Product {...props} {...otherProps} />;
};
export const CountReceive = props => {
  const {...otherProps} = useReceiveCount(props);
  return <Count {...props} {...otherProps} />;
};
export const CountRecount = props => {
  const {...otherProps} = useRecountCount(props);
  return <Count {...props} {...otherProps} />;
};
export const ProductListReceive = props => {
  const {...otherProps} = useProductList(props);
  return <ProductList {...props} {...otherProps} />;
};
export const ProductListRecount = props => {
  const {...otherProps} = useProductList(props);
  return <ProductList {...props} {...otherProps} />;
};
