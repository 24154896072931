import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import CommonStyles from '../../style/commonStyles';

function SaveItemToOrder(props) {
  return (
      <View
          style={CommonStyles.saveItemToOrderStyle}
        >
          <TouchableOpacity
           testID='saveButton'
            style={{
              marginRight: 10,
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: '#fff',
              borderRadius: 30,
              justifyContent: 'center',
              alignItems: 'center',
              height: 30,
              shadowColor: '#2bfac3',
              shadowOffset: {width: 0, height: 0.5},
              shadowRadius: 8,
              elevation: 5,
              shadowOpacity: 0.5
            }}
            onPress={props.onPress}
          >
            <Text
              style={CommonStyles.saveItemButtonStyle}
            >
              Save & Close
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            testID='cancelButton'
            onPress={props.onCancel}
            style={[CommonStyles.saveButton, {shadowColor: '#e853fa'}]}
          >
            <Text
              style={CommonStyles.saveItemButtonStyle}
            >
              Cancel
            </Text>
          </TouchableOpacity>
        </View>
  );
}

export default SaveItemToOrder;