import {StyleSheet, Platform} from 'react-native';
import { fontFamily } from '../helpers/fontFamily';
const globalStyles = StyleSheet.create({
  flex1: {
    flex: 1
  },
  flex2: {
    flex: 2
  },
  flex3: {
    flex: 3
  },
  flex6: {
    flex: 6
  },
  flex7: {
    flex: 7
  },
  flex9: {
    flex: 9
  },
  flexDirectionRow: {
    flexDirection: 'row'
  },
  m_2: {
    margin: 2
  },
  m_10: {
    margin: 10
  },
  justifyCenter: {
    justifyContent: 'center'
  },
  mt_30: {
    marginTop: 30
  },
  mb_4: {
    marginBottom: 4
  },
  mb_5: {
    marginBottom: 5
  },
  mb_40: {
    marginBottom: 40
  },
  mb_65: {
    marginBottom: 65
  },
  mx_10: {
    marginBottom: 10,
    marginTop: 10
  },
  py_10: {
    paddingLeft: 10,
    paddingRight: 10
  },
  font: {
    fontFamily: 'Poppins_800ExtraBold'
  },

  bgWhite: {
    backgroundColor: '#ffff'
  },

  bgGrey: {
    backgroundColor: '#dbdada'
  },
  p_10: {
    padding: 10
  },
  p_5: {
    padding: 5
  },
  pb_50: {
    paddingBottom: 50
  },
  border_1: {
    borderWidth: 1
  },
  m_5: {
    margin: 5
  },
  textWhite: {
    color: 'white'
  },
  bgBlack: {
    backgroundColor: 'black'
  },
  alignItmCntr: {
    alignItems: 'center'
  },
  fullWidth: {
    width: '100%'
  },
  displayFlex: {
    display: 'flex'
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  flexDirectionClm: {
    flexDirection: 'column'
  },
  backgroundColorWhite: {
    backgroundColor: '#fff'
  },
  positionRelative: {
    position: 'relative'
  },
  positionFixed: {
    ...Platform.select({
      ios: {
        position: 'absolute'
      },
      android: {
        position: 'absolute'
      },
      default: {
        // other platforms, web for example
        position: 'fixed'
      }
    })
  },
  displayTypeOne:{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  textStyleTypeOne:{
    fontSize: 14,
    fontFamily: fontFamily.font700,
    color: '#fff'
  },
  nextItemNotes:{
    width: '100%',
    backgroundColor: '#d3dfea',
    paddingHorizontal: 15,
    paddingVertical: 10,
    alignItems: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'space-between',
    zIndex: 1,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    marginBottom: '0.8rem',
    top: 10
  },
  nextItemText:{
    fontFamily: fontFamily.font700,
    color: '#393838',
    // width: 50,
  },
  nextItemFlex:{
    width: '100%',
    flex: 1,
    backgroundColor: '#000'
  },
  nextItemOrder:{
    textAlign: 'center',
    fontSize: 22,
    paddingVertical: 10,
    fontFamily: fontFamily.font700,
    color: '#8e8b8b'
  },
  nextItemMobile:{
    padding: 10,
    backgroundColor: '#707070',
    borderRadius: 5,
    marginTop: 1
  },
  nextItemMobileFlex:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  serialRecord:{
    backgroundColor: '#ebebeb',
    height: 10,
    width: 10,
    borderRadius: 5,
    marginRight: 10,
    shadowColor: '#fff',
    shadowOffset: {
      width: 0,
      height: 0
    },
    shadowOpacity: 1,
    shadowRadius: 10,
    elevation: 24
  },
  scanBarCodeInput:{
    outline: 'none',
    flex: 15,
    paddingLeft: 20,
    padding: 5,
    backgroundColor: '#ebebeb',
    fontSize: 15,
    fontFamily: fontFamily.font600,
    width: '100%',
    textAlign: 'left',
    borderRadius: 5
  },
  noOrderPresent:{
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000'
  },
  productConfirmationCheckMobile:{
    fontFamily: 'Poppins_300Light',
    color: "#8A8A8A",
    color: '#000',
    fontSize: 12,
    width: '98%',
    lineHeight: 18,
    marginTop: '2%',
  },
  scanCountText:{
    fontFamily: 'Poppins_300Light',
    fontSize: 16,
    marginTop: 10,
    lineHeight: '24px',
    textAlign: 'center'
  },
  scrollView:{
    fontFamily: 'Poppins_400Regular',
    padding: 5,
    fontSize: '20px',
    marginLeft: '1%',
    height: 120
  },
  productConfirmationView:{
    borderRadius: 15,
    marginTop: '2%',
    height: '24%',
    justifyContent: 'center'
  },
  ProductConfirmationCheckInput:{
    fontFamily: 'Poppins_300Light',
    fontSize: "20px",
    lineHeight: "30px",
    color: "#8A8A8A",
    flex: "none",
    order: 0,
    alignSelf: "stretch",
    flexGrow: 0,
  },
  customScrollBar:{
    backgroundColor: '#f8e96a',
    borderRadius: 10,
    marginTop: '2%',
    height: "100px",
    flexDirection: 'row',
    justifyContent: 'center'
  },
  productConfirmation:{
    width: '50%',
    height: 365,
    marginTop: '3%'
  },
  productConfirmationMobile:{
    width: '100%',
    height: '65%',
    marginTop: '7%'
  },
  ProductCheckInputStyleMobile:{
    paddingVertical: "13px",
    paddingHorizontal: "20px",
    borderRadius: "10px",
    outline: 'none',
    width: '98%',
    marginTop: "10%"
  },
  ProductCheckInputStyle:{
    width: "98%",
    marginTop: "24px",
    borderRadius: "10px",
    paddingHorizontal: 20,
    paddingVertical: 6,
    height: 39
  },
  confirmTrackingNumber:{
    backgroundColor: '#000',
    height: 64,
    width: '100%',
    shadowColor: '#000',
    shadowOffset: {
      width: 1,
      height: 10
    },
    shadowOpacity: 3,
    shadowRadius: 10,
    elevation: 5
  },
  trackingNumberTextExtra:{
    fontFamily: 'Poppins_800ExtraBold',
    fontSize: '23px'
  },
  trackingNumberTextSemi:{
    fontFamily: 'Poppins_600SemiBold',
    fontSize: '21px'
  },
  trackingNumberView:{
    alignItems: 'center', 
    width: '80%'
  },
  checkInputStyle:{
    borderRadius: "10px",
    paddingVertical: 5,
    marginTop: "2%",
    width: "98%",
    paddingHorizontal: 13
  },
  checkInputStyleMobile:{
    borderRadius: "10px",
    flex: "none",
    order: 4,
    alignSelf: "stretch",
    flexGrow: 0,
    paddingVertical: 10,
    marginTop: "5%",
    paddingHorizontal: 13
  },
  orderConfirmationPopUp:{
    backgroundColor: '#d3dfea',
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 18,
    width: '50%',
    height: '35rem',
    position: 'absolute',
    marginTop: '52px'
  },
  orderConfirmationPopUpMobile:{
    backgroundColor: '#d3dfea',
    paddingHorizontal: 10,
    paddingVertical: 5,
    width: '99%',
    borderRadius: 18,
    height:  352,
    position: 'fixed',
    bottom: '10px',
    // top:"29%",
  },
  orderConfirmationText:{
    fontSize: 16,
    lineHeight: "24px",
    marginTop: 10,
  }
});
export default globalStyles;
