import React, { useState, useEffect } from 'react';
import {
  View,
  TextInput,
  Text,
  StyleSheet,
  TouchableOpacity,
} from 'react-native';

const EditableInput = (props) => {
  const [isValueChanged, setIsValueChanged] = useState(false);
  const [value, setValue] = useState(props.value);
  let onSubmitCalled = false;
  const handleSubmit = () => {
    if (value !== '' && isValueChanged) {
      onSubmitCalled = true;
      props.onSubmitEditing(value);
      setIsValueChanged(false);
      props.setIsEditing(false);
    }
    else if(value === '' && isValueChanged){
      setValue(props.value)
      setIsValueChanged(false);
      props.setIsEditing(false);
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };
  const handleChange = (text) => {
    setValue(text);
    handleIsValueChanged(text);
  };
  const handleIsValueChanged = (value) => {
    if (value === props.value && value !== '') {
      setIsValueChanged(false);
      props.setIsEditing(false);
    } else {
      setIsValueChanged(true);
      props.setIsEditing(true);
    }
  };
  useEffect(() => {
    isValueChanged && handleIsValueChanged(value);
  }, []);
  return (
    <View
      style={{
        flexDirection: 'column',
        width: '100%',
      }}
    >
      {isValueChanged && (
        <Text style={style.textStyle}>ENTER to Validate & Save</Text>
      )}
      <View style={{ width: '100%' }}>
        <TextInput
          testID={props.testID}
          style={[
            isValueChanged ? style.inputStyle : style.input,
            {
              marginTop: isValueChanged ? 0 : 15,
              marginLeft: props.marginLeft ? 0 : 15,
              width: props.marginLeft ? '95%' : '100%',
            },
          ]}
          onSubmitEditing={() => {
            !onSubmitCalled && handleSubmit();
          }}
          onChangeText={(text) => {
            props.isAllowed ? props.checkPermission() : handleChange(text);
          }}
          autoCapitalize={'none'}
          returnKeyType={'done'}
          value={value}
          onKeyPress={handleKeyPress}
          onBlur={handleSubmit}
        />
        <TouchableOpacity
          key={props.index}
          testID={props.id}
          onPress={props.onRemove}
        >
          <i
            className='icon-cancel'
            style={{
              fontSize: 22,
              marginTop: '-44px',
              position: 'absolute',
              right: props.marginLeft ? '4%' : '-6%',
              color: '#2b3d4b',
            }}
          ></i>
        </TouchableOpacity>
      </View>
    </View>
  );
};
export default EditableInput;

const style = StyleSheet.create({
  input: {
    margin: 15,
    borderWidth: 1,
    border: 'none',
    height: 31,
    borderRadius: 10,
    padding: 10,
    backgroundColor: '#cdcdcd',
    color: '#1d3045',
    fontFamily: 'Poppins_500Medium',
    fontSize: 15,
    width: '100%',
  },
  inputStyle: {
    margin: 15,
    borderWidth: 3,
    height: 31,
    borderRadius: 10,
    padding: 10,
    backgroundColor: '#cdcdcd',
    color: '#1d3045',
    fontFamily: 'Poppins_500Medium',
    fontSize: 15,
    borderColor: '#4BDA9C',
    width: '100%',
  },
  textStyle: {
    backgroundColor: '#4BDA9C',
    width: 'fit-content',
    alignSelf: 'center',
    fontSize: 12,
    fontWeight: 600,
    paddingTop: 5,
    paddingHorizontal: 5,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    color: '#465667',
    fontFamily: 'Poppins_300Light',
  },
});