import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Vibration } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { fontFamily } from '../helpers/fontFamily';
import * as Clipboard from 'expo-clipboard';
import { useSelector } from 'react-redux';

const OrderItem = ({
  redirectToItemDetail,
  item,
  testID,
}) =>
  {
    const copyTextToClipboard = async (text) => {
      await Clipboard.setStringAsync(`${text}`);
    };
    const isHaptic = useSelector((state) => state.menu.isHaptics);
    return (
      <View
        key={item.id}
        style={styles.container}
      >
        <LinearGradient
          colors={['#646469', '#b6c2d2', '#ddedfc', '#dceafc']}
          locations={[0, 0.1, 0.44, 1]}
          style={styles.gradientContainer}
        >
          <TouchableOpacity
            testID={testID}
            onPress={() => redirectToItemDetail(item)}
            delayLongPress={1000}
          >
            <Text
              testID='itemName'
              numberOfLines={2}
              ellipsizeMode="tail"
              style={[styles.nameText,{
                fontSize: item.name?.length <= 32 ? 18 : 16,
              }]}
            >
              {item.name}
            </Text>
          </TouchableOpacity>
        </LinearGradient>
        {item.sku && (
          <View
            style={styles.skuContainer}
          >
            <TouchableOpacity
              testID='copySKU'
              onPress={()=>{
                isHaptic && Vibration.vibrate(30)
                copyTextToClipboard(item.sku)
              }}
            >
              <Text
                numberOfLines={1}
                ellipsizeMode="tail"
                style={styles.skuSubContainer}
              >
                <Text
                  style={styles.skuText}
                >
                  SKU :
                </Text>
                {item.sku}
              </Text>
            </TouchableOpacity>
          </View>
        )}
        <View
          style={styles.upcContainer}
        >
          {item?.barcodes[0]?.barcode && (
            <View
              style={styles.upcSubContainer}
            >
              <TouchableOpacity
                testID='copyUPC'
                onPress={()=>{
                  isHaptic && Vibration.vibrate(30)
                  copyTextToClipboard(item.barcodes[0]?.barcode)
                }}
              >
                <Text
                  style={styles.upcText}
                  numberOfLines={1}
                  ellipsizeMode="tail"
                >
                  <Text
                    style={styles.upcLabel}
                  >
                    UPC :
                  </Text>
                  {item.barcodes[0]?.barcode}
                </Text>
              </TouchableOpacity>
            </View>
          )}
          <View
            style={styles.locationContainer}
          >
            {item.location !== null && item.location !== '' && item.location !== undefined&& (
              <View
                style={styles.locationSubContainer}
              >
                <Text
                  style={styles.locationLabel}
                >
                  L1 :
                </Text>
                <Text
                  numberOfLines={1}
                  style={styles.locationText}
                >
                  {item.location}
                </Text>
              </View>
            )}
            {item.custom_product_1 !== null &&
              item.custom_product_1 !== '' &&
              item.custom_product_1 !== undefined && (
                <View
                  style={styles.locationSubContainer}
                >
                  <Text
                    style={styles.c1Label}
                  >
                    C1:
                  </Text>
                  <Text
                    numberOfLines={1}
                    style={styles.locationText}
                  >
                    {item.custom_product_1}
                  </Text>
                </View>
              )}
          </View>
        </View>
      </View>
    );
  };
const styles = StyleSheet.create({
  container:{
    width: '100%',
    minHeight: 125,
  },
  gradientContainer: {
    flex: 16,
    padding: 4,
    borderTopLeftRadius: 12,
    borderTopEndRadius: 12,
    justifyContent: 'center',
  },
  nameText: {
    fontFamily: fontFamily.font400,
    width: '65%',
    textAlign: 'left',
  },
  skuContainer: {
    padding: 4,
    justifyContent: 'center',
    backgroundColor: '#b6cadd',
  },
  skuSubContainer: {
    fontSize: 18,
    fontFamily: fontFamily.font700,
    color: '#3f638b',
    width: '65%',
  },
  skuText: {
    fontSize: 12,
    fontFamily: fontFamily.font400,
    fontWeight: 'bold',
    paddingRight: 2,
  },
  upcContainer: {
    backgroundColor: '#c1d4e6',
    paddingLeft: 3,
    paddingRight: 3,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    paddingBottom: 3
  },
  upcSubContainer:{
    backgroundColor: '#d3def4',
    paddingLeft: 3,
    paddingRight: 3,
  },
  upcLabel:{
    fontSize: 12,
    fontFamily: fontFamily.font400,
    fontWeight: 'bold',
    paddingRight: 2,
  },
  upcText:{
    fontSize: 18,
    maxWidth: '75%',
  },
  locationContainer: {
    maxWidth: '75%',
    flexDirection: 'row',
  },
  locationSubContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  locationLabel:{
    fontSize: 12,
    fontFamily: fontFamily.font400,
    paddingRight: 2,
  },
  c1Label:{
    fontSize: 12,
    fontFamily: fontFamily.font400,
    paddingRight: 2,
  },
  locationText:{
    fontSize: 15,
    fontFamily: fontFamily.font700,
  }

})
export default OrderItem;