import {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {GetProductDetail} from '../../actions/productAction';

const useProductList = ({navigation, route}) => {
  const {selectedWorkFlow} = route.params;
  const store = useSelector(state => state.product);
  const dispatch = useDispatch();
  const isMounted = useRef(false);
  const onSelectItem = id => {
    dispatch(GetProductDetail(id));
  };
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }
    if (store.product) {
      navigation.navigate(selectedWorkFlow.screen, {
        steps: selectedWorkFlow.steps
      });
    }
  }, [store.product]);
  return {
    products: store.searchProduct,
    onSelectItem
  };
};
export default useProductList;
