import React from 'react';
import {
    View,
    Text,
    StyleSheet,
    Image
} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
const ItemData = ({name, sku , barcode,imageUrl,width,placeholder,color}) =>{
    return (
        <LinearGradient
        locations={[0,1]}
        colors= {['#C9D2DD','#DCEBFC','#DCEAFC']}
        style={[styles.mainContainer,{width: width > 900 ? '45%' : '70%'}]}
        >
            <View
                style={[styles.textContainer,{width: width > 900 ? '80%' : '70%'}]}
            >
                <Text
                    numberOfLines={1}
                    style={{padding: 5}}
                >
                    {name}
                </Text>
                <View
                 style={styles.skuContainer}
                >
                    <Text
                    numberOfLines={1}
                    style={styles.textStyle1}
                    >
                        SKU :
                    </Text>
                    <Text
                    numberOfLines={1}
                    style={styles.textStyle}
                    >
                        {sku}
                    </Text>
                </View>
                <View
                 style={[
                    styles.skuContainer,
                    {backgroundColor: color? color : '', borderBottomLeftRadius: color ? 15 : ''}]}
                >
                    <Text
                    numberOfLines={1}
                    style={styles.textStyle1}
                    >
                        UPC :
                    </Text>
                    <Text
                    numberOfLines={1}
                    style={styles.textStyle}
                    >
                        {barcode}
                    </Text>
                </View>
            </View>
            <View style={{width: width > 900 ? '20%' : '30%'}}>
                <Image 
                style={styles.image}
                defaultSource={placeholder} 
                source={{ uri:imageUrl}}
                />
            </View>
        </LinearGradient>
    )
}
export default ItemData;

const styles = StyleSheet.create({
    mainContainer: {
        display: 'flex',
        flexDirection:'row',
        borderRadius: 15,
        marginTop: 10,
        marginBottom: 10
    },
    textContainer : {
        display: 'flex',
        flexDirection: 'column',
    },
    skuContainer: {
        backgroundColor: '#B6CADD',
        padding: 5, 
        flexDirection: 'row',
        alignItems:'center'
    },
    image: {
        height: '100%', 
        width: '102%',
        borderRadius: 15,
        marginLeft: 'auto'
    },
    textStyle: {
        fontFamily: 'Poppins_600SemiBold',
        fontSize: 18, 
        paddingLeft: 5
    },
    textStyle1: {
        fontFamily: 'Poppins_300Light', 
        fontSize: 12,
    }
})