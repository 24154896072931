import React, {Component} from 'react';
import {Text, TouchableOpacity, View, Image} from 'react-native';
import reprintPdfImage from '../../../assets/reprinticon.svg';
import moment from 'moment';

import styles from '../../style/shippinglabel.js';
import { ScrollView } from 'react-native';
// import {ScrollView} from 'react-native-gesture-handler';
import {LinearGradient} from 'expo-linear-gradient';
export default class ReprintSSlabel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
      difftimeCheck: []
    };
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    let shipmentData = this.props?.sslabelData?.shipments.filter(
      z => z.voided === false
    );
    const timeDifferences = shipmentData?.map(shipmentinsideData => {
      const date1 = new Date(shipmentinsideData.createDate);
      const date2 = new Date();
      const timestamp1 = date1.getTime();
      const timestamp2 = date2.getTime();
      const timeDifference = Math.abs(timestamp2 - timestamp1);
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
      if (days > 0) {
        let daycheck = days === 1 ? `${days} day` : `${days} days`;
        return `${daycheck} ago`;
      } else if (hours > 0 && minutes > 0) {
        let hourCheck = hours === 1 ? `${hours} hour` : `${hours} hours`;
        let minuteCheck =
          minutes === 1 ? `${minutes} minute` : `${minutes} minutes`;
        return `${hourCheck} ${minuteCheck} ago`;
      } else if (hours > 0) {
        let hourCheck = hours === 1 ? `${hours} hour` : `${hours} hours`;
        return `${hourCheck} ago`;
      } else {
        let minuteCheck =
          minutes === 1 ? `${minutes} minute` : `${minutes} minutes`;
        return `${minuteCheck} ago`;
      }
    });
    this.setState({difftimeCheck: timeDifferences});
  }
  componentWillUnmount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  updateDimensions = () => {
    this.setState({windowWidth: window.innerWidth});
  };

  handlePress = matchingItem2 => {
    let setting = this.props.setting;
    if (matchingItem2) {
      setting?.print_ss_shipping_labels
        ? this.props.printlabelNew(setting, '', matchingItem2.url)
        : window.open(matchingItem2.url);
      this.props.closePreviousModel();
    }
  };
  skipLabelPress = () => {
    this.props.navigation.navigate('ScanPack', {
      time: new Date()
    });
  };
  render() {
    let windowWidth = this.state.windowWidth;
    let shipmentData = this.props?.sslabelData?.shipments.filter(
      z => z.voided == false
    );
    let sslabelData = this.props?.sslabelData?.shipping_labels;
    let unMatchedData = shipmentData?.filter(shipmentinsideData => {
      return !sslabelData.some(
        sslabelDataInside =>
          shipmentinsideData?.shipmentId === sslabelDataInside?.shipment_id
      );
    });

    let count = 0;
    const dataToShow = () => {
      count++;
      return (
        <>
          {' '}
          <Text
            style={
              windowWidth >= 900
                ? styles.reprintInfoHead
                : styles.reprintInfoHeadMobile
            }>
            Previously printed labels can be reprinted below
          </Text>
          <Text
            style={
              windowWidth >= 900
                ? styles.reprintInfoSubhead
                : styles.reprintInfoSubheadMobile
            }>
            Click a label to reprint it.
          </Text>
        </>
      );
    };

    return (
      <>
        <View style={styles.reprintWrapView}>
          <LinearGradient
            locations={[0, 1]}
            colors={['#FFB538', '#CF7C08']}
            style={
              windowWidth >= 900
                ? styles.reprintMainView
                : styles.reprintMainViewMobile
            }>
            <TouchableOpacity
              testID='close-model'
              style={{height: 25, width: 34, marginLeft: 'auto'}}
              onPress={() => this.props?.iconCancelPress()}>
              <i
                className="icon-cancel"
                style={{
                  color: '#000',
                  fontSize: '24px'
                }}></i>
            </TouchableOpacity>
            <ScrollView showsVerticalScrollIndicator={false}>
              <View
                style={
                  windowWidth >= 900
                    ? styles.reprintOuterView
                    : styles.reprintOuterViewMobile
                }>
                {shipmentData?.map((shipmentinsideData, index) => {
                 const matchingItem2 = sslabelData.find(
                    sslabelDataInside =>
                      shipmentinsideData?.shipmentId ===
                      sslabelDataInside?.shipment_id
                  );
                  const parsedDate = moment(shipmentinsideData.createDate);
                  const formattedDate = parsedDate.format(
                    'MMM DD[th] YYYY [at] hh:mm a'
                  );

                  return (
                    <>
                      <View>
                        {unMatchedData[unMatchedData.length - 1]?.shipmentId ===
                        shipmentinsideData?.shipmentId ? (
                          <>
                            {' '}
                            <Text
                              style={
                                windowWidth >= 900
                                  ? styles.reprintInfoHead
                                  : styles.reprintInfoHeadMobile
                              }>
                              Previously purchased labels found
                            </Text>
                            <Text
                              style={
                                windowWidth >= 900
                                  ? styles.reprintInfoSubhead
                                  : styles.reprintInfoSubheadMobile
                              }>
                              They were purchased in the order manager and would need to be reprinted there.
                            </Text>
                          </>
                        ) : (
                          <>{count === 0 && dataToShow()}</>
                        )}
                      </View>
                      <TouchableOpacity
                        testID='showing-item'
                        onPress={() => this.handlePress(matchingItem2)}>
                        <LinearGradient
                          locations={[0, 1]}
                          colors={
                            matchingItem2
                              ? ['#D7D7D7', '#D7D7D7']
                              : ['#D7D7D7', '#979797']
                          }
                          style={styles.reprintInfoView}>
                          <Image
                            source={reprintPdfImage}
                            style={{width: 58, height: 80}}
                          />
                          <View style={{width: 'calc(100% - 60px)'}}>
                            <Text
                              style={
                                windowWidth >= 900
                                  ? styles.reprintInfoLtView
                                  : styles.reprintInfoLtViewMobile
                              }>
                              Label 1 - {formattedDate}{' '}
                              {this.state.difftimeCheck[index]}
                            </Text>
                            <Text
                              style={
                                windowWidth >= 900
                                  ? styles.reprintInfoLtView
                                  : styles.reprintInfoLtViewMobile
                              }>
                              Service: {shipmentinsideData.serviceCode}
                            </Text>
                            <Text
                              style={
                                windowWidth >= 900
                                  ? styles.reprintInfoLtView
                                  : styles.reprintInfoLtViewMobile
                              }>
                              Shipment {index + 1} of {shipmentData.length}
                            </Text>
                          </View>
                        </LinearGradient>
                      </TouchableOpacity>
                    </>
                  );
                })}
              </View>
            </ScrollView>
            <View
              style={
                windowWidth >= 900
                  ? styles.reprintButtonView
                  : styles.reprintButtonViewMobile
              }>
              <TouchableOpacity 
              testID = "skip-label"
              onPress={() => this.skipLabelPress()}>
                <Text
                  style={
                    windowWidth >= 900
                      ? styles.reprintButton
                      : styles.reprintButtonMobile
                  }>
                  Skip Label Printing
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                testID = "close-previous-model"
                onPress={() => this.props?.closePreviousModel()}>
                <Text
                  style={
                    windowWidth >= 900
                      ? styles.reprintButton
                      : styles.reprintButtonMobile
                  }>
                  Exit and Purchase a New Label
                </Text>
              </TouchableOpacity>
            </View>
          </LinearGradient>
        </View>
      </>
    );
  }
}
