import {
  ORDER_LIST,
  ORDER_LIST_FAIL,
  ORDER_DETAIL,
  ORDER_DETAIL_FAIL,
  SEARCH_ORDER,
  SEARCH_ORDER_COUNT,
  UPDATE_ORDER_QUANTITY,
  ADD_ORDER_ITEMS,
  REMOVE_ORDER_ITEMS,
  UPDATE_ORDER_INFO,
  SEARCH_ORDER_FAIL,
  REMOVE_ORDER_ITEMS_FAIL,
  UPDATE_ORDER_INFO_FAIL,
  RECORD_EXCEPTION,
  CLEAR_EXCEPTION,
  ADD_ORDER_ITEMS_FAIL,
  ADD_ORDER_IMAGE_FAIL,
  ADD_ORDER_IMAGE,
  ADD_ORDER_SUCCESS,
  ADD_ORDER_FAIL,
  ADD_ORDER_IMAGE_LOADING,
  ADD_ORDER_IMAGE_CLEAR,
  REMOVE_PACKING_IMAGE_LOADING,
  REMOVE_PACKING_IMAGE_DONE,
  REMOVE_PACKING_IMAGE_FAIL,
  GET_SHIPMENT_ID_SUCCESS,
  GET_SHIPMENT_ID_FAIL,
  IS_LOADING,
  GET_SS_LABEL,
  GET_SS_LABEL_FAIL,
  FETCH_REAL_TIME_RATES,
  FETCH_REAL_TIME_RATES_FAIL,
  FETCH_REAL_TIME_RATES_SELECTED,
  FETCH_REAL_TIME_RATES_SELECTED_FAIL,
  UPDATE_ORDER_ADDRESS,
  UPDATE_ORDER_ADDRESS_FAIL,
  CARRIER_LOADING,
  SET_CARRIER_VISIBILITY,
  SET_CARRIER_VISIBILITY_FAIL,
  SET_RATE_VISIBILITY,
  SET_RATE_VISIBILITY_FAIL,
  CREATE_SS_LABEL,
  CREATE_SS_LABEL_FAIL,
  SET_LABEL_SHORTCUT,
  SET_LABEL_SHORTCUT_FAIL,
  PURCHASE_LOADING,
  PURCHASE_LOADING_DONE,
  SET_CONTRACTED_CARRIER,
  SET_CONTRACTED_CARRIER_FAIL,
  SET_PRESETS,
  SET_PRESETS_FAIL,
  IS_ORDER_CREATED,
  ARE_ORDERS_UPDATED,
  GET_ORDERS_TAGS,
  CREATE_ORDER
} from '../constants';

const initialState = {
  isOrderCreated: true,
  ordersUpdated: false,
  add_tag_success: false
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ORDER_LIST:
      return {...state, list: action?.payload};
    case ORDER_LIST_FAIL:
      return {...state, list: action?.payload};
    case ORDER_DETAIL:
      return {...state, order: action?.payload};
    case ORDER_DETAIL_FAIL:
      return {...state, order: action?.payload};
    case SEARCH_ORDER:
      return {...state, searchResult: action?.payload};
    case SEARCH_ORDER_COUNT:
      return {...state, searchResultCount: action?.payload};
    case IS_LOADING:
      return {...state, isLoading: action?.payload};
    case SEARCH_ORDER_FAIL:
      return {...state, searchResult: action?.payload};
    case UPDATE_ORDER_QUANTITY:
      return {...state, updateOrderQuantity: action.payload};
    case ADD_ORDER_ITEMS:
      return {...state, addOrderItems: action.payload};
    case ADD_ORDER_ITEMS_FAIL:
      return {...state, addOrderItems: action.payload};
    case REMOVE_ORDER_ITEMS:
      return {...state, removeOrderItems: action.payload};
    case REMOVE_ORDER_ITEMS_FAIL:
      return {...state, removeOrderItems: action.payload};
    case UPDATE_ORDER_INFO:
      return {...state, updateOrder: action.payload};
    case UPDATE_ORDER_INFO_FAIL:
      return {...state, updateOrder: action.payload};
    case RECORD_EXCEPTION:
      return {...state, exception: action.payload};
    case CLEAR_EXCEPTION:
      return {...state, clearException: action.payload};
    case ADD_ORDER_IMAGE:
      let order = {};
      if (state.order) {
        order = {
          order: {
            ...state.order,
            packing_cams: action.payload.status
              ? [...state.order.packing_cams, action.payload.image]
              : [...state.order.packing_cams]
          }
        };
      }
      return {
        ...state,
        addOrderImage: action.payload.status,
        uploadingImage: false,
        ...order
      };
    case ADD_ORDER_IMAGE_LOADING:
      return {...state, uploadingImage: true, addOrderImage: null};
    case ADD_ORDER_SUCCESS:
      return {...state, add_tag_success: true};
    case ADD_ORDER_FAIL:
      return {...state, add_tag_success: false};
    case ADD_ORDER_IMAGE_FAIL:
      return {...state, addOrderImage: false, uploadingImage: false};
    case ADD_ORDER_IMAGE_CLEAR:
      return {...state, addOrderImage: null, uploadingImage: null};
    case REMOVE_PACKING_IMAGE_LOADING:
      return {...state, isRemovingImage: true};
    case REMOVE_PACKING_IMAGE_DONE:
      return {
        ...state,
        isRemovingImage: false,
        order: {
          ...state.order,
          packing_cams: action.payload.status
            ? state.order.packing_cams.filter(pc => pc.id !== action.payload.id)
            : state.order.packing_cams
        }
      };
    case REMOVE_PACKING_IMAGE_FAIL:
      return {...state, isRemovingImage: false};
    case GET_SHIPMENT_ID_SUCCESS:
      return {...state, getShipment: action.payload};
    case GET_SHIPMENT_ID_FAIL:
      return {...state, getShipment: action.payload};
    case CARRIER_LOADING:
      return {...state, carrierLoading: true,isLoadingActive : true};
      case GET_SS_LABEL:
        return {
          ...state,
          ssLabelData: {...action?.payload, time: new Date()},
          carrierLoading: false,
          isLoadingActive : false,
        };
    case GET_SS_LABEL_FAIL:
      return {
        ...state,
        ssLabelData: {...action?.payload, time: new Date()},
        carrierLoading: false
      };
    case FETCH_REAL_TIME_RATES:
      return {
        ...state,
        updatedAvailableCarriers: {...action?.payload, time: new Date()},
        carrierLoading: false,
        isLoadingActive : false
      };
    case FETCH_REAL_TIME_RATES_FAIL:
      return {
        ...state,
        updatedAvailableCarriers: {...action?.payload, time: new Date()},
        carrierLoading: false
      };
    case FETCH_REAL_TIME_RATES_SELECTED:
      return {
        ...state,
        updatedAvailableCarriers: {
          ...action?.payload,
          time: new Date(),
          selected: true
        },
        carrierLoading: false
      };
    case FETCH_REAL_TIME_RATES_SELECTED_FAIL:
      return {
        ...state,
        updatedAvailableCarriers: {
          ...action?.payload,
          time: new Date(),
          selected: true
        },
        carrierLoading: false
      };
    case UPDATE_ORDER_ADDRESS:
      return {...state, orderAddress: action?.payload};
    case UPDATE_ORDER_ADDRESS_FAIL:
      return {...state, orderAddress: action?.payload};
    case SET_CARRIER_VISIBILITY:
      return {...state, carrierVisibility: action?.payload};
    case SET_CARRIER_VISIBILITY_FAIL:
      return {...state, carrierVisibility: action?.payload};
    case SET_RATE_VISIBILITY:
      return {...state, rateVisibility: action?.payload};
    case SET_RATE_VISIBILITY_FAIL:
      return {...state, rateVisibility: action?.payload};
    case CREATE_SS_LABEL:
      return {...state, ssLabel: {...action?.payload, time: new Date()}};
    case CREATE_SS_LABEL_FAIL:
      return {...state, ssLabel: {...action?.payload, time: new Date()}};
    case SET_LABEL_SHORTCUT:
      return {...state, labelShortcuts: action?.payload};
    case SET_LABEL_SHORTCUT_FAIL:
      return {...state, labelShortcuts: action?.payload};
    case PURCHASE_LOADING:
      return {...state, purchaseLoading: true};
    case GET_ORDERS_TAGS:
      return {...state, ordersTags: action.payload};
    case PURCHASE_LOADING_DONE:
      return {...state, purchaseLoading: false};
    case SET_CONTRACTED_CARRIER:
      return {
        ...state,
        contractedCarrier: {...action?.payload, time: new Date()}
      };
    case SET_CONTRACTED_CARRIER_FAIL:
      return {
        ...state,
        contractedCarrier: {...action?.payload, time: new Date()}
      };
    case SET_PRESETS:
      return {
        ...state,
        dimensionPresets: {...action?.payload, time: new Date()}
      };
    case SET_PRESETS_FAIL:
      return {
        ...state,
        dimensionPresets: {...action?.payload, time: new Date()}
      };
    case IS_ORDER_CREATED:
      return {...state, isOrderCreated: action.payload};
    case ARE_ORDERS_UPDATED:
      return {...state, ordersUpdated: action.payload};
    case CREATE_ORDER:
      return {...state, isOrderCreated: false, orderId: action.payload};
    default:
      return state;
  }
};

export default orderReducer;
