import React, { Component } from 'react';
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Dimensions
} from 'react-native';
import styles from '../../style/scanpack';
import globalStyles from '../../style/global';
import promptStyle from '../../style/popupmodel'
import { fontFamily } from '../../helpers/fontFamily';
import { LinearGradient } from 'expo-linear-gradient';
import './addBar.css';
import i18n from 'i18n-js';

export default class AddBarcode extends Component {
  constructor(props) {
    super(props);
    this.typeScanFocus = React.createRef();
    this.state = {
      windowWidth: Dimensions.get('window').width
    };
  }

  componentDidUpdate() {
    // this.typeScanFocus.current.focus()
  }
  updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentWillUnmount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentDidMount = () => {
    window.addEventListener('resize', this.updateDimensions);
  };

  render() {
    return (
      <View style={styles.typeScanContainer}>
        <LinearGradient
           testID='addBarcodeContainer'
          locations={[0, 1]}
          start={{ x: 0, y: 1 }}
          end={{ x: 0, y: 0 }}
          colors={['#d47d02', '#ffb636']}
          style={[promptStyle.promptModel, { width: this.state.windowWidth >= 900 ? '50%' : '97%', top: this.state.windowWidth >= 600 ? "17%" : "9%" }]}
        >
          <View style={[globalStyles.flexDirectionRow]}>
            <Text
              style={[
                styles.addBarcodeText,
                { marginTop: this.state.windowWidth <= 900 ? '4%' : '0' }
              ]}
            >
              {i18n.t('scanpackItem.addBar_text')}{' '}
              {i18n.t('scanpackItem.addBarText')}
            </Text>
          </View>
          <View
            style={{
              position: 'absolute',
              right: 0,
              top: this.state.windowWidth <= 900 ? 5 : 10
            }}
          >
            <TouchableOpacity testID='cancelIcon' onPress={() => this.props.closeAlert()}>
              <i
                className="icon-cancel"
                style={{ fontSize: '26px', color: '#4b4132' }}
              ></i>
            </TouchableOpacity>
          </View>
          <View
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%'
            }}
          >
            <View style={{ alignItems: 'center', width: '80%' }}>
              <TextInput
                value={this.props.addBarcodeText}
                onChangeText={this.props.addBarcodeChange}
                autoFocus={true}
                ref={this.typeScanFocus}
                onSubmitEditing={
                  this.props.addBarcodeText !== ''
                    ? this.props.addBarcodeSubmit
                    : ''
                }
                className="borderInput"
                style={[promptStyle.promptTextIn, { marginTop: '3px !important',border: 'none'}]}
              ></TextInput>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <TouchableOpacity
                testID='cancelButton'
                onPress={this.props.closeAlert}
                style={[
                  styles.modalButton,
                  {
                    marginLeft: this.state.windowWidth >= 900 ? '-50%' : '-50%'
                  }
                ]}
              >
                <Text>Cancel</Text>
              </TouchableOpacity>
              <TouchableOpacity
                testID='enterButton'
                onPress={
                  this.props.addBarcodeText !== ''
                    ? this.props.addBarcodeSubmit
                    : ''
                }
                style={[
                  styles.modalButton,
                  { marginLeft: this.state.windowWidth >= 900 ? '84%' : '100%' }
                ]}
              >
                <Text>Enter</Text>
              </TouchableOpacity>
            </View>
          </View>
        </LinearGradient>
      </View>
    );
  }
}
