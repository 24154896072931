import React, {Component} from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  Dimensions,
  Picker,
  ScrollView
} from 'react-native';
import AddItemInBox from './add_item_in_box';
import MultiBoxDetailMobile from './multi_box_detail_mobile';
import styles from '../../style/orderdetail';
import OrderInfoHeader from '../scanpack/order-info-header';
import CommonStyles from '../../style/commonStyles';
import TollTip from './tolltip';
import i18n from 'i18n-js';
export default class MultiBoxDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: Dimensions.get('window').width,
      pickerSelectedOptions: [
        {
          label: 'Print Packing Slips for All Boxes',
          value: 'all_boxes'
        },
        {
          label: 'Print Packing Slips for Selected Boxes',
          value: 'selected_boxes'
        }
      ],
      toolTip: false,
      checkstate: '',
      boxId: '',
      indexCheck: null,
      selectValue: 'all_boxes',
      addUnscanItem: false,
      editable: Number,
      hideSaveClose: true
    };
  }

  removeBoxList = id => {
    this.props.removeBox(id);
    let idorder = this.props?.basicinfo?.id;
    setTimeout(() => {
      this.props.orderDetailFun(idorder);
    }, 1000);
  };
  hideSaveFun = () => {
    this.setState({hideSaveClose: !this.state.hideSaveClose});
  };

  printSlip = stateCheckbox => {
    let arrayempty = [];
    this?.props?.orderDetail?.order_item_boxes?.map((item, index) => {
      arrayempty.push(item.box_id);
    });
    let item = {
      box_ids:
        this.state.selectValue === 'all_boxes'
          ? arrayempty
          : [this.state.boxId],
      orderArray: [{id: this.props?.basicinfo?.id}],
      packing_type: this.state.selectValue
    };
    this.props.printSlip(item);
  };
  updateDimensions = () => {
    this.setState({windowWidth: window.innerWidth});
  };
  boxSelect = (box, index) => {
    this.setState({boxId: box.id});
    this.setState({indexCheck: index});
  };
  pickerValueChange = itemValue => {
    this.setState({selectValue: itemValue});
  };

  componentDidMount() {
    const box = this?.props?.orderDetail?.boxes[0];
    const boxlength = this?.props?.orderDetail?.boxes.length;
    if (boxlength > 0) {
      this.boxSelect(box, 0);
    }
    window.addEventListener('resize', this.updateDimensions);
  }

  render() {
    let basicinfo = this?.props && this?.props?.basicinfo;
    return (
      <ScrollView
        contentContainerStyle={{flexGrow: 1, paddingBottom: 10}}
        showsVerticalScrollIndicator={false}>
        <View style={CommonStyles.packingCamContainer} />
        <View
          style={{
            flex: 1,
            paddingLeft: 20,
            paddingRight: 20
          }}>
          <OrderInfoHeader
            updateOrderStatus={this.props.updateOrderStatus}
            status={basicinfo.status}
            onSave={this.props.updateBasicInfo}
            show={this.props.show}
            onCancel={this.props.back}
            id={basicinfo}
            isListOpen={!this.state.hideSaveClose}
          />
          {this.state.windowWidth >= 900 ? (
            <>
              {basicinfo && (
                <>
                  {this.state.addUnscanItem === true ? (
                    <View>
                      <AddItemInBox
                        onchange={() =>
                          this.setState({
                            addUnscanItem: !this.state.addUnscanItem
                          })
                        }
                        {...this.props}
                        hideSaveFun={() => this.hideSaveFun()}
                      />
                    </View>
                  ) : (
                    <View
                      testID="multiBoxContainer"
                      onStartShouldSetResponder={() => {
                        this.setState({toolTip: false});
                      }}
                      style={CommonStyles.orderInfoStyle}>
                      <View style={{marginTop: 40, marginLeft: 5, zIndex: 1}}>
                        <View>
                          <Text style={{color: '#fff'}}>
                            Multi-box printing Options
                          </Text>
                        </View>
                        <View style={{flexDirection: 'row'}}>
                          <Picker
                            mode="dropdown"
                            style={[
                              CommonStyles.multiBoxDropDown,
                              {background: 'transparent'}
                            ]}
                            onValueChange={(itemValue, itemIndex) => {
                              this.pickerValueChange(itemValue);
                              this.props.handleChange('country', itemValue);
                            }}>
                            {this.state.pickerSelectedOptions.map(
                              (option, index) => {
                                return (
                                  <Picker.Item
                                    label={option.label}
                                    value={option.value}
                                    color="#000"
                                  />
                                );
                              }
                            )}
                          </Picker>
                          <View>
                            <TouchableOpacity
                              testID="infoIcon"
                              onPress={() =>
                                this.setState({toolTip: !this.state.toolTip})
                              }
                              style={{
                                marginTop: 4,
                                position: 'relative',
                                zIndex: 1
                              }}>
                              <i
                                className="icon-info-circled"
                                style={{
                                  color: 'rgb(172,187,199)',
                                  fontSize: '17px'
                                }}></i>
                            </TouchableOpacity>
                            {this.state.toolTip && (
                              <TollTip
                                message={i18n.t('order.multiBoxTollTip')}
                              />
                            )}
                          </View>
                          <TouchableOpacity
                            testID="printSlipIcon"
                            onPress={() => this.printSlip()}
                            style={{marginTop: -10}}>
                            <i
                              className="icon-print"
                              style={{color: '#fff', fontSize: '40px'}}></i>
                          </TouchableOpacity>
                        </View>
                      </View>
                      <View style={{flexDirection: 'row', marginTop: '1%'}}>
                        <View style={{width: '25%', flexDirection: 'row'}}>
                          <View>
                            <Text style={CommonStyles.multiBoxWebBoxes}>
                              Boxes
                            </Text>
                          </View>
                        </View>
                        <View style={{width: '70%', flexDirection: 'row'}}>
                          <View style={{marginLeft: '37%'}}>
                            <Text
                              style={[
                                CommonStyles.multiBoxWebBoxes,
                                {fontSize: 25}
                              ]}>
                              Box Contents
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View style={{flexDirection: 'row', marginTop: '1%'}}>
                        <View style={CommonStyles.multiBoxScrollWeb}>
                          <ScrollView style={{marginTop: 25}}>
                            {this?.props?.orderDetail?.boxes?.length > 0 &&
                              this?.props?.orderDetail?.boxes?.map(
                                (box, index) => {
                                  const id = box.id;
                                  return (
                                    <View
                                      testID="boxesContainer"
                                      style={{
                                        flexDirection: 'row',

                                        // marginTop:25,
                                        alignItems: 'center',
                                        // marginLeft: 10,
                                        backgroundColor:
                                          this.state.indexCheck === index
                                            ? '#586975'
                                            : ''
                                      }}>
                                      <View style={{width: '85%'}}>
                                        <TouchableOpacity
                                          testID="selectBox"
                                          onPress={() =>
                                            this.boxSelect(box, index)
                                          }>
                                          <Text
                                            style={{
                                              fontSize: 21,
                                              color: '#fff',
                                              paddingLeft: 21
                                            }}>
                                            Box{index + 1}
                                          </Text>
                                        </TouchableOpacity>
                                      </View>
                                      <View style={{}}>
                                        <TouchableOpacity
                                          testID="removeBoxIcon"
                                          onPress={() =>
                                            this.removeBoxList(id)
                                          }>
                                          <i
                                            className="icon-trash"
                                            style={{
                                              color: 'white',
                                              fontSize: '21px'
                                            }}></i>
                                        </TouchableOpacity>
                                      </View>
                                    </View>
                                  );
                                }
                              )}
                          </ScrollView>
                        </View>
                        <View style={CommonStyles.multiBoxItemContainer}>
                          <View style={[styles.itemContainer]}>
                            <View
                              style={[
                                styles.itemView,
                                CommonStyles.itemsDetailMain
                              ]}></View>

                            <View
                              style={CommonStyles.multiBoxProductNameSecond}>
                              <View style={[styles.itemView, {}]}></View>
                              <View style={[styles.itemView, {width: '18%'}]}>
                                <Text style={[styles.gridHeader]}>
                                  Product Name
                                </Text>
                              </View>

                              <View style={[styles.itemView, {width: '15%'}]}>
                                <Text style={styles.gridHeader}>SKU</Text>
                              </View>
                              <View style={[styles.itemView, {width: '15%'}]}>
                                <Text style={styles.gridHeader}>Barcode</Text>
                              </View>

                              <View
                                style={[
                                  styles.itemView,
                                  CommonStyles.QtyOrdered
                                ]}>
                                <Text style={styles.gridHeader}>
                                  QTY Ordered
                                </Text>
                              </View>

                              <View
                                style={[
                                  styles.itemView,
                                  CommonStyles.itemName
                                ]}>
                                <Text style={styles.gridHeader}>
                                  Location l
                                </Text>
                              </View>
                            </View>
                          </View>
                          <ScrollView showsVerticalScrollIndicator={false}>
                            <View>
                              {this?.props?.orderDetail?.order_item_boxes?.map(
                                (box, index) => {
                                  let childItemCheck =
                                    this?.props?.orderDetail?.list[
                                      this.state.boxId
                                    ];
                                  return this?.props?.orderDetail?.items?.map(
                                    (item, index) => {
                                      if (
                                        item?.iteminfo?.id ===
                                          box?.order_item_id &&
                                        this.state.boxId === box.box_id
                                      ) {
                                        return (
                                          <View style={[styles.itemContainer]}>
                                            <View
                                              style={
                                                CommonStyles.multiBoxItemsView
                                              }>
                                              <View
                                                style={[
                                                  styles.itemView,
                                                  {width: '30%'}
                                                ]}>
                                                <Text
                                                  style={[
                                                    styles.itemName,
                                                    {
                                                      color: '#aad9fc',
                                                      textAlign: 'center'
                                                    }
                                                  ]}>
                                                  {box.kit_id
                                                    ? childItemCheck.map(
                                                        child_item =>
                                                          child_item.kit_id ===
                                                            box.kit_id &&
                                                          child_item?.product_name
                                                      )
                                                    : item.productinfo.name}
                                                </Text>
                                              </View>
                                              <View
                                                style={[
                                                  styles.itemView,
                                                  {
                                                    width: '15%',
                                                    marginLeft: '-5%'
                                                  }
                                                ]}>
                                                <Text style={[styles.itemName]}>
                                                  {box.kit_id
                                                    ? childItemCheck.map(
                                                        child_item =>
                                                          child_item.kit_id ===
                                                            box.kit_id &&
                                                          child_item?.sku
                                                      )
                                                    : item?.sku}
                                                </Text>
                                              </View>
                                              <View
                                                style={[
                                                  styles.itemView,
                                                  {width: '15%'}
                                                ]}>
                                                <Text
                                                  ellipsizeMode="tail"
                                                  numberOfLines={1}
                                                  style={[styles.itemName]}>
                                                  {/* {item?.barcode} */}
                                                  {box.kit_id
                                                    ? childItemCheck.map(
                                                        child_item =>
                                                          child_item.kit_id ===
                                                            box.kit_id &&
                                                          child_item?.barcode
                                                      )
                                                    : item?.barcode}
                                                </Text>
                                              </View>
                                              <View
                                                style={[
                                                  styles.itemView,
                                                  CommonStyles.qtyOrderStyle
                                                ]}>
                                                <Text
                                                  ellipsizeMode="tail"
                                                  numberOfLines={1}
                                                  style={[styles.itemName]}>
                                                  {box.kit_id
                                                    ? childItemCheck.map(
                                                        child_item =>
                                                          child_item.kit_id ===
                                                            box.kit_id &&
                                                          child_item?.qty
                                                      )
                                                    : box?.item_qty}
                                                </Text>
                                              </View>
                                              <View
                                                style={[
                                                  styles.itemView,
                                                  CommonStyles.itemName
                                                ]}>
                                                <Text style={styles.itemName}>
                                                  {/* {item?.location_primary} */}
                                                  {box.kit_id
                                                    ? childItemCheck.map(
                                                        child_item =>
                                                          child_item.kit_id ===
                                                            box.kit_id &&
                                                          child_item?.location_primary
                                                      )
                                                    : item?.location_primary}
                                                </Text>
                                              </View>
                                            </View>
                                          </View>
                                        );
                                      }
                                    }
                                  );
                                }
                              )}
                            </View>
                          </ScrollView>
                        </View>
                      </View>
                    </View>
                  )}
                </>
              )}
            </>
          ) : (
            <MultiBoxDetailMobile
              {...this.props}
              pickerSelectedOptions={this.state.pickerSelectedOptions}
              pickerValueChange={itemValue => {
                this.pickerValueChange(itemValue);
              }}
              printSlip={stateCheckbox => {
                this.printSlip(stateCheckbox);
              }}
              selectValue={this.state.selectValue}
              message={i18n.t('order.multiBoxTollTip')}
              boxSelect={(box, index) => this.boxSelect(box, index)}
              removeBoxList={id => this.removeBoxList(id)}
              indexCheck={this.state.indexCheck}
              boxId={this.state.boxId}
            />
          )}
        </View>
      </ScrollView>
    );
  }
}
