import React, {Component} from 'react';
import {
  Text,
  View,
  Picker,
  TouchableOpacity,
  ScrollView,
  Image
} from 'react-native';
import styles from '../../style/orderdetail';
import TollTip from './tolltip';
import CommonStyles from '../../style/commonStyles';
class MultiBoxDetailMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectValue: 'all_boxes',
      tollTip: false
    };
  }
  render() {
    return (
      <View
        testID="multiBoxContainer"
        onStartShouldSetResponder={() => {
          this.setState({tollTip: false});
        }}
        style={{
          paddingHorizontal: '1%',
          backgroundColor: 'rgba(104, 135, 156, 0.4)',
          borderRadius: 10,
          marginTop: 20,
          width: '100%',
          paddingBottom: 10,
          shadowColor: '#50708b',
          shadowOffset: {width: 1, height: 1},
          shadowRadius: 10
        }}>
        <View style={{zIndex: 1}}>
          <View>
            <Text
              style={{
                color: '#fff',
                fontSize: 16,
                fontFamily: 'Poppins_600SemiBold'
              }}>
              Multi-box printing Options
            </Text>
          </View>
          <View style={{flexDirection: 'row'}}>
            <Picker
              mode="dropdown"
              style={[
                CommonStyles.multiBoxDropDown,
                {width: 250, background: 'transparent'}
              ]}
              onValueChange={(itemValue, itemIndex) => {
                this.props.pickerValueChange(itemValue);
                this.props.handleChange('country', itemValue);
              }}>
              {this.props.pickerSelectedOptions.map((option, index) => {
                return (
                  <Picker.Item
                    label={option.label}
                    value={option.value}
                    color="#000"
                  />
                );
              })}
            </Picker>
            <View>
              <TouchableOpacity
                testID="infoIcon"
                style={{marginTop: 4}}
                onPress={() => {
                  this.setState({tollTip: !this.state.tollTip});
                }}>
                <i
                  className="icon-info-circled"
                  style={{
                    color: 'rgb(172,187,199)',
                    fontSize: '17px'
                  }}></i>
              </TouchableOpacity>
              {this.state.tollTip && (
                <TollTip message={this.props.message} isMobile={true} />
              )}
            </View>
            <TouchableOpacity
              testID="printSlipIcon"
              onPress={() => this.props.printSlip(this.state.selectValue)}
              style={{marginTop: -10}}>
              <i
                className="icon-print"
                style={{color: '#fff', fontSize: '40px'}}></i>
            </TouchableOpacity>
          </View>
        </View>
        <View style={{justifyContent: 'flex-start', width: '50%'}}>
          <Text
            style={{
              color: '#fff',
              fontSize: 26,
              marginLeft: '12%',
              marginTop: '13%'
            }}>
            Boxes
          </Text>
        </View>
        <View style={CommonStyles.multiBoxScroll}>
          <ScrollView style={{marginTop: '8%', marginBottom: 14}}>
            {this?.props?.orderDetail?.boxes?.length > 0 &&
              this?.props?.orderDetail?.boxes?.map((box, index) => {
                const id = box.id;
                return (
                  <View
                    key={index}
                    style={{
                      flexDirection: 'row',
                      height: 40,
                      alignItems: 'center',
                      backgroundColor:
                        this.props.indexCheck === index ? '#586975' : ''
                    }}>
                    <View style={{width: '85%'}}>
                      <TouchableOpacity
                        testID="selectBox"
                        onPress={() => this.props.boxSelect(box, index)}>
                        <Text
                          style={{fontSize: 24, color: '#fff', marginLeft: 30}}>
                          Box{index + 1}
                        </Text>
                      </TouchableOpacity>
                    </View>
                    <View style={{}}>
                      <TouchableOpacity
                        testID="removeBox"
                        onPress={() => this.props.removeBoxList(id)}>
                        <i
                          className="icon-trash"
                          style={{
                            color: 'white',
                            fontSize: '26px',
                            marginTop: '-3px'
                          }}></i>
                      </TouchableOpacity>
                    </View>
                  </View>
                );
              })}
          </ScrollView>
        </View>
        <View style={{justifyContent: 'flex-start', width: '53%'}}>
          <Text
            style={{
              color: '#fff',
              fontSize: 26,
              marginLeft: '12%',
              marginTop: '13%'
            }}>
            Box contents
          </Text>
        </View>
        <View style={CommonStyles.multiBoxItemStyle}>
          <View>
            {this?.props?.orderDetail?.order_item_boxes?.map((box, index) => {
              return this?.props?.orderDetail?.items?.map((item, index) => {
                if (
                  item?.iteminfo?.id === box?.order_item_id &&
                  this.props.boxId === box.box_id
                ) {
                  return (
                    <View style={[styles.mblItemsView]}>
                      <View style={CommonStyles.multiBoxProductName}>
                        <View
                          style={[styles.itemView, {alignSelf: 'flex-start'}]}>
                          <Text
                            style={[styles.mblViewName, {color: '#aad9fc'}]}>
                            {box.kit_id
                              ? this?.props?.orderDetail?.list[
                                  this.props.boxId
                                ].map(
                                  child_item =>
                                    child_item.kit_id === box.kit_id &&
                                    child_item?.product_name
                                )
                              : item.productinfo.name}
                          </Text>
                        </View>
                        <View
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '100%'
                          }}>
                          <View style={{width: '70%'}}>
                            <View
                              style={[
                                styles.itemView,
                                {alignSelf: 'flex-start', flexDirection: 'row'}
                              ]}>
                              <Text style={styles.mblViewheading}>SKU</Text>
                              <Text style={styles.mblViewName}>
                                {box.kit_id
                                  ? this?.props?.orderDetail?.list[
                                      this.props.boxId
                                    ].map(
                                      child_item =>
                                        child_item.kit_id === box.kit_id &&
                                        child_item?.sku
                                    )
                                  : item.sku}
                              </Text>
                            </View>

                            <View
                              style={[
                                styles.itemView,
                                CommonStyles.multiBoxFlex
                              ]}>
                              <Text style={styles.mblViewheading}>QTY </Text>
                              <Text style={styles.mblViewName}>
                                {box.kit_id
                                  ? this?.props?.orderDetail?.list[
                                      this.props.boxId
                                    ].map(
                                      child_item =>
                                        child_item.kit_id === box.kit_id &&
                                        child_item?.qty
                                    )
                                  : box?.item_qty}
                              </Text>
                            </View>
                            <View
                              style={[
                                styles.itemView,
                                {alignSelf: 'flex-start', flexDirection: 'row'}
                              ]}>
                              <Text style={styles.mblViewheading}>L1</Text>
                              <Text style={styles.mblViewName}>
                                {box.kit_id
                                  ? this?.props?.orderDetail?.list[
                                      this.props.boxId
                                    ].map(
                                      child_item =>
                                        child_item.kit_id === box.kit_id &&
                                        child_item?.location_primary
                                    )
                                  : item?.location_primary}
                              </Text>
                            </View>
                          </View>
                          <View
                            style={[
                              styles.itemView,
                              CommonStyles.multiBoxItemView
                            ]}>
                            <Image
                              style={CommonStyles.multiBoxImage}
                              source={{uri: item.image}}
                            />
                          </View>
                        </View>
                      </View>
                    </View>
                  );
                }
              });
            })}
          </View>
        </View>
      </View>
    );
  }
}
export default MultiBoxDetailMobile;
