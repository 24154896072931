import React, { Component } from 'react';
import {
  View,
  Dimensions,
  Text,
  TextInput,
  Image,
  TouchableOpacity,
  Vibration,
  Platform,
  ScrollView,
  FlatList
} from 'react-native';
import * as Clipboard from 'expo-clipboard';
import styles from '../../style/scanpack';
import commonStyles from '../../style/commonStyles';
import globalStyles from '../../style/global';
import moment from 'moment';
import HTML from 'react-native-render-html';
import upArrow from '../../../assets/up_arrow.png';
import downArrow from '../../../assets/down_arrow.png';
import note from '../../../assets/note.png';
import placeholder from '../../../assets/placeholder.jpg';
import { LinearGradient } from 'expo-linear-gradient';
import { fontFamily } from '../../helpers/fontFamily';
import { connect } from 'react-redux';
import Input from '../../commons/Inputs/input';
import DisplayImage from './displaySuggestedImage';
import i18n from 'i18n-js';
import { getIconType } from 'react-native-elements';
import OrderItem from '../../commons/orderItem';
import OrderItemImage from '../../commons/orderItemImage';
import OrderKitItem from '../../commons/orderKitItem';
import ActionBarButtons from './actionBarButtons';
import DisplayOrderNumber from '../../commons/displayOrderNumber';
import OrderItemListMenu from '../../commons/orderItmesListMenu';
import DisplayInstructions from '../../commons/displayOrderInstructions';
import DisplayLogs from '../../commons/displayLogs';
import OrderItemSuggested from '../../commons/orderItemSuggested';
import { AddBox, GetOrderDetail, RemoveBoxItems,PrintSlip } from '../../actions/orderActions';
import ScanButtonContainer from './scanButtonContainer';
import ScannedKitItem from '../../commons/scannedKitItem';
import ScannedKitItemContainer from '../../commons/ScannedKitItemContainer';
import SuggestedMenuMobile from './suggestedMenuMobile';
import DisplayDashboardData from '../../commons/displayLiveStats';

let inputValue = '';
let isPassButtonPressed =  false
let isRemoveCalled = false;
// import ScanBarCodeInput from '../../commons/Inputs/scanBarcodeInput';
class NextItemMobileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        filter: 'awaiting',
        order: 'DESC',
        limit: '1',
        offset: '0'
      },
      showMenu: false
    }
  }
  // keyDownHandler = event => {
  //   if (event.key === 'Enter' && event.target.name === 'scanBarCodeInput') {
  //     if (this.props.barcodeState === '' || this.props.barcodeState === '*') {
  //       this.props.scanBarcode();
  //     }
  //   }
  // };
  getTop = () => {
    if (Platform.OS === 'ios' || window.navigator.platform === 'iPhone' && window.navigator.standalone) {
      return 85
    }
    return 40
  }

  componentDidMount() {
    if (window.navigator.platform === 'iPhone' || Platform.OS === 'ios') {
      document.addEventListener('keydown', this.keyDownHandler, false);
    }
  }
  componentDidUpdate() {
    if (this.props.removeOrderItems && isRemoveCalled) {
      isRemoveCalled = false;
      this.props.getOrderData(this.props?.order?.order?.increment_id);
      this.props.getUpdateSettings();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyDownHandler);
    let props = this?.props
    let unscannedCount = props?.order?.order?.unscanned_items;
    if(unscannedCount.length === 0){
      this.addBox()
    }
    let multiboxSetting = props?.bothSettings?.data?.data?.general_setting;
    if(
      unscannedCount.length == 0 &&
      multiboxSetting?.per_box_packing_slips === 'when_order_is_complete' &&
      multiboxSetting?.multi_box_shipments
    ) {
      this.orderCompletePrintSlip();
      return false;
    }
  }
  orderCompletePrintSlip = () => {
    let arrayempty = [];
    let Order =  this?.props?.Order
    Order?.boxes?.map((item, index) => {
      arrayempty.push(item.id);
    });
    let item = {
      box_ids: arrayempty,
      orderArray: [{id: Order?.basicinfo?.id}]
    };
    this.props.PrintSlip(item);
  };

  copyOrderNumberToClipboard = async () => {
    await Clipboard.setStringAsync(`${this.props.order.order.increment_id}`);
  };
  addBox = () => {
    let OrderCommon = this.props?.Order
    let boxItemProps = this.props?.Order?.order_item_boxes
    let addBoxAfterPrint = this.props?.bothSettings?.data?.data?.general_setting?.per_box_packing_slips
    let boxProps = OrderCommon?.boxes
    let boxFilter = boxItemProps?.filter((i) => i.box_id == boxProps[boxProps.length - 1]?.id)
    let item = {
      name: `Box ${this?.props?.Order?.boxes?.length + 1}`,
      order_id: OrderCommon?.basicinfo?.id
    };
    if (boxItemProps !==undefined && boxItemProps[boxProps?.length - 1] && boxFilter.length > 0) {
      this.props.AddBox(item, this.props.goNext, OrderCommon?.boxes);
      if (addBoxAfterPrint === 'when_new_boxes_are_started') {
        this.printSlip();
      }
      let idorder = OrderCommon?.basicinfo?.id
      this.orderDetailFun(idorder);
    }
    else {
      this.props.multiBoxStateUpdate()
    }
  }

  removeBoxItem = async (box_id, order_item_id, kit_product_id) => {
    const id = this.props?.Order?.basicinfo?.id
    let data = {
      box_id,
      kit_product_id,
      order_item_id,
      id,
    };
    this.props?.RemoveBoxItems(data)
    setTimeout(() => { this.props.updateOrder(this?.props?.order?.order?.increment_id) }, 300)
  }

  orderDetailFun = id => {
    let detail = {
      id: id,
      orderDetail: this.state.data
    };
    if (id !== null) {
      this.props.GetOrderDetail(detail);
    }
  };
  checky = (boxId) => {
    if (boxId) {
      return this.props?.Order.order_item_boxes.filter((data) => data.box_id === boxId).map((data) => data.item_qty).reduce((a, b) => a + b, 0)
    }
    return 0
  }
  printSlip = () => {
    let item = {
      box_ids: this.props?.Order?.boxes[this.props?.activeBoxIndex]?.id,
      orderArray: [{ id: this.props?.Order?.basicinfo?.id }],
    }
    this.props.PrintSlip(item)
  }


  handlePassButton = (item) =>{
    if(inputValue !== ''){
      this.props.stateUpdate(inputValue)
      this.props.scanBarcode()
      inputValue = ''
      isPassButtonPressed = !isPassButtonPressed
      return
    }
    this.props.clickScan(item)
  }

  render() {
    const scannedItems = this.props?.order?.order?.scanned_items
    const { bothSettings: { data: { data: { general_setting: { multi_box_shipments } } } } } = this.props;
    let Order = this.props.order.order;
    let slider_image = [];
    let localLogs = this.props.localLogs;
    // const rows = this.props.getAttributeRows()
    const windowHeight = Dimensions.get('window').height;
    return (
      <>
        {this.state.showMenu &&
          <SuggestedMenuMobile
            {...this.props}
            getWidth={this.props.getWidth}
            getMargin={this.props.getMargin}
            updateShowMenu={() =>  {
              this.setState({ showMenu: false })}
            }
            updateIsRemove={() =>  {
              isRemoveCalled = true
            }}
          />
        }
        {Order?.unscanned_items[0] ? (
          <View
            style={[
              globalStyles.flex1,
              {
                width: '100%',
                height: '100%',
                zIndex: -1,
                top: this.getTop(),
                position: 'fixed'
              }
            ]}>
            <ScrollView
              keyboardShouldPersistTaps="handled"
              scrollEventThrottle={10}
              style={{ height: '100%' }}>
              <View style={styles.nextItemsMain}>
                {this.props.order && Order.unscanned_items[0] && (
                  <View style={styles.RFPScreen}>
                    <View
                      style={globalStyles.nextItemFlex}>
                      <View style={styles.actionButtonsView}>
                        <DisplayOrderNumber
                         orderDetail={() =>this.props.orderDetail(Order)}
                         incrementId={Order.increment_id}
                         actionBarShow={this.props.actionBarShow}
                         setActionBarState={() => {
                          let actionBarShow = !this.props.actionBarShow;
                          this.props.actionBarShowFunMobile(actionBarShow);
                         }}
                         downArrow={downArrow}
                         upArrow={upArrow}
                         copyOrderNumberToClipboard={this.copyOrderNumberToClipboard}
                         id={'orderDetail'}
                        />
                        {this.props.actionBarShow && (

                          <ActionBarButtons
                           width={this.props.windowWidth}
                           onPressRestartOrder={()=>{
                            this.props.popInternet === true && (
                                this.props.restartButton()
                              )
                            }}
                            restartButtonDesign={styles.restartButtonDesign}
                            onPressGetOrderData={() => {
                              this.props.getOrderData(Order.increment_id);
                              this.props.getUpdateSettings();
                              }}
                            reloadButtonDesign={styles.noteSaveButtonDesign}
                            onPressAddNote={()=>{
                              this.props.popInternet === true && (
                                  this.props.addNote()
                                )
                            }}
                          />
                        )}
                      </View>
                      <LinearGradient
                        style={globalStyles.nextItemMobile}
                        start={{ x: 0, y: 1 }}
                        end={{ x: 0, y: 0 }}
                        colors={['#142130', '#304454']}>
                        <View style={{ flexDirection: 'row' }}>
                          <View
                            style={globalStyles.nextItemMobileFlex}>
                            <LinearGradient
                              start={{ x: 0, y: 1 }}
                              end={{ x: 0, y: 0 }}
                              colors={['#6384ff', '#1329ff', '#35096c']}
                              style={globalStyles.serialRecord}
                            />
                          </View>
                          {!this.props.serialRecordPopUpShow && (
                            <Input
                              dataSet={{ componentName: 'scanBarCodeInput' }}
                              name="scanBarCodeInput"
                              value={this.props.barcodeState}
                              isClicked ={this.props.isClicked}
                              isPassButtonPressed = { isPassButtonPressed}
                              autoFocus={false}
                              ref={this.props.InputField}
                              onSubmitEditing={()=>{
                                this.props.popInternet === true && (
                                  this.props.scanBarcode())
                                }
                              }
                              onChangeText={this.props.stateUpdate}
                              onChangeValue={(text)=>{inputValue = text}}
                              style={globalStyles.scanBarCodeInput}
                              placeholder={this.props.scanPlaceHolderValue}
                              placeholderTextColor={'#acacad'}
                              // setIsBarcodeEnter = {() => { this.setState({ isBarcodeEnter: true }); }}
                              onBlur={() => {
                                const blurValue =
                                  Platform.OS === 'web'
                                    ? 'Click Here Before Scanning'
                                    : 'Tap Here Before Scanning';
                                this.props.scanPlaceHolderValueFunMobile(
                                  blurValue
                                );
                                // this.setState({ scanPlaceHolderValue: blurValue })
                              }}
                              onFocus={() =>
                                this.props.scanPlaceHolderValueFunMobile(
                                  'Ready For Product Scan'
                                )
                              }
                            // onFocus={() => this.setState({ scanPlaceHolderValue: 'Ready For Product Scan' })}
                            />
                          )}
                          <ScanButtonContainer
                            Order={Order}
                            popInternet={this.props.popInternet}
                            scanAllItem={this.props.scanAllItem}
                            windowWidth={this.props.windowWidth}
                            setType={this.props.setType}
                            checkCount={this.props.checkCount}
                            scanAllOption={this.props.scanPackSetting.scan_all_option}
                            typeScanState={this.props.typeScanState}
                            handlePassButton = {this.handlePassButton}
                            isBarcodeEnter = {this.props.isBarcodeEnter}
                            setIsBarcodeEnter = {this.props.setIsBarcodeEnter}
                          />
                        </View>
                      </LinearGradient>
                      {(Order.tags !== null &&
                        Order.tags.trim() !== '' &&
                        this.props.settings.show_tags &&
                        this.props.tagsToPackerFlag) ||
                        (Order.notes_toPacker !== null &&
                          Order.notes_toPacker &&
                          Order.notes_toPacker.trim() !== '' &&
                          this.props.notesToPackerFlag &&
                          this.props.settings.show_internal_notes) ||
                        (Order.customer_comments !== null &&
                          Order.customer_comments &&
                          Order.customer_comments.trim() !== '' &&
                          this.props.customerCommentsFlag &&
                          this.props.settings.show_customer_notes) ||
                        (Order.notes_internal !== null &&
                          Order.notes_internal &&
                          Order.notes_internal.trim() !== '' &&
                          this.props.internalNotesFlag &&
                          this.props.settings.show_internal_notes) ? (
                        <LinearGradient
                          locations={[0, 0.13, 1]}
                          colors={['#000000', '#fdf359', '#d2c609']}
                          style={globalStyles.nextItemNotes}
                        >
                          <View style={{ width: '100%' }}>
                            {Order.tags !== null && Order.tags.trim() !== '' && (
                              <View>
                                <Text
                                  style={commonStyles.nextItemText}
                                >
                                  {Order.tags}
                                </Text>
                                {((Order.customer_comments !== null &&
                                  Order.customer_comments.trim() !== '') ||
                                  (Order.notes_toPacker !== null &&
                                    Order.notes_toPacker.trim() !== '') ||
                                  (Order.notes_internal !== null &&
                                    Order.notes_internal &&
                                    Order.notes_internal.trim() !== '')) && (
                                    <View
                                      style={commonStyles.nextItemNotes}
                                    />
                                  )}
                              </View>
                            )}
                            {Order.notes_internal &&
                              Order.notes_internal !== null &&
                              Order.notes_internal.trim() !== '' && (
                                <View>
                                  {Order.notes_internal !== null && (
                                    <Text
                                      style={commonStyles.nextItemText}
                                    >
                                      {Order.notes_internal}
                                    </Text>
                                  )}
                                  {((Order.customer_comments !== null &&
                                    Order.customer_comments.trim() !== '') ||
                                    (Order.notes_toPacker !== null &&
                                      Order.notes_toPacker.trim() !== '')) && (
                                      <View
                                        style={commonStyles.nextItemNotes}
                                      />
                                    )}
                                </View>
                              )}
                            {Order.customer_comments !== null &&
                              Order.customer_comments.trim() !== '' && (
                                <View>
                                  <Text
                                    style={commonStyles.nextItemText}
                                  >
                                    {Order.customer_comments}
                                  </Text>
                                  {(Order.notes_toPacker !== null
                                    ? Order.notes_toPacker.trim() !== ''
                                    : false) && (
                                      <View
                                        style={commonStyles.nextItemNotes}
                                      />
                                    )}
                                </View>
                              )}
                            {Order.notes_toPacker !== null &&
                              Order.notes_toPacker.trim() !== '' && (
                                <Text
                                  style={commonStyles.nextItemText}
                                >
                                  {Order.notes_toPacker}
                                </Text>
                              )}
                            <View>
                              <TouchableOpacity
                                onPress={() => this.props.closeNotes()}
                                style={commonStyles.nextItemCloseNote}>
                                <Text
                                  style={[commonStyles.nextItemText, { lineHeight: '100%' }]}
                                >
                                  Got it.
                                </Text>
                              </TouchableOpacity>
                            </View>
                          </View>
                        </LinearGradient>
                      ) : null}
                      {Order.unscanned_items[0].product_type === 'individual' &&
                        Order.unscanned_items[0].child_items.length > 0 ? (
                        <React.Fragment>
                          {Order.unscanned_items[0].child_items[0]
                            .instruction !== '' &&
                            Order.unscanned_items[0].child_items[0]
                              .instruction !== null && (
                              <DisplayInstructions
                               item={Order.unscanned_items[0].child_items[0]}
                               width={this.props.windowWidth}
                              />
                            )}
                          <DisplayDashboardData />
                          <View
                            style={commonStyles.nextItemRowFlexMobile}
                          >
                            <OrderItemSuggested
                             redirectToItemDetail={(item)=>{this.props.redirectToItemDetail(item)}}
                             item={Order.unscanned_items[0].child_items[0]}
                             isSwap={this.props.isSwap}
                             displayRow={!this.props.isSwap &&
                                this.props.rowsChild.map(row => row)}
                             displayRowAtTop = {this.props.isSwap &&
                                this.props.rowsChild.map(row => row)}
                             numberOfKeysEven={this.props.numberOfKeysEven}
                             testID={'redirectToItem'}
                             getFontSize={this.props.getFontSize}
                             addBarcodeFun={(item)=>{this.props.addBarcode(item)}}
                             width={this.props.windowWidth}
                            />
                            <DisplayImage
                              webBigImage={this.props.webBigImage}
                              onError={err => this.props.imageCheck(err)}
                              source={placeholder}
                              defaultSource={placeholder}
                              scanned_qty={
                                Order.unscanned_items[0].child_items[0]
                                  .scanned_qty
                              }
                              total_qty={
                                Order.unscanned_items[0].child_items[0]
                                  .qty_remaining +
                                Order.unscanned_items[0].child_items[0]
                                  .scanned_qty
                              }
                              images={
                                Order.unscanned_items[0].child_items[0].images
                              }
                              updateShowMenu={() =>  {
                                this.setState({ showMenu: true })}}
                            />
                          </View>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {
                            this.props.checkMultiOrderPresent && this.props.checkMultiOrderPresent?.length > 1 ? (
                              <View style={{ padding: "10px" }}>
                                <View style={[styles.sameOrderAlertTopView]}>
                                  {
                                    this.props.checkShippingEasyOrder === true ?
                                      <Text style={[styles.alertBoxOutsideTextMobile]}>{i18n.t('scanpackItem.shippingEsaySameOrderTextAlert')} </Text> :
                                      <Text style={[styles.alertBoxOutsideTextMobile]}>{i18n.t('scanpackItem.shipStationSameOrderText')} </Text>}
                                </View>
                                {
                                  this.props.checkMultiOrderPresent?.map((i) =>
                                    <>
                                      {
                                        this.props.searchOrder?.searchOrder?.data?.order[0]?.id != i.id ?
                                          <TouchableOpacity
                                            testID='redirecToSameOrder'
                                            onPress={() => this.props.redirecToSameOrder(i.increment_id, i.store_order_id)}>
                                            <LinearGradient
                                              start={{ x: 0, y: 1 }}
                                              end={{ x: 0, y: 0 }}
                                              colors={['#142130', '#304454']}
                                              style={[styles.sameOrderAlertLinear]}>
                                              <i
                                                className="icon-attention"
                                                style={{
                                                  color: 'orange', fontSize: '36px', position: 'static',
                                                }}
                                              ></i>
                                              {this.props.checkShippingEasyOrder === true ?
                                                <Text style={[styles.alertInsideTextMobile]}>{i.increment_id} / {moment(i.order_placed_time).format("ll")} / To: {i.firstname} {i.lastname} / PCS: {i.items_count} {i.tracking_num != null ? `/ Tracking Last 6: ${i.tracking_num.slice(-6)}` : ""} / SID: {i.store_order_id}</Text> :
                                                <Text style={[styles.alertInsideTextMobile]}>{moment(i.order_placed_time).format("ll")} / To: {i.firstname} {i.lastname} / PCS: {i.items_count} {i.tracking_num != null ? `/ Tracking Last 6:${i.tracking_num.slice(-6)}` : ""} </Text>}
                                            </LinearGradient>
                                          </ TouchableOpacity>
                                          : <></>
                                      }
                                    </>)
                                }
                              </View>
                            )
                              :
                              <></>
                          }

                          {Order.unscanned_items[0].instruction !== '' &&
                            Order.unscanned_items[0].instruction !== null && (
                              <DisplayInstructions
                               item={Order.unscanned_items[0]}
                               width={901}
                               name={'instructionCheck'}
                              />
                            )}
                          <DisplayDashboardData />
                          <View
                            style={commonStyles.nextItemRowFlexMobile}
                          >
                            <OrderItemSuggested
                             redirectToItemDetail={(item)=>{this.props.redirectToItemDetail(item)}}
                             item={Order.unscanned_items[0]}
                             isSwap={this.props.isSwap}
                             displayRow={!this.props.isSwap &&
                                this.props.rows.map(row => row)}
                             displayRowAtTop = {this.props.isSwap &&
                                this.props.rows.map(row => row)}
                             numberOfKeysEven={this.props.numberOfKeysEven}
                             testID={'redirectToItem'}
                             getFontSize={this.props.getFontSize}
                             addBarcodeFun={(item)=>{this.props.addBarcode(item)}}
                             width={this.props.windowWidth}
                            />
                            <DisplayImage
                              webBigImage={this.props.webBigImage}
                              onError={err => this.props.imageCheck(err)}
                              source={placeholder}
                              defaultSource={placeholder}
                              scanned_qty={Order.unscanned_items[0].scanned_qty}
                              total_qty={
                                Order.unscanned_items[0].qty_remaining +
                                Order.unscanned_items[0].scanned_qty
                              }
                              images={Order.unscanned_items[0].images}
                              updateShowMenu={() =>  {
                                this.setState({ showMenu: true })}}
                            />
                          </View>
                        </React.Fragment>
                      )}
                      <OrderItemListMenu
                        showScannedList={this.props.showScannedList}
                        showUnScannedList={this.props.showUnscannedList}
                        showLogList={this.props.showLogList}
                        unScannedCount={this.props.unscannedCount}
                        scannedCount={this.props.scannedCount}
                        scannedList={this.props.scannedList}
                        unScannedList={this.props.unscannedList}
                        logList={this.props.logList}
                        navButtonLeft={this.props.navButtonLeft}
                        icon={upArrow}
                      />
                           {multi_box_shipments && this.props.order.order.scanned_items.length > 0 &&
                          <View
                            testID='multiBox'
                            style={{
                              display: 'flex',
                              flexDirection: 'initial',
                              justifyContent: 'space-between',
                              marginInline: '10px',
                              padding: '5px',
                            }}
                          >
                            <TouchableOpacity testID='goPrevIcon' onPress={() => { this.props.goPrev() }}>
                              <i className='icon-up-open' style={{
                                color: 'white', transform: 'rotate(270deg)',
                                visibility: this.props.activeBoxIndex <= 0 && 'hidden',
                                fontSize: '21px',
                              }}></i>
                            </TouchableOpacity>
                            <TouchableOpacity
                              onPress={this.props.showUnscannedList}
                            >
                              {this.props.scannedList?
                              <Text style={{ color: 'white', fontSize: '17px' }}>{this.props?.Order?.boxes[this.props.activeBoxIndex]?.name} is Active</Text> :
                              <Text style={{ color: 'white', fontSize: '17px' }}>{this.checky(this.props?.Order?.boxes[this.props.activeBoxIndex]?.id)} of {this.props.scannedCount} Scanned Items in {this.props?.Order?.boxes[this.props.activeBoxIndex]?.name} </Text>}
                            </TouchableOpacity>
                            {this.props.unscannedList &&
                            <TouchableOpacity
                              testID='printSlip'
                              style={{
                                color: "white",
                                position: "absolute",
                                right: 42,
                                marginTop: "1%",
                                marginRight: '3%',
                                width: "5%"
                              }}
                              onPress={() => { this.printSlip(); }}
                            >
                              <i className='icon-print' style={{ fontSize: 18 }} ></i>
                            </TouchableOpacity>
                            }
                            {this.props?.activeBoxIndex == this.props?.Order?.boxes?.length - 1 &&
                              <TouchableOpacity
                                testID='addBoxIcon'
                                onPress={async () => {
                                  this.addBox();
                                  await this.props.GetOrderDetail({ id: this.props?.order?.order?.id })
                                }}>
                                <i className='icon-plus' style={{
                                  color: 'white',
                                  fontSize: '25px',
                                }}
                                  disable={true}></i>
                              </TouchableOpacity>}
                            {this.props.activeBoxIndex !== this.props.Order?.boxes?.length - 1 && this.props.activeBoxIndex !== this.props.Order?.boxes?.length && <TouchableOpacity testID='goNextIcon' onPress={() => {
                              this.props.goNext()
                            }}>
                              <i className='icon-up-open' style={{
                                color: 'white',
                                fontSize: '25px',
                                transform: 'rotate(90deg)',
                              }}
                              ></i>
                            </TouchableOpacity>}
                          </View>}
                      <ScrollView>
                        {this.props.scannedList && (
                          <React.Fragment>
                            {Order.unscanned_items &&
                              Order.unscanned_items.length > 0 ? (
                              <FlatList
                                data={Order.unscanned_items.slice(0, 10)}
                                keyExtractor={item => item.id}
                                extraData={this.state}
                                contentContainerStyle={{paddingBottom: '3rem'}}
                                renderItem={({ item, index }) => (
                                  <React.Fragment>
                                    {(item && item.product_type === 'single') ||
                                      (item &&
                                        item.product_type === 'depends') ? (
                                      item &&
                                        item.qty_remaining == 1 &&
                                        index == 0 ? (
                                        <View></View>
                                      ) : (
                                        <View
                                          key={index}
                                          style={styles.scanPackSingleItemContainer}>
                                          <OrderItem
                                          item={item}
                                          redirectToItemDetail={(item)=>{this.props.redirectToItemDetail(item)}}
                                          testID ={'ProductName'+index}
                                          />
                                          <OrderItemImage
                                          item={item}
                                          testID={'shuffleItem'}
                                          shuffleTheItem={(sku)=>{this.props.shuffleTheItem(sku)}}
                                          />

                                        </View>
                                      )
                                    ) : (
                                      <OrderKitItem
                                      item={item}
                                      index={index}
                                      windowWidth={this.props.windowWidth}
                                      redirectToItemDetail={(item)=>{this.props.redirectToItemDetail(item)}}
                                      shuffleTheItem={(sku)=>{this.props.shuffleTheItem(sku)}}
                                      shuffleTheKitItem={(kitSku,itemSku)=>{this.props.shuffleTheItem(kitSku,itemSku)}}
                                      />
                                    )}
                                  </React.Fragment>
                                )}
                              />
                            ) : (
                              <View style={globalStyles.p_10}>
                                <Text style={styles.scannedItemNameText}>
                                  No more item remaining to scan
                                </Text>
                              </View>
                            )}
                          </React.Fragment>
                        )}
                        {this.props.unscannedList && (
                          <React.Fragment>
                             {multi_box_shipments && Order.scanned_items.length > 0 ? <>
                              {this.props?.Order?.order_item_boxes.map((order) => {
                                return (<>
                                  {this.props?.Order?.boxes[this.props.activeBoxIndex]?.id === order.box_id && <>
                                    <View>
                                    {scannedItems
                                        .map((item, index) => {
                                          console.log("item", index,":::", item)
                                          console.log("order:", order.kit_id )
                                          return (
                                            <View>
                                            {(item?.product_id ===
                                              order?.product_id) && order.kit_id === null &&
                                              order.order_item_id === item?.order_item_id &&
                                              (item && item.product_type === 'single') ||
                                              (item && item.product_type === 'depends') ?
                                              (
                                              <ScannedKitItem
                                                item = {item}
                                                order = {order}
                                                activeBoxName = {this.props?.Order?.boxes[this.props.activeBoxIndex]?.name}
                                                redirectToItemDetail={item => {
                                                  this.props.redirectToItemDetail(item);
                                                }}
                                                removeBoxItem = {(box_id, order_item_id, kit_id) =>
                                                  {this.removeBoxItem(box_id, order_item_id, kit_id)
                                                }}
                                              />
                                            ):(
                                              <View>
                                              {(item?.scanned_qty > 0) &&
                                              (item?.product_id === order?.product_id) &&
                                              order.order_item_id === item?.order_item_id
                                              && (
                                                <ScannedKitItemContainer
                                                  item = {item}
                                                  order = {order}
                                                  index = {index}
                                                  lists = {Order}
                                                  activeBoxName = {this.props?.Order?.boxes[this.props.activeBoxIndex]?.name}
                                                  redirectToItemDetail={item => {
                                                    this.props.redirectToItemDetail(item);
                                                  }}
                                                  removeBoxItem = {(box_id, order_item_id, kit_id) =>
                                                    {this.removeBoxItem(box_id, order_item_id, kit_id)
                                                  }}
                                                />
                                              )}
                                              </View>
                                            )}
                                            </View>
                                          );
                                        })}
                                    </View>
                                  </>}
                                </>)
                              })}
                              <View style={{marginTop: '3rem'}}/>
                            </> : <>
                            {Order.scanned_items &&
                              Order.scanned_items.length > 0 ? (
                              <FlatList
                                data={scannedItems.slice(0, 10)}
                                keyExtractor={item => item.id}
                                extraData={this.state}
                                contentContainerStyle={{paddingBottom: '3rem'}}
                                renderItem={({ item, index }) => (
                                  <View style={{ textAlign: 'left' }}>
                                    {item.scanned_qty > 0 &&
                                    item?.product_type != "individual" &&
                                    (item?.child_items?.length === 0 || item?.child_items === undefined) && (
                                      <View
                                        testID='ScannedItems'
                                        key={index}
                                        style={styles.scanPackSingleItemContainer}
                                      >
                                        <OrderItem
                                         item={item}
                                         testID={'redirectToItemDetail'}
                                         redirectToItemDetail={(item)=>{this.props.redirectToItemDetail(item)}}
                                        />
                                        <OrderItemImage
                                         item={item}
                                         shuffleTheItem={()=>{}}
                                        />
                                      </View>
                                    )}
                                  </View>
                                )}
                              />
                            ) : (
                              <View style={globalStyles.p_10}></View>
                            )}
 </>}

                          </React.Fragment>
                        )}
                        {this.props.logList && (
                          <React.Fragment>
                            {localLogs && localLogs.length > 0 ? (
                              <DisplayLogs
                              logs={localLogs}
                              sliceNum={10}
                              checkMultiBoxActive={this.props?.checkMultiBoxActive}
                             />
                            ) : (
                              <View style={globalStyles.p_10}></View>
                            )}
                          </React.Fragment>
                        )}
                      </ScrollView>
                    </View>
                  </View>
                )}
              </View>
            </ScrollView>
          </View>
        ) : (
          <View
            testID='NoOrderPresent'
            style={globalStyles.noOrderPresent}
          >
            <Text style={{ fontSize: 14, fontWeight: 'bold', color: '#fff' }}>
              No order is present to scan
            </Text>
          </View>
        )}
      </>
    );
  }
}
const mapDispatchToProps = {
  AddBox,
  GetOrderDetail,
  PrintSlip,
  RemoveBoxItems
}
const mapStateToProps = state => {
  return {
    isSwap: state.menu.isSwap,
    isHaptics: state.menu.isHaptics,
    removeOrderItems: state.order.removeOrderItems,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NextItemMobileView);
