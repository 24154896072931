import React from 'react';
import CommonStyles from '../../style/commonStyles';
import {Image, Text, TextInput, TouchableOpacity, View} from 'react-native';
import styles from '../../style/orderdetail';

export const MobileOrderItem = ({
  item,
  index,
  editable,
  show,
  permissionCheck,
  ClearSearchProduct,
  goToProductDetail,
  handleChange,
  handleChanges,
  Capitalize,
  handleEditAbleIndex,
  removeNewItem
}) => {
  return (
    <View key={index} style={[styles.mblItemsView]}>
      <TouchableOpacity
        onPress={() => {
          show === true ? permissionCheck() : removeNewItem(item);
        }}
        style={[
          styles.itemView,
          {
            width: '3%'
          }
        ]}>
        <Image
          style={CommonStyles.imageStyle3}
          source={require('../../../assets/cross.png')}
        />
      </TouchableOpacity>
      <View style={CommonStyles.itemDetailProductName}>
        <View style={[styles.itemView, {alignSelf: 'flex-start'}]}>
          <TouchableOpacity
            testID="ProductName"
            onPress={() => {
              ClearSearchProduct();
              goToProductDetail(item.productinfo.id);
            }}>
            <Text style={[styles.mblViewName, {color: '#aad9fc'}]}>
              {item.productinfo.name}
            </Text>
          </TouchableOpacity>
        </View>

        <View style={CommonStyles.itemDetailFlex}>
          <View style={{width: '70%'}}>
            <View style={[styles.itemView, {alignSelf: 'flex-start'}]}>
              <Text style={styles.mblViewName}>{item.sku}</Text>
            </View>

            <View style={[styles.itemView, CommonStyles.itemDetailItemView]}>
              <Text style={styles.mblViewName}>QTY </Text>
              {editable == index ? (
                <TextInput
                  placeholderTextColor={'white'}
                  keyboardType="default"
                  autoFocus={true}
                  selectTextOnFocus={true}
                  value={item.iteminfo.qty}
                  onChangeText={text => handleChange(text, item, index)}
                  onSubmitEditing={() => handleChanges(item, index)}
                  onBlur={() => handleChanges(item, index)}
                  style={CommonStyles.updateQtyStyle}
                />
              ) : (
                <Text style={styles.mblViewName}>{item.iteminfo.qty}</Text>
              )}
              <TouchableOpacity
                testID="updateQty"
                onPress={() =>
                  index !== editable && handleEditAbleIndex(index)
                }>
                <i
                  className="icon-edit"
                  style={{
                    color: 'white',
                    fontSize: '22px'
                  }}></i>
              </TouchableOpacity>
            </View>
            <View style={[styles.itemView, {alignSelf: 'flex-start'}]}>
              <Text
                style={
                  item.productinfo.status === 'active'
                    ? styles.statusActive
                    : styles.statusNew
                }>
                {Capitalize(item.productinfo.status)}
              </Text>
            </View>
          </View>
          <View
            style={[
              styles.itemView,
              {
                alignSelf: 'flex-end',
                paddingHorizontal: '2%',
                width: '30%',
                alignItems: 'flex-end',
                paddingVertical: 5
              }
            ]}>
            <Image
              style={CommonStyles.imageStyle4}
              source={{uri: item.image}}
            />
          </View>
        </View>
      </View>
    </View>
  );
};
