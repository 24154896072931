import React, {Component} from 'react';
import {
  View,
  Text,
  TextInput,
  ScrollView,
  Picker,
  TouchableOpacity
} from 'react-native';
import styles from '../../style/orderdetail';
// import RadioButtonGroup, {RadioButtonItem} from 'expo-radio-button';
import {LinearGradient} from 'expo-linear-gradient';
import ActivitiesButtons from './activitiesButtons';
import CommonStyles from '../../style/commonStyles';
let exceptionIndex = 0;
export default class Exception extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activitiesActive: true,
      exceptionActive: false
    };
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  updateDimensions = () => {
    this.setState({windowWidth: window.innerWidth});
  };

  componentWillUnmount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  decriptionOnChange = text => {
    this.props.show === true
      ? this.props.permissionCheck()
      : (this.props.decriptionOnChange(text), this.props.userSelectState);
  };

  dropDownToggleActive = () => {
    this.props.dropDownToggleActive();
  };

  userChange = (option, index, username) => {
    this.props.userChange(option, index, username);
  };

  recordExceptionFun = () => {
    this.props.show === true
      ? this.props.permissionCheck()
      : this.props.recordExceptionFun();
  };

  clearExceptioFun = () => {
    this.props.show === true
      ? this.props.permissionCheck()
      : this.props.clearExceptioFun();
  };
  optionClicked = e => {
    this.props.show === true
      ? this.props.permissionCheck()
      : this.props.optionClicked(e);
  };

  render() {
    let userdetail = this?.props?.orderDetail?.users;
    return (
      <View>
        <View
          style={{
            flexDirection: 'row',
            marginTop: this.state.windowWidth <= 435 ? '10%' : '2%',
            marginLeft: '7%'
          }}
        >
          <Text
            style={CommonStyles.exceptionType}
          >
            Exception Type
          </Text>
          <i
            className="icon-info-circled"
            style={{color: 'rgb(172,187,199)', marginTop: 9, marginLeft: 15}}
          ></i>
        </View>
        <View style={{marginTop: 20, marginLeft: 58}}>
          <ActivitiesButtons
          show={this.props.show} showModal={this.props.showModal}
          setChecked={(value)=>{
          this.props.optionClicked(value)
          }}
          style={{marginBottom: 10}}
          initialSelected={this.props.initialSelected}
          />
        </View>
        <View style={{marginTop: 30, marginLeft: 58}}>
          <Text
            style={CommonStyles.exceptionTextStyle}
          >
            Description
          </Text>
          <TextInput
            multiline={true}
            numberOfLines={4}
            style={styles.inputBox}
            onChangeText={text => this.decriptionOnChange(text)}
            value={this.props.descriptionText}
          />
        </View>

        <View style={{width: '200px', marginTop: 10, marginLeft: 72}}>
          <Text
            style={[CommonStyles.exceptionTextStyle, {marginLeft: -13}]}
          >
            Associated exception with
          </Text>
          <View>
            <TouchableOpacity onPress={() => this.dropDownToggleActive()}>
              <View style={[styles.mainContainer]}>
                <View
                  style={[styles.dropDownToggleStyle, {flexDirection: 'row'}]}
                >
                  <Text style={{color: '#fff'}}>
                    {this.props.userSelectState}
                  </Text>
                  <i
                    class="icon-down-open"
                    style={{
                      color: 'rgb(205,205,205)',
                      position: 'absolute',
                      left: '87%'
                    }}
                  />
                </View>
              </View>
            </TouchableOpacity>
          </View>

          {this.props.dropDownToggle && (
            <View
              style={[CommonStyles.userListStyle, {zIndex: 1, width: 223}]}
            >
              <ScrollView
                style={{height: 112}}
                showsVerticalScrollIndicator={false}
              >
                {userdetail.map((option, index) => {
                  return (
                    <View key={index}>
                      <TouchableOpacity
                        testID='changeUser'
                        onPress={() => {
                          this.props.show === true
                            ? this.props.permissionCheck()
                            : this.userChange(option.username, index, option);
                        }}
                        style={{
                          marginTop: '5%',
                          justifyContent: 'center',
                          backgroundColor:
                            index == exceptionIndex ? '#4b5f76' : ''
                        }}
                      >
                        <Text
                          style={CommonStyles.exceptionTextStyleTwo}
                        >
                          {option.username}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  );
                })}
              </ScrollView>
            </View>
          )}
        </View>
        <View style={{flexDirection: 'column', width: '100%'}}>
          <TouchableOpacity
           testID='recordExceptionFun'
            onPress={() => {
              this.recordExceptionFun();
            }}
          >
            <LinearGradient
              colors={['#95abbf', '#516b83']}
              style={CommonStyles.exceptionStyle}
            >
              <Text style={{color: 'white', fontSize: 15}}>
                Record Exception
              </Text>
            </LinearGradient>
          </TouchableOpacity>
          <TouchableOpacity onPress={id => this.clearExceptioFun(id)}
           testID='clearExceptionFun'
          >
            <LinearGradient
              colors={['#95abbf', '#516b83']}
              style={CommonStyles.exceptionStyle}
            >
              <Text style={{color: 'white', fontSize: 15}}>
                Clear Exception
              </Text>
            </LinearGradient>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}
