import { LinearGradient } from 'expo-linear-gradient';
import React from 'react';
import { Text, TouchableOpacity} from 'react-native';
const OrderButton = (props) => {
  return (
    <LinearGradient
      start={{x: 0, y: 1}}
      end={{x: 0, y: 0}}
      colors={[
        props.firstColor,props.secondColor
      ]}
      style={{
        position: 'relative',
        backgroundColor: '#292929',
        paddingHorizontal:5,
        paddingVertical: 3,
        width: props.width,
        borderWidth: 3,
        borderColor: props.firstColor,
        marginLeft: !props.marginLeft ? 25 : props.marginLeft ,
        borderRadius: 10,
      }}
    >
      <TouchableOpacity
      testID='orderStatusBtn'
      onPress={props.handleOnPress}
      >
        <Text style={{ fontFamily:'Poppins_600SemiBold', fontSize: props.fontSize, color: props.textColor}}>
          {props?.label}
        </Text>
      </TouchableOpacity>
    </LinearGradient>
  )
}
export default OrderButton;