import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Dimensions } from 'react-native';
import styles from '../../style/popupmodel';
import i18n from 'i18n-js';

const SearchNotFoundPrompt = (props) => {
  const windowWidth = Dimensions.get('window').width;
  const windowHeight = Dimensions.get('window').height;

  return (
    <View style={[
      styles.alertContainer, 
      { 
        backgroundColor: 'rgb(49, 57, 67)', 
        width: windowWidth >= 1400 ? '75%' : '68%', 
        alignItems: 'center', 
        top: windowWidth >= 1400 ? '15%' : '20%', 
        left: windowWidth >= 1400 ? '7%' : '5%', 
        // height: ((windowHeight / 3) * 2)-50 ,
      }
    ]}>
      <View style={[
        internalStyles.innerContainer,
        {
          height: windowHeight - 350,
          flexDirection: 'row'
        }
      ]}>
        <View style={internalStyles.columnContainer}>
          <View style={{ width: '105%', flexDirection: 'row', alignSelf: 'stretch' }} />
          <View style={{ display: 'flex', justifyContent: 'center', width: '95%' }} />
          <View style={{ alignSelf: 'stretch' }} />
        </View>
        <View testID='searchNotFoundPrompt'>
          <View style={internalStyles.messageContainer}>
            <Text style={internalStyles.messageText}>
                {i18n.t('clearFilterPrompt.clearFiltersText')}
            </Text>
          </View>
          <TouchableOpacity testID='clearAllfiltersBtn' onPress={props.clearFiltersAndExpandSearch}>
            <Text style={internalStyles.clearFiltersText}>
                {i18n.t('clearFilterPrompt.clearFiltersBtnText')}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const internalStyles = StyleSheet.create({
  innerContainer: {
    zIndex: -1,
    fontFamily: 'Poppins_300Light',
    padding: 13,
  },
  columnContainer: {
    flexDirection: 'column',
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '30%',
  },
  messageText: {
    color: 'white',
    fontSize: 23,
    fontFamily: "Poppins_400Regular",
  },
  clearFiltersText: {
    color: '#849db8',
    marginTop: '3%',
    textAlign: 'center',
    fontSize: 15,
    fontFamily: "Poppins_400Regular",
  }
});

export default SearchNotFoundPrompt;
