import React, { Component } from 'react';
import {
  View,
  TextInput,
  TouchableOpacity,
  FlatList,
  SafeAreaView,
  Vibration
} from 'react-native';
import { connect } from 'react-redux';
import { GetAllProduct, GetSearchProduct } from '../../actions/productAction';
import { AllKITProduct, updateProductAlias, GetProductDetail, resetIsProductUpdated, resetIsProductInfo, GetProductInfoDetail } from '../../actions/productAction';
import styles from '../../style/productdetail';
import { Text } from 'react-native-elements';
import { LinearGradient } from 'expo-linear-gradient';
import { fontFamily } from '../../helpers/fontFamily';
import { FontAwesome } from '@expo/vector-icons';
import Prompt from '../../commons/prompt';
import i18n from 'i18n-js';
import TollTip from './tolltip';
let addItemSuccess = true;
let update = true;
let updateSearch = false;
let presentkitData = [];
let aliasing = false;
class AddProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      getAllItem: {
        filter: 'all',
        sort: '',
        order: 'DESC',
        is_kit: 0,
        limit: 20,
        offset: 0
      },
      searchData: {
        search: '',
        sort: '',
        order: 'DESC',
        is_kit: -1,
        limit: 20,
        offset: 0
      },
      allProduct: [],
      prompt: false,
      itemData: {},
      childKit: '',
      selected: [],
      searchProduct: [],
      existingItems: [],
      orderID: '',
      windowWidth: 0,
      checkWidth: 0,
      searchTxt: '',
      start: 0,
      end: 10,

      quickScanToolTip3: false,
      presentkitid: []
    };
    toolTipCheck: false, window.addEventListener('resize', this.update);
    this.tooltipRef = React.createRef();
    this.searchField = React.createRef();
  }
  update = () => {
    this.setState({
      windowWidth: window.innerWidth
    });
  };

  componentDidMount() {
    this.props.hideSaveFun();
    this.update();
    this?.props?.productskit?.productskit.map(presentkit => {
      presentkitData.push(presentkit.option_product_id);
    });
    let product = this.props.product;

    if (product) {
      let existingItems = [];
      this.props?.items?.items.map((item, index) => {
        existingItems.push(item.productinfo.id);
      });
      update = true;
      this.setState({ existingItems });
      this.props.GetAllProduct(this.state.getAllItem);
    }
    if (this.props?.items?.items?.length) {
      let sorted = this.props.items.items.sort(
        (a, b) =>
          Date.parse(a.iteminfo?.updated_at) -
          Date.parse(b.iteminfo?.updated_at)
      );
      this.setState({ items: sorted });
    }
    let searchData = this.state.searchData;
    searchData['search'] = this.props.lastSearched;
    if(this.props.searchProduct){
      this.setState({searchProduct: this.props.searchProduct})
    }
  }
  componentDidUpdate() {
    if (update && this.state.allProduct !== this.props.allProduct) {
      let updated = [];
      let indexLength = 0;
      if (this.props.allProduct && this.props.allProduct.length > 0) {
        let existing = this.state.existingItems;
        this.props.allProduct.filter((arrayItem, index) => {
          presentkitData.includes(arrayItem.id)
            ? null
            : updated.push(arrayItem);
          indexLength = index;
        });
        if (
          this.props.allProduct &&
          this.props.allProduct.length === indexLength + 1
        ) {
          this.setState({ allProduct: updated });
          update = false;
        }
      }
    }
    if(this.props.isProductUpdated === false && this.props.aliasProduct && aliasing){
      this.props.aliasOfAnother ? this.props.GetProductInfoDetail(this.state.selected[0])
        : this.props.makeProductAlias();
      this.props.resetIsProductUpdated()
    }
    if(this.props.isProductLoaded === false && this.props.aliasProduct && aliasing){
      this.props.makeProductAlias()
      aliasing = false;
      this.props.resetIsProductInfo()
    }
    if (addItemSuccess && this.props.addOrderItems) {
      let id = this.props?.items?.items[0]?.iteminfo?.order_id;
      addItemSuccess = false;
    }
    if (
      updateSearch === false &&
      this.props.searchProduct &&
      this.state.searchProduct !== this.props.searchProduct
    ) {
      let temp = this.state.searchData;
      temp['search'] = this.props.lastSearched;
      this.searchField.current.focus()
      this.setState({
        allProduct: this.props.searchProduct,
        searchProduct: this.props.searchProduct,
        temp
      });
      update = false;
      updateSearch = true;
    }
  }

  makeProductAliasOfAnother(productID) {
    let data = {
      product_alias_ids: [productID]
    };
    this.props.updateProductAlias(this.state.selected[0], data)
    aliasing = true;
  }

  makeProductAlias(productID) {
    if(this.props.aliasOfAnother){
      this.makeProductAliasOfAnother(productID)
      return;
    }
    let data = {
      product_alias_ids: this.state.selected
    };
    this.props.updateProductAlias(productID, data)
    aliasing = true;
  }

  submitItems() {
    let productID = this.props.product.basicinfo.id;

    if (productID && this.state.selected.length > 0) {
      let data = {
        product_ids: this.state.selected
      };
      !this.props.aliasProduct && this.props.AllKITProduct(productID, data)
      this.props.aliasProduct ? 
      this.makeProductAlias(productID) : 
      setTimeout(() => this.props.AddItemToInventory(), 1000);

      addItemSuccess = true;
    }
  }

  handleOnPress(item) {
    if ((item?.productkitskus && !this.state.selected.includes(item.id)) && !this.props.aliasProduct) {
      this.setState({ prompt: true, itemData: item, childKit: item.name })
    }
    else if(this.props.aliasOfAnother){
      this.setState({ prompt: true,  itemData: item })
    }
    else {
      this.itemSelect(item)
    }
  }

  itemSelect(item) {
    let selected = this.state.selected;
    var check = selected.includes(item.id);
    if (check) {
      const index = selected.findIndex(selected => selected === item.id);
      selected.splice(index, 1);
    } else {
      selected.push(item.id);
    }
    this.setState({ selected });
  }

  searchItem() {
    let search = this.state.searchData;
    if (search.search !== '') {
      this.props.upDateState(search.search)
      this.props.GetSearchProduct(search);
      updateSearch = false;
    }
  }

  handleChange(text) {
    let searchData = this.state.searchData;
    searchData['search'] = text;
    this.setState({ searchData });
  }

  componentWillUnmount() {
    update = true;
    presentkitData = [];
    this.props.GetAllProduct(this.state.getAllItem);
    this.setState({ allProduct: this.props.allProduct, searchProduct: [] });
    this.props.hideSaveFun();
    this.props.upDateState(this.state.searchData.search)
  }
  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  toolTipFun = () => {
    this.setState({ toolTipCheck: !this.state.toolTipCheck });
    this.setState({ quickScanToolTip3: !this.state.quickScanToolTip3 });
  };
  getMessage = () => {
    const parentKit = this.props.product?.basicinfo.name
    const message = i18n.t('order.addKitToKitMessage', { childKit: this.state.childKit, parentKit: parentKit });
    const aliasMessage = i18n.t('product.aliasOfAnotherMessage')
    return this.props.aliasOfAnother ? aliasMessage : message
  }

  render() {
    return (
      <>
        {this.state.prompt &&
          <Prompt
            onPressCancel={() => {
              this.setState({ prompt: false });
            }}
            onPressOk={() => {
              !this.props.aliasProduct && this.setState({ prompt: false });
              this.itemSelect(this.state.itemData)
              this.props.aliasOfAnother && this.submitItems();
            }}
            msg={this.getMessage()}
            loading = {this.props.isProductUpdated}
          />
        }
        <View
          testID='mainContainer'
          onStartShouldSetResponder={() =>
            this.state.toolTipCheck ? this.toolTipFun() : <></>
          }
        >
          <View
            testID='addItemsContainer'
            style={{
              alignSelf: 'flex-end',
              justifyContent: 'center',
              alignItems: 'center',
              paddingVertical: 10,
              flexDirection: 'row'
            }}
          >
            <TouchableOpacity
              testID='saveButton'
              style={[styles.saveCloseButton, { shadowColor: "#2bfac3", shadowOffset: { width: 0, height: 0.5 } }]}
              onPress={() => {
                {
                  this.state.selected.length > 0
                    ? this.submitItems()
                    : this.props?.onchange();
                }
                this.props.isHaptics && Vibration.vibrate(30);
              }}
            >
              <Text style={[styles.saveCloseButtonText]}>
                Save & Close
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              testID='cancelButton'
              onPress={() => {
                this.props.isHaptics && Vibration.vibrate(30);
                this.props?.onchange()
              }}
              style={[styles.saveCloseButton, { shadowColor: "#e853fa", shadowOffset: { width: 0, height: 0.5 }, }]}
            >
              <Text style={[styles.saveCloseButtonText]}>
                Cancel
              </Text>
            </TouchableOpacity>
          </View>

          <View
            style={{
              marginTop: 0,
              alignSelf: 'center',
              flexDirection: 'row',
              marginBottom: 10
            }}
            onStartShouldSetResponder={() =>
              this.state.toolTipCheck ? this.toolTipFun() : <></>
            }
          >
            <Text style={[styles.searchTextAddItem]}>
              Search
            </Text>
            <i
              className="icon-search"
              style={{
                color: 'white',
                flexDirection: 'row',
                resizeMode: 'contain',
                marginTop: '2%',
                fontSize: '19px'
              }}
            ></i>
            <Text style={[styles.searchTextAddItem]}>
              or Scan
            </Text>
            <i
              className="icon-barcode"
              style={{ color: 'white', marginTop: '2%', fontSize: '19px' }}
            ></i>
            <Text style={[styles.searchTextAddItem]}>
              to Add items
            </Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
              justifyContent: 'space-around',
              marginTop: 10,
              height: 40,
              flexWrap: 'wrap',
              paddingHorizontal: 15
            }}
            onStartShouldSetResponder={() =>
              this.state.toolTipCheck ? this.toolTipFun() : <></>
            }
          >
            <View style={{ width: '90%', marginLeft: 50, flexDirection: 'row' }}>
              <TextInput
                placeholder="Type order to search"
                name="searchOrder"
                autoFocus={true}
                ref={this.searchField}
                value={this.state.searchData && this.state.searchData.search}
                onChangeText={text => {
                  this.handleChange(text), this.setState({ searchTxt: text });
                }}
                selectTextOnFocus={true}
                onSubmitEditing={e => this.searchItem(e)}
                style={{
                  backgroundColor: '#f4efef',
                  borderRadius: 5,
                  height: 30,
                  paddingHorizontal: 10,
                  flexDirection: 'row',
                  width: '90%',
                  marginLeft: 30
                }}
              />
              <TouchableOpacity
                testID='searchProduct'
                onPress={() => this.searchItem(this.state.searchTxt)}
                style={{
                  flexDirection: 'row',
                  marginLeft: 10,
                  marginTop: '5.5px'
                }}
              >
                <i
                  className="icon-search"
                  style={{
                    color: 'rgb(172,187,199)',
                    marginTop: '34%',
                    marginLeft: -50,
                    fontSize: '19px'
                  }}
                ></i>
              </TouchableOpacity>
              <TouchableOpacity
                testID='tollTipFun'
                onPress={() => {
                  this.toolTipFun();
                }}
                style={{
                  flexDirection: 'row',
                  marginLeft: 10
                }}
              >
                <i
                  className="icon-info-circled"
                  style={{
                    color: 'rgb(172,187,199)',
                    marginTop: '41%',
                    fontSize: '17px'
                  }}
                ></i>
              </TouchableOpacity>
            </View>
          </View>
          {this.state.quickScanToolTip3 && (
            <TollTip 
             message={i18n.t('order.kitItemTolltip')}
             top={146}
             right={10}
             iconRight={this.state.windowWidth <= 450 ? '0%' : '36%'}
             testID={'toolTip3'}
            />
          )}
          {this.state.windowWidth > 900 ? (
            <View
              style={{
                flex: 1,
                backgroundColor: 'rgba(15, 39, 58, 0.4)',
                borderRadius: 15,
                marginBottom: 50,
                minHeight: 200,
                marginTop: 10,
                paddingLeft: 10,
                width: '97%',
                marginLeft: 23,
                flexWrap: 'wrap'
              }}
            >
              <View style={[styles.itemContainerProduct]}>
                <View
                  style={{
                    flexDirection: 'row',
                    paddingHorizontal: '1%',
                    width: '100%',
                    justifyContent: 'space-around',
                    flexWrap: 'wrap'
                  }}
                >
                  <View style={{ width: '95%', flexDirection: 'row' }}>
                    <View style={[styles.addItemViewProduct, { width: '25%' }]}>
                      <Text style={styles.gridHeader2Product}>SKU</Text>
                    </View>

                    <View style={[styles.addItemViewProduct, { width: '25%' }]}>
                      <Text style={[styles.gridHeader2Product]}>
                        Product Name
                      </Text>
                    </View>

                    <View
                      style={[
                        styles.addItemViewProduct,
                        {
                          width: '15%'
                        }
                      ]}
                    >
                      <Text style={styles.gridHeader2Product}>QOH</Text>
                    </View>

                    <View style={[styles.addItemViewProduct, { width: '15%' }]}>
                      <Text style={styles.gridHeader2Product}>Status</Text>
                    </View>
                    <View style={[styles.addItemViewProduct, { width: '23%' }]}>
                      <Text style={styles.gridHeader2Product}>Barcode</Text>
                    </View>
                  </View>
                </View>
              </View>

              {this.state.allProduct.length > 0 && (
                <View>
                  {this.state.allProduct &&
                    this.state.allProduct.length > 0 &&
                    this.state.allProduct.map((item, index) => {
                      if ((item.id === this.props.product.basicinfo.id) && this.props.aliasProduct)
                      {
                        return null;
                      }
                      return (
                        <TouchableOpacity
                          testID='selectItem'
                          key={index}
                          onPress={() => {
                            this.props.isHaptics && Vibration.vibrate(30);
                            this.handleOnPress(item);
                          }}
                          delayLongPress={1000}
                        >
                          <View style={[styles.itemContainerProduct]}>
                            <View
                              style={{
                                flexDirection: 'row',
                                paddingHorizontal: '1%',
                                width: '100%',
                                flexWrap: 'wrap'
                              }}
                            >
                              {this.state.selected.includes(item.id) ? (
                                <FontAwesome
                                  name="dot-circle-o"
                                  style={{ resizeMode: 'contain' }}
                                  size={32}
                                  color="rgba(250, 250, 250, .4)"
                                />
                              ) : (
                                <FontAwesome
                                  name="circle-o"
                                  style={{ resizeMode: 'contain' }}
                                  size={32}
                                  color="rgba(250, 250, 250, .4)"
                                />
                              )}

                              <View
                                testID='ItemInList'
                                style={{
                                  width: '95%',
                                  flexDirection: 'row',
                                  backgroundColor: this.state.selected.includes(item.id) ? 'rgba(250, 250, 250, .1)' : '',
                                  borderBottomWidth: 0.7,
                                  borderColor: 'white'
                                }}
                              >
                                <View
                                  style={[
                                    styles.addItemViewProduct,
                                    { width: '25%' }
                                  ]}
                                >
                                  <Text
                                    style={[
                                      styles.itemName2Product,
                                      { fontSize: 16 }
                                    ]}
                                  >
                                    {item.sku}
                                  </Text>
                                </View>

                                <View
                                  style={[
                                    styles.addItemViewProduct,
                                    { width: '25%' }
                                  ]}
                                >
                                  <Text
                                    style={[
                                      styles.itemName2Product,
                                      { fontSize: 16 }
                                    ]}
                                  >
                                    {item.name}
                                  </Text>
                                </View>

                                <View
                                  style={[
                                    styles.addItemView,
                                    {
                                      width: '16%'
                                    }
                                  ]}
                                >
                                  <Text
                                    style={[
                                      styles.itemName2Product,
                                      { fontSize: 16 }
                                    ]}
                                  >
                                    {item.qty_on_hand}
                                  </Text>
                                </View>

                                <View
                                  style={[styles.addItemView, { width: '14%' }]}
                                >
                                  {item.status === 'active' ? (
                                    <Text
                                      style={[
                                        styles.statusActiveProduct,
                                        { fontSize: 16 }
                                      ]}
                                    >
                                      {this.Capitalize(item.status)}
                                    </Text>
                                  ) : (
                                    <Text
                                      style={[
                                        styles.statusNewProduct,
                                        { fontSize: 16 }
                                      ]}
                                    >
                                      {this.Capitalize(item.status)}
                                    </Text>
                                  )}
                                </View>

                                <View
                                  style={[
                                    styles.addItemViewProduct,
                                    { width: '25%' }
                                  ]}
                                >
                                  <Text
                                    style={[
                                      styles.itemName2Product,
                                      { fontSize: 16 }
                                    ]}
                                  >
                                    {item.barcode}
                                  </Text>
                                </View>
                              </View>
                            </View>
                          </View>
                        </TouchableOpacity>
                      );
                    })}
                </View>
              )}
            </View>
          ) : (
            <View
              style={{
                flex: 1,
                marginHorizontal: 10,
                backgroundColor: 'rgba(15, 39, 58, 0.4)',
                borderRadius: 15,
                marginBottom: 50,
                minHeight: 200,
                marginTop: 10,
                flexWrap: 'wrap'
              }}
            >
              <View style={[styles.itemContainerProduct]}>
                <View
                  style={{
                    flexDirection: 'row',
                    paddingHorizontal: '2%',
                    width: '100%',
                    justifyContent: 'space-around',
                    flexWrap: 'wrap'
                  }}
                >
                  <View
                    style={{
                      width: '95%',
                      flexDirection: 'row',
                      justifyContent: 'space-around'
                    }}
                  >
                    <View style={[styles.addItemViewProduct, { width: '25%' }]}>
                      <Text style={[styles.gridHeader2Product, { fontSize: 16 }]}>
                        SKU
                      </Text>
                    </View>

                    <View style={[styles.addItemViewProduct, { width: '35%' }]}>
                      <Text
                        style={[
                          styles.gridHeader2Product,
                          { fontSize: 16, lineHeight: 13 }
                        ]}
                      >
                        Product Name
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.addItemViewProduct,
                        {
                          width: '10%'
                        }
                      ]}
                    >
                      <Text style={[styles.gridHeader2Product, { fontSize: 16 }]}>
                        QOH
                      </Text>
                    </View>
                    <View style={[styles.addItemViewProduct, { width: '15%' }]}>
                      <Text style={[styles.gridHeader2Product, { fontSize: 16 }]}>
                        Status
                      </Text>
                    </View>
                  </View>
                </View>
              </View>

              <SafeAreaView>
                {this.state.allProduct.length > 0 && (
                  <View style={{ overflow: 'hidden' }}>
                    {this.state.allProduct && this.state.allProduct.length > 0 && (
                      <FlatList
                        data={this.state.allProduct}
                        keyExtractor={item => item.id}
                        extraData={this.state}
                        renderItem={({ item }) => (
                          <>
                          {(item.id === this.props.product.basicinfo.id) && this.props.aliasProduct ?
                          <></>:
                          <TouchableOpacity
                            testID='selectItem'
                            onPress={() => {
                              this.props.isHaptics && Vibration.vibrate(30);
                              this.handleOnPress(item);
                            }}
                          >
                            <View
                              style={[
                                styles.itemContainerProduct,
                                { justifyContent: 'center', alignItems: 'center' }
                              ]}
                            >
                              <View
                                testID='ItemInList'
                                style={{
                                  flexDirection: 'row',
                                  paddingHorizontal: '2%',
                                  width: '100%',
                                  justifyContent: 'space-around',
                                  alignItems: 'center',
                                  backgroundColor: this.state.selected.includes(item.id) ? 'rgba(250, 250, 250, .1)' : '',
                                  flexWrap: 'wrap'
                                }}
                              >
                                {this.state.selected.includes(item.id) ? (
                                  <FontAwesome
                                    name="dot-circle-o"
                                    style={{
                                      resizeMode: 'contain',
                                      width: '5%',
                                      justifyContent: 'center',
                                      alignItems: 'center'
                                    }}
                                    size={24}
                                    color="rgba(250, 250, 250, .4)"
                                  />
                                ) : (
                                  <FontAwesome
                                    name="circle-o"
                                    style={{
                                      resizeMode: 'contain',
                                      width: '5%',
                                      justifyContent: 'center',
                                      alignItems: 'center'
                                    }}
                                    size={24}
                                    color="rgba(250, 250, 250, .4)"
                                  />
                                )}

                                <View
                                  style={{
                                    width: '95%',
                                    flexDirection: 'row',
                                    borderBottomWidth: 0.7,
                                    borderColor: 'white',
                                    justifyContent: 'space-around'
                                  }}
                                >
                                  <View
                                    style={[
                                      styles.addItemViewProduct,
                                      { width: '25%' }
                                    ]}
                                  >
                                    <Text
                                      style={[
                                        styles.itemskuProduct,
                                        { fontSize: 16 }
                                      ]}
                                    >
                                      {item.sku}
                                    </Text>
                                  </View>

                                  <View
                                    style={[
                                      styles.addItemViewProduct,
                                      { width: '35%' }
                                    ]}
                                  >
                                    <Text
                                      style={[
                                        styles.itemName2Product,
                                        { fontSize: 16 }
                                      ]}
                                    >
                                      {item.name}
                                    </Text>
                                  </View>
                                  <View
                                    style={[
                                      styles.addItemViewProduct,
                                      {
                                        width: '10%'
                                      }
                                    ]}
                                  >
                                    <Text
                                      style={[
                                        styles.itemName2Product,
                                        { fontSize: 16 }
                                      ]}
                                    >
                                      {item.qty_on_hand}
                                    </Text>
                                  </View>
                                  <View
                                    style={[
                                      styles.addItemViewProduct,
                                      { width: '15%' }
                                    ]}
                                  >
                                    {item.status === 'active' ? (
                                      <Text
                                        style={[
                                          styles.statusActiveProduct,
                                          { fontSize: 16 }
                                        ]}
                                      >
                                        {this.Capitalize(item.status)}
                                      </Text>
                                    ) : (
                                      <Text
                                        style={[
                                          styles.statusNewProduct,
                                          { fontSize: 16 }
                                        ]}
                                      >
                                        {this.Capitalize(item.status)}
                                      </Text>
                                    )}
                                  </View>
                                </View>
                              </View>
                            </View>
                          </TouchableOpacity>
                          }
                          </>
                        )}
                      />
                    )}
                  </View>
                )}
              </SafeAreaView>
            </View>
          )}
        </View>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    allProduct: state.product.allProduct,
    addOrderItems: state.order.addOrderItems,
    searchProduct: state.product.searchProduct,
    isProductUpdated: state.product.isProductUpdated,
    isProductLoaded: state.product.isProductLoaded,
    isHaptics: state.menu.isHaptics
  };
};

const mapDispatchToProps = {
  GetAllProduct,
  AllKITProduct,
  updateProductAlias,
  GetProductDetail,
  resetIsProductUpdated,
  GetProductInfoDetail,
  resetIsProductInfo,
  GetSearchProduct
};

export default connect(mapStateToProps, mapDispatchToProps)(AddProduct);
