import React from 'react';
import {Camera} from 'expo-camera';

export const preloadCamera = async (cameraRef, onCameraReady) => {
  const {status} = await Camera.requestCameraPermissionsAsync();
  if (status === 'granted') {
    cameraRef.current = (
      <Camera
        ref={cameraRef}
        style={{width: 1, height: 1}}
        onCameraReady={onCameraReady}
      />
    );
  } else {
    console.error('Camera permission not granted');
  }
};
