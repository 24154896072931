import React, { useLayoutEffect, useState } from 'react';
import { Text, View, TouchableOpacity, Image, Dimensions } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import Close from '../../assets/closeicon.png';
import i18n from 'i18n-js';
import promptStyle from '../style/popupmodel'
function SocketSession(props) {
  const [windowWidth, setWindowWidth] = useState(
    Dimensions.get('window').width
  );
  const [toggle, setToggle] = useState(false);

  const updateDimensions = () => {
    setWindowWidth(window.innerWidth);
  };
  useLayoutEffect(() => {
    window.addEventListener('resize', updateDimensions);
    // setPopUpShow(this.props.message)
    setToggle(true);
    // if(props.message == "Too many connections. Please logout other sessions to continue here."){
    //     setToggle(true)
    // }
  }, []);
  const hideModal = () => {
    setToggle(false);
  };
  return (
    <View
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%'
      }}
    >
      {toggle ? (
        <LinearGradient
          locations={[0, 1]}
          colors={['rgb(207,68,55)', 'rgb(122,40,40)']}
          style={[promptStyle.alertPermission, { top: windowWidth <= 600 ? '8%' : '35px', }]}
        >
          <Text
            style={{
              marginLeft: 5,
              marginRight: 5,
              fontWeight: 'bold',
              fontSize: 16,
              color: '#fff'
            }}
          >
            {/* Too many connections. Please logout other sessions to continue here. */}
            {i18n.t('session.to_many_user_login')}
          </Text>
          <TouchableOpacity
            testID='hideModal'
            style={{ position: 'absolute', right: 11 }}
            onPress={() => {
              hideModal();
            }}
          >
            {/* <Image source={closeButton ? closeButton : ""}
                  style={{ height: 20, width: 20 }} /> */}
            {/* <Text style={{ color: '#fff', fontSize: 14, fontWeight: 'bold' }}>OK</Text> */}
            <Image
              style={
                // this.state.windowWidth > 1024
                //   ? { width: 22, height: 22 }
                { width: 18, height: 18 }
              }
              source={Close ? Close : ''}
            />
          </TouchableOpacity>
        </LinearGradient>
      ) : (
        <View></View>
      )}

      <View style={{ marginBottom: 10 }}>
        <Text style={{ fontSize: 34, fontFamily: 'Poppins_400Regular' }}>
          Continue Session ?
        </Text>
      </View>
      <View style={{ flexDirection: windowWidth <= 500 ? 'column' : 'row' }}>
        <LinearGradient
          colors={['#5bbc31', '#479523', '#3f851d']}
          style={{
            height: 40,
            alignItems: 'center',
            justifyContent: 'center',
            padding: 9,
            shadowOffset: { width: 0, height: 0 },
            shadowRadius: 3.84,
            shadowColor: '#000',
            shadowOpacity: 0.9,
            borderRadius: 5
          }}
        >
          <TouchableOpacity testID='logoutEveryone' onPress={() => props.logout_everyone()}>
            <Text
              style={{
                color: '#fff',
                fontFamily: 'Poppins_500Medium',
                fontSize: 18
              }}
            >
              Yes, Sign out all other sessions
            </Text>
          </TouchableOpacity>
        </LinearGradient>
        <LinearGradient
          colors={['#908f8f', '#484747', '#494848']}
          style={{
            height: 40,
            alignItems: 'center',
            marginLeft: 10,
            justifyContent: 'center',
            padding: 9,
            shadowOffset: { width: 0, height: 0 },
            shadowRadius: 3.84,
            shadowColor: '#000',
            shadowOpacity: 0.9,
            borderRadius: 5,
            marginTop: windowWidth <= 500 ? 20 : 0
          }}
        >
          <TouchableOpacity testID='logoutMe' onPress={() => props.logout_me()}>
            <Text
              style={{
                color: '#fff',
                fontFamily: 'Poppins_500Medium',
                fontSize: 18
              }}
            >
              No, Sign this session out
            </Text>
          </TouchableOpacity>
        </LinearGradient>
      </View>
    </View>
  );
}
export default SocketSession;
