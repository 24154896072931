import React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import styles from "../style/scanpack";
const AddBarcodeButton = ({ addBarcodeFun, width }) => {
  return (
    <View style={styles.addBarcodeContainer}>
      <TouchableOpacity
        testID="AddBarcode"
        style={[
          styles.addBarcodeButton,
          width < 900 && {width: 'fit-content',paddingHorizontal: 8, marginLeft : 'auto', marginRight: 'auto'}
        ]}
        onPress={addBarcodeFun}
      >
        <Text
          style={styles.scanAddBarcodeText}
          numberOfLines={1}
          ellipsizeMode="tail"
        >
          ADD A BARCODE
        </Text>
      </TouchableOpacity>
    </View>
  );
};
export default AddBarcodeButton;