import React, { Component } from 'react';
import { View, Text, Image, TouchableOpacity, Dimensions } from 'react-native';

import shortcutImage from '../../assets/shortcut-icon.png';
import styles from '../style/shippinglabel.js';
import { FontAwesome, AntDesign } from '@expo/vector-icons';
import Tooltip from './toolTip';
import { LinearGradient } from 'expo-linear-gradient';
export default class PanelContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      displayEye: null,
      toggleEye: false,
      windowWidth: window.innerWidth
    };
  }

  handleEyeClick = index => {
    this.setState({ displayEye: index });
    this.setState({ toggleEye: !this.state.toggleEye });
  };

  render() {
    return (
      <>
        {this.props?.carrierErrors &&
          this.props?.carrierErrors[this.props?.currentCarrier?.code] ? (
          <>
            <View style={styles.panelContentBoxError}>
              <View
                testID='carrierErrors'
                style={
                  this.state.windowWidth >= 1080
                    ? styles.ratesErrorBox
                    : styles.ratesErrorBoxMobile
                }>
                <Text
                  style={
                    this.state.windowWidth >= 1080
                      ? styles.ratesErrorText
                      : styles.ratesErrorTextMobile
                  }>
                  {this.props?.carrierErrors[this.props?.currentCarrier?.code]}
                </Text>
              </View>
            </View>
          </>
        ) : this.state.windowWidth >= 1080 ? (
          this.props?.currentCarrier?.rates?.map((el, index) => {
            return this.props?.activateEditButton ? (
              <View style={styles.panelContentBox} key={el.serviceName}>
                <View style={styles.eyeContainer}
                testID= 'currentCarrier'
                >
                  <TouchableOpacity
                    testID='toggleService'
                    onPress={() => {
                      //this.handleEyeClick(index);
                      this.props.toggleServiceVisibility({
                        carrierName: this.props.currentCarrier.name,
                        carrierCode: el.carrierCode,
                        serviceName: el.serviceName,
                        serviceCode: el.serviceCode,
                        visibility:
                          this.props.currentCarrier.rates[index].visible
                      });
                    }}
                    >
                    <FontAwesome
                      name={
                        // this.state.displayEye === index && this.state.toggleEye
                        this.props.currentCarrier.rates[index].visible
                          ? 'eye'
                          : 'eye-slash'
                      }
                      size={24}
                      color="white"
                      style={
                        this.props.activateEditButton
                          ? styles.showEye
                          : styles.hideEye
                      }
                    />
                  </TouchableOpacity>
                </View>
                <TouchableOpacity style={styles.panelBox}>
                  <Text
                    style={
                      this.state.windowWidth >= 1080
                        ? styles.upsText
                        : styles.upsTextMobile
                    }>
                    {el.serviceName}
                  </Text>
                  <Text
                    style={
                      this.state.windowWidth >= 1080
                        ? !this.props?.fetchRatesButton ? styles.price : styles.priceNew
                        : !this.props?.fetchRatesButton ? styles.priceMobile : styles.priceMobileNew
                    }>
                    ${el.cost}
                  </Text>
                </TouchableOpacity>
                {this.props.permissions.SHORTCUTS_WRITE ? (
                  <View style={styles.shortcutBox}>
                    {this.props.activateEditButton && !el.shortcutKey && (
                      <Tooltip tooltipText={'Click to Add a Keyboard Shortcut'} position={'top'}>
                        <TouchableOpacity
                          testID='addShortCut'
                          onPress={() =>
                            this.props.addShortcut(
                              this.props.currentCarrier.name,
                              el.serviceName
                            )
                          }>
                          <Image
                            source={shortcutImage}
                            style={styles.shortcutIconShow}
                          />
                        </TouchableOpacity>
                      </Tooltip>
                    )}
                    {this.props.activateEditButton && el.shortcutKey && (
                      <Tooltip
                        tooltipText={'Click to Replace Keyboard Shortcut'}>
                        <TouchableOpacity
                          onPress={() =>
                            this.props.addShortcut(
                              this.props.currentCarrier.name,
                              el.serviceName
                            )
                          }>
                          <Text
                            style={
                              this.state.windowWidth >= 1080
                                ? styles.shortcutText
                                : styles.shortcutTextMobile
                            }>
                            {el.shortcutKey.join('+')}
                          </Text>
                        </TouchableOpacity>
                      </Tooltip>
                    )}
                    {!this.props.activateEditButton && <></>}
                  </View>
                ) : (
                  <View style={styles.shortcutBox}>
                    {this.props.activateEditButton && el.shortcutKey && (
                      <Text
                        style={
                          this.state.windowWidth >= 1080
                            ? styles.shortcutText
                            : styles.shortcutTextMobile
                        }>
                        {el.shortcutKey.join('+')}
                      </Text>
                    )}
                    {!this.props.activateEditButton && <></>}
                  </View>
                )}
              </View>
            ) : (
              el.visible && (
                <View style={styles.panelContentBox} key={index}>
                  {
                    !this.props?.fetchRatesButton && !this.props.weightValidationError ?
                      <View style={styles.eyeContainer}
                      testID= 'currentCarrier'
                      >
                        <Text
                          style={
                            this.props?.selectedServiceToPurchase?.serviceName ===
                              el.serviceName
                              ? styles.greenBox
                              : styles.greenBoxInvisible
                          }>
                          <LinearGradient
                            colors={['#7BFAC3', '#1CBB77']}
                            locations={[0.1, 0.9]}
                            style={{ borderRadius: 10 }}
                          >

                           <Text>ENTER {'\n'} To purchase selected service</Text> 
                          </LinearGradient>
                        </Text>
                        <AntDesign
                          name="caretright"
                          size={24}
                          color={'#5af8bd'}
                          style={
                            this.props?.selectedServiceToPurchase?.serviceName ===
                              el.serviceName
                              ? styles.caretStyle
                              : styles.caretStyleInvisible
                          }
                        />
                      </View>
                      :
                      <View style={styles.eyeContainer}></View>
                  }
                  <TouchableOpacity
                    style={
                      this.props?.selectedServiceToPurchase?.serviceName ===
                        el.serviceName && !this.props?.fetchRatesButton && !this.props.weightValidationError
                        ? styles.panelBoxSelected
                        : styles.panelBox
                    }
                    testID='setSelectedServiceToPurchase'
                    onPress={() =>
                      this.props.setSelectedServiceToPurchase({
                        serviceCode: el.serviceCode,
                        carrierCode: el.carrierCode,
                        serviceName: el.serviceName,
                        packageCode: el.packageCode,
                        purchaseConfirm: true
                      })
                    }
                    >
                    <Text style={styles.upsText}>{el.serviceName}</Text>
                    <Text style={!this.props?.fetchRatesButton ? styles.price : styles.priceNew}>${el.cost}</Text>
                  </TouchableOpacity>
                  {this.props.permissions.SHORTCUTS_WRITE ? (
                    <View style={styles.shortcutBox}>
                      {!el.shortcutKey && (
                        <Tooltip
                          tooltipText={'Click to Add a Keyboard Shortcut'}
                          position={'top'}>
                          <TouchableOpacity
                            onPress={() =>
                              this.props.addShortcut(
                                this.props.currentCarrier.name,
                                el.serviceName
                              )
                            }>
                            <Image
                              source={shortcutImage}
                              style={styles.shortcutIconShow}
                            />
                          </TouchableOpacity>
                        </Tooltip>
                      )}
                      {el.shortcutKey && (
                        <Tooltip
                          tooltipText={'Click to Replace Keyboard Shortcut'}>
                          <TouchableOpacity
                            onPress={() =>
                              this.props.addShortcut(
                                this.props.currentCarrier.name,
                                el.serviceName
                              )
                            }>
                            <Text style={styles.shortcutText}>
                              {el.shortcutKey?.join('+')}
                            </Text>
                          </TouchableOpacity>
                        </Tooltip>
                      )}
                      {!this.props.activateEditButton && <></>}
                    </View>
                  ) : (
                    <View style={styles.shortcutBox}>
                      {el.shortcutKey && (
                        <Text style={styles.shortcutText}>
                          {el.shortcutKey?.join('+')}
                        </Text>
                      )}
                      {!this.props.activateEditButton && <></>}
                    </View>
                  )}

                  {/* <View style={styles.shortcutBox}>
                  {el.shortcutKey?.length > 0 ? (
                    <Text style={styles.shortcutText}>
                      {el.shortcutKey?.join('+')}
                    </Text>
                  ) : (
                    <></>
                  )}
                </View> */}
                </View>
              )
            );
          })
        ) : (
          this.props?.currentCarrier?.rates?.map((el, index) => {
            return this.props?.activateEditButton ? (
              <View style={styles.panelContentBox} key={el.serviceName}>
                <View style={styles.eyeContainerMobile}
                testID= 'currentCarrier'
                >
                  <TouchableOpacity
                    testID='toggleService'
                    onPress={() => {
                      //this.handleEyeClick(index);
                      this.props.toggleServiceVisibility({
                        carrierName: this.props.currentCarrier.name,
                        carrierCode: el.carrierCode,
                        serviceName: el.serviceName,
                        serviceCode: el.serviceCode,
                        visibility:
                          this.props.currentCarrier.rates[index].visible
                      });
                    }}>
                    <FontAwesome
                      name={
                        // this.state.displayEye === index && this.state.toggleEye
                        this.props.currentCarrier.rates[index].visible
                          ? 'eye'
                          : 'eye-slash'
                      }
                      size={14}
                      color="white"
                      style={
                        this.props.activateEditButton
                          ? styles.showEye
                          : styles.hideEye
                      }
                    />
                  </TouchableOpacity>
                </View>
                <TouchableOpacity style={styles.panelBoxMobile}>
                  <Text
                    style={
                      this.state.windowWidth >= 1080
                        ? styles.upsText
                        : styles.upsTextMobile
                    }>
                    {el.serviceName}
                  </Text>
                  <Text
                    style={
                      this.state.windowWidth >= 1080
                        ? !this.props?.fetchRatesButton ? styles.price : styles.priceNew
                        : !this.props?.fetchRatesButton ? styles.priceMobile : styles.priceMobileNew
                    }>
                    ${el.cost}
                  </Text>
                </TouchableOpacity>
                <View style={styles.shortcutBoxMobile}>
                  {this.props.activateEditButton && !el.shortcutKey && (
                    <Tooltip tooltipText={'Click to Add a Keyboard Shortcut'} position={'top'}>
                      <TouchableOpacity
                        testID='addShortCut'
                        onPress={() =>
                          this.props.addShortcut(
                            this.props.currentCarrier.name,
                            el.serviceName
                          )
                        }>
                        <Image
                          source={shortcutImage}
                          style={styles.shortcutIconShowMobile}
                        />
                      </TouchableOpacity>
                    </Tooltip>
                  )}
                  {this.props.activateEditButton && el.shortcutKey && (
                    <Tooltip tooltipText={'Click to Replace Keyboard Shortcut'}>
                      <TouchableOpacity
                        onPress={() =>
                          this.props.addShortcut(
                            this.props.currentCarrier.name,
                            el.serviceName
                          )
                        }>
                        <Text
                          style={
                            this.state.windowWidth >= 1080
                              ? styles.shortcutText
                              : styles.shortcutTextMobile
                          }>
                          {el.shortcutKey.join('+')}
                        </Text>
                      </TouchableOpacity>
                    </Tooltip>
                  )}
                  {!this.props.activateEditButton && <></>}
                </View>
              </View>
            ) : (
              el.visible && (
                <View style={styles.panelContentBoxMobile} key={index}>
                  {!this.props?.fetchRatesButton && 
                    <View style={styles.greenBoxTextMobile}
                    testID= 'currentCarrier'
                    >
                      {
                        this.state.windowWidth >= 900 ?
                          <Text
                            testID='EnterToPurchase'
                            style={
                              this.props?.selectedServiceToPurchase?.serviceName ===
                                el.serviceName
                                ? styles.greenBoxMobile
                                : styles.greenBoxInvisible
                            }>
                            ENTER To purchase preselected service
                          </Text>
                          :
                          <Text
                            style={
                              this.props?.selectedServiceToPurchase?.serviceName ===
                                el.serviceName
                                ? styles.greenBoxMobileNew
                                : styles.greenBoxInvisible
                            }>
                            Tap to purchase
                          </Text>
                      }

                    </View>
                  }
                  <View style={styles.panelRowBoxMobile}>
                    <TouchableOpacity
                      style={
                        this.props?.selectedServiceToPurchase?.serviceName ===
                          el.serviceName && !this.props?.fetchRatesButton
                          ? styles.panelBoxSelectedMobile
                          : styles.panelBoxMobile
                      }
                      testID='setSelectedServiceToPurchase'
                      onPress={() =>
                        this.props.setSelectedServiceToPurchase({
                          serviceCode: el.serviceCode,
                          carrierCode: el.carrierCode,
                          serviceName: el.serviceName,
                          packageCode: el.packageCode,
                          purchaseConfirm: true
                        })
                      }>
                      <Text style={styles.upsTextMobile}>{el.serviceName}</Text>
                      <Text style={!this.props?.fetchRatesButton ? styles.priceMobile : styles.priceMobileNew}>${el.cost}</Text>
                    </TouchableOpacity>
                    <View style={styles.shortcutBoxMobile}>
                      {!el.shortcutKey && (
                        <Tooltip
                          tooltipText={'Click to Add a Keyboard Shortcut'} position={'top'}>
                          <TouchableOpacity
                            onPress={() =>
                              this.props.addShortcut(
                                this.props.currentCarrier.name,
                                el.serviceName
                              )
                            }>
                            <Image
                              source={shortcutImage}
                              style={styles.shortcutIconShowMobile}
                            />
                          </TouchableOpacity>
                        </Tooltip>
                      )}
                      {el.shortcutKey && (
                        <Tooltip
                          tooltipText={'Click to Replace Keyboard Shortcut'}>
                          <TouchableOpacity
                            onPress={() =>
                              this.props.addShortcut(
                                this.props.currentCarrier.name,
                                el.serviceName
                              )
                            }>
                            <Text style={styles.shortcutTextMobile}>
                              {el.shortcutKey?.join('+')}
                            </Text>
                          </TouchableOpacity>
                        </Tooltip>
                      )}
                      {!this.props.activateEditButton && <></>}
                    </View>

                  </View>
                </View>
              )
            );
          })
        )}
      </>
    );
  }
}
