import { StyleSheet } from 'react-native';
import { fontFamily } from '../helpers/fontFamily';

const styles = StyleSheet.create({
  //inventory style start//
  inventoryContainer: {
    flex: 1,
    margin: 10,
    padding: 10,
    backgroundColor: '#dbdada',
    borderWidth: 1,
    borderRadius: 30
  },
  itemContainerKit: {
    flexDirection: 'row',
    borderRadius: 5,
    // width: "100%",
    // flexWrap: "wrap",
    width: '100%',
    // backgroundColor: "steelblue",
    // padding: 10,
    minHeight: 30,
    flexGrow: 0
  },
  itemContainerProduct: {
    // justifyContent: "space-around",
    flexDirection: 'row',
    borderRadius: 5,
    // width: "100%",
    // flexWrap: "wrap",
    width: '100%',
    // backgroundColor: "steelblue",
    // padding: 10,
    minHeight: 30,
    flexGrow: 0
    // paddingVertical: 15
  },
  addItemViewProduct: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    // flexShrink: 1,
    // paddingHorizontal: 1,
    borderRadius: 5
    // borderWidth: 1
  },
  gridHeader2Product: {
    fontSize: 19,
    color: '#fff',
    fontFamily: 'Poppins_600SemiBold',
    opacity: 0.5,
    // alignItems: "flex-start",
    // justifyContent: "flex-start",
    textAlign: 'center'
    // borderWidth: 0.5
  },
  itemName2Product: {
    fontSize: 22,
    color: '#fff',
    fontStyle: 'Poppins_800ExtraBold',
    textAlign: 'center'
    // color: 'red'
    // fontWeight: "600",
    // paddingHorizontal: 5
  },
  statusActiveProduct: {
    fontSize: 19,
    color: '#7bfac3',
    // fontStyle: "Poppins_800ExtraBold",
    fontWeight: 'bold',
    alignSelf: 'center',
    textAlign: 'center'
  },
  statusNewProduct: {
    fontSize: 19,
    color: '#eb53fa',
    // fontStyle: "Poppins_800ExtraBold",
    alignSelf: 'center',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  itemskuProduct: {
    fontSize: 22,
    color: '#fff',
    fontStyle: 'Poppins_800ExtraBold',
    textAlign: 'center',
    wordBreak: 'break-all'
    // color: 'red'
    // fontWeight: "600",
    // paddingHorizontal: 5
  },

  itemViewKit: {
    justifyContent: 'center',
    alignItems: 'center',
    // height: 70,
    borderRadius: 5,
    // borderWidth: 0.5,
    // marginVertical: 5,
    paddingHorizontal: 5
  },
  gridHeaderKit: {
    fontSize: 19,
    color: '#fff',
    fontFamily: 'Poppins_600SemiBold',
    // fontFamily: fontFamily.font500,
    opacity: 0.5,
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    textAlign: 'center'
    // borderWidth: 0.5
  },
  itemNameKit: {
    fontSize: 19,
    color: '#fff',
    fontFamily: 'Poppins_500Medium',
    alignSelf: 'center',
    textAlign: 'center',

    // color: 'green'
    paddingHorizontal: 5
  },
  statusActiveKit: {
    fontSize: 19,
    color: '#7bfac3',
    // fontStyle: "Poppins_800ExtraBold",
    fontWeight: 'bold',
    alignSelf: 'center',
    textAlign: 'center'
  },
  statusNewKit: {
    fontSize: 19,
    color: '#eb53fa',
    // fontStyle: "Poppins_800ExtraBold",
    alignSelf: 'center',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  inventoryHeading: {
    fontWeight: 'bold',
    fontSize: 18,
    margin: 10
  },
  inventoryColumnView: {
    flexDirection: 'row',
    alignItems: 'center',
    // borderBottomWidth: 1 ,
    borderBottomColor: '#000'
  },
  inventoryColumnViewLabel: {
    flex: 2,
    fontSize: 16,
    fontWeight: 'bold',
    backgroundColor: 'rgb(51, 101, 153)',
    color: '#fff',
    width: '100%',
    textAlign: 'center',
    padding: 10
  },
  inventoryColumnViewValue: {
    flex: 6,
    padding: 10,
    borderBottomColor: 'black',
    borderBottomWidth: 1
  },
  inventoryColumnMainView: {
    margin: 5,
    backgroundColor: '#ffff',
    // borderRadius: 20 ,
    borderWidth: 1,
    borderColor: 'black'
    // padding: 10
  },
  //inventory style end//

  // product activity logs start //
  productActivityLogContainer: {
    flex: 1,
    padding: 10,
    margin: 10,
    backgroundColor: '#dbdada',
    borderWidth: 1,
    borderRadius: 30
  },
  productActivityLogHeading: {
    fontWeight: 'bold',
    fontSize: 18,
    margin: 10
  },
  productActivityLogMainView: {
    flexDirection: 'row',
    margin: 5,
    backgroundColor: '#ffff',
    borderRadius: 30,
    padding: 10
  },
  productActivityLogIndex: {
    flex: 2,
    margin: 2,
    textAlign: 'center'
  },
  productActivityLogView: {
    flex: 19,
    margin: 2
  },
  productActivityLogViewActionUser: {
    flex: 1,
    flexWrap: 'wrap',
    marginRight: 10
  },
  productActivityLogViewTime: {
    paddingBottom: 5,
    marginRight: 4,
    marginRight: 4
  },
  tabBtn: {
    // justifyContent: "center",
    paddingHorizontal: 5,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    marginHorizontal: 10
  },
  tabLabel: {
    paddingHorizontal: 5,
    textAlign: 'center',
    // fontWeight: "bold",
    color: 'white',
    fontFamily: 'Poppins_700Bold',
    fontSize: 19
    // borderWidth: 1
  },
  statusActive: {
    fontSize: 19,
    color: '#7bfac3',
    // fontStyle: "Poppins_800ExtraBold",
    fontWeight: 'bold',
    alignSelf: 'center',
    textAlign: 'center'
  },
  statusNew: {
    fontSize: 19,
    color: '#eb53fa',
    // fontStyle: "Poppins_800ExtraBold",
    alignSelf: 'center',
    textAlign: 'center',
    fontWeight: 'bold'
  },

  gridHeader: {
    fontSize: 19,
    color: '#fff',
    fontFamily: fontFamily.font800,
    opacity: 0.5,
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    textAlign: 'center'
    // borderWidth: 0.5
  },

  dropDownToggleStyle: {
    height: 30,
    width: 'auto',
    backgroundColor: 'transparent',
    borderBottomWidth: 2,
    // borderBottomColor: '#000',
    borderBottomColor: '#fff'
    // backgroundColor: 'rgb(255,255,255)',
  },
  mainContainer: {
    // width :"auto",
    flex: 1,
    backgroundColor: 'transparent',
    padding: 4,
    justifyContent: 'center'
    // borderBottomWidth :2,
    // borderBottomColor: '#fff',
    // alignItems: 'center',
  },

  dropDownToggleMobileStyle: {
    height: 30,
    width: '100%',
    backgroundColor: 'transparent',
    borderBottomWidth: 2,
    borderBottomColor: '#fff'
    // backgroundColor: 'rgb(255,255,255)',
  },
  mainContainerMobile: {
    flex: 1,
    width: '100%',
    backgroundColor: 'transparent',
    padding: 4,
    justifyContent: 'center'
    // borderBottomWidth :2,
    // borderBottomColor: '#fff',
    // alignItems: 'center',
  },
  statusAwaitingOrder: {
    fontSize: 19,
    color: '#5cb85c',
    // fontStyle: "Poppins_800ExtraBold",
    fontWeight: 'bold',
    alignSelf: 'center',
    textAlign: 'center'
  },
  productInfoInput: {
    // margin: 15,
    borderWidth: 1,
    border: 'none',
    height: '31px',
    borderRadius: 10,
    padding: 10,
    backgroundColor: '#cdcdcd',
    width: '50%',
    color: '#1d3045',
    fontFamily: 'Poppins_500Medium',
    fontSize: 15
  },
  productInfoText: {
    color: '#cdcdcd',
    fontFamily: 'Poppins_600SemiBold',
    fontSize: '18px'
  },
  viewGap: {
    flexDirection: 'column',
    width: '49%',
  },
  KitHeading: {
    color: '#67869B',
    fontWeight: 'bold',
    fontFamily: 'Poppins_700Bold',
    fontSize: '18px',
    webkitBackgroundClip: 'text',
    webkitTextFillColor: 'transparent'
  },
  iconPlusMobileGradiant: {
    marginTop: '-66%',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    borderWidth: 1,
    borderRadius: 5,
    borderColor: '#869db1',
    borderBottomWidth: 0,
    shadowColor: '#ffff',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.9,
    shadowRadius: 3,
    elevation: 3,
    width: 90,
    paddingBottom: 15,
    zIndex: 1
  },
  iconPlusDesktopGradiant: {
    flex: 1,
    borderWidth: 1,
    borderRadius: 5,
    borderColor: '#869db1',
    borderBottomWidth: 0,
    shadowColor: '#ffff',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.9,
    shadowRadius: 3,
    elevation: 3,
    paddingBottom: 15,
    width: '93%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  saveCloseButton: {
    marginRight: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#fff',
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
    height: 30,
    shadowRadius: 8,
    elevation: 5,
    shadowOpacity: 0.5
  },
  saveCloseButtonText: {
    color: 'white',
    paddingHorizontal: 10,
    fontWeight: 'bold'
  },
  searchTextAddItem: {
    fontSize: 21,
    fontFamily: 'Poppins_600SemiBold',
    color: 'white'
  },
  inventoryTolltip: {
    paddingHorizontal: '1%',
    backgroundColor: 'rgba(104, 135, 156, 0.4)',
    borderRadius: 10,
    marginHorizontal: '1%',
    marginTop: 20,
    marginBottom: 10,
    shadowColor: '#50708b',
    shadowOffset: { width: 1, height: 1 },
    shadowRadius: 10
  },
  inventoryAddItemText: {
    fontFamily: 'Poppins_600SemiBold',
    color: 'white',
    fontSize: 16,
    textShadowColor: '#000',
    textShadowRadius: 5,
    elevation: 5
  },
  productListLinearView: {
    flex: 1,
    backgroundColor: 'rgba(15, 39, 58, 0.4)',
    borderRadius: 15,
    marginBottom: 50,
    minHeight: 200,
    marginTop: 10,
    flexWrap: 'wrap'
  }

  // product activity logs end //

  //product detail start //

  //product detail end //
});
export default styles;
