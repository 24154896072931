import React from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import CommonStyles from '../../style/commonStyles';
import {LinearGradient} from 'expo-linear-gradient';

export default function EmailSentPopup(props) {

  return (
    <>
      <View style={CommonStyles.packingCamEmailSent}>
        <LinearGradient
          locations={[0, 1]}
          colors={['#5bbc31', '#479523', '#3f851d']}
          style={CommonStyles.activitiesMainTextStyle}>
          <Text style={CommonStyles.activitiesTextStyle}>
          {props.msg}
          </Text>
          <TouchableOpacity
            style={CommonStyles.activitiesButtonStyle}
            onPress={props.closeAlertEmail}>
            <i
              className="icon-cancel"
              style={{fontSize: '22px', color: 'white'}}></i>
          </TouchableOpacity>
        </LinearGradient>
      </View>
    </>
  );
}
