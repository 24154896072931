import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import { RemoveOrderItemsQty } from '../actions/orderActions';

export const MailOutOfStockReport = async (props) => {
  try {
    const product_id = props.order['unscanned_items'][0].product_id;
    console.log(props?.id);
    const id = props?.id || props?.order?.id;
    const data = { id: id, product_id };
    const access_token = await AsyncStorage.getItem('access_token');
    const url = await AsyncStorage.getItem('url');

    if (access_token && url && product_id && id) {
      await axios({
        method: 'post',
        url: `${url}/scan_pack/send_out_of_stock_mail.json`,
        headers: { Authorization: `Bearer ${access_token}` },
        data,
      });
    } else {
      console.log('Access token or URL or Product not found');
    }
  } catch (error) {
    console.log(error);
  }
};

export const removeItem = async (props, dispatch) => {
  if (props) {
    const data = {
      orderitem: [props.order['unscanned_items'][0].order_item_id],
      on_ex: 'on GPX',
    };

    try {
      dispatch(RemoveOrderItemsQty(data));
    } catch (error) {
      console.error("Error removing order items:", error);
    }
  }
};
