import React, {useEffect, useState} from 'react';
import {View, Text, TouchableOpacity, Image, StyleSheet} from 'react-native';
import downArrow from '../../../assets/down_arrow.png';
import CommonStyles from '../../style/commonStyles';

const CustomTouchableOpacity = ({
  onPress,
  selectedStatus,
  handleStatusSelect,
  disableFilter,
  buttonName,
  buttonText,
  orderCount,
  testID
}) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={CommonStyles.customTouchable}
      testID={testID}>
      <View style={CommonStyles.customTouchableContent}>
        <Text style={CommonStyles.customTouchableCount}>{orderCount}</Text>
        <Text
          style={[
            handleStatusSelect?.includes(buttonName) &&
              styles.dropdownActiveButton,
            CommonStyles.customTouchableText
          ]}>
          {buttonText || buttonName}
        </Text>
        <TouchableOpacity
          onPress={onPress}
          style={CommonStyles.customTouchableReset}
          testID={testID + '_reset'}>
          {handleStatusSelect.includes(buttonName) && (
            <i className="icon-cancel" style={styles.customTouchableIcon}></i>
          )}
        </TouchableOpacity>
      </View>
    </TouchableOpacity>
  );
};

const ShowAllButton = ({onPress, showMore}) => (
  <TouchableOpacity style={styles.showAllButton} onPress={onPress}>
    <Text style={styles.showAllButtonText}>
      {showMore ? 'Show All' : 'Show Less'}
    </Text>
  </TouchableOpacity>
);

const OrderTagsDropdown = ({
  getFilteredOrders,
  resetFilters,
  includedTags,
  excludedTags,
  clearAllFilters,
  resetFilterCheck,
  status,
  orderCount,
  setSelectedTags,
  setFilterIncludedTags,
  filterIncludedTags
}) => {
  // let filterIncludedTags;
  const [isOpen, setIsOpen] = useState(() => {
    const storedValue = localStorage.getItem('isOpen');
    return storedValue ? JSON.parse(storedValue) : true;
  });
  const [selectedStatus, setSelectedStatus] = useState(() => [status]);
  const [showAllIncludedTags, setShowAllIncludedTags] = useState(false);
  const [showAllExcludedTags, setShowAllExcludedTags] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(prevIsOpen => !prevIsOpen);
  };

  useEffect(() => {
    localStorage.setItem('isOpen', JSON.stringify(isOpen));
  }, [isOpen]);

  const handleStatusSelect = (status) => {
    let statues;
    let isPresent = false;
    console.log(">>>>>>>>>>>.filterIncludedTags", filterIncludedTags)
    setSelectedStatus(prevSelectedStatus => {
      if (!prevSelectedStatus.includes(status)) {
        statues = [status];
        return [status];
    } else {
        statues = [prevSelectedStatus];
        isPresent = true;
        return prevSelectedStatus;
    }
    });
    isPresent === true ? disableFilter(status) : getFilteredOrders(statues)
    // setSelectedStatus([status]);
    setSelectedTags(statues, filterIncludedTags);
    getFilteredOrders(status);
  };

  const toggleShowAllIncludedTags = () => {
    setShowAllIncludedTags(prevShowAllTags => !prevShowAllTags);
  };

  const toggleShowAllExcludedTags = () => {
    setShowAllExcludedTags(prevShowAllTags => !prevShowAllTags);
  };

  const disableFilter = statusToRemove => {
    let updatedStatus = [];
    setSelectedStatus(prevSelectedStatus => {
      updatedStatus = prevSelectedStatus.filter(
        status => status !== statusToRemove
      );
      return updatedStatus;
    });
    resetFilters(updatedStatus);
  };

  useEffect(() => {
    if (clearAllFilters === true) {
      setSelectedStatus([]);
      resetFilterCheck();
    }
  }, [clearAllFilters]);

  // useEffect(() => {
  //   setSelectedStatus(status);
  // }, [status]);

  useEffect(() => {
    if(selectedStatus){
      // console.log(">>>>>>>>>>>.filterIncludedTags", filterIncludedTags)

      setSelectedTags(selectedStatus);
    }
  }, [selectedStatus]);

  // console.log("????????????/", includedTags)
  return (
    <View style={{borderTop: '2px solid rgb(0, 0, 0)'}}>
      <TouchableOpacity style={styles.button} onPress={toggleDropdown}>
        <View style={styles.buttonContent}>
          <Text style={styles.text}>Tags</Text>
          <Image
            style={[CommonStyles.icon, isOpen && styles.iconOpen]}
            source={downArrow}
          />
        </View>
        {isOpen && (
          <View>
            <Text style={[styles.text, styles.includeTagsText]}>
              Orders that include these tags:
            </Text>
          </View>
        )}
      </TouchableOpacity>
      {isOpen && (
        <View style={styles.dropdownContent}>
          {includedTags
            .slice(0, showAllIncludedTags ? includedTags.length : 2)
            .map(tag => (
              <CustomTouchableOpacity
                key={tag.name}
                onPress={() => {
                  setFilterIncludedTags(true);
                  handleStatusSelect(tag.name);
                }}
                selectedStatus={selectedStatus}
                handleStatusSelect={selectedStatus}
                disableFilter={() => disableFilter(tag.name)}
                buttonName={tag.name}
                buttonText={tag.text}
                orderCount={tag.order_count}
                testID={`${tag.name.toLowerCase()}_button`}
              />
            ))}

          {includedTags.length > 2 && (
            <ShowAllButton
              onPress={toggleShowAllIncludedTags}
              showMore={!showAllIncludedTags}
              testID={'show_all_included_tags_button'}
            />
          )}
          <View style={styles.button}>
            <Text style={[styles.text, styles.includeTagsText]}>
              Orders that do not include these tags:
            </Text>
          </View>
          {excludedTags
            .slice(0, showAllExcludedTags ? excludedTags.length : 2)
            .map(tag => (
              <CustomTouchableOpacity
                key={tag.name}
                onPress={() => {
                  setFilterIncludedTags(false);
                  handleStatusSelect(tag.name)
                }}
                selectedStatus={selectedStatus}
                handleStatusSelect={selectedStatus}
                disableFilter={() => disableFilter(tag.name)}
                buttonName={tag.name}
                buttonText={tag.text}
                orderCount={tag.not_present_in_order_count}
                testID={`${tag.name.toLowerCase()}_button`}
              />
            ))}
          {excludedTags.length > 2 && (
            <ShowAllButton
              onPress={toggleShowAllExcludedTags}
              showMore={!showAllExcludedTags}
              testID={'show_all_excluded_tags_button'}
            />
          )}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  dropdownActiveButton: {
    border: '1px solid gray',
    borderRadius: 8,
    paddingTop: 2
  },
  button: {
    maxWidth: '107%',
    backgroundColor: '#262B30',
    marginLeft: '-1px'
  },
  buttonContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  text: {
    color: '#9BA7B4',
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 13,
    padding: 5
  },
  iconOpen: {
    transform: [{rotate: '180deg'}]
  },
  customTouchableIcon: {
    fontSize: 15,
    color: 'gray'
  },
  showAllButton: {
    width: '97%',
    borderColor: 'gray',
    borderWidth: 1,
    borderRadius: 10
  },
  showAllButtonText: {
    textAlign: 'center',
    color: 'white',
    padding: 5
  },
  dropdownContent: {
    marginTop: 10
  },
  includeTagsText: {
    marginLeft: 10,
    fontSize: 11
  }
});

export default OrderTagsDropdown;
