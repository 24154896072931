import React, {useEffect, useMemo, useState} from 'react';
import {Text, View, StyleSheet} from 'react-native';
import {LinearGradient} from 'expo-linear-gradient';

export const LabelWithCount = ({label}) => {
  const [containerWidth, setContainerWidth] = useState(0);
  const [leftValue, setLeftValue] = useState('38%');

  const handleLayout = event => {
    const {width} = event.nativeEvent.layout;
    console.log('handleLayout, width:', width);
    setContainerWidth(width);
  };

  const widthConfig = useMemo(
    () => [
      {minWidth: 0, maxWidth: 350, percentage: 24},
      {minWidth: 350, maxWidth: 700, percentage: 32},
      {minWidth: 700, maxWidth: 800, percentage: 40},
      {minWidth: 800, maxWidth: 1200, percentage: 34},
      {minWidth: 1200, maxWidth: 1500, percentage: 39},
      {minWidth: 1500, maxWidth: 1800, percentage: 41},
      {minWidth: 1800, maxWidth: Infinity, percentage: 43.5}
    ],
    []
  );

  const handleLeft = () => {
    const windowWidth = window.innerWidth;
    const config = widthConfig.find(
      config => windowWidth >= config.minWidth && windowWidth < config.maxWidth
    );
    const percentage = config ? config.percentage : 39;
    const left = containerWidth > 200 ? `${percentage}%` : `${percentage + 1.5}%`;
    setLeftValue(left);

  };

  useEffect(() => {
    handleLeft();
    window.addEventListener('resize', handleLeft);
    return () => {
      window.removeEventListener('resize', handleLeft);
    };
  }, []);

  return (
    <View>
      <LinearGradient
        locations={[0, 1]}
        colors={['#00000006', '#393535']}
        onLayout={handleLayout}
        style={styles.container}>
        <Text style={[styles.text, {color: '#41D494'}]}>{label}</Text>
      </LinearGradient>
      <LinearGradient
        colors={['transparent', '#0CA144', 'transparent']}
        start={{x: 0, y: 0.5}}
        end={{x: 1, y: 0.5}}
        style={[
          styles.lineGradient,
          {
            width: containerWidth * 0.76,
            left: leftValue
          }
        ]}
        locations={[0.0, 0.5, 1.0]}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    borderWidth: 4,
    borderColor: '#292929',
    borderRadius: 25,
    alignSelf: 'center',
    textAlign: 'center',
    paddingVertical: 5,
    paddingHorizontal: 10,
    overflow: 'hidden'
  },
  lineGradient: {
    position: 'absolute',
    bottom: 0,
    height: 4,
    borderRadius: 2,
    zIndex: 10
  },
  text: {
    fontSize: 16
  }
});

export default LabelWithCount;
