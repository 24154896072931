import React from 'react';
import {
  View,
  Text
} from 'react-native';
import {getItemSkuByOrderItemId} from '../helpers/scanPackFunctions';
import ScannedKitItem from './scannedKitItem';




const ScannedKitItemContainer = ({ item, order, index, lists, activeBoxName, redirectToItemDetail, removeBoxItem }) => {
  return (
    <View
        key={index}
        style={{
          margin: 0,
          borderRadius: 16,
          borderWidth: 2,
          borderColor: '#rgb(124, 149, 174);',
          backgroundColor: '#3f5163',
          padding: 8,
          paddingTop: 1,
          marginBottom: 3
        }}
      >
        <View>
        <View style={{display: 'flex', justifyContent: 'start', flexDirection: 'row'}}>
          <View
            style={{
              borderRadius: 3,
              position: 'relative',
              width: '27%'
            }}
          >
              <Text
                style={{
                  textAlign: 'center',
                  color: '#fff',
                  marginTop: '8%',
                  fontSize: 12,
                  fontWeight: 800,
                }}
              >
                KIT PART
              </Text>
            </View>
            <View style={{width: '53%'}}>
              <Text
                style={{
                  textAlign: 'center',
                  color: '#fff',
                  fontSize: 17,
                  fontWeight: 800,
                }}
                numberOfLines={1}
                ellipsizeMode="tail"
              >
                {getItemSkuByOrderItemId(item.order_item_id, lists.unscanned_items)}
              </Text>
            </View>
        </View>
          <ScannedKitItem
              item = {item}
              order = {order}
              activeBoxName = {activeBoxName}
              redirectToItemDetail={(item) => {redirectToItemDetail(item)}}
              removeBoxItem = {(box_id, order_item_id, kit_id) => 
                {removeBoxItem(box_id, order_item_id, kit_id)
              }}
            />
        </View>
      </View>
    );
};

export default ScannedKitItemContainer;
