import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  GENERAL_SETTINGS,
  SCANPACK_SETTINGS,
  USER_INFO_SUCCESS,
  GET_BOTH_SETTINGS,
  USER_INFO_SUCCESS_FAIL,
  GET_BOTH_SETTINGS_FAIL,
  SEND_PASSWORD_RESET_EMAIL,
  SEND_PASSWORD_RESET_EMAIL_FAIL,
  IS_EMAIL_SEND,
  IS_BUG_SUPPORT_POPUP_OPEN,
  MOUNT_COMPONENT
} from '../constants';

const initialState = {
  mountComponent:false,
  isBugOrSupportPopup: false
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        status: action.payload,
        login: true,
        error: '',
        time: new Date()
      };
    case LOGIN_FAIL:
      return {
        ...state,
        status: '',
        login: false,
        error: action.payload,
        time: new Date()
      };
    case GET_BOTH_SETTINGS:
      return {...state, bothSettings: action.payload};
    case GET_BOTH_SETTINGS_FAIL:
      return {...state, bothSettings: action.payload};
    case GENERAL_SETTINGS:
      return {...state, settings: action.payload};
    case SCANPACK_SETTINGS:
      return {...state, scanpackSettings: action.payload};
    case USER_INFO_SUCCESS:
      return {...state, userInfo: action.payload};
    case USER_INFO_SUCCESS_FAIL:
      return {...state, userInfo: action.payload};
    case SEND_PASSWORD_RESET_EMAIL:
      return {...state, emailSendMsg: action.payload};
    case SEND_PASSWORD_RESET_EMAIL_FAIL:
      return {...state, sendEmailError: action.payload};
    case IS_EMAIL_SEND:
      return {...state, isEmailSend: action.payload};
    case IS_BUG_SUPPORT_POPUP_OPEN:
      return {...state, isBugOrSupportPopup: action.payload};
    case MOUNT_COMPONENT:
    return {...state,mountComponent:action.payload}
    default:
      return state;
  }
};

export default userReducer;
