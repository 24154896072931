import React, {Component} from 'react';
import {
  View,
  Text,
  TextInput,
  Dimensions,
  TouchableOpacity
} from 'react-native';
import styles from '../../style/productdetail';
import {Editor} from 'react-draft-wysiwyg';
import {EditorState} from 'draft-js';
import Switch from 'react-switch';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import {faCaretDown, faCaretUp} from '@fortawesome/free-solid-svg-icons';
import {LinearGradient} from 'expo-linear-gradient';
import AlertBoxPermission from '../../commons/alertBoxPermission';
import ScanPackOptionMobileView from './scanpack_option_mobile_view';
import HTML from "react-native-render-html";
import * as ImagePicker from 'expo-image-picker';
import { uploadHtmlImage } from '../../actions/productAction';
// import '.../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import i18n from 'i18n-js';
import { connect } from 'react-redux';
import { FontAwesome } from '@expo/vector-icons';
import Tooltip from '../../commons/toolTip';

let click_pass = true;
let typeICount = true;
let record_Serial = true;
let item_intan = false;
let any_Order = false;
let skippable = false;
let packing_inst = true;
let cust_prod1 = true;
let cust_prod2 = true;
let cust_prod3 = true;
let dis1 = false;
let dis2 = false;
let dis3 = false;
let clickScanIndex = 0;
let typeInCountIndex = 0;
let recordSerialIndex = 0;
let isImageUriPresent = false; 
class ScanpackOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
      lessMore: false,
      value: new Date(),
      pickedDate: new Date(),
      startDate: '',
      scanSeqcount: 0,
      quickScanToolTip: false,
      quickScanToolTip1: false,
      quickScanToolTip2: false,
      quickScanToolTip3: false,
      quickScanToolTip4: false,
      quickScanToolTip5: false,
      quickScanToolTip6: false,
      quickScanToolTip7: false,
      quickScanToolTip8: false,
      isDisabled : false,
      selectedTag: 'body',
      selectedStyles: [],
      editorState: EditorState.createEmpty(),
      showEditor: false,
      recordSerialDrop: '',
      contentState: '',
      dropDownToggleState: false,
      showHtml: true,
      clickScanstate: '',
      typeInCountState: '',
      dropDownTypeInToggle: false,
      recordSerialState: '',
      dropDownRecordSerial: false,
      toolTipNum: 0,
      toolTipCheck: false,
      permissionState: false,
      isHovered: false
    };
    (this.richText = React.createRef()), (this.editor = null);
    this.textInputRef = React.createRef();
  }
  dropDownToggle = () => {
    this.setState({dropDownToggleState: !this.state.dropDownToggleState});
  };
  htmlViewtoggle = () => {
    this.setState({showHtml: !this.state.showHtml});
  };
  dropDownToggleTypeIn = () => {
    this.setState({dropDownTypeInToggle: !this.state.dropDownTypeInToggle});
  };
  dropDownToggleRecord = () => {
    this.setState({dropDownRecordSerial: !this.state.dropDownRecordSerial});
  };
  showEditorFun = () => {
    this.setState({showEditor: !this.state.showEditor});
  };
  onEditorStateChange = editorState => {
    this.setState({
      editorState
    });
  };

  toolTipFun = open => {
    this.setState({toolTipCheck: !this.state.toolTipCheck});
    if (open === '0') {
      this.setState({
        toolTipNum: open,
        quickScanToolTip: !this.state.quickScanToolTip
      });
    }
    if (open === '1') {
      this.setState({
        toolTipNum: open,
        quickScanToolTip1: !this.state.quickScanToolTip1
      });
    }
    if (open === '2') {
      this.setState({
        toolTipNum: open,
        quickScanToolTip2: !this.state.quickScanToolTip2
      });
    }
    if (open === '3') {
      this.setState({
        toolTipNum: open,
        quickScanToolTip3: !this.state.quickScanToolTip3
      });
    }
    if (open === '4') {
      this.setState({
        toolTipNum: open,
        quickScanToolTip4: !this.state.quickScanToolTip4
      });
    }
    if (open === '5') {
      this.setState({
        toolTipNum: open,
        quickScanToolTip5: !this.state.quickScanToolTip5
      });
    }
    if (open === '6') {
      this.setState({
        toolTipNum: open,
        quickScanToolTip6: !this.state.quickScanToolTip6
      });
    }
    if (open === '7') {
      this.setState({
        toolTipNum: open,
        quickScanToolTip7: !this.state.quickScanToolTip7
      });
    }
    if (open === '8') {
      this.setState({
        toolTipNum: open,
        quickScanToolTip8: !this.state.quickScanToolTip8
      });
    }
  };

  //For Open and close Tooltip for mobile View(This two function)

  toolTipFunShowMobile = open => {
    if (open === '0') {
      this.setState({toolTipNum: open, quickScanToolTip: true});
    }
    if (open === '1') {
      this.setState({toolTipNum: open, quickScanToolTip1: true});
    }
    if (open === '2') {
      this.setState({toolTipNum: open, quickScanToolTip2: true});
    }
    if (open === '3') {
      this.setState({toolTipNum: open, quickScanToolTip3: true});
    }
    if (open === '4') {
      this.setState({toolTipNum: open, quickScanToolTip4: true});
    }
    if (open === '5') {
      this.setState({toolTipNum: open, quickScanToolTip5: true});
    }
    if (open === '6') {
      this.setState({toolTipNum: open, quickScanToolTip6: true});
    }
    if (open === '7') {
      this.setState({toolTipNum: open, quickScanToolTip7: true});
    }
    if (open === '8') {
      this.setState({toolTipNum: open, quickScanToolTip8: true});
    }
  };
  toolTipFunHideMobile = open => {
    if (open === '0') {
      this.setState({toolTipNum: open, quickScanToolTip: false});
    }
    if (open === '1') {
      this.setState({toolTipNum: open, quickScanToolTip1: false});
    }
    if (open === '2') {
      this.setState({toolTipNum: open, quickScanToolTip2: false});
    }
    if (open === '3') {
      this.setState({toolTipNum: open, quickScanToolTip3: false});
    }
    if (open === '4') {
      this.setState({toolTipNum: open, quickScanToolTip4: false});
    }
    if (open === '5') {
      this.setState({toolTipNum: open, quickScanToolTip5: false});
    }
    if (open === '6') {
      this.setState({toolTipNum: open, quickScanToolTip6: false});
    }
    if (open === '7') {
      this.setState({toolTipNum: open, quickScanToolTip7: false});
    }
    if (open === '8') {
      this.setState({toolTipNum: open, quickScanToolTip8: false});
    }
  };

  packingPlacement = () => {
    this.props.productInfoHandleChange(
      this.state.scanSeqcount,
      'packing_placement'
    );
  };
  countIncDec = check => {
    if (check == 'Inc' && this.state.scanSeqcount <= 100) {
      this.setState({scanSeqcount: this.state.scanSeqcount + 1});
      this.packingPlacement();
    }
    if (check == 'Dec' && this.state.scanSeqcount > 0) {
      this.setState({
        scanSeqcount: this.state.scanSeqcount - 1
      });
      this.packingPlacement();
    }
  };
  setCount = input => {
    const value = parseInt(input || 0)
    this.setState({scanSeqcount: value});
    this.props.productInfoHandleChange(
      value,
      'packing_placement'
    );
  }
  onStyleKeyPress = toolType => {
    this.editor.applyToolbar(toolType);
  };

  onSelectedTagChanged = tag => {
    this.setState({
      selectedTag: tag
    });
  };

  onSelectedStyleChanged = styles => {
    this.setState({
      selectedStyles: styles
    });
  };

  onValueChanged = value => {
    this.setState({
      value: value
    });
  };
  updateDimensions = () => {
    this.setState({windowWidth: window.innerWidth});
  };
  contentLess = () => {
    this.setState({lessMore: !this.state.lessMore});
  };
  componentDidMount() {
    this.setState({
      clickScanstate:
        this.props.basicinfo.click_scan_enabled == 'on'
          ? 'Enable'
          : this.props.basicinfo.click_scan_enabled == 'off'
          ? 'Disable'
          : 'On with confirmation'
    });
    clickScanIndex =
      this.props.basicinfo.click_scan_enabled == 'on'
        ? 0
        : this.props.basicinfo.click_scan_enabled == 'off'
        ? 1
        : 2;
    this.setState({
      typeInCountState:
        this.props.basicinfo.type_scan_enabled == 'on'
          ? 'Enable'
          : (this.props.basicinfo.type_scan_enabled = 'off'
              ? 'Disable'
              : 'On with confirmation')
    });
    typeInCountIndex =
      this.props.basicinfo.type_scan_enabled == 'on'
        ? 0
        : this.props.basicinfo.type_scan_enabled == 'off'
        ? 1
        : 2;

    window.addEventListener('resize', this.updateDimensions);
    if (this?.props?.basicinfo?.record_serial) {
      this.setState({recordSerialState: 'Record 1'});
    }
    if (
      this?.props?.basicinfo?.second_record_serial &&
      this?.props?.basicinfo?.record_serial
    ) {
      this.setState({recordSerialState: 'Record 2'});
    }
    if (
      this?.props?.basicinfo?.second_record_serial == false &&
      this?.props?.basicinfo?.record_serial == false
    ) {
      this.setState({recordSerialState: 'Disable'});
    }
    this.setState({
      isDisabled: this.props?.confProductInstruction === 'always' ? true : false,
    })

    this.setState({scanSeqcount: this?.props?.basicinfo?.packing_placement});
  }
  componentDidUpdate() {
    if (this.props.addHtmlImage && isImageUriPresent) {
      this.props.basicinfo.packing_instructions = !this.props.basicinfo.packing_instructions ? '' : this.props.basicinfo.packing_instructions
      this.props.basicinfo.packing_instructions += '<img src="' + this.props.addHtmlImage.product_image + '"/>';
      isImageUriPresent = false;
    }
    if(!this.state.showHtml){
        this.textInputRef.current.focus();
    }
  }
  updateSerialRecord = (itemValue, index) => {
    recordSerialIndex = index;
    if (itemValue == 'record_1') {
      this.setState({recordSerialState: 'Record 1'});
      this.props.productInfoHandleChange(true, 'record_serial');
      this.props.productInfoHandleChange(false, 'second_record_serial');
      this.setState({typeInCountState: 'Disable'});
      this.props.productInfoHandleChange(itemValue, 'type_scan_enabled');
      this.dropDownToggleRecord();
    }
    if (itemValue == 'record_2') {
      this.setState({recordSerialState: 'Record 2'});
      this.props.productInfoHandleChange(true, 'record_serial');
      this.props.productInfoHandleChange(true, 'second_record_serial');
      this.setState({typeInCountState: 'Disable'});
      this.props.productInfoHandleChange(itemValue, 'type_scan_enabled');
      this.dropDownToggleRecord();
    }
    if (itemValue == 'off') {
      this.setState({recordSerialState: 'Disable'});
      this.props.productInfoHandleChange(false, 'record_serial');
      this.props.productInfoHandleChange(false, 'second_record_serial');
      this.dropDownToggleRecord();
    }
  };

  clickScandrop = (itemValue, index) => {
    clickScanIndex = index;
    if (itemValue == 'on') {
      this.setState({clickScanstate: 'Enable'});
      this.props.productInfoHandleChange(itemValue, 'click_scan_enabled');
      this.dropDownToggle();
    }
    if (itemValue == 'off') {
      this.setState({clickScanstate: 'Disable'});
      this.props.productInfoHandleChange(itemValue, 'click_scan_enabled');
      this.dropDownToggle();
    }
    if (itemValue == 'on_with_confirmation') {
      this.setState({clickScanstate: 'On with confirmation'});
      this.props.productInfoHandleChange(itemValue, 'click_scan_enabled');
      this.dropDownToggle();
    }
  };

  pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1
    });
    let productId = this.props.basicinfo.id;
    let imageUri = result.uri

    await this.props.uploadHtmlImage(productId, imageUri);
    isImageUriPresent = true;
  };

  handleMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ isHovered: false });
  };

  typeInCountDrop = (itemValue, index) => {
    typeInCountIndex = index;
    if (itemValue == 'on') {
      if (this.state.recordSerialState !== 'Record 1' && this.state.recordSerialState !== 'Record 2'){ 
        this.setState({typeInCountState: 'Enable'});
        this.props.productInfoHandleChange(itemValue, 'type_scan_enabled');
        this.dropDownToggleTypeIn();
      }
    }
    if (itemValue == 'off') {
      this.setState({typeInCountState: 'Disable'});
      this.props.productInfoHandleChange(itemValue, 'type_scan_enabled');
      this.dropDownToggleTypeIn();
    }
    if (itemValue == 'on_with_confirmation') {
      if (this.state.recordSerialState !== 'Record 1' && this.state.recordSerialState !== 'Record 2'){ 
        this.setState({typeInCountState: 'On with confirmation'});
        this.props.productInfoHandleChange(itemValue, 'type_scan_enabled');
        this.dropDownToggleTypeIn();
      }
    }
  };
  permissionCheck = () => {
    if (this.props.show === true) {
      this.setState({permissionState: true});
      click_pass = false;
      typeICount = false;
      record_Serial = false;
      item_intan = true;
      any_Order = true;
      skippable = true;
      packing_inst = false;
      cust_prod1 = false;
      cust_prod2 = false;
      cust_prod3 = false;
      dis1 = true;
      dis2 = true;
      dis3 = true;
    }
  };
  onCloseAlert = () => {
    if (this.props.show === true) {
      this.setState({permissionState: false});
      click_pass = true;
      typeICount = true;
      record_Serial = true;
      item_intan = false;
      any_Order = false;
      skippable = false;
      packing_inst = true;
      cust_prod1 = true;
      cust_prod2 = true;
      cust_prod3 = true;
      dis1 = false;
      dis2 = false;
      dis3 = false;
    }
  };
  componentWillUnmount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  render() {
    let id = '111';
    const { isHovered } = this.state;
    let {basicinfo} = this.props;
    return (
      <View>
        <AlertBoxPermission
          permissionState={this.state.permissionState}
          onCloseAlert={() => this.onCloseAlert()}
        />
        {this.state.windowWidth >= 900 ? (
          <>
            {basicinfo && (
              <>
                <View style={{marginLeft: '30px', width: 600, display: 'none'}}>
                  {basicinfo.is_kit == 1 ? (
                    <Text
                      style={{
                        color: '#67869B',
                        fontWeight: 'bold',
                        fontFamily: 'Poppins_700Bold',
                        fontSize: '20px',
                        marginLeft: '30px',
                        marginTop: -30,
                        zIndex: 999,
                        background:
                          ' -webkit-linear-gradient(#4b657d  , #768ea5)',
                        webkitBackgroundClip: 'text',
                        webkitTextFillColor: 'transparent'
                      }}
                    >
                      KIT:{this?.props?.product?.skus[0]?.sku}
                    </Text>
                  ) : (
                    <Text
                      style={{
                        color: '#67869B',
                        fontWeight: 'bold',
                        fontFamily: 'Poppins_700Bold',
                        fontSize: '20px',
                        marginLeft: '30px',
                        marginTop: -30,
                        zIndex: 999,
                        background:
                          ' -webkit-linear-gradient(#4b657d  , #768ea5)',
                        webkitBackgroundClip: 'text',
                        webkitTextFillColor: 'transparent'
                      }}
                    >
                      {this?.props?.product?.skus[0]?.sku}
                    </Text>
                  )}
                </View>
                <View
                  testID='scanPackContainer'
                  style={{
                    paddingHorizontal: '1%',
                    backgroundColor: 'rgba(104, 135, 156, 0.4)',
                    borderRadius: 10,
                    marginHorizontal: '1%',
                    marginTop: 20,
                    marginBottom: 10,
                    shadowColor: '#50708b',
                    shadowOffset: {width: 1, height: 1},
                    shadowRadius: 10
                  }}
                  onStartShouldSetResponder={() =>
                    this.state.toolTipCheck ? (
                      this.toolTipFun(this.state.toolTipNum)
                    ) : (
                      <></>
                    )
                  }
                >
                  {this.state.quickScanToolTip && (
                    <LinearGradient
                      testID='quicKScanToolTip0'
                      locations={[0, 1]}
                      colors={['#8a9daf', '#d7e1e9']}
                      style={{
                        padding: 10,
                        position: 'absolute',
                        borderRadius: 5,
                        width: 335,
                        alignSelf: 'flex-start',
                        zIndex: 99,
                        top: 83
                      }}
                    >
                      <Text style={{fontSize: 20, color: '#030303'}}>
                        {i18n.t('product.click_scan_tooltip')}
                      </Text>
                      <View
                        style={{
                          position: 'absolute',
                          right: '40%',
                          top: -10,
                          borderLeftWidth: 10,
                          borderLeftColor: 'transparent',
                          borderRightWidth: 10,
                          borderRightColor: 'transparent',
                          borderBottomWidth: 10,
                          borderBottomColor: '#8d9fb1'
                        }}
                      />
                    </LinearGradient>
                  )}
                  {this.state.quickScanToolTip1 && (
                    <LinearGradient
                    testID='quicKScanToolTip1'
                      locations={[0, 1]}
                      colors={['#8a9daf', '#d7e1e9']}
                      style={{
                        padding: 10,
                        position: 'absolute',
                        top: 83,
                        borderRadius: 5,
                        width: 335,
                        alignSelf: 'center',
                        zIndex: 99
                      }}
                    >
                      <Text style={{fontSize: 20, color: '#030303'}}>
                        {i18n.t('product.type_count_tooltip')}
                      </Text>
                      <View
                        style={{
                          position: 'absolute',
                          right: '27%',
                          top: -10,
                          borderLeftWidth: 10,
                          borderLeftColor: 'transparent',
                          borderRightWidth: 10,
                          borderRightColor: 'transparent',
                          borderBottomWidth: 10,
                          borderBottomColor: '#8d9fb1'
                        }}
                      />
                    </LinearGradient>
                  )}
                  {this.state.quickScanToolTip2 && (
                    <LinearGradient
                    testID='quicKScanToolTip2'
                      locations={[0, 1]}
                      colors={['#8a9daf', '#d7e1e9']}
                      style={{
                        padding: 10,
                        position: 'absolute',
                        top: 83,
                        borderRadius: 5,
                        width: 335,
                        alignSelf: 'flex-end',
                        zIndex: 99
                      }}
                    >
                      <Text style={{fontSize: 20, color: '#030303'}}>
                        {i18n.t('product.record_serial_tooltip')}
                      </Text>
                      <View
                        style={{
                          position: 'absolute',
                          right: '11%',
                          top: -10,
                          borderLeftWidth: 10,
                          borderLeftColor: 'transparent',
                          borderRightWidth: 10,
                          borderRightColor: 'transparent',
                          borderBottomWidth: 10,
                          borderBottomColor: '#8d9fb1'
                        }}
                      />
                    </LinearGradient>
                  )}
                  {this.state.quickScanToolTip3 && (
                    <LinearGradient
                    testID='quicKScanToolTip3'
                      locations={[0, 1]}
                      colors={['#8a9daf', '#d7e1e9']}
                      style={{
                        padding: 10,
                        position: 'absolute',
                        top: 215,
                        borderRadius: 5,
                        width: 550,
                        alignSelf: 'flex-start',
                        zIndex: 99
                      }}
                    >
                      <Text style={{fontSize: 20, color: '#030303'}}>
                        {i18n.t('product.intangible_item_tooltip')}
                      </Text>
                      <View
                        style={{
                          position: 'absolute',
                          right: '57%',
                          top: -10,
                          borderLeftWidth: 10,
                          borderLeftColor: 'transparent',
                          borderRightWidth: 10,
                          borderRightColor: 'transparent',
                          borderBottomWidth: 10,
                          borderBottomColor: '#8d9fb1'
                        }}
                      />
                    </LinearGradient>
                  )}
                  {this.state.quickScanToolTip4 && (
                    <LinearGradient
                    testID='quicKScanToolTip4'
                      locations={[0, 1]}
                      colors={['#8a9daf', '#d7e1e9']}
                      style={{
                        padding: 10,
                        position: 'absolute',
                        top: 214,
                        right: '35%',
                        borderRadius: 5,
                        width: 335,
                        alignSelf: 'center',
                        zIndex: 99
                      }}
                    >
                      <Text style={{fontSize: 20, color: '#030303'}}>
                        {i18n.t('product.add_any_order_tooltip')}
                      </Text>
                      <View
                        style={{
                          position: 'absolute',
                          right: '29%',
                          top: -10,
                          borderLeftWidth: 10,
                          borderLeftColor: 'transparent',
                          borderRightWidth: 10,
                          borderRightColor: 'transparent',
                          borderBottomWidth: 10,
                          borderBottomColor: '#8d9fb1'
                        }}
                      />
                    </LinearGradient>
                  )}
                  {this.state.quickScanToolTip5 && (
                    <LinearGradient
                      testID='quicKScanToolTip5'
                      locations={[0, 1]}
                      colors={['#8a9daf', '#d7e1e9']}
                      style={{
                        padding: 10,
                        position: 'absolute',
                        top: 207,
                        borderRadius: 5,
                        width: 335,
                        alignSelf: 'flex-end',
                        zIndex: 99
                      }}
                    >
                      <Text style={{fontSize: 20, color: '#030303'}}>
                        {i18n.t('product.optional_item_tooltip')}
                      </Text>
                      <View
                        style={{
                          position: 'absolute',
                          right: '7%',
                          top: -10,
                          borderLeftWidth: 10,
                          borderLeftColor: 'transparent',
                          borderRightWidth: 10,
                          borderRightColor: 'transparent',
                          borderBottomWidth: 10,
                          borderBottomColor: '#8d9fb1'
                        }}
                      />
                    </LinearGradient>
                  )}
                  {this.state.quickScanToolTip6 && (
                    <LinearGradient
                      testID='quicKScanToolTip6'
                      locations={[0, 1]}
                      colors={['#8a9daf', '#d7e1e9']}
                      style={{
                        padding: 10,
                        position: 'absolute',
                        top: 266,
                        borderRadius: 5,
                        width: 335,
                        alignSelf: 'flex-start',
                        zIndex: 99
                      }}
                    >
                      <Text style={{fontSize: 20, color: '#030303'}}>
                        {i18n.t('product.packing_ins_tooltip')}
                      </Text>
                      <View
                        style={{
                          position: 'absolute',
                          right: '29%',
                          top: -10,
                          borderLeftWidth: 10,
                          borderLeftColor: 'transparent',
                          borderRightWidth: 10,
                          borderRightColor: 'transparent',
                          borderBottomWidth: 10,
                          borderBottomColor: '#8d9fb1'
                        }}
                      />
                    </LinearGradient>
                  )}
                  {this.state.quickScanToolTip7 && (
                    <LinearGradient
                      testID='quicKScanToolTip7'
                      locations={[0, 1]}
                      colors={['#8a9daf', '#d7e1e9']}
                      style={{
                        padding: 10,
                        position: 'absolute',
                        marginTop: '10%',
                        borderRadius: 5,
                        width: 660,
                        alignSelf: 'flex-start',
                        zIndex: 99
                      }}
                    >
                      <Text style={{fontSize: 20, color: '#030303'}}>
                        {i18n.t('product.scanning_seq_tooltip')}
                      </Text>
                      <View
                        style={{
                          position: 'absolute',
                          right: '38%',
                          bottom: -10,
                          borderLeftWidth: 10,
                          borderLeftColor: 'transparent',
                          borderRightWidth: 10,
                          borderRightColor: 'transparent',
                          borderTopWidth: 10,
                          borderTopColor: '#8d9fb1'
                        }}
                      />
                    </LinearGradient>
                  )}
                  {this.state.quickScanToolTip8 && (
                    <LinearGradient
                      testID='quicKScanToolTip8'
                      locations={[0, 1]}
                      colors={['#8a9daf', '#d7e1e9']}
                      style={{
                        padding: 10,
                        position: 'absolute',
                        marginTop: '15%',
                        borderRadius: 5,
                        width: 335,
                        alignSelf: 'flex-start',
                        zIndex: 99,
                        marginLeft: 148
                      }}
                    >
                      <Text style={{fontSize: 20, color: '#030303'}}>
                        {i18n.t('product.custom_prod1_tooltip')}
                      </Text>
                      <View
                        style={{
                          position: 'absolute',
                          right: '15%',
                          bottom: -10,
                          borderLeftWidth: 10,
                          borderLeftColor: 'transparent',
                          borderRightWidth: 10,
                          borderRightColor: 'transparent',
                          borderTopWidth: 10,
                          borderTopColor: '#8d9fb1'
                        }}
                      />
                    </LinearGradient>
                  )}
                  {this.state.dropDownToggleState && (
                    <View
                      style={{
                        position: 'absolute',
                        top: 104,
                        zIndex: 1,
                        width: 184,
                        left: 31,
                        backgroundColor: 'rgba(45, 68, 95,1)',
                        borderRadius: 12
                      }}
                    >
                      {this.props.pickerSelectedOptions.map((option, index) => {
                        return (
                          <View key={index}>
                            <TouchableOpacity
                              testID='clickScanOption'
                              onPress={() => {
                                this.props.show === true
                                  ? this.permissionCheck()
                                  : this.clickScandrop(option.value, index);
                              }}
                              style={{
                                marginBottom: 10,
                                marginTop: 10,
                                justifyContent: 'center',
                                backgroundColor:
                                  index == clickScanIndex ? '#4b5f76' : ''
                              }}
                            >
                              <Text
                                style={{
                                  fontFamily: 'Poppins_400Regular',
                                  fontSize: 14,
                                  color: '#fff',
                                  marginLeft: 10
                                }}
                              >
                                {option.label}
                              </Text>
                            </TouchableOpacity>
                          </View>
                        );
                      })}
                    </View>
                  )}

                  {this.state.dropDownTypeInToggle && (
                    <View
                      style={{
                        position: 'absolute',
                        alignSelf: 'center',
                        top: 104,
                        zIndex: 1,
                        width: 184,
                        backgroundColor: 'rgba(45, 68, 95, 1)',
                        borderRadius: 12
                      }}
                    >
                      {this.props.pickerSelectedOptions.map((option, index) => {
                        return (
                          <View key={index}>
                            <TouchableOpacity
                             testID='typeInCountOption'
                              onPress={() => {
                                this.props.show === true
                                  ? this.permissionCheck()
                                  : this.typeInCountDrop(option.value, index);
                              }}
                              style={{
                                marginBottom: 10,
                                marginTop: 10,
                                justifyContent: 'center',
                                backgroundColor:
                                  index == typeInCountIndex ? '#4b5f76' : ''
                              }}
                            >
                              <Text
                                style={{
                                  fontFamily: 'Poppins_400Regular',
                                  fontSize: 14,
                                  color: '#fff',
                                  marginLeft: 10
                                }}
                              >
                                {option.label}
                              </Text>
                            </TouchableOpacity>
                          </View>
                        );
                      })}
                    </View>
                  )}

                  {this.state.dropDownRecordSerial && (
                    <View
                      style={{
                        position: 'absolute',
                        alignSelf: 'flex-end',
                        top: 104,
                        zIndex: 1,
                        width: 174,
                        backgroundColor: 'rgba(45, 68, 95,1)',
                        borderRadius: 12
                      }}
                    >
                      {this.props.pickerSelectedRecordOptions.map(
                        (option, index) => {
                          return (
                            <TouchableOpacity
                              testID='serialRecordOption'
                              // onPress={() => this.updateSerialRecord(option.value, index)}
                              onPress={() => {
                                this.props.show === true
                                  ? this.permissionCheck()
                                  : this.updateSerialRecord(
                                      option.value,
                                      index
                                    );
                                // this.props.userRole.role.add_edit_products ?
                                //   this.updateSerialRecord(option.value, index) : <></>
                              }}
                              style={{
                                marginBottom: 10,
                                marginTop: 10,
                                justifyContent: 'center',
                                backgroundColor:
                                  index == recordSerialIndex ? '#4b5f76' : ''
                              }}
                            >
                              <Text
                                style={{
                                  fontFamily: 'Poppins_400Regular',
                                  fontSize: 14,
                                  color: '#fff',
                                  marginLeft: 10
                                }}
                              >
                                {option.label}
                              </Text>
                            </TouchableOpacity>
                          );
                        }
                      )}
                    </View>
                  )}
                  <View style={{margin: 10, justifyContent: 'center'}}>
                    <View style={{flexDirection: 'row'}}>
                      <View
                        style={{
                          flex: 2,
                          flexDirection: 'column',
                          justifyContent: 'flex-start',
                          alignItems: 'flex-start',
                          marginTop: '2%'
                        }}
                      >
                        <View style={{flexDirection: 'row'}}>
                          <Text
                            style={{
                              fontFamily: 'Poppins_600SemiBold',
                              fontSize: 18,
                              flex: 2,
                              padding: 10,
                              color: '#fff'
                            }}
                          >
                            {i18n.t('product.click_scan')}
                          </Text>
                          <TouchableOpacity
                            testID='tooltip0'
                            onPress={() => this.toolTipFun('0')}
                            style={{height: 30, width: 30, marginTop: 15}}
                          >
                            <i
                              className="icon-info-circled"
                              style={{
                                color: 'rgb(172,187,199)',
                                fontSize: '17px'
                              }}
                            ></i>
                          </TouchableOpacity>
                        </View>

                        <View style={{marginTop: -10, marginLeft: 10}}>
                          <View>
                            <TouchableOpacity
                              testID='clickScanDropDown'
                              onPress={() => this.dropDownToggle()}
                            >
                              <View style={[styles.mainContainer]}>
                                <View
                                  style={[
                                    styles.dropDownToggleStyle,
                                    {flexDirection: 'row'}
                                  ]}
                                >
                                  <Text
                                    style={{
                                      color: '#fff',
                                      fontFamily: 'Poppins_400Regular'
                                    }}
                                  >
                                    {this.state.clickScanstate}
                                  </Text>
                                  <i
                                    class="icon-down-open"
                                    style={{color: 'rgb(205,205,205)'}}
                                  />
                                </View>
                              </View>
                            </TouchableOpacity>
                          </View>
                        </View>
                      </View>

                      <View
                        style={{
                          flex: 2,
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginTop: '2%'
                        }}
                      >
                        <View style={{flexDirection: 'row'}}>
                          <Text
                            style={{
                              fontFamily: 'Poppins_600SemiBold',
                              fontSize: 18,
                              flex: 2,
                              padding: 10,
                              color: '#fff'
                            }}
                          >
                            {i18n.t('product.type_count')}
                          </Text>
                          <TouchableOpacity
                            testID='tooltip1'
                            onPress={() => this.toolTipFun('1')}
                            style={{height: 30, width: 30, marginTop: 10}}
                          >
                            <i
                              className="icon-info-circled"
                              style={{
                                color: 'rgb(172,187,199)',
                                marginTop: '5px',
                                fontSize: '17px'
                              }}
                            ></i>
                          </TouchableOpacity>
                        </View>
                        <View style={{marginTop: -10}}>
                          <View>
                            <TouchableOpacity
                              testID='typeInDropDown'
                              onPress={() => this.dropDownToggleTypeIn()}
                            >
                              <View style={[styles.mainContainer]}>
                                <View
                                  style={[
                                    styles.dropDownToggleStyle,
                                    {flexDirection: 'row'}
                                  ]}
                                >
                                  <Text
                                    style={{
                                      color: '#fff',
                                      fontFamily: 'Poppins_400Regular'
                                    }}
                                  >
                                    {this.state.typeInCountState}
                                  </Text>
                                  <i
                                    class="icon-down-open"
                                    style={{color: 'rgb(205,205,205)'}}
                                  />
                                </View>
                              </View>
                            </TouchableOpacity>
                          </View>
                        </View>
                      </View>
                      <View
                        style={{
                          flex: 2,
                          flexDirection: 'column',
                          justifyContent: 'flex-end',
                          alignItems: 'flex-end',
                          right: '1%'
                        }}
                      >
                        <View style={{flexDirection: 'row'}}>
                          <Text
                            style={{
                              fontFamily: 'Poppins_600SemiBold',
                              fontSize: 18,
                              flex: 2,
                              padding: 10,
                              color: '#fff'
                            }}
                          >
                            {i18n.t('product.record_serial')}
                          </Text>
                          <TouchableOpacity
                            testID='tooltip2'
                            onPress={() => this.toolTipFun('2')}
                            style={{height: 30, width: 30, marginTop: 10}}
                          >
                            <i
                              className="icon-info-circled"
                              style={{
                                color: 'rgb(172,187,199)',
                                marginTop: '6px',
                                fontSize: '17px'
                              }}
                            ></i>
                          </TouchableOpacity>
                        </View>
                        <View style={{marginTop: -10, marginRight: 10}}>
                          <View>
                            <TouchableOpacity
                              testID='serialRecordDropDown'
                              onPress={() => this.dropDownToggleRecord()}
                            >
                              <View style={[styles.mainContainer]}>
                                <View
                                  style={[
                                    styles.dropDownToggleStyle,
                                    {flexDirection: 'row'}
                                  ]}
                                >
                                  <Text
                                    style={{
                                      color: '#fff',
                                      fontFamily: 'Poppins_400Regular'
                                    }}
                                  >
                                    {this.state.recordSerialState}
                                  </Text>
                                  <i
                                    class="icon-down-open"
                                    style={{color: 'rgb(205,205,205)'}}
                                  />
                                </View>
                              </View>
                            </TouchableOpacity>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View style={{margin: 15, justifyContent: 'center'}}>
                    <View style={{flexDirection: 'row', marginTop: '3%'}}>
                      <View
                        style={{
                          flex: 2,
                          flexDirection: 'row',
                          justifyContent: 'flex-start'
                        }}
                      >
                        <Switch
                          checked={basicinfo.is_intangible}
                          disabled={item_intan}
                          onChange={(value, name) => {
                            this.props.show === true
                              ? this.permissionCheck()
                              : this.props.productInfoHandleChange(
                                  value,
                                  'is_intangible'
                                );
                          }}
                          offColor="#5c6e79"
                          onColor="#65baea"
                          offHandleColor="#969696"
                          onHandleColor="#fbfdfe"
                          handleDiameter={22}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          height={20}
                          width={40}
                        />

                        <Text
                          style={{
                            marginLeft: 10,
                            color: '#fff',
                            marginTop: -4,
                            fontFamily: 'Poppins_600SemiBold',
                            fontSize: 18
                          }}
                        >
                          {i18n.t('product.intangible_item')}
                        </Text>
                        <TouchableOpacity 
                          testID='tooltip3'
                          onPress={() => this.toolTipFun('3')}
                        >
                          <i
                            className="icon-info-circled"
                            style={{
                              color: 'rgb(172,187,199)',
                              marginTop: '4',
                              marginLeft: '9px',
                              fontSize: '17px'
                            }}
                          ></i>
                        </TouchableOpacity>
                      </View>
                      <View
                        style={{
                          flex: 2,
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                          right: '0.5%'
                        }}
                      >
                        <Switch
                          checked={basicinfo.is_skippable}
                          disabled={skippable}
                          onChange={(value, name) => {
                            this.props.show === true
                              ? this.permissionCheck()
                              : this.props.productInfoHandleChange(
                                  value,
                                  'is_skippable'
                                );
                          }}
                          offColor="#5c6e79"
                          onColor="#65baea"
                          offHandleColor="#969696"
                          onHandleColor="#fbfdfe"
                          handleDiameter={22}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          height={20}
                          width={40}
                        />
                        <Text
                          style={{
                            marginLeft: 10,
                            marginTop: -4,
                            color: '#fff',
                            fontFamily: 'Poppins_600SemiBold',
                            fontSize: 18
                          }}
                        >
                          {i18n.t('product.optional_item')}
                        </Text>
                        <TouchableOpacity 
                          testID='tooltip5'
                          onPress={() => this.toolTipFun('5')}
                        >
                          <i
                            className="icon-info-circled"
                            style={{
                              color: 'rgb(172,187,199)',
                              marginTop: '2',
                              marginLeft: '8px',
                              fontSize: '17px'
                            }}
                          ></i>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                  <View style={{margin: 10}}>
                    <View
                      style={{
                        flexDirection: 'row',
                        marginBottom: -5,
                        marginLeft: '1%'
                      }}
                    >
                      <View
                        style={{
                          flexDirection: 'row',
                          alignSelf: 'flex-start',
                          justifyContent: 'center',
                          marginBottom: -10
                        }}
                      >
                        <Text
                          style={{
                            color: '#fff',
                            fontFamily: 'Poppins_600SemiBold',
                            fontSize: 18
                          }}
                        >
                          {i18n.t('product.packing_ins')}
                        </Text>
                        <TouchableOpacity
                          testID='tooltip6'
                          onPress={() => this.toolTipFun('6')}
                          style={{marginTop: '6px', marginLeft: '6px'}}
                        >
                          <i
                            className="icon-info-circled"
                            style={{
                              color: 'rgb(172,187,199)',
                              fontSize: '17px'
                            }}
                          ></i>
                        </TouchableOpacity>
                        <View
                        style={{marginLeft: 25}}
                        >
                          <Switch
                            testID='packingInstructionSwitchDesktop'
                            checked={this.state.isDisabled ? true : basicinfo.packing_instructions_conf}
                            disabled={this.state.isDisabled}
                            onChange={(value, name) => {
                              this.props.show === true
                                ? this.permissionCheck()
                                : this.props.productInfoHandleChange(
                                  value,
                                  'packing_instructions_conf'
                                );
                            }}
                            offColor="#5c6e79"
                            onColor="#65baea"
                            offHandleColor="#969696"
                            onHandleColor="#fbfdfe"
                            handleDiameter={22}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={20}
                            width={40}
                          />
                        </View>
                        <Text
                            testID='packingInstructionText'
                            style={{
                              color: '#fff',
                              fontFamily: 'Poppins_600SemiBold',
                              fontSize: 18,
                              marginLeft: '1rem'
                            }}
                          >
                            {i18n.t('product.require_conf')}
                        </Text>
                        <View
                          style={{marginLeft: 25}}
                        >
                            <TouchableOpacity
                            onPress={() => this.htmlViewtoggle()}
                            testID='showHtml'
                          >
                            <Text
                              style={{
                                color: '#fff',
                                fontFamily: 'Poppins_600SemiBold',
                                fontSize: 18
                              }}
                            >
                              {this.state.showHtml ? 'Edit HTML' : 'Done Editing'}
                            </Text>
                          </TouchableOpacity>
                        </View>
                        <View>
                        <Tooltip tooltipText={'Add Image'} position={'right'}>
                          <TouchableOpacity 
                            testID='pickImage'
                            onPress={() => this.pickImage()}
                            style={{marginBottom: '9px'}}
                          >
                            <FontAwesome name="picture-o"
                              className="icon-picture"
                              style={{
                                color: 'white',
                                marginTop: 3,
                                marginLeft: 7,
                                fontSize: '20px'
                              }}
                            ></FontAwesome>
                          </TouchableOpacity>
                        </Tooltip>
                        </View>
                      </View>
                      <View
                        style={{
                          flex: 2,
                          alignSelf: 'flex-end',
                          justifyContent: 'center',
                          alignItems: 'flex-end',
                          right: '1%'
                        }}
                      >
                        <TouchableOpacity
                          //  onPress={() => this.showEditorFun()}
                          testID='showEditorFun'
                          onPress={() => {
                            this.props.show === true
                              ? this.permissionCheck()
                              : this.showEditorFun();
                            // this.props.userRole.role.add_edit_products ?
                            //   this.showEditorFun() : <></>
                          }}
                        >
                          <Text
                            style={{
                              color: '#fff',
                              fontFamily: 'Poppins_600SemiBold',
                              fontSize: 18,
                              display: 'none'
                            }}
                          >
                            HTML
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                    <View style={{top: '2%'}}>
                      {this.state.showEditor ? (
                        <View style={{marginTop: 5}}>
                          <Editor
                            editorState={this.state.editorState}
                            initialContentState={basicinfo.packing_instructions}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={(text, name) =>
                              this.onEditorStateChange(text)
                            }
                            onContentStateChange={text =>
                              this.props.productInfoHandleChange(
                                text.blocks[0].text,
                                'packing_instructions'
                              )
                            }
                            textAlignment="left"
                          />
                        </View>
                      ) : (
                        this.state.showHtml ? (
                          <TouchableOpacity
                          testID='HtmlView'
                          onPress={this.htmlViewtoggle}
                          style={{
                            margin: 10,
                            borderRadius: 15,
                            padding: 10,
                            backgroundColor: isHovered ? 'white' : 'black', // Change background color on hover
                            fontFamily: 'Poppins_500Medium',
                            fontSize: 15
                          }}
                          underlayColor="transparent" // To prevent the underlay color change
                          onMouseEnter={this.handleMouseEnter} // Handle mouse enter event
                          onMouseLeave={this.handleMouseLeave} // Handle mouse leave event
                        >
                          <View>
                            {!basicinfo.packing_instructions ? (
                              <Text style={{ color: isHovered ? 'black' : 'gray' }}>Click here to edit packing instructions (HTML accepted).</Text>
                            ) : (
                              <HTML
                                html={`<div style="color: white; font-size: 27px; line-height: 1.2; margin-top: 10px; margin-bottom: 10px">${basicinfo.packing_instructions}</div>`}
                                imagesMaxWidth={this.state.windowWidth / 2}
                              />
                            )}
                          </View>
                        </TouchableOpacity>
                        ):(
                          <TextInput
                          multiline={true}
                          ref={this.textInputRef}
                          numberOfLines={4}
                          editable={packing_inst}
                          style={{
                            margin: 10,
                            borderRadius: 15,
                            padding: 10,
                            backgroundColor: '#dbdada',
                            fontFamily: 'Poppins_500Medium',
                            fontSize: 15
                          }}
                          value={basicinfo.packing_instructions}
                          onChangeText={(text, name) => {
                            this.props.show === true
                              ? this.permissionCheck()
                              : this.props.productInfoHandleChange(
                                  text,
                                  'packing_instructions'
                                );
                          }}
                        />
                        )
                      )}
                    </View>
                  </View>
                  <View style={{marginLeft: 40}}>
                    <View style={{flexDirection: 'row'}}>
                      <TextInput
                      testID='scanning_sequence'
                      style={{
                        width: 50,
                        height: 30,
                        borderRadius: 5,
                        backgroundColor: '#dbdada',
                        marginTop: 7,
                        textAlign: 'center'
                      }}
                        value={this.state.scanSeqcount}
                        onChangeText={(text) =>
                          this.props.show === true
                          ? this.permissionCheck()
                          : this.setCount(text)
                        }
                      />
                      <View
                        style={{
                          flexDirection: 'column',
                          marginTop: 5,
                          marginLeft: 5,
                          marginRight: 5
                        }}
                      >
                        <TouchableOpacity
                          testID='countInc'
                          style={{marginBottom: -5}}
                          onPress={() => {
                            this.props.show === true
                              ? this.permissionCheck()
                              : this.countIncDec('Inc');
                          }}
                        >
                          <FontAwesomeIcon
                            style={{
                              color: '#cdcdcd',
                              height: '20',
                              width: '15'
                            }}
                            icon={faCaretUp}
                          />
                        </TouchableOpacity>
                        <TouchableOpacity
                          testID='countDec'
                          style={{marginTop: -5}}
                          onPress={() =>
                            this.props.show === true
                              ? this.permissionCheck()
                              : this.countIncDec('Dec')
                          }
                        >
                          <FontAwesomeIcon
                            style={{
                              color: '#cdcdcd',
                              height: '20',
                              width: '15'
                            }}
                            icon={faCaretDown}
                          />
                        </TouchableOpacity>
                      </View>
                      <View
                        style={{
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <Text
                          style={{
                            color: '#fff',
                            fontFamily: 'Poppins_600SemiBold',
                            fontSize: 18
                          }}
                        >
                          {i18n.t('product.scanning_seq')}
                        </Text>
                      </View>
                      <View>
                        <TouchableOpacity 
                          testID='tooltip7'
                          onPress={() => this.toolTipFun('7')}
                        >
                          <i
                            className="icon-info-circled"
                            style={{
                              color: 'rgb(172,187,199)',
                              marginTop: 10,
                              marginLeft: 7,
                              fontSize: '17px'
                            }}
                          ></i>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                  <View>
                    <View
                      style={{
                        flexDirection: 'row',
                        margin: 15,
                        width: '100%'
                      }}
                    >
                      <View
                        style={{
                          flexDirection: 'row',
                          width: '33%',
                          alignSelf: 'flex-start'
                        }}
                      >
                        <View style={{flexDirection: 'column', width: '70%'}}>
                          <Text
                            style={{
                              color: '#fff',
                              fontFamily: 'Poppins_600SemiBold',
                              fontSize: 18
                            }}
                          >
                            {i18n.t('product.custom_prod1')}
                          </Text>
                          <TextInput
                            editable={cust_prod1}
                            style={{
                              width: '100%',
                              borderRadius: 10,
                              padding: 10,
                              backgroundColor: '#dbdada',
                              fontFamily: 'Poppins_500Medium',
                              fontSize: 15
                            }}
                            value={basicinfo.custom_product_1}
                            onChangeText={(text, name) => {
                              this.props.show === true
                                ? this.permissionCheck()
                                : this.props.productInfoHandleChange(
                                    text,
                                    'custom_product_1'
                                  );
                            }}
                          />
                        </View>
                        <View
                          style={{
                            flexDirection: 'column',
                            marginLeft: 10,
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <Text
                            style={{
                              color: '#fff',
                              fontFamily: 'Poppins_600SemiBold',
                              fontSize: 18
                            }}
                          >
                            {i18n.t('product.display')}
                          </Text>
                          <Switch
                            checked={basicinfo.custom_product_display_1}
                            disabled={dis1}
                            onChange={(value, name) => {
                              this.props.show === true
                                ? this.permissionCheck()
                                : this.props.productInfoHandleChange(
                                    value,
                                    'custom_product_display_1'
                                  );
                            }}
                            offColor="#5c6e79"
                            onColor="#65baea"
                            offHandleColor="#969696"
                            onHandleColor="#fbfdfe"
                            handleDiameter={22}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={20}
                            width={40}
                          />
                        </View>
                        <View
                          style={{
                            marginTop: 32,
                            left: -5,
                            height: 30,
                            width: 30
                          }}
                        >
                          <TouchableOpacity
                            testID='tooltip8'
                            onPress={() => this.toolTipFun('8')}
                          >
                            <i
                              className="icon-info-circled"
                              style={{
                                color: 'rgb(172,187,199)',
                                fontSize: '17px'
                              }}
                            ></i>
                          </TouchableOpacity>
                        </View>
                      </View>
                      <View
                        style={{
                          flexDirection: 'row',
                          flex: 2,
                          alignSelf: 'center'
                        }}
                      >
                        <View style={{flexDirection: 'column', width: '70%'}}>
                          <Text
                            style={{
                              color: '#fff',
                              fontFamily: 'Poppins_600SemiBold',
                              fontSize: 18
                            }}
                          >
                            {i18n.t('product.custom_prod2')}
                          </Text>
                          <TextInput
                            editable={cust_prod2}
                            style={{
                              borderRadius: 10,
                              padding: 10,
                              backgroundColor: '#dbdada',
                              fontFamily: 'Poppins_500Medium',
                              fontSize: 15
                            }}
                            value={basicinfo.custom_product_2}
                            onChangeText={(text, name) => {
                              this.props.show === true
                                ? this.permissionCheck()
                                : this.props.productInfoHandleChange(
                                    text,
                                    'custom_product_2'
                                  );
                            }}
                          />
                        </View>
                        <View
                          style={{
                            flexDirection: 'column',
                            marginLeft: 10,
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <Text
                            style={{
                              color: '#fff',
                              fontFamily: 'Poppins_600SemiBold',
                              fontSize: 18
                            }}
                          >
                            {i18n.t('product.display')}
                          </Text>
                          <Switch
                            checked={basicinfo.custom_product_display_2}
                            disabled={dis2}
                            onChange={(value, name) => {
                              this.props.show === true
                                ? this.permissionCheck()
                                : this.props.productInfoHandleChange(
                                    value,
                                    'custom_product_display_2'
                                  );
                            }}
                            offColor="#5c6e79"
                            onColor="#65baea"
                            offHandleColor="#969696"
                            onHandleColor="#fbfdfe"
                            handleDiameter={22}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={20}
                            width={40}
                          />
                        </View>
                      </View>
                      <View
                        style={{
                          flexDirection: 'row',
                          flex: 2,
                          alignSelf: 'flex-end'
                        }}
                      >
                        <View style={{flexDirection: 'column', width: '70%'}}>
                          <Text
                            style={{
                              color: '#fff',
                              fontFamily: 'Poppins_600SemiBold',
                              fontSize: 18
                            }}
                          >
                            {i18n.t('product.custom_prod3')}
                          </Text>
                          <TextInput
                            editable={cust_prod3}
                            style={{
                              borderRadius: 10,
                              padding: 10,
                              backgroundColor: '#dbdada',
                              fontFamily: 'Poppins_500Medium',
                              fontSize: 15
                            }}
                            value={basicinfo.custom_product_3}
                            onChangeText={(text, name) => {
                              this.props.show === true
                                ? this.permissionCheck()
                                : this.props.productInfoHandleChange(
                                    text,
                                    'custom_product_3'
                                  );
                            }}
                          />
                        </View>
                        <View
                          style={{
                            flexDirection: 'column',
                            marginLeft: 10,
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <Text
                            style={{
                              color: '#fff',
                              fontFamily: 'Poppins_600SemiBold',
                              fontSize: 18
                            }}
                          >
                            {i18n.t('product.display')}
                          </Text>
                          <Switch
                            checked={basicinfo.custom_product_display_3}
                            disabled={dis3}
                            onChange={(value, name) => {
                              this.props.show === true
                                ? this.permissionCheck()
                                : this.props.productInfoHandleChange(
                                    value,
                                    'custom_product_display_3'
                                  );
                            }}
                            offColor="#5c6e79"
                            onColor="#65baea"
                            offHandleColor="#969696"
                            onHandleColor="#fbfdfe"
                            handleDiameter={22}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={20}
                            width={40}
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </>
            )}
          </>
        ) : (
          //Mobile View here

          <ScanPackOptionMobileView
            {...this.props}
            permissionCheck={() => {
              this.permissionCheck();
            }}
            typeInCountDrop={(itemValue, index) => {
              this.typeInCountDrop(itemValue, index);
            }}
            clickScandrop={(itemValue, index) => {
              this.clickScandrop(itemValue, index);
            }}
            updateSerialRecord={(itemValue, index) => {
              this.updateSerialRecord(itemValue, index);
            }}
            countIncDec={check => {
              this.countIncDec(check);
            }}
            setCount={value => {
              this.setCount(value);
            }}
            dropDownToggle={() => {
              this.dropDownToggle();
            }}
            dropDownToggleTypeIn={() => {
              this.dropDownToggleTypeIn();
            }}
            dropDownToggleRecord={() => {
              this.dropDownToggleRecord();
            }}
            showEditorFun={() => {
              this.showEditorFun();
            }}
            onEditorStateChange={() => {
              this.onEditorStateChange();
            }}
            toolTipFunShowMobile={open => {
              this.toolTipFunShowMobile(open);
            }}
            toolTipFunHideMobile={open => {
              this.toolTipFunHideMobile(open);
            }}
            htmlViewtoggle ={() =>{ 
              this.htmlViewtoggle();
            }}
            handleImagePicker ={() =>{ 
              this.pickImage();
            }}
            quickScanToolTip={this.state.quickScanToolTip}
            quickScanToolTip1={this.state.quickScanToolTip1}
            quickScanToolTip2={this.state.quickScanToolTip2}
            quickScanToolTip3={this.state.quickScanToolTip3}
            quickScanToolTip4={this.state.quickScanToolTip4}
            quickScanToolTip5={this.state.quickScanToolTip5}
            quickScanToolTip6={this.state.quickScanToolTip6}
            quickScanToolTip7={this.state.quickScanToolTip7}
            quickScanToolTip8={this.state.quickScanToolTip8}
            windowWidth={this.state.windowWidth}
            dropDownToggleState={this.state.dropDownToggleState}
            dropDownTypeInToggle={this.state.dropDownTypeInToggle}
            dropDownRecordSerial={this.state.dropDownRecordSerial}
            clickScanstate={this.state.clickScanstate}
            typeInCountState={this.state.typeInCountState}
            recordSerialState={this.state.recordSerialState}
            showEditor={this.state.showEditor}
            editorState={this.state.editorState}
            scanSeqcount={this.state.scanSeqcount}
            item_intan={item_intan}
            any_Order={any_Order}
            skippable={skippable}
            packing_inst={packing_inst}
            cust_prod1={cust_prod1}
            cust_prod2={cust_prod2}
            cust_prod3={cust_prod3}
            dis1={dis1}
            dis2={dis2}
            dis3={dis3}
            typeInCountIndex={typeInCountIndex}
            recordSerialIndex={recordSerialIndex}
            isDisabled={this.state.isDisabled}
            showHtml={this.state.showHtml}
            textInputRef={this.textInputRef}
          />
        )}
      </View>
    );
  }
}

const mapStateToProps = state => {
  return {
    addHtmlImage: state.product.addHtmlImage,
  };
};

const mapDispatchToProps = {
  uploadHtmlImage
};

export default connect(mapStateToProps, mapDispatchToProps)(ScanpackOptions);
