import React, { Component, useRef } from 'react';
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  ScrollView,
  Image,
  Dimensions,
  ImageBackground
} from 'react-native';
import styles from '../../style/scanpack';
import globalStyles from '../../style/global';
import promptStyle from '../../style/popupmodel'
import './addBar.css';
import { LinearGradient } from 'expo-linear-gradient';
import i18n from 'i18n-js';
import DefaultInput from '../../commons/Inputs/defaultInput';
import StyledInput from '../../commons/Inputs/stylesInput';
import commonStyles from '../../style/commonStyles';
export default class ConfirmTrackingNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: Dimensions.get('window').width
    };
    this.trackingFocus = React.createRef();
  }

  componentDidUpdate() {
    this.trackingFocus?.current?.focus();
  }
  updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentWillUnmount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    const keyDownHandler = event => {
      if (event.key === 'Enter' && !this.props.order.order.tracking_num) {
        this.props.onSubmitEditing();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
  }
  onSubmitFunc = () => {
    this.props.onSubmitEditing();
  };
  render() {
    return (
      <>
        <ImageBackground
          style={{ flex: 1 }}
          source={require('././../../../assets/background.png')}
        >
          <View
            style={globalStyles.confirmTrackingNumber}
          >
            <Text
              style={[commonStyles.trackingNumberText, globalStyles.trackingNumberTextExtra]}
            >
              {' '}
              {this?.props?.postScanningFieldLabel}{' '}
            </Text>
            <Text
              style={[commonStyles.trackingNumberText, globalStyles.trackingNumberTextSemi]}
            >
              Order #{this.props.postScanningOrderNumber}
            </Text>
          </View>
          <View>
            {this.props.statecheck === false ? (
              <View
                style={[commonStyles.viewStyle,{ textAlign: 'center' }]}
              >
                {!this.props.alertBoxTracking && !this.props.alertBoxShipping && (
                  <LinearGradient
                    testID='postScanningMessage'
                    locations={[0, 1]}
                    start={{ x: 0, y: 1 }}
                    end={{ x: 0, y: 0 }}
                    colors={['#d47d02', '#ffb636']}
                    style={[promptStyle.promptModel, { width: this.state.windowWidth >= 600 ? '60%' : '81%', left: this.state.windowWidth >= 600 ? '20%' : '10%', }]}
                  >
                    <View style={globalStyles.flexDirectionRow}>
                      <Text style={[styles.scanRecordCountText, {}]}>
                        {this?.props?.postScanningMessageDetail}
                      </Text>
                    </View>
                    <View
                      style={[commonStyles.viewStyle,{ alignItems: 'center' }]}
                    >
                      <View
                        style={globalStyles.trackingNumberView}>
                        <StyledInput
                          {...this.props}
                          placeholder="Scan"
                          name="trackingOrder"
                          autoFocus={true}
                          className="borderInput"
                          ref={this.trackingFocus}
                          value={this.props.trackingOrderInput}
                          onChangeText={this.props.onChangeText}
                          onSubmitEditing={this.props.onSubmitEditing}
                          style={[styles.inputBoxTracking, { width: '100%' }]}
                          inputStyle={{height: '100%',fontSize: 15,padding: 10}}
                          useInputStyle= {false}
                        />
                      </View>
                      <TouchableOpacity
                        testID='enterButton'
                        style={[promptStyle.promptButton]}
                        onPress={() => {
                          this.onSubmitFunc();
                        }}
                      >
                        <Text>Enter</Text>
                      </TouchableOpacity>
                    </View>
                  </LinearGradient>
                )}
              </View>
            ) : (
              <View
                style={[commonStyles.viewStyle,{ textAlign: 'center' }]}
              >
                {!this.props.alertBoxTracking && !this.props.alertBoxShipping && (
                  <LinearGradient
                    locations={[0, 1]}
                    start={{ x: 0, y: 1 }}
                    end={{ x: 0, y: 0 }}
                    colors={['#d47d02', '#ffb636']}
                    style={[promptStyle.promptModel, { width: this.state.windowWidth >= 600 ? '60%' : '81%', left: this.state.windowWidth >= 600 ? '20%' : '10%', }]}
                  >
                    <Text
                      style={[globalStyles.trackingNumberTextSemi,{ fontSize: 20 }]}
                    >
                      {i18n.t('popupTracking.noTracingFound')}
                    </Text>
                    <View style={globalStyles.flexDirectionRow}>
                      <Text style={[styles.scanRecordCountText, {}]}>
                        {this?.props?.postScanningMessageDetail}
                      </Text>
                    </View>
                    <TouchableOpacity
                      testID='enterButton'
                      style={[promptStyle.promptButton]}
                      onPress={() => {
                        this.onSubmitFunc();
                      }}
                    >
                      <Text>Enter</Text>
                    </TouchableOpacity>
                  </LinearGradient>
                )}
              </View>
            )}
          </View>
        </ImageBackground>
      </>
    );
  }
}
