import React,{forwardRef} from 'react';
import { TextInput,Platform, Dimensions } from 'react-native';

const DefaultInput = (props,ref) => {
  const width = Dimensions.get('window').width;
  let blurOnSubmit = window.navigator.platform === 'iPhone' || Platform.OS === 'ios' ? false : true
  let onSubmitCalled = false;
  const handleKeyPress = (e) =>{
    if(e.key === 'Enter'){
      if(width < 900 && !(window.navigator.platform === 'iPhone' || Platform.OS === 'ios')){
        props.onSubmitEditing();
        onSubmitCalled = true;
      }
    }
  }
  return(
    <TextInput
     ref={ref}
     value={props.value}
     onChangeText={props.onChangeText}
     onSubmitEditing={()=>{
      !onSubmitCalled && props.onSubmitEditing()
     }}
     blurOnSubmit={blurOnSubmit}
     onFocus={props.onFocus}
     autoFocus={false}
     returnKeyType='done'
     placeholder={props.placeholder}
     placeholderTextColor={props.placeholderTextColor}
     onBlur={props.onBlur}
     autoCapitalize='none'
     onKeyPress={handleKeyPress}
     style={props.style}
    />
  )
}
export default forwardRef(DefaultInput);