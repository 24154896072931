import React from "react";
import { View, Text } from "react-native";
import moment from "moment";
import globalStyles from "../style/global";
import styles from "../style/scanpack";

const DisplayLogs = ({ logs, sliceNum ,checkMultiBoxActive}) => {  
  return (
    <React.Fragment>
      {logs?.length > 0 &&
        logs.slice(0, sliceNum).map((log, index) => {
          return (
            <View
              testID="logsContainer"
              key={index}
              style={[globalStyles.flexDirectionRow, styles.logsContainer]}
            >
              <View style={styles.actionBox}>
                <Text style={[styles.logDate, styles.logActionTextDesktop]}>
                  {moment(log.time).format("MMMM Do YYYY, h:mm:ss a")}
                </Text>
                {log.event === "regular" && (
                  <View>
                    {log.Log_count !== "" ? (
                      <View>
                        <Message
                          label={`Qty ${log.Log_count} of SKU: ${
                            log.SKU
                          } was scanned with barcode: ${log.input} scanned ${checkMultiBoxActive ?`in ${log.box_name}` :""}  by: ${
                            log.name
                          } ${log.on_ex ?? ""}`}
                          id={"regularLog"}
                        />
                      </View>
                    ) : log.actionBarcode && log.input !== 'REMOVE' && log.input !== 'SKIP' ? (
                      <Message
                        label={`Product with barcode: ${log.input} and sku: ${
                          log.SKU
                        } scanned - by: ${log.name} ${log.on_ex ?? ""}`}
                      />
                    ) : log.input === 'REMOVE' && log.actionBarcode ? (
                      <Message
                        label={`Item with sku: ${ log.SKU } removed using REMOVE barcode - by: ${log.name} ${log.on_ex ?? ""}`}
                      /> 
                    ) : log.input === 'SKIP' && log.actionBarcode ? (
                        <Message
                        testID="skiplog"
                        label={`Qty ${log.qty_rem} of SKU ${
                          log.SKU
                          } ${log.removeSkipableItem ? "skipped & removed" : "skipped"} with the SKIP barcode - by: ${log.name} ${log.on_ex ?? ""} `}
                        />
                    ) : (
                      <Text
                        testID="invalidScanLog"
                        style={[styles.logAction, styles.logActionTextDesktop]}
                      >
                        INVALID SCAN - Barcode:{" "}
                        <Text
                          style={[
                            styles.logAction,
                            styles.logActionText,
                            { fontStyle: "italic" },
                          ]}
                        >
                          {log.input}
                        </Text>
                        {"  "}
                        scanned while SKU: {log.SKU} was suggested-by:{" "}
                        {log.name} {log.on_ex ?? ""}
                      </Text>
                    )}
                  </View>
                )}
                {log.event === "by_passed" && (
                  <Message
                    label={` Product instructions confirmation for SKU: ${
                      log.SKU
                    } bypassed - by: ${log.name} ${log.on_ex ?? ""} `}
                  />
                )}
                {log.event === "click_scan" && (
                  <Message
                    label={` Qty 1 of SKU: ${
                      log.SKU
                    } was passed with the Pass option in ${checkMultiBoxActive?log.box_name:""} - by: ${log.name} ${
                      log.on_ex ?? ""
                    } `}
                    id={"clickScanLog"}
                  />
                )}
                {log.event === "add_next" && (
                  <Message
                    label={` SKU: ${
                      log.SKU
                    } added to the order using ADDNEXT by: ${log.name} ${
                      log.on_ex ?? ""
                    } `}
                  />
                )}
                {log.event === "bulk_scan" && (
                  <Message
                    label={`Item ${log.SKU} scanned through Bulk Scan - by: ${
                      log.name
                    } ${log.on_ex ?? ""}`}
                    id={'bulkScanLog'}
                  />
                )}
                {log.event === "scan_all_items" && (
                  <Message
                    label={`${log.total_qty} remaining items with ${log.SKU} have been scanned with the Scan-All option - by: ${
                      log.name
                    } ${log.on_ex ?? ""}`}
                    id={'scanAllLog'}
                  />
                )}
                {log.event === "type_scan" && (
                  <View>
                    {logs[index+1] && logs[index+1].event === "click_scan" && <Message
                      label={`Item with SKU:${log.SKU} has been passed for a Type-In count and`}
                    />}
                    <Message
                      label={`Type-In count of ${
                        log.count
                      } entered for product ${log.SKU} ${checkMultiBoxActive ?`in ${log.box_name}` :""} - by: ${
                        log.name
                      }  ${log.on_ex ?? ""}`}
                    />
                  </View>
                )}
                {log.event === "serial_scan" && (
                  <Message
                    label={`Product: ${log.SKU} Serial scanned: "check" - by: ${
                      log.name
                    } ${log.on_ex ?? ""}`}
                  />
                )}
              </View>
            </View>
          );
        })}
    </React.Fragment>
  );
};
const Message = ({ label, id }) => {
  return (
    <Text testID={id} style={[styles.logAction, styles.logActionTextDesktop]}>
      {label}
    </Text>
  );
};
export default DisplayLogs;