import React from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import styles from '../style/popupmodel';
import DropDownButton from './dropDownButton';
const DropDown = props => {

  const handleChange = (value, index) => {
    props.handlePickerChange(value.value,index)
  }

 return (
  <View 
    style={styles.alertContainer}
  >
    <View style={[styles.alertPermission,
      { 
        top: props.top,
        width: props.width,
        alignSelf: props.alignSelf, 
        flexDirection: 'column',
        backgroundColor: 'rgba(45, 68, 95,1)',
        marginLeft: props.marginLeft,
        position:'absolute',
        zIndex:999
      }]
      }
      >
     {props.pickerStates.map(
      (option, index) => {
        return (
          <>
          {option.value !== 'onhold' &&
          <TouchableOpacity
            key={index}
            testID='DropDownText'
            onPress={() => {
                handleChange(option, index)
            }}
            style={{
              marginBottom: 10,
              marginTop: 10,
              justifyContent: 'center',
            }}
          >
           <DropDownButton 
           label={option.label}
           firstColor={props.colors[index].firstColor}
           secondColor={props.colors[index].secondColor}
           textColor={props.colors[index].textColor}
           width='fit-content'
           />
          </TouchableOpacity>
          }
        </>
        );
      }
    )}
    </View>
  </View>
 )
}

export default DropDown;
