import React from "react";
import { ScrollView, Text, TouchableOpacity } from "react-native";
const ScrollAbleText = ({
  onPress,
  id,
  textStyle,
  labelStyle,
  label,
  value,
}) => {
  return (
    <ScrollView
      horizontal={true}
      showsHorizontalScrollIndicator={false}
      contentContainerStyle={{
        paddingRight: "1rem",
      }}
    >
      <TouchableOpacity testID={id} onPress={onPress}>
        <Text style={textStyle} numberOfLines={1} ellipsizeMode="tail">
          <Text style={labelStyle}>{label} :</Text>
          {value}
        </Text>
      </TouchableOpacity>
    </ScrollView>
  );
};
export default ScrollAbleText;