import { useEffect, useRef, useCallback } from 'react';

const useDropdown = (onSelect, isOpen, toggleDropdown) => {
  const dropdownRef = useRef(null);

  const handleSelectOption = (option) => {
    onSelect(option.value);
    toggleDropdown();
  };

  const handleClickOutside = useCallback((event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      isOpen && toggleDropdown();
    }
  }, [isOpen, toggleDropdown]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return { dropdownRef, handleSelectOption };
};

export default useDropdown;
