import { LinearGradient } from 'expo-linear-gradient';
import React, { Component } from 'react';
import i18n from 'i18n-js';
import {
  Dimensions, ImageBackground, Text, TouchableOpacity, View
} from 'react-native';
import globalStyles from '../../style/global';
import styles from '../../style/scanpack';
import promptStyle from '../../style/popupmodel';
import StyledInput from '../../commons/Inputs/stylesInput';
export default class PropmtBarcode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: Dimensions.get('window').width,
      barcodefouc: null
    };
    this.barcodefouc = React.createRef();
  }
  updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentWillUnmount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentDidUpdate = () => {
    this.barcodefouc.current.focus();
  };
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  render() {
    return (
      <>
        <View style={{ height: '50%', width: '100%' }}>
          <ImageBackground
            style={{ flex: 1, zIndex: 999 }}
            source={require('././../../../assets/Left_new2.jpg')}>
            <View
              style={{
                textAlign: 'center',
                justifyContent: 'center',
                width: '100%',
                zIndex: 999,
                position: 'fixed'
              }}>
              {this.props.openPromptBarcode === true ? (
                <LinearGradient
                  locations={[0, 1]}
                  start={{ x: 0, y: 1 }}
                  end={{ x: 0, y: 0 }}
                  colors={['#d47d02', '#ffb636']}
                  style={[
                    promptStyle.promptModel,
                    {
                      width: this.state.windowWidth >= 600 ? '60%' : '81%',
                      left: this.state.windowWidth >= 600 ? '20%' : '10%'
                    }
                  ]}>
                  <View
                    testID='openPromptBarcode'
                    style={[
                      globalStyles.flexDirectionRow,
                      { width: this.state.windowWidth >= 900 ? '100%' : '76%' }
                    ]}>
                    <Text style={[styles.scanRecordCountText]}>
                      {i18n.t('product.unique_bar')}
                    </Text>
                    <TouchableOpacity testID='iconCancel' onPress={() => this.props.closePrompt()}>
                      <i
                        className="icon-cancel"
                        style={{
                          fontSize: '26px',
                          color: '#4b4132',
                          marginTop: -2,
                          position: 'fixed',
                          right: this.state.windowWidth >= 600 ? '20%' : '9%'
                        }}></i>
                    </TouchableOpacity>
                  </View>
                  <View
                    style={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%'
                    }}>
                    <View style={{ alignItems: 'center', width: '80%' }}>
                      <StyledInput
                        style={[promptStyle.promptTextIn, { border: 'none' }]}
                        autoFocus={true}
                        ref={this.barcodefouc}
                        onSubmitEditing={() => this.props.update()}
                        onChangeText={text => {
                          this.props.onChangeBarcode(text);
                        }}
                        inputStyle={{ height: '100%', fontSize: 20, padding: 10 }}
                        useInputStyle={false}
                      />
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                      <TouchableOpacity
                        testID='cancelButton'
                        style={[promptStyle.promptButton, { marginLeft: '-50%' }]}
                        onPress={() => {
                          this.props.closePrompt();
                        }}>
                        <Text>Cancel</Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        testID='EnterButton'
                        style={[promptStyle.promptButton, { marginLeft: '100%' }]}
                        onPress={() => this.props.update()}>
                        <Text>Enter</Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </LinearGradient>
              ) : (
                <LinearGradient
                  locations={[0, 1]}
                  start={{ x: 0, y: 1 }}
                  end={{ x: 0, y: 0 }}
                  colors={['#d47d02', '#f5ab2b']}
                  style={[
                    promptStyle.promptModel,
                    {
                      width: this.state.windowWidth >= 600 ? '60%' : '81%',
                      left: this.state.windowWidth >= 600 ? '20%' : '10%'
                    }
                  ]}>
                  <View style={globalStyles.flexDirectionRow}>
                    <Text style={[styles.scanRecordCountText, {}]}>
                      {i18n.t('product.unique_sku')}
                    </Text>
                    <TouchableOpacity testID='iconCancel' onPress={() => this.props.closePrompt()}>
                      <i
                        className="icon-cancel"
                        style={{
                          fontSize: '26px',
                          color: '#4b4132',
                          marginTop: -2,
                          position: 'fixed',
                          right: this.state.windowWidth >= 600 ? '20%' : '9%'
                        }}></i>
                    </TouchableOpacity>
                  </View>
                  <View
                    style={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%'
                    }}>
                    <View style={{ alignItems: 'center', width: '80%' }}>
                      <StyledInput
                        style={[promptStyle.promptTextIn, { border: 'none' }]}
                        ref={this.barcodefouc}
                        autoFocus={true}
                        onSubmitEditing={() => this.props.update()}
                        onChangeText={text => {
                          this.props.onChangeSku(text);
                        }}
                        inputStyle={{ height: '100%', fontSize: 20, padding: 10 }}
                        useInputStyle={false}
                      />
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                      <TouchableOpacity
                        testID='cancelButton'
                        style={[promptStyle.promptButton, { marginLeft: '-50%' }]}
                        onPress={() => {
                          this.props.closePrompt();
                        }}>
                        <Text>Cancel</Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        testID='EnterButton'
                        style={[promptStyle.promptButton, { marginLeft: '100%' }]}
                        onPress={() => {
                          this.props.update();
                        }}>
                        <Text>Enter</Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </LinearGradient>
              )}
            </View>
          </ImageBackground>
        </View>
      </>
    );
  }
}
