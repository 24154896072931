import * as Network from 'expo-network';
let num = 0;
const InterNetCheck = async () => {
  const networkCheck = await Network.getNetworkStateAsync();
  // console.log("mmmmm",networkCheck)
  num = +1;
  if (!networkCheck.isConnected && num === 1) {
    return false;
    // debugger
    // Alert.alert('This alert was dismissed by tapping outside of the alert dialog')
    // <AlertNetwork />
  } else {
    num = 0;
    return true;
    // if(!netcheck){

    // }
  }
};

export default InterNetCheck;
