import React, { Component, useLayoutEffect, useState } from 'react';
import i18n from 'i18n-js';
import styles from '../style/popupmodel';
import { View, Text, Dimensions, TouchableOpacity } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
function AlertBoxSaving(props) {
  const [windowWidth, setWindowWidth] = useState(
    Dimensions.get('window').width
  );
  useLayoutEffect(() => {
    function updateSize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return (
    <View style={styles.alertContainer}>
      <LinearGradient
        locations={[0, 1]}
        colors={['#5bbc31', '#479523', '#3f851d']}
        style={[styles.alertPermission, { top: windowWidth <= 600 ? '16%' : '104px', }]}
      >
        <Text
          style={{
            marginLeft: 15,
            fontSize: 16,
            color: '#fff',
            width: '80%'
          }}
        >
          {i18n.t('product.alertBoxSaveData')}
         {/* {prop.name} */}
        </Text>
        <TouchableOpacity
          testID='cancelButton'
          style={{
            height: 28,
            borderRadius: 1,
            padding: 5,
            borderRadius: 10,
            position: 'absolute',
            right: '0%',
            top: windowWidth <= 600 ? '-8%' : '-8%'
          }}
          onPress={() => {
            props.closeAlert();
          }}
        >
          <i
            className="icon-cancel"
            style={{ fontSize: '22px', color: 'white' }}
          ></i>
        </TouchableOpacity>
      </LinearGradient>
    </View>
  )
}

export default AlertBoxSaving