import React, {useEffect, useState, useRef, memo} from 'react';
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  Modal,
  FlatList
} from 'react-native';

import {AntDesign} from '@expo/vector-icons';

const Dropdown = ({
  selectedValue,
  data,
  onSelect,
  error,
  pickerOpen,
  setPickerOpen
}) => {
  const DropdownButton = useRef();
  const [dropdownTop, setDropdownTop] = useState(0);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    selectedValue &&
      setSelected(data[data.findIndex(el => el.value === selectedValue)]);
  }, [selectedValue]);

  const toggleDropdown = () => {
    pickerOpen ? setPickerOpen(false) : openDropdown();
  };

  const openDropdown = () => {
    DropdownButton.current.measure((_fx, _fy, _w, h, _px, py) => {
      setDropdownTop(py + h);
    });
    setPickerOpen(true);
  };

  const onItemPress = item => {
    setSelected(item);
    onSelect(item.value);
    setPickerOpen(false);
  };

  const renderItem = ({item}) => {
    return (
      <TouchableOpacity
        style={[
          styles.item,
          item.value === selected.value ? {backgroundColor: '#1e90ff'} : ''
        ]}
        onPress={() => onItemPress(item)}>
        <Text
          style={[
            styles.dropdownItem,
            item.value === selected.value
              ? {backgroundColor: 'transparent', color: '#fff'}
              : ''
          ]}>
          {item.label}
        </Text>
      </TouchableOpacity>
    );
  };

  const renderDropdown = () => {
    if (pickerOpen) {
      return (
        <Modal
          visible={pickerOpen}
          style={[styles.dropdown]}
          transparent
          animationType="none">
          <FlatList
            data={data}
            renderItem={renderItem}
            keyExtractor={(item, index) => index.toString()}
            initialNumToRender={10}
            maxToRenderPerBatch={10}
          />
        </Modal>
      );
    }
  };

  return (
    <>
      <TouchableOpacity
        ref={DropdownButton}
        style={[styles.button, {borderColor: error ? 'red' : '#fff'}]}
        onPress={toggleDropdown}>
        {renderDropdown()}
        <Text style={styles.buttonText}>
          {(selected && selected.label) || (
            <Text style={styles.placeholder}>{selected?.label}</Text>
          )}
        </Text>
        <AntDesign name={pickerOpen ? 'up' : 'down'} size={15} color="#fff" />
      </TouchableOpacity>
    </>
  );
};

const styles = StyleSheet.create({
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 10,
    zIndex: 1,
    borderBottomWidth: 0.5,
    borderColor: '#fff',
    padding: 0,
    paddingLeft: 3,
    marginLeft: 10,
    color: '#fff',
    width: '29%',
    height: 35,
    backgroundColor: 'transparent'
  },
  buttonText: {
    flex: 1,
    fontSize: 18,
    lineHeight: 30,
    color: '#fff',
    fontFamily: '400Regular'
  },
  dropdown: {
    position: 'absolute',
    top: 35,
    left: 0,
    backgroundColor: '#DBDADA',
    width: '100%',
    shadowColor: '#000000',
    borderWidth: 0,
    borderColor: 'gray',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    overflow: 'hidden',
    shadowRadius: 4,
    shadowOffset: {height: 4, width: 0},
    shadowOpacity: 0.5
  },
  item: {
    paddingHorizontal: 5,
    paddingVertical: 2,

    fontFamily: '400Regular',
    color: 'white',
    fontSize: 18
  },
  dropdownItem: {
    fontSize: 18,
    lineHeight: 30,
    color: '#000',
    fontFamily: '400Regular'
  },
  placeholder: {color: '#fff'}
});

export default Dropdown;
