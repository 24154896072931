import React, { Component } from 'react';
import {
  View,
  Dimensions,
  Text,
  TextInput,
  Image,
  TouchableOpacity,
  Platform,
  ScrollView
} from 'react-native';
import styles from '../../style/scanpack';
import commonStyles from '../../style/commonStyles';
import globalStyles from '../../style/global';
import moment from 'moment';
import * as Clipboard from 'expo-clipboard';
import HTML from 'react-native-render-html';
// import restart from '../../../assets/restart.png';
// import upArrow from '../../../assets/up_arrow.png';
// import downArrow from '../../../assets/down_arrow.png';
// import note from '../../../assets/note.png';
// import deleteImage from '../../../assets/delete.png';
// import toggle from '../../../assets/dotToggle.png';
import placeholder from '../../../assets/placeholder.jpg';
import { SearchScanpackOrder } from '../../actions/scanpackAction';
import { GetProductDetail } from '../../actions/productAction'
import { GetOrderDetail } from '../../actions/orderActions'
import { LinearGradient } from 'expo-linear-gradient';
import InterNetChecks from '../internateConnectionCheck';
import InerternetConnectionAlert from '../internetConnectionAlert';
import { SwapUPC } from '../../actions/menuActions';
import { connect } from 'react-redux';
import { GetBothSettings } from '../../actions/userAction';
import i18n from 'i18n-js';
import NextItemMobileView from './next_item_mobile_view';
import ActionBarButtons from './actionBarButtons';
import PassButton from '../../commons/passButton';
import DisplaySuggestedImage from './displaySuggestedImageDesktop';
import OrderItemSuggested from '../../commons/orderItemSuggested';
import DisplayLogs from '../../commons/displayLogs';
import DisplayInstructions from '../../commons/displayOrderInstructions';
import ScanButtonContainer from './scanButtonContainer';
import DisplayLiveStats from '../../commons/displayLiveStats';

let locCust = [];
let numberOfKeysEven = false;
let numberOfKeys = 0;
let widthOnMobile = '100%';
let redirectOrderSucess = false;
class NextItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showKeyboard: true,
      scanPackView: true,
      logView: false,
      enableLog: false,
      nextItemPageShow: true,
      activityLogPageShow: false,
      actionBarShow: false,
      localValue: [],
      ascii: [
        97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111,
        112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 65, 66, 67, 68,
        69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86,
        87, 88, 89, 90, 27
      ],
      scannedList: true,
      unscannedList: false,
      logList: false,
      logPerPage: 1,
      scanPlaceHolderValue: 'Ready For Product Scan',
      windowWidth: Dimensions.get('window').width,
      webBigImage: false,
      unscannedBrokenImage: [],
      scannedBrokenImage: [],
      popInternet: true,
      data: {
        input: '',
        state: 'scanpack.rfo',
        id: null,
        box_id: null,
        store_order_id: null,
        app: 'app'
      },
      loading: false,
      loaderTitle: '',
    };
  }

  actionBarShowFunMobile = actionBarShow => {
    this.setState({ actionBarShow });
  };

  scanPlaceHolderValueFunMobile = blurValue => {
    this.setState({ scanPlaceHolderValue: blurValue });
  };

  enableToggle(e) {
    if (e === 0) {
      this.props.route.params.item(true);
      this.setState({ enableLog: false });
      // this.props.barcode.current.focus()
    } else {
      this.props.route.params.item(false);
      this.setState({ enableLog: true });
    }
  }

  orderDetail = order => {
    if (order) {
      this.props.leaveComponent(this.props.order, 'submitLog');
      this.props.navigation.navigate('OrderDetail', {
        item: order,
        userData: this.props?.route?.params?.userdata,
        updateSetting: this.props?.callOrder?.data?.order[0] ,
        callOrder : this.props?.order?.order,
      });
    }
  };

  updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  };
  copyTextToClipboard = async (text) => {
    await Clipboard.setStringAsync(`${text}`);
  };

  componentDidMount() {
    // this.props.SearchScanpackOrder('', false)
    // console.log("check data",this.props?.route?.params?.data?.order);
    if(this.props?.route?.params?.packingcamPage === "PackingCam"){
      this.props.GetOrderDetail(this.props?.route?.params?.data);
    }
    else{
      this.props.GetOrderDetail(this.props?.route?.params?.data?.order);
    }
    window.addEventListener('resize', this.updateDimensions());
    if (
      this.props?.settings?.show_tags ||
      this.props?.settings?.show_customer_notes ||
      this.props?.settings?.show_internal_notes
    ) {
      this.props.showNotesFun();
    }
    const sI = setInterval(() => {
      this.test();
    }, 2000);
  }

  componentWillUnmount() {
    window.addEventListener('resize', this.updateDimensions);

    locCust = [];
  }
  AttributeRow = (label, text, color) => {
    if (locCust.includes(label)) {
    } else {
      locCust.push(label);
    }
    if (label === 'L1 : ' && this.state.windowWidth < 900) {
      widthOnMobile = '85%';
    } else {
      if (this.state.windowWidth > 900) {
        widthOnMobile = this.getFontSize();
      } else {
        widthOnMobile = '90%';
      }
    }
    return (
      <View
        style={{
          flexDirection: 'row',
          paddingVertical: this.state.windowWidth > 900 ? 8 : 0,
          paddingHorizontal: this.state.windowWidth < 900 ? 8 : 8,
          alignItems: 'center',
          backgroundColor: color
        }}>
        <Text
          style={[globalStyles.nextItemText,{ fontSize: 18, paddingTop: 2 }]}
        >
          {label}
        </Text>
        <View style={{ width: widthOnMobile }}>
          <ScrollView
            horizontal={true}
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={{ paddingRight: '1rem' }}>
            <Text
              style={[globalStyles.nextItemText,{ fontSize: 28, textAlign: 'left' }]}
            >
              {text}
            </Text>
          </ScrollView>
        </View>
        {label === 'L1 : ' && this.state.windowWidth < 900 ? (
          <TouchableOpacity
            onPress={() => {
              this.props.SwapUPC(!this.props.isSwap);
            }}>
            <i
              className="icon-updown-circle"
              style={{
                color: this.props.isSwap ? '#000' : '#7c95ae',
                fontSize: '20px',
                marginLeft: '2%',
                marginRight: ' 2%'
              }}></i>
          </TouchableOpacity>
        ) : (
          <></>
        )}
      </View>
    );
  };
  getAttributeValue = (attrOne, attrTwo) => {
    let Order = this.props.order.order;
    let spSetting = this.props.scanPackSetting;
    let UnScannedItem = Order?.unscanned_items[0]
    return !!(UnScannedItem && UnScannedItem[attrOne] !== undefined && UnScannedItem[attrOne] !== null &&
      UnScannedItem[attrOne] !== '' &&
      (UnScannedItem[attrTwo] || spSetting[attrTwo]))
  }
  getAttributeRows = () => {
    locCust = [];
    let rowsObj = {};
    let Order = this.props.order.order;
    if (Order.unscanned_items.length === 0) return;
    if (
      (Order.unscanned_items[0].product_type === 'individual' &&
        Order.unscanned_items[0].child_items.length > 0) === true
    )
      return;

    rowsObj.isShowL1 = this.getAttributeValue('location','display_location')
    rowsObj.isShowL2 = this.getAttributeValue('location2','display_location2')
    rowsObj.isShowL3 = this.getAttributeValue('location3','display_location3')
    rowsObj.isShowC1 = this.getAttributeValue('custom_product_1','custom_product_display_1')
    rowsObj.isShowC2 = this.getAttributeValue('custom_product_2','custom_product_display_2')
    rowsObj.isShowC3 = this.getAttributeValue('custom_product_3','custom_product_display_3')
    const showKeys = Object.keys(rowsObj)
      .map(key => (rowsObj[key] ? key : null))
      .filter(v => v);
    numberOfKeys = showKeys.length;
    if (showKeys?.length % 2 === 0) {
      numberOfKeysEven = true;
    } else {
      numberOfKeysEven = false;
    }
    return showKeys.map((key, index) => {
      if (this.props.isSwap) {
        index = index + 1;
      }
      switch (key) {
        case 'isShowL1':
          return this.AttributeRow(
            'L1 : ',
            Order.unscanned_items[0].location,
            index % 2 === 0 ? '#b6cadd' : '#768da5'
          );
        case 'isShowL2':
          return this.AttributeRow(
            'L2 : ',
            Order.unscanned_items[0].location2,
            index % 2 === 0 ? '#b6cadd' : '#768da5'
          );
        case 'isShowL3':
          return this.AttributeRow(
            'L3 : ',
            Order.unscanned_items[0].location3,
            index % 2 === 0 ? '#b6cadd' : '#768da5'
          );
        case 'isShowC1':
          return this.AttributeRow(
            'C1 : ',
            Order.unscanned_items[0].custom_product_1,
            index % 2 === 0 ? '#b6cadd' : '#768da5'
          );
        case 'isShowC2':
          return this.AttributeRow(
            'C2 : ',
            Order.unscanned_items[0].custom_product_2,
            index % 2 === 0 ? '#b6cadd' : '#768da5'
          );
        case 'isShowC3':
          return this.AttributeRow(
            'C3 : ',
            Order.unscanned_items[0].custom_product_3,
            index % 2 === 0 ? '#b6cadd' : '#768da5'
          );
        default:
          break;
      }
    });
  };
  getChildAttributeValue = (attrOne, attrTwo) => {
    let Order = this.props.order.order;
    let spSetting = this.props.scanPackSetting;
    let Item = Order?.unscanned_items[0]?.child_items[0]
    return !!(Item && Item[attrOne] !== undefined && Item[attrOne] !== null &&
      Item[attrOne] !== '' &&
     (Item[attrTwo] || spSetting[attrTwo]))
  }
  getAttributeRowsChild = () => {
    // locCust = []
    let rowsObj = {};
    let Order = this.props.order.order;
    let spSetting = this.props.scanPackSetting;
    if (Order.unscanned_items.length === 0) return;
    if (
      (Order.unscanned_items[0].product_type === 'individual' &&
        Order.unscanned_items[0].child_items.length > 0) === false
    )
      return;
      rowsObj.isShowL1 = this.getChildAttributeValue('location','display_location')
      rowsObj.isShowL2 = this.getChildAttributeValue('location2','display_location2')
      rowsObj.isShowL3 = this.getChildAttributeValue('location3','display_location3')
      rowsObj.isShowC1 = this.getChildAttributeValue('custom_product_1','custom_product_display_1')
      rowsObj.isShowC2 = this.getChildAttributeValue('custom_product_2','custom_product_display_2')
      rowsObj.isShowC3 = this.getChildAttributeValue('custom_product_3','custom_product_display_3')
    const showKeys = Object.keys(rowsObj)
      .map(key => (rowsObj[key] ? key : null))
      .filter(v => v);
    numberOfKeys = showKeys.length;
    if (showKeys?.length % 2 === 0) {
      numberOfKeysEven = true;
    } else {
      numberOfKeysEven = false;
    }
    return showKeys.map((key, index) => {
      if (this.props.isSwap) {
        index = index + 1;
      }
      switch (key) {
        case 'isShowL1':
          return this.AttributeRow(
            'L1 : ',
            Order.unscanned_items[0].child_items[0].location,
            index % 2 === 0 ? '#b6cadd' : '#768da5'
          );
        case 'isShowL2':
          return this.AttributeRow(
            'L2 : ',
            Order.unscanned_items[0].child_items[0].location2,
            index % 2 === 0 ? '#b6cadd' : '#768da5'
          );
        case 'isShowL3':
          return this.AttributeRow(
            'L3 : ',
            Order.unscanned_items[0].child_items[0].location3,
            index % 2 === 0 ? '#b6cadd' : '#768da5'
          );
        case 'isShowC1':
          return this.AttributeRow(
            'C1 : ',
            Order.unscanned_items[0].child_items[0].custom_product_1,
            index % 2 === 0 ? '#b6cadd' : '#768da5'
          );
        case 'isShowC2':
          return this.AttributeRow(
            ' C2: ',
            Order.unscanned_items[0].child_items[0].custom_product_2,
            index % 2 === 0 ? '#b6cadd' : '#768da5'
          );
        case 'isShowC3':
          return this.AttributeRow(
            'C3 : ',
            Order.unscanned_items[0].child_items[0].custom_product_3,
            index % 2 === 0 ? '#b6cadd' : '#768da5'
          );
        default:
          break;
      }
    });
  };
  calculateScanned = () => {
    let Order = this.props.order.order;
    let localCount = 0;
    this.props.order &&
      Order &&
      Order.scanned_items &&
      Order.scanned_items.length > 0 &&
      Order.scanned_items.map(item => {
        if (item?.child_items?.length === 0 || item?.child_items === undefined){
          localCount = localCount + item.scanned_qty;
        }
      });
    return localCount;
  };
  copyOrderNumberToClipboard = async () => {
    await Clipboard.setStringAsync(`${this.props.order.order.increment_id}`);
  };
  calculateUnScanned = () => {
    let Order = this.props.order.order;
    let localCount = 0;
    Order &&
      Order.unscanned_items &&
      Order.unscanned_items.length > 0 &&
      Order.unscanned_items.map(item => {
        if (
          item.child_items &&
          item.child_items.length > 0 &&
          item.product_type === 'individual'
        ) {
          item.child_items.map(childItem => {
            localCount = localCount + childItem.qty_remaining;
          });
        } else {
          localCount = localCount + item.qty_remaining;
        }
      });
    return localCount;
  };
  imageCheck = err => {
    let ll = err.nativeEvent.error.includes('Failed to load');
    this.setState({ webBigImage: ll });
    // console.log("image errr in next ",err.nativeEvent.error)
  };
  unscannedImageCheck = err => {
    let ll = err.nativeEvent.error.includes('404');
    this.setState({
      unscannedBrokenImage: [this.state.unscannedBrokenImage, ll]
    });
    // console.log("image errr in next unsca web ",err.nativeEvent.error)
  };
  scannedImageCheck = err => {
    let ll = err.nativeEvent.error.includes('404');
    this.setState({ scannedBrokenImage: [this.state.scannedBrokenImage, ll] });
    // console.log("image errr in next scanned web ",err.nativeEvent.error)
  };

  handlePassButton = (item, isShiftKey) =>{
    if(this.props.barcodeState !== ''){
      this.props.scanBarcode()
      return
    }
    this.props.clickScan(item, "", isShiftKey)
    this.props.GetOrderDetail(this.props?.route?.params?.data?.order)
    // let Order = this.props.order.order
    // setTimeout(() => {
    //   Order.unscanned_items.length!==0 ? this.props.getOrderData(Order.increment_id): "";
    // }, 700);
  }
  test = async () => {
    let num = await InterNetChecks();
    if (num !== true) {
      this.setState({ popInternet: false });
    } else {
      this.setState({ popInternet: true });
    }
  };
  getWidth = () => {
    let windowWidth = this.props.width;
    if (windowWidth > 870 && windowWidth < 1090) {
      return 300;
    } else if (windowWidth >= 1090 && windowWidth < 1450) {
      return 340;
    } else if (windowWidth >= 1450 && windowWidth < 1650) {
      return 400;
    } else {
      return 450;
    }
  };
  getFontSize = () => {
    let windowWidth = this.props.width;
    if (windowWidth > 870 && windowWidth < 1070) {
      return '30%';
    } else if (windowWidth >= 1070 && windowWidth < 1350) {
      return '35%';
    } else if (windowWidth >= 1350 && windowWidth < 1700) {
      return '40%';
    } else {
      return '50%';
    }
  };
  getMargin = () => {
    let windowWidth = this.props.width;
    if (windowWidth > 870 && windowWidth < 1199) {
      return '1.65rem';
    } else if (windowWidth >= 1200 && windowWidth < 1450) {
      return '1.71rem';
    } else if (windowWidth >= 1450 && windowWidth < 1650) {
      return '1.8rem';
    } else {
      return '1.95rem';
    }
  };
  getUpdateSettings = () => {
    this.props.GetOrderDetail(this.props?.route?.params?.data?.order)
    this.props.GetBothSettings();
  };

  redirecToSameOrder = (id, store_id) => {
    this.setState({ loading: true, loaderTitle: 'Loading...' });
    if (id !== '') {
      this.checkSameOrderId(id, store_id);
    }
  };

  checkSameOrderId = (id, store_id) => {
    let shipmentOrder = this.state.data;
    shipmentOrder['input'] = id;
    shipmentOrder['store_order_id'] = store_id;
    if (id !== '') {
      shipmentOrder.input = id;
      this.props.SearchScanpackOrder(shipmentOrder);
      redirectOrderSucess = true;
    }
  };

  orderDetails = (order) => {
    this.props.duplicateShipStationOrder(order);
  };

  componentDidUpdate() {
    let orderData = this.props?.searchOrder?.searchOrder?.data?.order[0];
    if (redirectOrderSucess === true
      &&
      this.props.order.order.id !== orderData?.id) {
      let order = orderData?.scan_hash?.data;
      this.orderDetails(order)
      redirectOrderSucess = false;
    }
  }


  render() {
    let slider_image = [];
    let Order = this.props.order.order;
    let activities = this.props.activities;
    let localLogs = this.props.localLogs;
    let spSetting = this.props.scanPackSetting;
    let isRealTimeStatEnabled = this.props.generalSetting.allow_rts;
    const config = {
      velocityThreshold: 0.1,
      directionalOffsetThreshold: 100,
      gestureIsClickThreshold: 5
    };
    let logPerPage = this.state.logPerPage * 14;
    const rows = this.getAttributeRows();
    const rowsChild = this.getAttributeRowsChild();
    let unscannedCount = this.calculateUnScanned();
    let scannedCount = this.calculateScanned();
    // let kitBigImgL1 = Order?.unscanned_items[0].child_items[0]?.location?.trim() !== ''
    // let kitBigImgL2 = Order?.unscanned_items[0].child_items[0]?.location2?.trim() !== ''
    // let kitBigImgL3 = Order?.unscanned_items[0].child_items[0]?.location3?.trim() !== ''
    // let kitBigImgC1 = Order?.unscanned_items[0].child_items[0]?.custom_product_display_1 == true
    // let kitBigImgC2 = Order?.unscanned_items[0].child_items[0]?.custom_product_display_2 == true
    // let kitBigImgC3 = Order?.unscanned_items[0].child_items[0]?.custom_product_display_3 == true
    // let kitBigImg = Order?.unscanned_items[0]?.child_items[0]?.location?.trim() !== '' && Order?.unscanned_items[0]?.child_items[0]?.location2?.trim() !== '' && Order?.unscanned_items[0]?.child_items[0]?.location3?.trim() !== '' && Order?.unscanned_items[0]?.child_items[0]?.custom_product_display_1 == true  && Order?.unscanned_items[0]?.child_items[0]?.custom_product_display_2 == true && Order?.unscanned_items[0]?.child_items[0]?.custom_product_display_3 == true
    // let kitBigImgLoc1 = Order?.unscanned_items[0]?.child_items[0]?.location?.trim() !== '' && Order?.unscanned_items[0]?.child_items[0]?.location2?.trim() !== '' && Order?.unscanned_items[0]?.child_items[0]?.location3?.trim() !== ''
    // let kitBigImgCus1 = Order?.unscanned_items[0]?.child_items[0]?.custom_product_display_1 == true && Order?.unscanned_items[0]?.child_items[0]?.custom_product_display_2 == true && Order?.unscanned_items[0]?.child_items[0]?.custom_product_display_3 == true

    // let BigImg1 = Order?.unscanned_items[0]?.location?.trim() !== '' && Order?.unscanned_items[0]?.location2?.trim() !== '' && Order?.unscanned_items[0]?.location3?.trim() !== '' && Order?.unscanned_items[0]?.custom_product_display_1 == true  && Order?.unscanned_items[0]?.custom_product_display_2 == true && Order?.unscanned_items[0]?.custom_product_display_3 == true
    // let BigImgL1 = Order?.unscanned_items[0]?.location?.trim() !== ''
    // let BigImgL2 = Order?.unscanned_items[0]?.location2?.trim() !== ''
    // let BigImgL3 = Order?.unscanned_items[0]?.location3?.trim() !== ''
    // let BigImgC1 = Order?.unscanned_items[0]?.custom_product_display_1 == true
    // let BigImgC2 = Order?.unscanned_items[0]?.custom_product_display_2 == true
    // let BigImgC3 = Order?.unscanned_items[0]?.custom_product_display_3 == true
    // let BigImgLoc1 = Order?.unscanned_items[0]?.location?.trim() !== '' && Order?.unscanned_items[0]?.location2?.trim() !== '' && Order?.unscanned_items[0]?.location3?.trim() !== ''
    // let BigImgCus1 = Order?.unscanned_items[0]?.custom_product_display_1 == true && Order?.unscanned_items[0]?.custom_product_display_2 == true && Order?.unscanned_items[0]?.custom_product_display_3 == true
    let checkMultiOrderPresent = this.props?.searchOrder?.searchOrder?.data?.matched_orders
    let checkShippingEasyOrder = this.props?.searchOrder?.searchOrder?.data?.order[0]?.scan_hash?.data?.order?.multi_shipments?.se_all_shipments?.present
    let checkMultiBoxActive = this.props?.bothSettings?.data?.data?.general_setting?.multi_box_shipments
   return (
      <>
        <View style={[globalStyles.flex1]}>
          {this.state.windowWidth >= 900 ? (
            <View style={[globalStyles.flex1]}>
              {this.state.popInternet === false ? (
                <InerternetConnectionAlert />
              ) : null}
              {this.props.order && Order.unscanned_items[0] ? (
                <View style={styles.nextItemsMain}>
                  {this.props.order && Order.unscanned_items[0] && (
                    <View style={styles.RFPScreen}>
                      <View
                        style={globalStyles.nextItemFlex}>
                        <LinearGradient
                          start={{ x: 0, y: 1 }}
                          end={{ x: 0, y: 0 }}
                          colors={[
                            '#000000',
                            '#00000006',
                            '#00000006',
                            '#000000'
                          ]}
                          style={[styles.actionButtonsView]}>
                          <View
                            dataSet={{ componentName: 'orderDetailLink' }}
                          >
                            {/* <TouchableOpacity onPress={() => this.props.userData.role.access_orders ? this.orderDetail(Order) : <></>}
                                  delayLongPress={1000}
                                  style={{ marginRight: 45, marginLeft: 45 }}
                                > */}
                            <TouchableOpacity
                              testID='orderDetail'
                              onPress={() => this.orderDetail(Order)}
                              delayLongPress={1000}
                              style={{ marginRight: 45, marginLeft: 45 }}>
                              <Text
                                numberOfLines={1}
                                ellipsizeMode="tail"
                                style={[
                                  styles.nowScanningTextView, globalStyles.nextItemOrder ]}>
                                Order {Order.increment_id}
                                <TouchableOpacity
                                  onPress={this.copyOrderNumberToClipboard}>
                                  <i
                                    className="icon-docs"
                                    style={{
                                      color: '#8e8b8b',
                                      fontSize: '20px',
                                      marginLeft: '10px'
                                    }}></i>
                                </TouchableOpacity>
                              </Text>
                            </TouchableOpacity>
                          </View>
                          <ActionBarButtons
                           width={this.state.windowWidth}
                           onPressRestartOrder={()=>{
                            this.state.popInternet === true ? (
                                this.props.restartButton()
                              ) : (
                                <></>
                              )
                            }}
                            restartButtonDesign={styles.restartButtonDesign}
                            onPressGetOrderData={() => {
                                this.props.getOrderData(Order.increment_id);
                                this.getUpdateSettings();
                              }}
                            reloadButtonDesign={styles.noteSaveButtonDesign}
                            onPressAddNote={()=>{
                              this.state.popInternet === true ? (
                                  this.props.addNote()
                                ) : (
                                  <></>
                                )
                            }}
                          />
                        </LinearGradient>
                        <LinearGradient
                          start={{ x: 0, y: 1 }}
                          end={{ x: 0, y: 0 }}
                          colors={['#142130', '#304454']}
                          style={styles.scanBarcodeGradientDesktop}>
                          <View style={{ flexDirection: 'row' }}>
                            <View
                              style={styles.scanBarcodeContainer}>
                              <LinearGradient
                                start={{ x: 0, y: 1 }}
                                end={{ x: 0, y: 0 }}
                                colors={['#6384ff', '#1329ff', '#35096c']}
                                style={styles.scanBarcodeSubGradient}
                              />
                            </View>
                            <View
                              dataSet={{ componentName: 'barcodeScan' }}
                              style={styles.scanBarCodeInputContainer}>
                              <TextInput
                                value={this.props.barcodeState}
                                autoFocus={false}
                                ref={this.props.InputField}
                                style={styles.scanBarCodeInput}
                                onSubmitEditing={
                                  this.state.popInternet === true && (
                                    this.props.scanBarcode
                                  )
                                 }
                                onChangeText={this.props.stateUpdate}
                                placeholder={this.state.scanPlaceHolderValue}
                                placeholderTextColor={'#acacad'}
                                onBlur={() => {
                                  const blurValue =
                                    Platform.OS === 'web'
                                      ? 'Click Here Before Scanning'
                                      : 'Tap Here Before Scanning';
                                  this.setState({
                                    scanPlaceHolderValue: blurValue
                                  });
                                }}
                                onFocus={() =>
                                  this.setState({
                                    scanPlaceHolderValue:
                                      'Ready For Product Scan'
                                  })
                                }
                              />
                            </View>
                            <ScanButtonContainer
                              Order={Order}
                              popInternet={this.state.popInternet}
                              scanAllItem={this.props.scanAllItem}
                              windowWidth={this.state.windowWidth}
                              setType={this.props.setType}
                              checkCount={this.props.checkCount}
                              scanAllOption={this.props.scanPackSetting.scan_all_option}
                              typeScanState={this.props.typeScanState}
                              handlePassButton = {this.handlePassButton}
                              isBarcodeEnter = {this.props.isBarcodeEnter}
                              setIsBarcodeEnter = {this.props.setIsBarcodeEnter}
                            />
                          </View>
                        </LinearGradient>
                        {(Order.tags !== null &&
                          Order.tags !== null &&
                          Order.tags.trim() !== '' &&
                          this.props.settings.show_tags &&
                          this.props.tagsToPackerFlag) ||
                          (Order.notes_toPacker !== null &&
                            Order.notes_toPacker &&
                            Order.notes_toPacker.trim() !== '' &&
                            this.props.notesToPackerFlag &&
                            this.props.settings.show_internal_notes) ||
                          (Order.customer_comments !== null &&
                            Order.customer_comments &&
                            Order.customer_comments.trim() !== '' &&
                            this.props.customerCommentsFlag &&
                            this.props.settings.show_customer_notes) ||
                          (Order.notes_internal !== null &&
                            Order.notes_internal &&
                            Order.notes_internal.trim() !== '' &&
                            this.props.internalNotesFlag &&
                            this.props.settings.show_internal_notes) ? (
                          //not remove the props validation tagsToPackerFlag , notesToPackerFlag , customerCommentsFlag , internalNotesFlag
                          //on component mount then shownotesfun call fun define in scanpack_item
                          <LinearGradient
                            locations={[0, 0.13, 1]}
                            colors={['#000000', '#fdf359', '#d2c609']}
                            style={globalStyles.nextItemNotes}>
                            <View style={{ width: '100%' }}>
                              {Order.tags !== null && Order.tags.trim() !== '' && (
                                <View style={{ width: '100%' }}>
                                  <Text
                                    style={commonStyles.nextItemText}>
                                    {Order.tags}
                                  </Text>
                                  {((Order.customer_comments !== null &&
                                    Order.customer_comments.trim() !== '') ||
                                    (Order.notes_toPacker !== null &&
                                      Order.notes_toPacker.trim() !== '') ||
                                    (Order.notes_internal !== null &&
                                      Order.notes_internal &&
                                      Order.notes_internal.trim() !== '')) && (
                                      <View
                                        style={commonStyles.nextItemNotes}
                                      />
                                    )}
                                </View>
                              )}
                              {Order.notes_internal !== null &&
                                Order.notes_internal.trim() !== '' && (
                                  <View style={{ width: '100%' }}>
                                    <Text
                                      style={commonStyles.nextItemText}>
                                      {Order.notes_internal}
                                    </Text>
                                    {((Order.customer_comments !== null &&
                                      Order.customer_comments.trim() !== '') ||
                                      (Order.notes_toPacker !== null &&
                                        Order.notes_toPacker.trim() !==
                                        '')) && (
                                        <View
                                          style={commonStyles.nextItemNotes}
                                        />
                                      )}
                                  </View>
                                )}
                              {Order.customer_comments !== null &&
                                Order.customer_comments.trim() !== '' && (
                                  <View style={{ width: '100%' }}>
                                    <Text
                                      style={commonStyles.nextItemText}>
                                      {Order.customer_comments}
                                    </Text>
                                    {(Order.notes_toPacker !== null
                                      ? Order.notes_toPacker.trim() !== ''
                                      : false) && (
                                        <View
                                          style={commonStyles.nextItemNotes}
                                        />
                                      )}
                                  </View>
                                )}
                              {Order.notes_toPacker !== null &&
                                Order.notes_toPacker.trim() !== '' && (
                                  <Text
                                    style={commonStyles.nextItemText}>
                                    {Order.notes_toPacker}
                                  </Text>
                                )}
                              <View>
                                <TouchableOpacity
                                  onPress={() => this.props.closeNotes()}
                                  style={commonStyles.nextItemCloseNote}>
                                  <Text
                                    style={[commonStyles.nextItemText, { lineHeight: '100%' }]}
                                  >
                                    Got it.
                                  </Text>
                                </TouchableOpacity>
                              </View>
                            </View>
                          </LinearGradient>
                        ) : null}
                        {Order.unscanned_items[0].product_type ===
                          'individual' &&
                          Order.unscanned_items[0].child_items.length > 0 ? (
                          <React.Fragment>
                            {Order.unscanned_items[0].child_items[0]
                              .instruction !== '' &&
                              Order.unscanned_items[0].child_items[0]
                                .instruction !== null && (
                                <DisplayInstructions
                                 item={Order.unscanned_items[0].child_items[0]}
                                 width={this.state.windowWidth}
                                />
                              )}
                            {isRealTimeStatEnabled && <DisplayLiveStats />}
                            <View
                              style={commonStyles.nextItemRowFlex}
                            >
                              <OrderItemSuggested
                               redirectToItemDetail={(item)=>{this.props.redirectToItemDetail(item)}}
                               item={Order.unscanned_items[0].child_items[0]}
                               name={'suggestedChildItem'}
                               testID={'redirectToItem'}
                               displayRow={rowsChild.map(row => row)}
                               addBarcodeFun={(item)=>{this.props?.addBarcode(item)}}
                               getFontSize={this.getFontSize}
                               width={this.props.width}
                              />
                              <DisplaySuggestedImage
                                source={placeholder}
                                defaultSource={placeholder}
                                scannedQty={
                                  Order.unscanned_items[0].child_items[0]
                                    .scanned_qty
                                }
                                totalQty={
                                  Order.unscanned_items[0].child_items[0]
                                    .qty_remaining +
                                  Order.unscanned_items[0].child_items[0]
                                    .scanned_qty
                                }
                                remainingQty={Order.unscanned_items[0].child_items[0].qty_remaining}
                                images={Order.unscanned_items[0].child_items[0].images}
                                id={Order.id}
                                order={Order}
                                onPressGetOrderData={() => {
                                  this.props.getOrderData(Order.increment_id);
                                  this.getUpdateSettings();
                                }}
                                getWidth={this.getWidth}
                                getMargin={this.getMargin}
                              />
                            </View>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            {
                              checkMultiOrderPresent && checkMultiOrderPresent?.length > 1 ? (
                                <>
                                  <View style={{ padding: "10px" }}
                                    testID='checkMatchedOrder'
                                  >
                                    <View style={[styles.sameOrderAlertTopView]}>
                                      {
                                        checkShippingEasyOrder === true ?
                                          <Text style={[styles.alertBoxOutsideText]}>{i18n.t('scanpackItem.shippingEsaySameOrderTextAlert')} </Text> :
                                          <Text style={[styles.alertBoxOutsideText]}>{i18n.t('scanpackItem.shipStationSameOrderText')} </Text>
                                      }
                                    </View>

                                    {
                                      checkMultiOrderPresent.map((i, index) =>
                                      <>
                                          {
                                            this.props.searchOrder?.searchOrder?.data?.order[0]?.id != i.id ?
                                              <TouchableOpacity
                                                key={index}
                                                testID='redirectOrder'
                                                onPress={() => this.redirecToSameOrder(i.increment_id, i.store_order_id)}>
                                                <LinearGradient
                                                  start={{ x: 0, y: 1 }}
                                                  end={{ x: 0, y: 0 }}
                                                  colors={['#142130', '#304454']}
                                                  style={[styles.sameOrderAlertLinear]}>
                                                  <i
                                                    className="icon-attention"
                                                    style={{ color: 'orange', fontSize: '36px', position: 'static', }}
                                                  ></i>
                                                  {
                                                    checkShippingEasyOrder === true ? <Text style={[styles.alertInsideText]}>{i.increment_id} / {moment(i.order_placed_time).format("ll")} / To: {i.firstname} {i.lastname} / PCS: {i.items_count} {i.tracking_num != null ? `/ Tracking Last 6: ${i.tracking_num.slice(-6)}` : ""} / SID: {i.store_order_id}</Text>
                                                      :
                                                      <Text style={[styles.alertInsideText]}>{moment(i.order_placed_time).format("ll")} / To: {i.firstname} {i.lastname} / PCS: {i.items_count} {i.tracking_num != null ? `/ Tracking Last 6: ${i.tracking_num.slice(-6)}` : ""} / SID: {i.store_order_id}</Text>}
                                                </LinearGradient>
                                              </ TouchableOpacity>
                                              : <></>
                                          }

                                        </>)
                                    }
                                  </View>
                                </>
                              ) : <></>
                            }
                            {Order.unscanned_items[0].instruction !== '' &&
                              Order.unscanned_items[0].instruction !== null && (
                                <DisplayInstructions
                                 item={Order.unscanned_items[0]}
                                 width={this.state.windowWidth}
                                />
                              )}
                            {isRealTimeStatEnabled && <DisplayLiveStats />}
                            <View
                              style={commonStyles.nextItemRowFlex}
                            >
                              <OrderItemSuggested
                               redirectToItemDetail={(item)=>{this.props.redirectToItemDetail(item)}}
                               item={Order.unscanned_items[0]}
                               name={'suggestedItemSingle'}
                               testID={'redirectToItem'}
                               displayRow={rows.map(row => row)}
                               addBarcodeFun={(item)=>{this.props?.addBarcode(item)}}
                               getFontSize={this.getFontSize}
                               width={this.props.width}
                              />
                              <DisplaySuggestedImage
                                source={placeholder}
                                defaultSource={placeholder}
                                scannedQty={ Order.unscanned_items[0].scanned_qty}
                                totalQty={
                                  Order.unscanned_items[0].qty_remaining +
                                  Order.unscanned_items[0].scanned_qty
                                }
                                remainingQty={Order.unscanned_items[0].qty_remaining}
                                images={Order.unscanned_items[0].images}
                                id={Order.id}
                                order={Order}
                                onPressGetOrderData={() => {
                                  this.props.getOrderData(Order.increment_id);
                                  this.getUpdateSettings();
                                }}
                                getWidth={this.getWidth}
                                getMargin={this.getMargin}
                              />
                            </View>
                          </React.Fragment>
                        )}
                        <React.Fragment>
                          {localLogs && localLogs.length > 0 ? (
                            <DisplayLogs
                             checkMultiBoxActive = {checkMultiBoxActive}
                             logs={localLogs}
                             sliceNum={14}
                            />
                          ) : (
                            <View style={globalStyles.p_10}></View>
                          )}
                        </React.Fragment>
                      </View>
                    </View>
                  )}
                </View>
              ) : (
                <View
                  style={styles.noOrderContainer}>
                  <Text
                    style={globalStyles.textStyleTypeOne}>
                    No order is present to scan
                  </Text>
                </View>
              )}
            </View>
          ) : (
            //Mobile View Start from here...
            <NextItemMobileView
            {...this.props}
            imageCheck={() => this.imageCheck()}
            rowsChild={rowsChild}
            rows={rows}
            goNext={() => this.props.goNext()}
            goPrev={() => this.props.goPrev()}
            updateOrder={(id) => this.props.updateOrder(id)}
            activeBoxIndex={this.props.activeBoxIndex}
            serialRecordPopUpShow={this.props.serialRecordPopUpShow}
            orderDetail={order => this.orderDetail(order)}
            actionBarShowFunMobile={actionBarShow =>
              this.actionBarShowFunMobile(actionBarShow)
            }
            actionBarShow={this.state.actionBarShow}
            scanPlaceHolderValueFunMobile={blurValue =>
              this.scanPlaceHolderValueFunMobile(blurValue)
            }
            getUpdateSettings={() => this.getUpdateSettings()}
            numberOfKeysEven={numberOfKeysEven}
            numberOfKeys={numberOfKeys}
            scanPlaceHolderValue={this.state.scanPlaceHolderValue}
            checkMultiBoxActive={checkMultiBoxActive}
            windowWidth={this.state.windowWidth}
            //windowWidth={this.state.webBigImage}
            popInternet={this.state.popInternet}
            checkMultiOrderPresent = {this.props?.searchOrder?.searchOrder?.data?.matched_orders}
            checkShippingEasyOrder = {this.props?.searchOrder?.searchOrder?.data?.order[0]?.scan_hash?.data?.order?.multi_shipments?.se_all_shipments?.present}
            redirecToSameOrder ={(id,store_id)=>{this.redirecToSameOrder(id,store_id)}}
            unscannedCount={unscannedCount}
            scannedCount={scannedCount}
            typeScanState={this.props.typeScanState}
            isBarcodeEnter = {this.props.isBarcodeEnter}
            setIsBarcodeEnter = {this.props.setIsBarcodeEnter}
            scanAllOption={this.props.scanPackSetting.scan_all_option}
            getWidth={this.getWidth}
            getMargin={this.getMargin}></NextItemMobileView>
          )}
        </View>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    isSwap: state.menu.isSwap
  };
};
const mapDispatchToProps = {
  SwapUPC,
  GetOrderDetail,
  GetBothSettings,
  GetProductDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(NextItems);
