import React, {useState, useEffect, useRef} from 'react';
import {View, TouchableOpacity, Platform, Text, Dimensions, Vibration} from 'react-native';
import * as Clipboard from 'expo-clipboard';
import CommonStyles from '../../style/commonStyles';
import DropDown from '../../commons/dropDown';
import AlertBoxPermission from '../../commons/alertBoxPermission';
import OrderButton from '../../commons/orderStatusButton';
import { useDispatch } from 'react-redux';
import { FullOrderScan } from '../../actions/scanpackAction';
import { ResetSearchOrder } from '../../actions/orderActions';
import { useSelector } from 'react-redux';

const OrderInfoHeader = props => {
  const dispatch = useDispatch();
  const states = [
    {label: 'Awaiting', value: 'awaiting'},
    {label: 'Action Required', value: 'onhold'},
    {label: 'Service Issue', value: 'serviceissue'},
    {label: 'Cancelled', value: 'cancelled'},
    {label: 'Scanned', value: 'scanned'}
  ];

  const colors = [
    {firstColor: '#1CBB77', secondColor: '#7BFAC3', textColor: 'black'},
    {firstColor: '#CF7C08', secondColor: '#FFB538', textColor: 'black'},
    {firstColor: '#8E0000', secondColor: '#E41400', textColor: 'white'},
    {firstColor: '#045586', secondColor: '#056EAA', textColor: 'white'},
    {firstColor: '#979797', secondColor: '#D7D7D7', textColor: 'black'}
  ];

  const [showDropDown, setShowDropDown] = useState(false);
  const [permission, setPermission] = useState(false);
  const [index, setIndex] = useState(0);
  const [saveAndCloseTop, setSaveAndCloseTop] = useState(-100);
  const windowWidth = Dimensions.get('window').width;
  const statusButtonRef = useRef();
  const isHaptic = useSelector((state) => state.menu.isHaptics);

  const copyOrderNumberToClipboard = async () => {
    isHaptic && Vibration.vibrate(30);
    await Clipboard.setStringAsync(`${props.id?.increment_id}`);
  };

  const handleOnPress = () => {
    if (props.show) {
      setPermission(true);
    } else {
      setShowDropDown(!showDropDown);
    }
  };

  const closeAlert = () => {
    setPermission(false);
  };

  const handleSaveWithVibration = () => {
    props.isFromSearchPage && dispatch(ResetSearchOrder())
    isHaptic && Vibration.vibrate(30);
    props.onSave();
  };

  const handleCancelWithVibration = () => {
    props.isFromSearchPage && dispatch(ResetSearchOrder())
    isHaptic && Vibration.vibrate(30);
    props.onCancel();
  };

  const handleOrderStatus = (value, index) => {
    props.updateOrderStatus(value);
    if(value==='scanned'){
      setTimeout(()=>{
        dispatch(FullOrderScan(props.id?.id))
      },10000)
    }
    setIndex(index);
    setShowDropDown(!showDropDown);
    return false
  };
  useEffect(() => {
    const indexNumber = states.findIndex(object => {
      return object.value === props?.status;
    });
    setIndex(indexNumber);
    statusButtonRef?.current?.measure((_fx, _fy, _w, h, _px, py) => {
      setSaveAndCloseTop(_fy);
    });
  }, [props]);

  return (
    <>
      <AlertBoxPermission
        permissionState={permission}
        onCloseAlert={() => closeAlert()}
        top={8}
      />
      <View style={{position: 'relative', zIndex: 1}}>
        {props.id?.increment_id !== '' ? (
          <View>
            <Text
              style={{
                fontSize: windowWidth < 900 ? 18 :22,
                fontWeight: 'bold',
                marginHorizontal: 25,
                marginVertical: 8,
                marginTop: windowWidth < 900 ? '-3%': 8,
                marginLeft: windowWidth < 900 ? 0 : 25,
                fontFamily: 'Poppins_700Bold',
                background:
                  '-webkit-linear-gradient(\n90deg\n, #4b657d, #768ea5 80%)',
                WebkitTextFillColor: 'transparent',
                webkitBackgroundClip: 'text'
              }}>
              Order {props.id?.increment_id}
              <TouchableOpacity testID='copyToClip' onPress={copyOrderNumberToClipboard}>
                <i
                  className="icon-docs"
                  style={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    marginLeft: '5px',
                    background:
                      '-webkit-linear-gradient(\n90deg\n, #4b657d, #768ea5 80%)',
                    WebkitTextFillColor: 'transparent',
                    webkitBackgroundClip: 'text'
                  }}></i>
              </TouchableOpacity>
            </Text>
            <View ref={statusButtonRef} style={{position: 'relative'}}>
              <OrderButton
                label={states[index]?.label}
                width="fit-content"
                marginLeft={windowWidth < 900 ? 1 : false}
                fontSize={windowWidth > 900 ? 14 : 12}
                handleOnPress={handleOnPress}
                firstColor={colors[index]?.firstColor}
                secondColor={colors[index]?.secondColor}
                textColor={colors[index]?.textColor}
              />
              {showDropDown && (
                <DropDown
                  width="fit-content"
                  testID="statuslist"
                  pickerStates={states}
                  fontSize={windowWidth > 900 ? 14 : 12}
                  handlePickerChange={handleOrderStatus}
                  top={windowWidth > 900 ? 24 : 21}
                  marginLeft={windowWidth > 900 ? '1.4%' : 0}
                  alignSelf={'flex-start'}
                  colors={colors}
                />
              )}
            </View>
          </View>
        ) : (
          <Text
              style={CommonStyles.noIDText}
            >
            no id
          </Text>
        )}
      </View>
      {props.isListOpen && props.isListOpen ? (
        <></>
      ) : (
        <View
            style={[
             CommonStyles.saveButtonContainer,
             {top: saveAndCloseTop,marginRight: windowWidth < 900 && '-3%'}
             ]
            }
          >
          {!props.hideSaveBtn && (
            <TouchableOpacity
              testID='saveButton'
              style={CommonStyles.saveButton}
              onPress={handleSaveWithVibration}>
              <Text
                 style={CommonStyles.saveButtonText}
              >
                Save & Close
              </Text>
            </TouchableOpacity>
          )}

          <TouchableOpacity
            testID='cancelButton'
            onPress={handleCancelWithVibration}
            style={[CommonStyles.saveButton,{
              shadowColor: '#E853FA', shadowOffset: {width: 0, height: 0.5}, shadowRadius: 8,
            }]}
            >
            <Text
                style={CommonStyles.saveButtonText}
              >
              Cancel
            </Text>
          </TouchableOpacity>
        </View>
      )}
    </>
  );
};
export default OrderInfoHeader;
