import React, {Component} from 'react';
import {
  View,
  Text,
  TextInput,
  Image,
  TouchableOpacity,
} from 'react-native';
import styles from '../../style/orderdetail';
import {connect} from 'react-redux';
import {UpdateOrderQuantity} from '../../actions/orderActions';
import {ClearSearchProduct} from '../../actions/productAction';
import AlertBoxPermission from '../../commons/alertBoxPermission';
import AddItemToOrder from './add_item_to_order';
import OrderInfoHeader from '../scanpack/order-info-header';
import _ from 'lodash';
import CommonStyles from '../../style/commonStyles';
import {MobileOrderItem} from './mobile_order_Item';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import '@inovua/reactdatagrid-enterprise/theme/default-dark.css';

class ItemsDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      formattedItems: [],
      test: [],
      unTouch: true,
      loading: true,
      editable: Number,
      instruction: false,
      windowWidth: 0,
      lastSearch: '',
      order_id: '',
      addItem: false,
      itemadd: false,
      hideSaveClose: true,
      permissionState: false,
      updatedValue: Number
    };
    window.addEventListener('resize', this.update);
  }
  isQty = false
  itemPresent = null

  filterValue = [
    { name: 'ProductName', operator: 'contains', type: 'string' },
    { name: 'SKU', operator: 'contains', type: 'string' },
    { name: 'Status', operator: 'startsWith', type: 'string' },
    { name: 'Barcode', operator: 'startsWith', type: 'string' },
    { name: 'QTYOrdered', operator: 'eq', type: 'string' },
    { name: 'Locationl', operator: 'startsWith', type: 'string' },
    { name: 'QOH', operator: 'eq', type: 'string' }
  ]
  
  columns = [
    { name: 'removeItem', header: '', maxWidth: 50, defaultFlex: 2, showColumnMenuSortOptions: false,
    render: ({value})=> (
      <TouchableOpacity
        testID="removeItem"
        dataSet={{componentName: 'removeItemFromOrder'}}
        onPress={() => {
          if (this.props.show === true) {
            this.permissionCheck();
          } else {
            this.removeNewItem(value);
          }
        }}
        style={[styles.itemView, {width: '100%', marginHorizontal: '1%'}]}>
        <i
            className="icon-cancel"
            style={{fontSize: '22px', color: 'gray'}}
          ></i>
      </TouchableOpacity>
    ) 
  },
    { name: 'Image', header: '', maxWidth: 100, defaultFlex: 2, showColumnMenuSortOptions: false,
      render: ({ value })=> (
        <Image source={{ uri: value }} style={{ width: 50, height: 36, left: '17%', borderRadius: '4px' }} />
      ) 
    }
    ,
    { name: 'ProductName', header: 'Product Name', maxWidth: 1000, showColumnMenuSortOptions: false, defaultFlex: 2, render: ({ value }, { cellProps })=> (
      <TouchableOpacity
        testID="ProductName"
        onPress={() => {
          this.props.ClearSearchProduct();
          const item = this.state.items.find(item => cellProps.data.currentItem.iteminfo.id === item.iteminfo.id);
          this.props.goToProductDetail(item.productinfo.id);
        }}>
        <Text style={[styles.itemName, {color: '#aad9fc', fontSize: 15}]} numberOfLines={1} ellipsizeMode="tail" >
          {value}
        </Text>
      </TouchableOpacity>
      ) 
    },
    { name: 'SKU', header: 'SKU', maxWidth: 800, defaultFlex: 1, showColumnMenuSortOptions: false },
    { name: 'Status', header: 'Status', maxWidth: 100, defaultFlex: 2, showColumnMenuSortOptions: false },
    { name: 'Barcode', header: 'Barcode', maxWidth: 1000, defaultFlex: 1, showColumnMenuSortOptions: false },
    { name: 'QTYOrdered', header: 'QTY', maxWidth: 80, defaultFlex: 2, showColumnMenuSortOptions: false, type: 'number', render: ({ value }, { cellProps }) => {
      return (
        <View style={[styles.itemView, CommonStyles.qtyOrderStyle, {marginLeft: '30%' }]}>
            <TextInput
            testID="updateQty"
            placeholderTextColor="white"
            value={cellProps.data.currentItem.iteminfo.qty}
            onChangeText={text => 
              {
                if (this.handleEditAbleIndex(cellProps.data.id)){
                  this.itemPresent= this.state.items.find(item => cellProps.data.currentItem.iteminfo.id === item.iteminfo.id);
                  const formattedItems = this.formatItems(this.props.propsData?.order?.order?.items);
                  this.setState({
                    formattedItems: formattedItems
                  })
                  this.props.handleChange(text, this.itemPresent, cellProps.data.id -1) 
                }
              }
            }
            onSubmitEditing={() => this.handleChanges(cellProps.data.currentItem, cellProps.data.id -1)}
            onBlur={() => this.handleChanges(cellProps.data.currentItem, cellProps.data.id -1)}
            style={CommonStyles.updateQtyStyle}
            />
        </View>
      )
    }},
    { name: 'Locationl', header: 'Location l', maxWidth: 1000, defaultFlex: 1, showColumnMenuSortOptions: false },
    { name: 'QOH', header: 'QOH', maxWidth: 80, defaultFlex: 1, showColumnMenuSortOptions: false },
  ];
  gridStyle = { minHeight: 375, height: '100%' };
  componentDidUpdate() {
    if (
      !_.isEqual(this.state.items, this.props.propsData?.order?.order?.items) ||
      this.state.order_id !=
        this.props.propsData?.order?.order?.basicinfo?.increment_id
    ) {
      this.setState({
        items: this.props.items?.items,
        order_id: this.props.propsData?.order?.order?.basicinfo?.increment_id,
        itemadd: false
      });
      const formattedItems = this.formatItems(this.props.propsData?.order?.order?.items);
      this.setState({
        formattedItems: formattedItems
      })
      this.props.resetFromProductPage()
    }
  }
  hideSaveFun = () => {
    this.setState({hideSaveClose: !this.state.hideSaveClose});
  };

  formatItems = (items) => {
    return items?.map((item, index) => ({
      id: index+1,
      currentItem: item,
      removeItem: item,
      Image: item.image,
      ProductName: item.productinfo.name || '',
      SKU: item.sku || '',
      Status: this.Capitalize(item.productinfo.status) || '',
      Barcode: item.barcode || '',
      QTYOrdered: item.iteminfo.qty.toString() || '',
      Locationl: item.location_primary || '',
      QOH: item.qty_on_hand.toString() || ''
    }));
  }

  imageRenderer = ({ value }) => {
    return <Image source={{ uri: value }} style={{ width: 50, height: 50 }} />;
  }

  
  componentDidMount() {
    this.secondTextInput = React.createRef();
    const {propsData} = this.props;
    const {order} = propsData?.order || {};
    const {items, basicinfo} = order || {};
    const {increment_id} = basicinfo || {};
    if (items && items.length && increment_id) {
      this.setState({
        items: items,
        order_id: increment_id,
        loading: items.length !== 0 ? false : true
      });
      const formattedItems = this.formatItems(items);
      this.setState({
        formattedItems: formattedItems
      })
    }
    this.update();
  }
  update = () => {
    this.setState({
      windowWidth: window.innerWidth
    });
  };
  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  addItemCall = () => {
    this.props.addOrderItem(this.state.items);
    this.setState({itemadd: true});
  };

  permissionCheck = () => {
    if (this.props.show === true) {
      this.setState({permissionState: true});
    }
  };

  onCloseAlert = () => {
    if (this.props.show === true) {
      this.setState({permissionState: false});
    }
  };
  handleChanges = (item, index) => {
    this.isQty = false
    const {editable} = this.state;
    const {updateQuantity, removeIds, removeOrderItem, removeItems} =
      this.props;
    if (editable > -1) {
      setTimeout(() => {
        if (editable === index) {
          this.setState({editable: -1});
        }
      }, 200);
    }
    if (item.iteminfo.qty > 0) {
      updateQuantity(item);
    } else {
      if (removeIds.includes(item.iteminfo.id)) {
        removeOrderItem(item);
        removeItems();
      }
    }
  };

  handleEditAbleIndex = index => {
    if (this.props.show) {
      this.permissionCheck();
      return false;
    }
    this.setState({editable: index});
    return true;
  };
  removeNewItem = (item) => {
    this.props.removeOrderItem(item);
    this.props.removeItems();
  };
  render() {
    return (
      <>
        <AlertBoxPermission
          permissionState={this.state.permissionState}
          onCloseAlert={() => this.onCloseAlert()}
        />
        <View
          contentContainerStyle={{flexGrow: 1}}
          horizontal={false}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}>
          <View
            testID="itemsContainer"
            style={[CommonStyles.packingCamContainer, {marginLeft: ''}]}
          />
          <View style={{marginHorizontal: 20, height: this.state.windowWidth > 1440 && '70rem'}}>
            <View>
              <OrderInfoHeader
                status={this.props.propsData?.order?.order?.basicinfo?.status}
                onSave={this.props.updateBasicInfo}
                onCancel={this.props.back}
                id={this.props.items?.basicinfo}
                updateOrderStatus={this.props.updateOrderStatus}
                isListOpen={this.state.itemadd}
                show={this.props.show}
                isFromSearchPage={this.props.isFromSearchPage}
              />
            </View>

            {this.state.itemadd ? (
              <View>
                <AddItemToOrder
                  onchange={() => this.setState({itemadd: !this.state.itemadd})}
                  {...this.props}
                  hideSaveFun={() => this.hideSaveFun()}
                  upDateState={value => {
                    this.setState({lastSearch: value});
                  }}
                  lastSearched={this.state.lastSearch}
                />
              </View>
            ) : (
              <View style={[CommonStyles.orderInfoStyle, {zIndex: -1, fontFamily: 'Poppins_300Light', paddingBottom: '13px',
                height: this.state.windowWidth >= 900 ? '84%' : '', 
                backgroundColor: this.state.windowWidth >= 900 ? 'rgb(49, 57, 67)': 'rgba(104, 135, 156, 0.4)'}]}>
                <View style={CommonStyles.itemDetailStyle}>
                  <TouchableOpacity
                    testID="addItemToOrder"
                    onPress={() => {
                      {
                        this.props.show === true
                          ? this.permissionCheck()
                          : this.addItemCall();
                      }
                    }}
                    style={CommonStyles.itemDetailAddItem}>
                    <i
                      className="icon-plus"
                      style={{
                        color: 'white',
                        fontSize: '32px',
                        marginTop: '5%'
                      }}></i>
                    <View>
                      <Text style={CommonStyles.ItemDetailAddItemText}>
                        Add Items
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>
                {this.state.windowWidth >= 900 ? (
                  <View style={{height: this.state.windowWidth > 1440 && '92%'}}>
                    <ReactDataGrid
                      idProperty="id"
                      columns={this.columns}
                      dataSource={this.state.formattedItems}
                      style={this.gridStyle}
                      theme={'default-dark'}
                      showZebraRows={false}
                      pagination
                      defaultFilterValue={this.filterValue}
                      className="global-datagrid-3px-tomato-border"
                    />
                  </View>
                ) : (
                  <View style={CommonStyles.itemDetailMobile}>
                    {this.state.items &&
                      this.state.items.length > 0 &&
                      this.state.items.map((item, index) => (
                        <MobileOrderItem
                          key={index}
                          item={item}
                          index={index}
                          editable={this.state.editable}
                          show={this.props.show}
                          permissionCheck={this.permissionCheck}
                          handleEditAbleIndex={index =>
                            this.handleEditAbleIndex(index)
                          }
                          removeIds={this.props.removeIds}
                          removeNewItem={item =>
                            this.removeNewItem(item)
                          }
                          removeItems={this.props.removeItems}
                          ClearSearchProduct={this.props.ClearSearchProduct}
                          goToProductDetail={this.props.goToProductDetail}
                          handleChange={this.props.handleChange}
                          handleChanges={this.handleChanges}
                          Capitalize={this.Capitalize}
                        />
                      ))}
                  </View>
                )}
              </View>
            )}
          </View>
        </View>
      </>
    );
  }
}

const mapStateToProps = state => {
  const propsData = state;
  return {
    propsData
  };
};

const mapDispatchToProps = {
  UpdateOrderQuantity,
  ClearSearchProduct
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemsDetail);
