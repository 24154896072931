import React,{useState,useEffect} from 'react';
import { View,Text,TouchableOpacity } from 'react-native';
import {FontAwesome} from '@expo/vector-icons';
import CommonStyles from '../../style/commonStyles';

const ActivitiesButtons = (props) => {
  const [selectedIndex,setSelectedIndex] = useState(null);
  const data = [
    {
      label: 'Quantity Related',
      value: 'qty_related'
    },
    {
      label: 'Incorrect Item',
      value: 'incorrect_item'
    },
    {
      label: 'Missing Item',
      value: 'missing_item'
    },
    {
      label: 'Damaged Item',
      value: 'damaged_item'
    },
    {
      label: 'Special instruction missed',
      value: 'special_instruction'
    },
    {
      label: 'Other',
      value: 'other'
    }
  ]
  const onSelect = (index) => {
    if (props.show ) {
       props.showModal()
    }
    else {
      setSelectedIndex(index);
      props.setChecked(data[index].value)
    }
  }
  useEffect(()=>{
    setSelectedIndex(null)
  },[props.initialSelected])
  return (
     <View style={props.style}>
      {data.map((obj,index)=>(
       <TouchableOpacity
       testID={'Button'+ index}  
       key={index}
       onPress={()=>{
        onSelect(index)
       }}
       >
          <View
            testID='activityButtonContainer'
           style={{display: 'flex', flexDirection: 'row',marginTop: 5}}
          >
            {index === selectedIndex ?(
            <FontAwesome
              name="dot-circle-o"
              style={{resizeMode: 'contain'}}
              size={28}
              color="rgba(250, 250, 250, .7)"
            />
            ) : (
            <FontAwesome
              name="circle-o"
              style={{resizeMode: 'contain'}}
              size={28}
              color="rgba(250, 250, 250, .7)"
            />)
          }
          <Text
          style={[CommonStyles.exceptionTextStyle,{marginLeft: '1%'}]}
          >
          {obj.label}
          </Text>
          </View>
        </TouchableOpacity>
      ))}
     </View>
  )
}
export default ActivitiesButtons;
