import React, {Component, useRef} from 'react';
import i18n from 'i18n-js';
import styles from '../../style/popupmodel';
import CommonStyles from '../../style/commonStyles';
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Dimensions
} from 'react-native';
import {LinearGradient} from 'expo-linear-gradient';
import DefaultInput from '../../commons/Inputs/defaultInput';
export default class AlertBoxShippingLabel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: Dimensions.get('window').width
    };
    this.trackingFocusAlert = React.createRef();
  }
  componentDidUpdate() {
    this.trackingFocusAlert.current.focus();
  }
  updateDimensions = () => {
    this.setState({windowWidth: window.innerWidth});
  };
  componentWillUnmount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  navigateState = () => {
    this.props.navigation.navigate('ScanPack');
  };
  render() {
    let tracking_no = this.props?.trackState;
    return (
      <>
        {this.props.orderConfirmationPer ? (
          <View style={styles.alertContainer}>
            <LinearGradient
              locations={[0, 1]}
              colors={['rgb(207,68,55)', 'rgb(122,40,40)']}
              style={[
                CommonStyles.gradientContainerTwo,
                {width: this.state.windowWidth >= 600 ? '60%' : '90%'}
              ]}
            >
              <TouchableOpacity
                testID='cancelIcon'
                onPress={() => this.navigateState()}
                style={CommonStyles.cancelIcon}
              >
                <i
                  className="icon-cancel"
                  style={{fontSize: '22px', color: 'white'}}
                ></i>
              </TouchableOpacity>
              <Text
                style={CommonStyles.textStyleOne}
              >
                {i18n.t('popupTracking.header')}
              </Text>
              <Text
                style={CommonStyles.textStyleTwo}
              >
                {i18n.t('popupTracking.noUser_permission_text_one')}{' '}
              </Text>
              <DefaultInput
                {...this.props}
                placeholder="Scan"
                name="trackingOrder"
                autoFocus={true}
                ref={this.trackingFocusAlert}
                value={this.props.trackingOrderInput}
                onChangeText={this.props.onChangeText}
                onSubmitEditing={this.props.onSubmitEditing}
                style={[styles.alertBoxShipping, {width: '94%'}]}
              />
              <Text
                style={CommonStyles.textStyleThree}
              >
                {i18n.t('popupTracking.noUser_permission_text_two')}
              </Text>
            </LinearGradient>
          </View>
        ) : (
          <View style={styles.alertContainer}>
            <LinearGradient
              locations={[0, 1]}
              colors={['rgb(207,68,55)', 'rgb(122,40,40)']}
              style={[
                CommonStyles.gradientContainerTwo,
                {width: this.state.windowWidth >= 600 ? '60%' : '90%'}
              ]}
            >
              <TouchableOpacity
                testID='cancelIcon'
                onPress={() => this.navigateState()}
                style={CommonStyles.cancelIcon}
              >
                <i
                  className="icon-cancel"
                  style={{fontSize: '22px', color: 'white'}}
                ></i>
              </TouchableOpacity>
              <Text
                style={CommonStyles.textStyleOne}
              >
                Please try again
              </Text>
              <Text
                style={CommonStyles.textStyleTwo}
              >
                {`The barcode you have scanned `}
                <b>{tracking_no}</b>{' '}
                {`does not match the tracking number imported with this order.`}{' '}
              </Text>
              <TextInput
                {...this.props}
                placeholder="Scan"
                name="trackingOrder"
                autoFocus={true}
                ref={this.trackingFocusAlert}
                blurOnSubmit={false}
                value={this.props.trackingOrderInput}
                onChangeText={this.props.onChangeText}
                onSubmitEditing={this.props.onSubmitEditing}
                onKeyPress={(e)=>{
                  if(e.key === 'Enter'){
                  this.props.onSubmitEditing()
                  }
                }}
                style={[styles.alertBoxShipping, {width: '94%'}]}
              />
              <Text
                style={CommonStyles.textStyleThree}
              >
                {i18n.t('scanpackItem.Scanpack_Item_shipping_msg_two')}
              </Text>
            </LinearGradient>
          </View>
        )}
      </>
    );
  }
}
