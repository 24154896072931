import React, {Component} from 'react';
import {
  View,
  SafeAreaView,
  Text,
  ActivityIndicator,
  TextInput,
  TouchableOpacity,
  ScrollView,
  Alert,
  Platform
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {connect} from 'react-redux';
import NetInfo from '@react-native-community/netinfo';
import {GetOrderList} from '../../actions/orderActions';
import {SearchScanpackOrder} from '../../actions/scanpackAction';
import {GetGeneralSetting} from '../../actions/userAction';
import globalStyles from '../../style/global';
import styles from '../../style/orderlist';
import axios from 'axios';

class OrderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        filter: 'awaiting',
        order: 'DESC',
        limit: '1',
        offset: '0',
        app: 'app',
        count: 0
      },
      scanpack: {
        input: '',
        state: 'scanpack.rfo',
        id: null,
        box_id: null,
        store_order_id: null,
        app: 'app'
      },
      connection_Status: '',
      updateData: [],
      searchOrdersCheck: [],
      errorMessage: 'No Record Found',
      loading: false,
      loaderTitle: 'Loading...'
    };
    this.searchField = React.createRef();
  }

  componentDidMount = async () => {
    this.setState({loading: true});
    this.hitApi();
  };

  //According to platform call the function
  hitApi = () => {
    if (Platform.OS === 'ios' || Platform.OS === 'android') {
      this.checkConnection();
    } else {
      this.apiCall();
    }
  };

  //Check the connection for the ios and android
  checkConnection = async () => {
    const connectionInfo = await NetInfo.fetch();
    if (connectionInfo.isConnected) {
      this.apiCall();
    } else {
      this.setState({errorMessage: 'Please check your internet connection'});
    }
  };

  //Call the api to fetch the data from backend
  apiCall = () => {
    this.props.GetOrderList(this.state.data);
  };

  //Set focus on the field
  setFocusField = () => {
    if (!this.state.loading) {
      this.searchField.current.focus();
    }
  };

  //Logout function
  logout = async () => {
    try {
      const access_token = await AsyncStorage.getItem('access_token')

      const url = await AsyncStorage.getItem('url');

      try {
        await axios({
          method: 'post',
          url: `${url}/auth/v1/logout`,
          headers: { Authorization: `Bearer ${access_token}` },
          data: {
            token: access_token
          }
        });
      } catch (e) {}

      await AsyncStorage.removeItem('access_token');

      this.props.navigation.reset({index: 0, routes: [{name: 'SignIn'}]});
    } catch (e) {
      alert('Failed to clear the async storage.');
    }
  };

  //Handle the input for the search order field
  handleChange = (name, value) => {
    const scanpack = {...this.state.scanpack};
    if (name === 'searchOrder') {
      scanpack.input = value;
    }
    this.setState({scanpack});
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.ordersList &&
      this.props.ordersList !== prevProps.ordersList
    ) {
      this.setState({
        searchOrdersCheck: this.props.ordersList,
        orders: this.props.ordersList.orders,
        loading: false
      });
      this.setFocusField();
    }

    if (
      this.props.SearchScanPackOrder &&
      this.props.SearchScanPackOrder !== prevState.searchOrder
    ) {
      this.setState({
        orders: this.props.SearchScanPackOrder.data.order,
        searchOrder: this.props.SearchScanPackOrder
      });
      this.setFocusField();
    }
  }

  //Redirect ot the scanpack Item page
  searchProduct = item => {
    debugger
    if (item.scan_hash !== null) {
      this.props.navigation.navigate('ScanPackItem', {
        data: item.scan_hash.data
      });
    }
  };

  //Search the order when useer hit the enter on the field
  searchOrder = () => {
    this.props.SearchScanpackOrder(this.state.scanpack);
  };

  itemDetail = item => {
    if (item) {
      this.props.navigation.navigate('OrderDetail', {item});
    }
  };

  render() {
    return (
      <SafeAreaView style={globalStyles.flex1}>
        {this.state.loading ? (
          <View style={{flex: 1, justifyContent: 'center'}}>
            <ActivityIndicator size="large" color="#336599" />
            <Text
              style={{fontSize: 13, fontWeight: '500', textAlign: 'center'}}>
              {this.state.loaderTitle}
            </Text>
          </View>
        ) : (
          <>
            <View style={globalStyles.flexDirectionRow}>
              <View style={[globalStyles.flexDirectionRow, globalStyles.flex7]}>
                {/* <TextInput
                  placeholder="Type order to search"
                  name="searchOrder"
                  autoFocus={true}
                  ref={this.searchField}
                  value={this.state.scanpack.input}
                  onChangeText={text => {
                    this.handleChange('searchOrder', text);
                  }}
                  onSubmitEditing={this.searchOrder.bind(this)}
                  style={styles.inputBox}
                /> */}
              </View>
            </View>
            {this.props.ordersList && this.state.orders && (
              <View>
                {this.state.orders.length > 0 ? (
                  <ScrollView style={styles.listContainer}>
                    {this.state.orders.map((item, index) => (
                      <TouchableOpacity
                        key={index}
                        onPress={() => this.itemDetail(item)}
                        onLongPress={() => this.searchProduct(item)}
                        delayLongPress={1000}>
                        <View style={styles.orderRow}>
                          <View
                            style={[globalStyles.flex2, styles.orderColumn]}>
                            <Text style={styles.text}>S.No.</Text>
                            <Text style={styles.text}>{index + 1}</Text>
                          </View>
                          <View
                            style={[globalStyles.flex3, styles.orderColumn]}>
                            <Text style={styles.text}>Order #</Text>
                            <Text style={styles.text}>
                              {item.order_info.ordernum}
                            </Text>
                          </View>
                          <View
                            style={[globalStyles.flex3, styles.orderColumn]}>
                            <Text style={styles.text}>Store</Text>
                            <Text style={styles.text}>
                              {item.order_info.store_name}
                            </Text>
                          </View>
                          <View
                            style={[globalStyles.flex2, styles.orderColumn]}>
                            <Text style={styles.text}>Item</Text>
                            <Text style={styles.text}>
                              {item.order_info.itemslength}
                            </Text>
                          </View>
                          <View
                            style={[globalStyles.flex3, styles.orderColumn]}>
                            <Text style={styles.text}>Status</Text>
                            <Text style={styles.text}>
                              {item.order_info.status}
                            </Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    ))}
                  </ScrollView>
                ) : (
                  <View style={{alignItems: 'center'}}>
                    <Text style={{fontSize: 16, fontWeight: '500'}}>
                      {this.state.errorMessage}
                    </Text>
                  </View>
                )}
              </View>
            )}
            <View style={styles.buttonContainer}>
              <TouchableOpacity onPress={() => this.hitApi()}>
                <Text style={[styles.button]}>All Orders</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={this.logout}>
                <Text style={[styles.button, globalStyles.m_10]}>Logout</Text>
              </TouchableOpacity>
            </View>
          </>
        )}
      </SafeAreaView>
    );
  }
}

const mapStateToProps = state => ({
  ordersList: state.order.list,
  searchOrder: state.order.searchResult,
  SearchScanPackOrder: state.scanpack.searchOrder,
  generalSettings: state.user.settings,
  saveData: state.updateAsync.retriveData
});

const mapDispatchToProps = {
  GetOrderList,
  SearchScanpackOrder,
  GetGeneralSetting
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderList);
