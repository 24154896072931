import {LinearGradient} from 'expo-linear-gradient';
import React, {Component, useRef} from 'react';
import {Dimensions, Image, Text, TouchableOpacity, View} from 'react-native';
import close from '../../assets/closeicon.png';
import i18n from 'i18n-js';
// import Close from "../../closeicon.png";
// let linkShare = window.open("google.com")
export default class internateConnectionAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: Dimensions.get('window').width,
      hideModalAlert: true
    };
  }
  updateDimensions = () => {
    this.setState({
      windowWidth: window.innerWidth
    });
  };
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  // hideModal = () => {
  //   this.setState({hideModalAlert : false})
  // }
  componentWillUnmount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  openUrl = () => {
    let url = 'https://www.speedtest.net/';
    window.open(url);
  };
  render() {
    return (
      <>
        <View>
          <LinearGradient
            colors={['#a73737', '#e74c3c', '#7a2828']}
            style={{
              position: 'fixed',
              flexDirection: 'row',
              // height: 40,
              display: 'flex',
              alignItems: 'center',
              // justifyContent: "center",
              top: 120,
              left: 0,
              right: 0,
              marginLeft: 'auto',
              marginRight: 'auto',
              padding: 4,
              marginTop: 20,
              shadowOffset: {width: 0, height: 0},
              shadowRadius: 3.84,
              shadowColor: '#000',
              shadowOpacity: 0.9,
              zIndex: 9,
              // width:this.state.windowWidth >= 600 ? "60%" : "90%"
              width: this.state.windowWidth >= 600 ? '50%' : '90%',
              borderRadius: 5,
              zIndex: 9
            }}
          >
            <Text
              style={{
                marginLeft: 5,
                marginRight: 5,
                fontWeight: 'bold',
                fontSize: 16,
                color: '#fff'
              }}
            >
              {i18n.t(`session.connectionCheck`)}
              <TouchableOpacity testID='openUrl' onPress={() => this.openUrl()}>
                <Text style={{textDecorationLine: 'underline'}}>
                  {i18n.t(`session.checkConnection`)}
                </Text>
              </TouchableOpacity>
              <Text>{i18n.t(`session.tryAgain`)}</Text>
            </Text>

            {/* <TouchableOpacity onpress={() => { this.openplease() }}>
              <Text>{i18n.t(`session.checkConnection`)}</Text>
            </TouchableOpacity> */}

            {/* <TouchableOpacity style={{ position: "absolute", right: 11 }} onPress={() => { hideModal() }}>
                <Image source={close ? close : ""}
                  style={{ height: 20, width: 20 }} /> */}
            {/* <Text style={{ color: '#fff', fontSize: 14, fontWeight: 'bold' }}>OK</Text> */}

            {/* </TouchableOpacity> */}
          </LinearGradient>
        </View>
      </>
    );
  }
}
