import React, {useEffect, useLayoutEffect, useState} from 'react';
import {View, Text, TouchableOpacity, Dimensions} from 'react-native';
import styles from '../../style/popupmodel';
import {useDispatch} from 'react-redux';
import {LinearGradient} from 'expo-linear-gradient';
import {UpdateOrderStatus} from '../../actions/orderActions';

export default function ReScanOrderPopup(props) {
  const dispatch = useDispatch();
  const [windowWidth, setWindowWidth] = useState(
    Dimensions.get('window').width
  );

  useLayoutEffect(() => {
    function updateSize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <>
      <View style={styles.rescanOrderMainView}>
        <LinearGradient
          locations={[0, 1]}
          colors={['#FFB538', '#CF7C08']}
          style={
            windowWidth >= 900
              ? styles.linearGradMainView
              : styles.linearGradMainViewMobile
          }>
          <TouchableOpacity
            testID="close-model"
            style={{height: 25, width: 34, marginLeft: 'auto'}}
            onPress={() => props?.closePopupRescan()}>
            <i
              className="icon-cancel"
              style={{
                color: '#000',
                fontSize: '24px'
              }}></i>
          </TouchableOpacity>
          <View>
            <Text style={styles.alreadyScanHead}>{props?.popupHead}</Text>
          </View>
          <View
            style={
              windowWidth >= 900
                ? styles.buttonMainView
                : styles.buttonMainViewMobile
            }>
            <TouchableOpacity
              testID="await-btn"
              onPress={() => props?.awaitOrderDataFun(false)}
              style={styles.buttonBox}>
              <View>
                <Text style={styles.buttonTextOrderRescan}>{props.preOrderRescan}</Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              testID="close-rescan-popup"
              onPress={() => props?.awaitOrderDataFun(true)}
              style={styles.buttonBox}>
              <View>
                <Text style={styles.buttonTextOrderRescan}>{props?.newItemQues}</Text>
              </View>
            </TouchableOpacity>
          </View>
        </LinearGradient>
      </View>
    </>
  );
}
