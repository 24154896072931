import AsyncStorage from '@react-native-async-storage/async-storage';
import i18n from 'i18n-js';

export function ShuffleTheItem(order, ItemSku, childItemSku) {
  let orderData = order.order;
  const index = orderData.unscanned_items.findIndex(object => {
    return object.sku === ItemSku;
  });
  const item = orderData.unscanned_items[index];
  if (childItemSku) {
    const childItemIndex = item.child_items.findIndex(object => {
      return object.sku === childItemSku;
    });
    const childItem = item.child_items[childItemIndex];
    item.child_items.splice(childItemIndex, 1);
    item.child_items.unshift(childItem);
  }
  orderData.unscanned_items.splice(index, 1);
  orderData.unscanned_items.unshift(item);
  return { order: orderData }
}

export function RestartLog(order, scanPackSettings, localLogs, userName = '') {
  let order_num = order.order.id;
  let Log_count = '';
  let SKU = '';
  let product_name = '';
  let scanned_items = order.order.scanned_items;
  let unscanned_items = order.order.unscanned_items;
  let remain_qty =
    unscanned_items.length > 0 ? unscanned_items[0].qty_remaining : null;
  let temp_order = {}
  temp_order['input'] = scanPackSettings.restart_code;
  temp_order['id'] = order_num;
  temp_order['order_item_id'] =
    unscanned_items.length > 0
      ? unscanned_items[0].order_item_id
      : scanned_items.length > 0
        ? scanned_items[0].order_item_id
        : '';
  temp_order['time'] = new Date();
  temp_order['rem_qty'] = remain_qty;
  temp_order['SKU'] = SKU ? SKU : unscanned_items[0].sku;
  temp_order['Log_count'] = Log_count;
  temp_order['product_name'] = product_name;
  temp_order['name'] = userName
  temp_order['state'] = 'scanpack.rfp.default';
  temp_order['event'] = 'regular';
  temp_order['on_ex'] = 'on GPX'
  temp_order['updated_at'] = order.order.updated_at;
  temp_order['increment_id'] = order.order.increment_id;
  return temp_order;
}

export function SerialRecordLog(serialRecordInput, userName= '', type, item, recordFrom, order_id,both) {
  let temp = {};
  temp['clicked'] = type === 'clickscan' ? true : false;
  temp['barcode'] = item?.barcodes[0]?.barcode;
  temp['order_id'] = order_id;
  temp['order_item_id'] = item?.order_item_id;
  temp['product_lot_id'] = null;
  temp['ask'] = recordFrom === 'recordOne' ? true : false;
  temp['ask_2'] = recordFrom === 'recordTwo' ? true : false;
  temp['product_id'] = item?.product_id;
  if (both === false) {
    temp['is_scan'] = true;
    temp['second_serial'] = false;
  } else {
    if (recordFrom === 'recordOne') {
      temp['is_scan'] = false;
      temp['second_serial'] = false;
    } else {
      temp['is_scan'] = true;
      temp['second_serial'] = true;
    }
  }
  temp['box_id'] = null;
  temp['serial'] = serialRecordInput;
  temp['event'] = 'serial_scan';
  temp['on_ex'] = 'on GPX'
  let localLog = {};
  localLog['order_item_id'] = item?.order_item_id;
  localLog['time'] = new Date();
  localLog['event'] = 'serial_scan';
  localLog['SKU'] = item?.sku ?? '';
  localLog['name'] = userName;
  return { temp, localLog }
}

export function handleByPassLog(loggingArray, userName = '', order) {
  let LoggingArray = loggingArray;

  if (LoggingArray.length >= 80) {
    LoggingArray.splice(0, 1);
  }

  let SKU = order?.order.unscanned_items?.length > 0 &&
    order.order.unscanned_items[0].child_items.length > 0
    ? order.order.unscanned_items[0].child_items[0].sku
    : order.order.unscanned_items[0].sku;

  let lastLog = {
    event: 'by_passed',
    id: order.order.id,
    name: userName,
    time: new Date(),
    Log_count: '',
    SKU: SKU
  };

  LoggingArray.push(lastLog);

  return {LoggingArray,SKU,lastLog};
}

export function submitTrackingLog (order, state, event, input) {
  let temp_order = {}
  temp_order['id'] = order.order.id;
  temp_order['input'] = input;
  temp_order['state'] = state;
  temp_order['event'] = event;
  temp_order['updated_at'] = order.order.updated_at;
  temp_order['increment_id'] = order.order.increment_id;
  return temp_order;
}
export function AddNoteLog (order, notes_fromPacker,input = '', userName = '',event) {
   let temp_order = {}
    temp_order['message'] = notes_fromPacker;
    temp_order['input'] = input;
    temp_order['id'] = order.order.id;
    temp_order['order_item_id'] = '';
    temp_order['time'] = new Date();
    temp_order['state'] = '';
    temp_order['event'] = event;
    temp_order['SKU'] = '';
    temp_order['name'] = userName
    temp_order['updated_at'] = order.order.updated_at;
    temp_order['increment_id'] = order.order.increment_id;
   return temp_order;
}
export function scanAllItemLog (order,input, next_state,event, items, SKU,userName = '', scanAllCount = 0, scanned_count = 0,product_id_child) {
  let temp_order = {}
  temp_order['input'] = input;
  temp_order['id'] = order.order.id;
  temp_order['order_item_id'] = items?.order_item_id;
  temp_order['time'] = new Date();
  temp_order['state'] = next_state;
  temp_order['event'] = event;
  temp_order['on_ex'] = 'on GPX'
  temp_order['SKU'] = SKU ? SKU : '';
  temp_order['name'] = userName
  temp_order['updated_at'] = order.order.updated_at;
  temp_order['increment_id'] = order.order.increment_id;
  temp_order['total_qty'] = scanAllCount - scanned_count
  temp_order['product_id']= product_id_child
  return temp_order;
}
export function typeScanLog(order, input, count, event, items, SKU, Box, userName = '') {
  let temp_order = {}
  temp_order['input'] = input;
  temp_order['id'] = order.order.id;
  temp_order['order_item_id'] = items?.order_item_id;
  temp_order['time'] = new Date();
  temp_order['box_id'] = null;
  temp_order['state'] = 'scanpack.rfp.default';
  temp_order['count'] = count;
  temp_order['on_ex'] = 'on GPX'
  temp_order['event'] = event;
  temp_order["box_name"] = Box;
  temp_order['SKU'] = SKU ? SKU : '';
  temp_order['name'] = userName;
  temp_order['updated_at'] = order.order.updated_at;
  temp_order['increment_id'] = order.order.increment_id;
  return temp_order;
}
export function InstructionCheck(product, order) {
  if (product) {
    return product?.instruction?.length > 0;
  }
  let unScannedItem = order?.order?.unscanned_items[0];
  let instructions = unScannedItem?.child_items?.length > 0
    ? unScannedItem?.child_items[0]?.instruction
    : unScannedItem?.instruction;
  if (instructions?.length > 0) {
    return true;
  }
  return false;
}

export function QtyCheck(product, order) {
  if (product) {
    return product.scanned_qty == 0;
  }
  let unScannedItem = order?.order?.unscanned_items[0];
  let qty = unScannedItem?.child_items?.length > 0
    ? unScannedItem?.child_items[0]?.scanned_qty
    : unScannedItem?.scanned_qty;
  if (qty === 0) {
    return true;
  }
  return false;
}

export function GetPackingInstructionConf(order) {
  let unScannedItem = order?.order?.unscanned_items[0];
  return unScannedItem?.child_items?.length > 0
    ? unScannedItem?.child_items[0]?.confirmation
    : unScannedItem?.confirmation;
}

export function CheckItemExists(data, packingInstructionsConf, barCodeInput) {
  let itemExist = false;
  let product = null;
  let product_barcode;
  let packing_instructions_conf = packingInstructionsConf
  data &&
    data.map(data => {
      data.child_items &&
        data.child_items.map(childItem => {
          childItem.barcodes.map(childItemBarCode => {
            product_barcode = childItemBarCode.barcode.toLowerCase();
            if (product_barcode === barCodeInput.toLocaleLowerCase()) {
              itemExist = true;
              product = childItem;
              packing_instructions_conf = childItem.confirmation;
            }
          });
        });
      data.barcodes.map(itemBarcode => {
        product_barcode = itemBarcode.barcode.toLowerCase();
        if (product_barcode === barCodeInput.toLocaleLowerCase()) {
          itemExist = true;
          product = data;
          packing_instructions_conf = data.confirmation;
        }
      });
    });

  return { itemExist, product, packing_instructions_conf };
}

export function HandleKitUpdateData(unScannedItem, numberOfKits) {
  let updateKit = false
  let updateKitFlag = true
  if (unScannedItem.scanned_qty > 0) {
    unScannedItem.child_items.map(item => {
      let eachKitConsistOn = (item.scanned_qty + item.qty_remaining) / numberOfKits
      let scannedQty = item.scanned_qty > unScannedItem.scanned_qty * eachKitConsistOn
      let isKitScanned = item.scanned_qty % eachKitConsistOn
      if (!isKitScanned && scannedQty && updateKitFlag) {
        updateKit = true
      }
      else {
        let numberOfItems = unScannedItem.scanned_qty + 1
        if (item.scanned_qty > eachKitConsistOn * numberOfItems) {
          updateKit = true
        }
        else {
          updateKit = false
          updateKitFlag = false
        }
      }
    })
  }
  else {
    unScannedItem.child_items.map(item => {
      let eachKitConsistOn = (item.qty_remaining + item.scanned_qty) / numberOfKits
      let isKitScanned = item.scanned_qty % eachKitConsistOn
      if (isKitScanned < 1 && item.scanned_qty > unScannedItem.scanned_qty) {
        updateKit = true
      }
      else {
        if (item.scanned_qty > eachKitConsistOn) {
          updateKit = true
        }
        else {
          updateKit = false
        }
      }
    })
  }
  return updateKit;
}

export function HandleUpdateScannedQty(unScannedItem, scannedItems, oneChildItem, eachKitConsistOn) {
  if (unScannedItem.product_type === 'depends') {
    return
  }
  if (oneChildItem) {
    let scannedQty = Math.floor(unScannedItem.child_items[0].scanned_qty / eachKitConsistOn)
    unScannedItem.scanned_qty = scannedQty
    unScannedItem.qty_remaining = unScannedItem.total_qty - scannedQty
  }
  else {
    unScannedItem.scanned_qty = unScannedItem.scanned_qty + 1
    unScannedItem.qty_remaining = unScannedItem.qty_remaining - 1
  }
  let itemExist = false
  scannedItems.map(item => {
    if (item.name === unScannedItem.name) {
      itemExist = true
    }
  })
  if (itemExist) {
    return
  }
  scannedItems.splice(0, 0, unScannedItem)
}

export const checkUnScannedSku = (Order, typeScanState) => {
  if (Order?.unscanned_items[0]?.child_items.length > 0) {
    return typeScanState !== Order.unscanned_items[0].child_items[0].sku;
  }
  return typeScanState !== Order?.unscanned_items[0]?.sku;
};

export const setAllScanCount = (Order) => {
  const unscanned = Order.unscanned_items[0];
  const child = unscanned?.child_items[0];
  let count;

  if (unscanned?.child_items.length > 0) {
    count = child?.scanned_qty + child?.qty_remaining;
  } else {
    count = unscanned.total_qty;
  }
  return count;
}

export let updateSerialLog = async logData => {
  let temp = [];
  let getItem = await AsyncStorage.getItem('logsArray');
  let getTemp = getItem !== 'undefined' && JSON.parse(getItem);
  let arr = getTemp ? temp.concat(getTemp) : temp;
  arr.push(logData);
  await AsyncStorage.setItem('logsArray', JSON.stringify(arr));
};

export function serialLogUpdate(type, item, recordFrom, order_id, both, serialRecordInput, userInfo, localLogs){
  const { temp,localLog } = SerialRecordLog(serialRecordInput, userInfo?.name,type, item, recordFrom, order_id,both)
  let updateSerialLogs = temp;
  if (both) {
    if (recordFrom === 'recordTwo') {
      localLogs.splice(0, 0, localLog);
    }
  } else {
    localLogs.splice(0, 0, localLog);
  }
  updateSerialLog(temp);
  return updateSerialLogs
}

export function udpateScanAllItem(childItem, scanned_items, items, unscanned_items, index){
  childItem.scanned_qty =
    childItem.scanned_qty + childItem.qty_remaining;
  childItem.qty_remaining = 0;
  scanned_items.splice(0, 0, childItem);
  items.child_items.splice(index, 1);

  items.child_items.length === 0 &&
    unscanned_items.splice(index, 1);
}

export function updateUnscannedItem(unscan, scanned_items, unscanned_items, index){
  unscan.scanned_qty =
    unscan.scanned_qty + unscan.qty_remaining;
  unscan.qty_remaining = 0;
  scanned_items.splice(0, 0, unscan);
  unscanned_items.splice(index, 1);
}

// Type Scan 
export function updateTheItem (item,scannedItems,sku,count, unscanned_items,index,child = false, parentItem = {}) {
  let SKU = sku;
  let Attribute = child ? 'product_id' : 'order_item_id'
  if (scannedItems.length > 0) {
    scannedItems.map((scan) => {
      let count_item = count;
      if (item[Attribute] === scan[Attribute]) {
        item.qty_remaining = item.qty_remaining - count_item;
        item.scanned_qty = item.scanned_qty + count_item;
        scan.qty_remaining = item.qty_remaining;
        scan.scanned_qty = item.scanned_qty;
        SKU = item.sku;

        if (child) {
          item.qty_remaining === 0 && parentItem.child_items.splice(index, 1);
        }
      } 
    });
    if (item.qty_remaining === 0) {
      if (child) {
        item.qty_remaining === 0 && parentItem.child_items.splice(index, 1);
      }
      unscanned_items.splice(index, 1);
    }
  } 
  return SKU;
}


export function getSerialScanAccess (item,type, setStateCallback, enableSerial) {
  let serialScanAccess = false;
  if (
    enableSerial ||
    (item.record_serial === false &&
      item.second_record_serial === false)
  ) {
    serialScanAccess = true;
  } else {
    if (
      item.record_serial ||
      item.second_record_serial
    ) {
      let eventType = type;
      if (item.record_serial) {
        setStateCallback({
          serialRecordText: i18n.t('serialRecord.serial_number')
        });
      } else if (
        item.record_serial === false &&
        item.second_record_serial === true
      ) {
        setStateCallback({
          serialRecordText: i18n.t('serialRecord.serial_number_two')
        });
      }
      setStateCallback({ serialRecordPopUpShow: true, type: eventType });
    }
  }
  return serialScanAccess;
}


export function submitTypeScan(setStateCallback,
  Order,
  propsOrder,
  typeScanCount,
  scan_pack_settings,
  LocalLogs,
  type,
  typeScanState,
  typeScanDeductCountState,
  activeBoxIndex,
  userInfo,
  enableSerial,
  typeScanResponse,
  typeScanError,
  updateLog
  ){
  let order = Order;
  let count = parseInt(typeScanCount);
  let unscanned_items = order.order.unscanned_items;
  let items = unscanned_items && unscanned_items[0];
  let scanned_items = order.order.scanned_items;
  let temp_order = {};
  let skip = false;
  let localLogs = LocalLogs;
  let orderInputType =
    items.product_type === 'individual' && items.child_items.length > 0
      ? items.child_items[0].barcodes[0].barcode
      : items.barcodes[0].barcode;
  let SKU = '';
  if (type === 'submit_type_scan') {
    count = 1;
  } else {
    count =
      typeScanState !== typeScanDeductCountState
        ? count - 1
        : count;
  }
  if (items.product_type === 'individual' && items.child_items.length > 0) {
    count = typeScanState === typeScanDeductCountState && items.child_items[0]?.qty_remaining + items.child_items[0]?.scanned_qty < count + items.child_items[0]?.scanned_qty ? count - 1 : count;
    if (items.child_items[0].qty_remaining >= count) {
      let serialScanAccess = getSerialScanAccess(items.child_items[0],'submit_type_scan', setStateCallback, enableSerial);
      if (serialScanAccess) {
        items.child_items &&
          items.child_items.map((child, index) => {
            if (index === 0) {
              SKU = updateTheItem(child, scanned_items, SKU,count, unscanned_items,index,true,items)
            }
          });
          typeScanResponse(unscanned_items)
          count = typeScanState === typeScanDeductCountState && items.child_items[0]?.qty_remaining + items.child_items[0]?.scanned_qty >= count + items.child_items[0]?.scanned_qty ? count + 1 : count
      }
    } else {
      skip = typeScanError(items.child_items[0].qty_remaining)
    }
  } else {
    count = typeScanState === typeScanDeductCountState && items.qty_remaining + items.scanned_qty < count + items.scanned_qty ? count - 1 : count;
    if (items.qty_remaining >= count) {
      let serialScanAccess = getSerialScanAccess(items,'submit_type_scan', setStateCallback, enableSerial);
      if (serialScanAccess) {
        unscanned_items.map((unscan, index) => {
          if (index === 0) {
            SKU = updateTheItem(unscan, scanned_items, SKU,count, unscanned_items,index)
            typeScanResponse(unscanned_items);
          }
        });
        count = typeScanState === typeScanDeductCountState && items.scanned_qty + items.qty_remaining > count + items.scanned_qty ? count + 1 : count
      }
    } else {
      skip = typeScanError(items.qty_remaining,skip)
    }
  }
  if (type !== 'submit_type_scan') {
    let Box = propsOrder?.boxes?.[activeBoxIndex]?.name??"BOX 1";
    let userName = userInfo?.name
    temp_order = typeScanLog(order,orderInputType,count,'type_scan',items,SKU,Box,userName)
    if (skip === false) {
      updateLog(temp_order);
      localLogs.splice(0, 0, temp_order);
    }
  } 
  enableSerial = false;
  setStateCallback({
    typeScanCount: '',
    typeScanDeductCountState: typeScanState
  });
  !skip && setStateCallback({ typeScanAlert: false, typeScanCount: '' });
  return enableSerial
};

export function checkTypeCount(scan_pack_settings, unscanned_items, 
  showAlert, setTypeInCountModal, resetBarCodeInput, setStateCallback, typeState, typeInCountModal, bar_code) {
  const typeScanState = unscanned_items[0]?.child_items[0]?.sku;
  const result ={}
  const isTypeScanEnabled = (item) => item.type_scan_enabled === 'on_with_confirmation';
  const isTypeScanDisabled = (item) => item.type_scan_enabled === 'Disable' || item.type_scan_enabled === 'off';

  const handleTypeScanAlert = () => {
    showAlert(i18n.t('scanpackItem.Scanpack_type_count_msg'))
    setStateCallback({ bar_code: '' });
    resetBarCodeInput();
  };

  const handleproductConfirmationCheck = () => {
    setStateCallback({ productConfirmationCheck: true });
    setTypeInCountModal(true);
  }

  const handleAlert = () => {
    setStateCallback({ typeScanAlert: true });
    setTypeInCountModal(false);
  }
  if (scan_pack_settings.type_scan_code === bar_code) {
    if (typeState === typeScanState) {
      const currentItem = unscanned_items[0];

      if (currentItem.product_type === 'individual' && currentItem.child_items.length > 0) {
        const childItem = currentItem.child_items[0];

        if (typeScanState === childItem.sku) {
          if (isTypeScanDisabled(childItem) || isTypeScanDisabled(currentItem)) {
            showAlert(i18n.t('scanpackItem.Disable_type_count_msg'))
          } else if (isTypeScanEnabled(childItem) && !typeInCountModal) {
            handleproductConfirmationCheck();
          } else {
            handleAlert();
          }
        } else {
          handleTypeScanAlert();
        }
      }
    } else {
      const currentItem = unscanned_items[0];

      if (typeState === currentItem.sku) {
        if (isTypeScanDisabled(currentItem)) {
          showAlert(i18n.t('scanpackItem.Disable_type_count_msg'))
        } else if (isTypeScanEnabled(currentItem) && !typeInCountModal) {
          handleproductConfirmationCheck();
        } else {
          handleAlert();
        }
      } else {
        handleTypeScanAlert();
      }
    }
    result.skipError = true
    result.stop_add_log = true
    return result;
  }
}
// SKIP barcode
export function skipBarcodeFunction(lowercase,
  bar_code,
  scan_pack_settings,
  item,
  unscanned_items,
  index,
  showAlert,
  responseAction
  ){    
  let result = {};

  if (
    item.product_type === 'individual' &&
    item.child_items &&
    item.child_items[0]?.sku === unscanned_items[0]?.child_items[0]?.sku &&
    item.child_items.length > 0
  ) {
    if (item.child_items[0].skippable) {
      let skip_code =
        lowercase === true
          ? scan_pack_settings.skip_code.toLowerCase()
          : scan_pack_settings.skip_code;
      if (scan_pack_settings.skip_code_enabled && skip_code === bar_code) {
        result.sku = item.child_items[0].sku;
        result.product_id = item.child_items[0].product_id;
        result.qty_remain = item.child_items[0].qty_remaining;
        item.child_items.splice(index, 1);
        result.itemFound = true;
        responseAction('scan_success')
        if (unscanned_items.length === 0) {
          result.order_complete_flag = true;
        }
        result.ActionBarcode = true;
        result.kitItem = true;
      } else if (skip_code === bar_code) {
        showAlert(i18n.t('scanpackItem.Disable_skip_msg'));
      }
    }
  } else {
    if (item.skippable && item.sku === unscanned_items[0].sku) {
      let skip_code =
        lowercase === true
          ? scan_pack_settings.skip_code.toLowerCase()
          : scan_pack_settings.skip_code;
      if (scan_pack_settings.skip_code_enabled && skip_code === bar_code) {
        result.sku = item.sku
        result.product_id = item.product_id;
        result.qty_remain = item.sku;
        unscanned_items.splice(index, 1);
        result.itemFound = true;
        responseAction('scan_success')
        if (unscanned_items.length === 0) {
          result.order_complete_flag = true;
        }
        result.ActionBarcode = true;
        result.kitItem = true;
      } else if (skip_code === bar_code) {
        showAlert(i18n.t('scanpackItem.Disable_skip_msg'));
      }
    }
  }
  return result;
}

// Bulk Scan

export function scanAllItem(setStateCallback,
  order,
  typeScanCount,
  scan_pack_settings,
  log,
  general_settings,
  localLogs,
  type,
  userInfo,
  isScanAllDisable,
  updateLog,
  responseAction,
  blockScan,
  confirmProductConfirmation,
  isBulkScan,
  enableSerial,
  setEnableSerial,
  setConfirmProductConfirmation,
  setIsBulk
){
  let bar_code = scan_pack_settings.type_scan_code;
  let unscanned_items = order.order.unscanned_items;
  let scanAllCount = setAllScanCount(order.order);
  let items = unscanned_items && unscanned_items[0];
  let scanned_count = items?.child_items.length > 0 ? 
                              items?.child_items[0].scanned_qty : items?.scanned_qty
  let product_id_child = items?.child_items[0]?.product_id
  let scanned_items = order.order.scanned_items;
  let next_state = order.next_state;
  let temp_order = {};
  let itemFound = false;
  let SKU = '';
  let orderComplete = false;

  if (blockScan === false || confirmProductConfirmation === true) {
    if (
      items.product_type === 'individual' &&
      items.child_items &&
      items.child_items.length > 0
    ) {
      if(isScanAllDisable(items.child_items[0], isBulkScan) === false) { return } 
      let serialScanAccess = getSerialScanAccess(items.child_items[0],'scan_all_item', setStateCallback, enableSerial);
      if (serialScanAccess) {
        items.child_items &&
          items.child_items.length > 0 &&
          items.child_items.map((childItem, index) => {
            if (index === 0) {
              if (scanned_items.length > 0) {
                scanned_items.map(scan => {
                  if (
                    childItem.product_id === scan.product_id &&
                    itemFound === false
                  ) {
                    scan.scanned_qty =
                      childItem.scanned_qty + childItem.qty_remaining;
                    childItem.qty_remaining = 0;
                    items.child_items.splice(index, 1);
                    itemFound = true;
                    SKU = childItem.sku;

                    items.child_items.length === 0 &&
                      unscanned_items.splice(index, 1);
                  } else {
                    if (childItem.scanned_qty === 0 && itemFound === false) {
                      udpateScanAllItem(childItem, scanned_items, items, unscanned_items, index)
                    }
                  }
                });
              } else {
                if (itemFound === false) {
                  udpateScanAllItem(childItem, scanned_items, items, unscanned_items, index)
                }
              }
              if (unscanned_items.length === 0) {
                orderComplete = true;
              }
            }
            return childItem;
          });
      }
    } else {
      if (items && items.qty_remaining > 0) {
        if(isScanAllDisable(items, isBulkScan) === false) { return } 
        let serialScanAccess = getSerialScanAccess(items,'scan_all_item', setStateCallback, enableSerial);
        if (serialScanAccess) {
          unscanned_items.map((unscan, index) => {
            if (index === 0) {
              if (scanned_items.length > 0) {
                scanned_items.map(scan => {
                  if (
                    unscan.order_item_id === scan.order_item_id &&
                    itemFound === false
                  ) {
                    scan.scanned_qty =
                      unscan.scanned_qty + unscan.qty_remaining;
                    scan.qty_remaining = 0;
                    unscanned_items.splice(index, 1);
                    itemFound = true;
                    SKU = unscan.sku;
                  } else {
                    if (unscan.scanned_qty === 0 && itemFound === false) {
                      updateUnscannedItem(unscan, scanned_items, unscanned_items, index)
                      itemFound = true;
                    }
                  }
                });
              } else {
                updateUnscannedItem(unscan, scanned_items, unscanned_items, index)
              }
              if (unscanned_items.length === 0) {
                orderComplete = true;
              }
            }
            return unscan;
          });
        }
      }
    }
    if (type !== 'scan_all_item') {
      const scanType = isBulkScan !== 'scan_all_items' ? 'bulk_scan' : 'scan_all_items';
      temp_order = scanAllItemLog(order,bar_code,next_state,scanType,items,SKU,userInfo?.name, scanAllCount, scanned_count,product_id_child)
      setIsBulk('')
      updateLog(temp_order, 'skip_curser');
      localLogs.splice(0, 0, temp_order);
    }
    setStateCallback({ typeScanAlert: false, typeScanCount: '' });
    setEnableSerial(false)
    if (orderComplete === true) {
      updateLog(temp_order, 'order_complete');
    } else {
      responseAction('scan_success');
    }
    setConfirmProductConfirmation(false)
  }
};

//Scan barcode

export function removeStringFromBarcode (input, removalString) {
  if (input && input.includes(removalString) && removalString !== '') {
    let tempIndex = input.indexOf(removalString);
    return input.slice(0, tempIndex);
  }
  return input;
};

export function processBarCode(bar_code, scan_pack_settings) {
  let result = {
    barCodeWithoutSpaces: bar_code,
  };

  function removeEscapeString(check, barCodeWithoutSpaces) {
    if (barCodeWithoutSpaces && barCodeWithoutSpaces.includes(check) && check !== '') {
      let tempIndex = barCodeWithoutSpaces.indexOf(check);
      barCodeWithoutSpaces = barCodeWithoutSpaces.slice(0, tempIndex);
    }
    barCodeWithoutSpaces = barCodeWithoutSpaces.endsWith(' ') ? barCodeWithoutSpaces.trimRight() : barCodeWithoutSpaces;
    return barCodeWithoutSpaces;
  }

  // First escape string
  if (scan_pack_settings.first_escape_string_enabled) {
    result.barCodeWithoutSpaces = removeEscapeString(scan_pack_settings.escape_string, result.barCodeWithoutSpaces);
  }

  // Second escape string
  if (scan_pack_settings.second_escape_string_enabled) {
    result.barCodeWithoutSpaces = removeEscapeString(scan_pack_settings.second_escape_string, result.barCodeWithoutSpaces);
  }

  return result;
}

export function processClickAndPassScan(unscanned_items, clickScan) {
  let item = unscanned_items[0]?.child_items[0] || unscanned_items[0];
  clickScan(item, 'actionbarcode');
  return
}

export const handleScanning = (
  scanItemIndex,
  swapunscan,
  childIndex,
  scan,
  barcode,
  bar_code,
  Scancode,
  scancode,
  type,
  childItem,
  Barcode,
  item,
  unscanned_items,
  scanned_items,
  scanpush,
  setStateCallback,
  scanAccess
) => {
  let result = {
    typeScanState: null,
    SKU: null,
    Log_count: null,
    product_id: null,
    is_kit: null,
    product_name: null,
    qty_remain: null,
    unscanned_items: null,
    scanned_items: null,
    item: null,
    childItem: null,
    scanpush: null
  };

  const updateStateAndResult = () => {
    setStateCallback({ typeScanState: childItem.sku });
    result = updateItemAndScanState(childItem, barcode, item, unscanned_items, scanned_items, result);
  };

  const handleCount = () => {
    if (type === 'item_present') {
      childItem.qty_remaining -= 1;
      childItem.scanned_qty += 1;
    } else {
      childItem.qty_remaining -= parseInt(barcode.packing_count);
      childItem.scanned_qty += parseInt(barcode.packing_count);
    }
  }

  const handleScanPush = () => {
    if (!scanpush) {
      handleCount()
      item.child_items.splice(childIndex, 1);
      item.child_items.unshift(childItem);
      unscanned_items.splice(swapunscan, 1);
      unscanned_items.unshift(item);
      scanned_items.splice(0, 0, childItem);
      updateStateAndResult();
    }
  };

  const handleQtyRemaining = (item) => {
    handleCount()
    item.qty_remaining = childItem.qty_remaining;
    item.scanned_qty = childItem.scanned_qty;
  };

  if (
    (type === 'item_present' ? childItem.qty_remaining >= 1 : childItem.qty_remaining >= parseInt(barcode.packing_count))
  ) {
    if (Scancode === Barcode && Barcode === bar_code && childItem.order_item_id === scan.order_item_id) {
      if (scan.qty_remaining === 1 && !scanpush) {
        if (type === 'item_present') {
          childItem.qty_remaining -= 1;
          childItem.scanned_qty += 1;
          scan.qty_remaining = childItem.qty_remaining;
          scan.scanned_qty = childItem.scanned_qty;
        } else {
          if (childItem.qty_remaining > 0) {
            childItem.scanned_qty += parseInt(barcode.packing_count);
            childItem.qty_remaining -= parseInt(barcode.packing_count);
          }
        }

        item.child_items.splice(childIndex, 1);
        unscanned_items.splice(swapunscan, 1);
        unscanned_items.unshift(item);

        if (item.child_items.length === 0) {
          unscanned_items.splice(scanAccess, 1);
        }

        scanned_items.splice(scanItemIndex, 1);
        scanned_items.splice(0, 0, scan);

        updateStateAndResult();
      } else {
        if (scan.qty_remaining > 0 && !scanpush) {
          handleQtyRemaining(scan);

          item.child_items.splice(childIndex, 1);
          item.child_items.unshift(childItem);

          unscanned_items.splice(swapunscan, 1);
          unscanned_items.unshift(item);

          scanned_items.splice(scanItemIndex, 1);
          scanned_items.splice(0, 0, scan);

          updateStateAndResult();
        }

        if (childItem.qty_remaining === 0) {
          item.child_items.splice(scanAccess, 1);

          if (item.child_items.length === 0) {
            unscanned_items.splice(index, 1);
          }
        }
      }
    } else {
      if ((scancode !== bar_code || scancode?.barcode !== bar_code) && childItem.scanned_qty === 0) {
        if (childItem.qty_remaining === 1 && !scanpush) {

          handleQtyRemaining(childItem);

          unscanned_items.splice(swapunscan, 1);
          unscanned_items.unshift(item);

          scanned_items.splice(0, 0, childItem);

          item.child_items.splice(scanAccess, 1);

          if (item.child_items.length === 0) {
            unscanned_items.splice(0, 1);
          }

          updateStateAndResult();
        } else {
          handleScanPush();
        }
      }
    }
  }
  return result;
};

const updateItemAndScanState = (childItem, barcode, item, unscanned_items, scanned_items, result) => {
  
  result.SKU = childItem.sku;
  result.Log_count = barcode.packing_count;
  result.product_id = childItem.product_id;
  result.is_kit = true;
  result.product_name = childItem.name;
  result.qty_remain = childItem.qty_remaining;
  result.unscanned_items =  unscanned_items
  result.scannedItems = scanned_items
  result.item = item,
  result.childItem = childItem

  result.scanpush = true;
  return result
};

export function handleScanningForUnscan(childItem, scanpush, type, barcode, item, childIndex, scanAccess, swapunscan, unscanned_items, scanned_items, index, setStateCallback) {
  let result = {
    typeScanState: null,
    SKU: null,
    Log_count: null,
    product_id: null,
    is_kit: null,
    product_name: null,
    qty_remain: null,
    unscanned_items: null,
    scanned_items: null,
    item: null,
    childItem: null,
    scanpush: null
  };

  const decrementQty = (qty) => {
    childItem.qty_remaining -= qty;
    childItem.scanned_qty += qty;
  };

  const updateItemArrays = () => {
    if (childItem.qty_remaining !== 0) {
      item.child_items.splice(childIndex, 1);
      item.child_items.unshift(childItem);
    }
    if (
      childItem.qty_remaining === 0 &&
      scanpush === false
    ) {
      item.child_items.splice(scanAccess, 1);
    }
    unscanned_items.splice(swapunscan, 1);
    unscanned_items.unshift(item);
    scanned_items.splice(0, 0, childItem);
  };

  if (childItem.qty_remaining === 1 && !scanpush || !scanpush) {
    const qtyToDecrement = type === 'item_present' ? 1 : parseInt(barcode.packing_count);
    decrementQty(qtyToDecrement);
    updateItemArrays();

    setStateCallback({typeScanState: childItem.sku})
    result.typeScanState = childItem.sku;
    result.SKU = childItem.sku;
    result.Log_count = barcode.packing_count;
    result.product_id = childItem.product_id;
    result.is_kit = true;
    result.product_name = childItem.name;
    result.qty_remain = childItem.qty_remaining;
    result.scanpush = true;
  }

  return result;
}

export function scanForNoBarocode(Scancode, scancode, item, bar_code, scanpush, Barcode, barcode, unscanned_items, setStateCallback, scan, scanned_items, scanAccess, type, scanIndex){
  let result = {
    item: null,
    SKU: null,
    scan: null,
    scanned_items: null,
    unscanned_items: null,
    Log_count: null,
    scanpush: null,
    product_name: null,
    qty_remain: null
  };  if (Scancode === Barcode && Barcode === bar_code && item.order_item_id === scan.order_item_id) {
    if (scan.qty_remaining === 1 && !scanpush) {
      if (type === 'item_present') {
        item.qty_remaining = item.qty_remaining - 1;
        item.scanned_qty= item.scanned_qty + 1;
      } else {
        item.qty_remaining = item.qty_remaining - parseInt(barcode.packing_count);
        item.scanned_qty = item.scanned_qty + parseInt(barcode.packing_count);
      }

      unscanned_items.splice(scanAccess, 1);
      scanned_items.splice(scanIndex, 1, scan);
      scan.qty_remaining = item.qty_remaining;
      scan.scanned_qty = item.scanned_qty;

      result = updateQuantitiesAndArrays(item, scan, barcode, setStateCallback, result, scanned_items);
    } else if (scan.qty_remaining > 0 && !scanpush) {
      if (type === 'item_present') {
        item.scanned_qty= item.scanned_qty + 1;
        item.qty_remaining = item.qty_remaining-1;
      } else {
        item.scanned_qty = item.scanned_qty + parseInt(barcode.packing_count);
        item.qty_remaining = item.qty_remaining + parseInt(barcode.packing_count);
      }
      scanned_items.splice(scanIndex, 1, scan);

      scan.qty_remaining = item.qty_remaining;
      scan.scanned_qty = item.scanned_qty;
      scanned_items.unshift(scan);  

      result = updateQuantitiesAndArrays(item, scan, barcode, setStateCallback, result, scanned_items);
    }
    if (item.qty_remaining === 0 && item?.barcodes?.includes(Barcode)) {
        unscanned_items.splice(scanAccess, 1);
        result.unscanned_items = unscanned_items;
    }
  } else if (scancode !== bar_code && item.scanned_qty === 0) {
      if (item.qty_remaining === 1 && !scanpush) {
        if (type === 'item_present') {
          item.qty_remaining = item.qty_remaining - 1;
          item.scanned_qty= item.scanned_qty + 1;
        } else {
          item.qty_remaining = item.qty_remaining - parseInt(barcode.packing_count);
          item.scanned_qty = item.scanned_qty + parseInt(barcode.packing_count);
        }
        unscanned_items.splice(scanAccess, 1);

        scanned_items.unshift(item);
        result = updateQuantitiesAndArrays(item, scan, barcode, setStateCallback, result, scanned_items);
      } else if (!scanpush) {
        if (type === 'item_present') {
          item.qty_remaining = item.qty_remaining - 1;
          item.scanned_qty= item.scanned_qty + 1;
        } else {
          item.qty_remaining = item.qty_remaining - parseInt(barcode.packing_count);
          item.scanned_qty = item.scanned_qty + parseInt(barcode.packing_count);
        }
        scanned_items.unshift(item);

        result = updateQuantitiesAndArrays(item, scan, barcode, setStateCallback, result, scanned_items);
      }
  }
  return result
}

function updateQuantitiesAndArrays(item, scan, barcode, setStateCallback, result, scanned_items) {
  setStateCallback({ typeScanState: item.sku });
  let scanpush = true;
  let SKU = item.sku;
  let Log_count = barcode.packing_count;
  let product_name = item.name;
  let qty_remain = item.qty_remaining;

  result.item = item
  result.SKU = SKU
  result.scan = scan
  result.scanned_items = scanned_items
  result.Log_count = Log_count
  result.scanpush = scanpush
  result.product_name = product_name
  result.qty_remain = qty_remain
  
  return result
}