import React, { Component, useRef } from 'react';
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  ScrollView,
  Image
} from 'react-native';
import styles from '../../style/scanpack';
import stylesalert from '../../style/popupmodel';
import globalStyles from '../../style/global';
import CommonStyles from '../../style/commonStyles';
import cross from '../../../assets/close_black.png';
import {
  scanConfirmationPopMsg,
  scanConfirmationPopErrMsg
} from '../notification';
import i18n from 'i18n-js';
import { LinearGradient } from 'expo-linear-gradient';
import DefaultInput from '../../commons/Inputs/defaultInput';

export default class ClickScanConfirmationPopUp extends Component {
  constructor(props) {
    super(props);
    this.typeScanFocus = React.createRef();
  }

  render() {
    return (
      <View style={styles.typeScanContainer} dataSet={{ componentName: 'clickScanConfirmation'}}>
        <View style={{ width: this.props.windowWidth > 800 ? '60%' : '80%' }}>
          {this.props.errorMessageClickScanConfirmation && (
            <LinearGradient
               testID='errorMessage'
              locations={[0, 1]}
              colors={['rgb(207,68,55)', 'rgb(122,40,40)']}
              style={CommonStyles.gradientContainer}
            >
              <View style={globalStyles.flexDirectionRow}>
                <Text style={[styles.scanCountText, { color: "#ffff", marginLeft: "5px" }]}>
                  {i18n.t('product.scanRecordErrorText')}
                </Text>
                <TouchableOpacity
                  testID='errorCancelIcon'
                  onPress={this.props.closeErrorAlert}
                >
                  <i
                    className="icon-cancel"
                    style={{
                      fontSize: '26px',
                      color: '#ffff',
                      marginTop: -2,
                    }}
                  ></i>
                </TouchableOpacity>
              </View>
            </LinearGradient>
          )}
          <LinearGradient
            testID='clickScanContainer'
            locations={[0, 1]}
            start={{ x: 0, y: 1 }}
            end={{ x: 0, y: 0 }}
            colors={['#d47d02', '#ffb636']}
            style={CommonStyles.gradientContainerOne}
          >
            <View style={globalStyles.flexDirectionRow}>
              <Text style={[styles.scanCountText, { marginLeft: '5%', marginTop: "2%" }]}>
                {i18n.t('product.scanRecordCountText')}
              </Text>
              <TouchableOpacity
                testID='cancelIcon'
                onPress={this.props.closeAlert}
              >
                <i
                  className="icon-cancel"
                  style={{
                    fontSize: '26px',
                    color: '#4b4132',
                    marginTop: -2,
                  }}
                ></i>
              </TouchableOpacity>
            </View>
            <View
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%'
              }}
            >
              <View style={{ alignItems: 'center', width: '80%' }}>
                <DefaultInput
                  value={this.props.clickScanConfirmInput}
                  onChangeText={this.props.handleClickScanConfirmInput}
                  autoFocus={true}
                  ref={this.props.serialFocus}
                  onSubmitEditing={this.props.clickScanConfirmFun}
                  style={styles.scanCountTextInput}
                />
              </View>
              <View style={{ flexDirection: 'row' }}>
                <TouchableOpacity
                  testID='cancelButton'
                  style={[styles.scanCountButton, {
                    marginLeft: '-50%'
                  }]
                  }
                  onPress={this.props.closeAlert}
                >
                  <Text>Cancel</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  testID='enterButton'
                  style={[styles.scanCountButton, {
                    marginLeft: '100%'
                  }
                  ]}
                  onPress={this.props.clickScanConfirmFun}
                >
                  <Text>Enter</Text>
                </TouchableOpacity>
              </View>
            </View>
          </LinearGradient>
        </View>
      </View>
    );
  }
}
