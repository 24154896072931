import {LinearGradient} from 'expo-linear-gradient';
import React,{useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  View,
  Text,
  ImageBackground,
  ScrollView,
  Dimensions,
  TextInput,
  TouchableOpacity
} from 'react-native';
import {ClearSearchProduct} from '../../actions/productAction'
import {fontFamily} from '../../helpers/fontFamily';
import styles from './styles';
import promptStyle from '../../style/popupmodel'

const HEIGHT = Dimensions.get('window').height;
const Scan = ({
  workflowOptions,
  isWorkflowContainerVisible,
  setWorkflowContainerVisible,
  selectedWorkFlow,
  setSelectedWorkflow,
  searchProduct,
  searchTerm,
  setSearchTerm,
  oldBarCode,
  searchRef,
  isInvalidBarcode,
  toggleTooltip,
  isTooltipVisible,
  setTooltipVisible,
  showModal,
  setFocus,
  setShowModal,
  route,
  goToNewProductWithBarcode,
  tooltipText
}) => {
  const dispatch = useDispatch();
  const WIDTH = Dimensions.get('window').width
  const isUnMounted = useSelector(state => state.menu.isUnMounted);
  useEffect(()=>{
    return ()=> {
      dispatch(ClearSearchProduct())
    }
  },[])

  useEffect(()=>{
    setFocus()
  },[isUnMounted])
  return (
    <ImageBackground
      style={{flex: 1}}
      source={require('././../../../assets/background.png')}
    >
      <ScrollView
        contentContainerStyle={{flexGrow: 1}}
        showsVerticalScrollIndicator={false}
      >
        <ImageBackground
          style={{flex: 1, marginTop: 20, alignItems: 'center'}}
          source={require('././../../../assets/scaning.png')}
          resizeMode="contain"
          imageStyle={{height: 365}}
        >
          <Text
            style={{
              textAlign: 'center',
              fontSize: '22px',
              color: '#ffffff',
              fontFamily: fontFamily.font300,
              marginTop: 184
            }}
          >
            Scan a product to continue
          </Text>
          <View style={styles.searchContainer}>
            <TextInput
              ref={searchRef}
              testID='scanInput'
              autoFocus={true}
              onSubmitEditing={searchProduct}
              blurOnSubmit={false}
              autoCapitalize='none'
              name="searchOrder"
              value={searchTerm}
              onChangeText={setSearchTerm}
              placeholder="Ready for Product Scan"
              placeholderTextColor={'rgb(172,187,199)'}
              style={[
                styles.searchInput,
                isInvalidBarcode
                  ? {
                      shadowColor: 'red',
                      shadowOpacity: 0.9,
                      shadowRadius: 16.0,
                      elevation: 24
                    }
                  : {}
              ]}
              onKeyPress={(e)=>{
                if(e.key === 'Enter') {
                    window.innerWidth < 900 && searchProduct()
                }
              }}
            />
            <TouchableOpacity onPress={searchProduct}>
              <i
                className="icon-search"
                style={{
                  color: 'rgb(172,187,199)',
                  fontSize: '19px',
                  margin: 10
                }}
              ></i>
            </TouchableOpacity>
          </View>
           {showModal && route.name === 'ScanReceive' ?
              <LinearGradient
                  locations={[0, 1]}
                  start={{ x: 0, y: 1 }}
                  end={{ x: 0, y: 0 }}
                  colors={['#d47d02', '#ffb636']}
                  style={[promptStyle.promptModel, {
                    width: WIDTH > 900 ? '35%' : '90%',
                    position: 'absolute',
                    top:WIDTH > 900 ? '5%' : '17%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                  }]}
               >
                  <View
                  style={{maxWidth: '90%'}}
                  >
                      <TouchableOpacity
                      testID='createProductWithBarCode'
                      onPress={goToNewProductWithBarcode}
                      >
                        <Text
                        style={{
                          textAlign: 'center',
                          paddingVertical: 12,
                          fontWeight: 'bold',
                          marginLeft: 5
                        }}
                        >
                        Click or tap here to create a new product with barcode {oldBarCode}
                        </Text>
                      </TouchableOpacity>
                  </View>
                  <TouchableOpacity
                    testID='cancelIcon'
                    onPress={()=>{
                      setShowModal(false)
                    }}
                    style={{
                      marginRight: 2,
                      paddingVertical: 8
                    }}
                  >
                  <i
                          className="icon-cancel"
                          style={{fontSize: '22px'}}
                  ></i>
                  </TouchableOpacity>
              </LinearGradient>
              : <></>
            }

          <View style={{width: '95%', marginTop: 20, maxWidth: '419px'}}>
            <Text
              style={{
                fontFamily: fontFamily.font700,
                fontSize: 13,
                color: '#ffffff'
              }}
            >
              Workflow
            </Text>
            <View style={{flexDirection: 'row', flex: 1}}>
              <TouchableOpacity
                 testID='workFlowContainer'
                style={styles.dropDownToggleStyle}
                onPress={() => {
                  setTooltipVisible(false);
                  setWorkflowContainerVisible(!isWorkflowContainerVisible);
                }}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                  }}
                >
                  <Text
                    style={{
                      color: '#fff',
                      fontSize: 16,
                      fontFamily: fontFamily.font400,
                      lineHeight: '36px'
                    }}
                    numberOfLines={1}
                  >
                    {selectedWorkFlow?.label}
                  </Text>
                  <i
                    class="icon-down-open"
                    style={{
                      color: 'rgb(205,205,205)'
                    }}
                  />
                </View>
              </TouchableOpacity>
              <TouchableOpacity style={{marginLeft: 5}} onPress={toggleTooltip}>
                <i
                  className="icon-info-circled"
                  style={{color: 'rgb(119,143,165)', fontSize: '20px'}}
                />
              </TouchableOpacity>
            </View>
            {isWorkflowContainerVisible && (
              <View style={{paddingBottom: 100}}>
                <LinearGradient colors={['#132131', '#1b374f']}>
                  {workflowOptions.map(option => (
                    <TouchableOpacity
                      onPress={() => {
                        setSelectedWorkflow(option);
                        setWorkflowContainerVisible(false);
                        setFocus()
                      }}
                      testID='workflowOption'
                    >
                      <Text
                        numberOfLines={2}
                        style={{
                          padding: 5,
                          fontFamily: fontFamily.font400,
                          color: '#ffffff',
                          fontSize: 16,
                          lineHeight: '36px',
                          backgroundColor:
                            selectedWorkFlow === option.label
                              ? 'rgba(72,90,114,0.5)'
                              : ''
                        }}
                        key={option.label.trim()}
                      >
                        {option.label}
                      </Text>
                    </TouchableOpacity>
                  ))}
                </LinearGradient>
              </View>
            )}
            {isTooltipVisible && (
              <View style={{paddingBottom: 100}} testID= 'tooltip'>
                <LinearGradient colors={['#132131', '#1b374f']}>
                  {tooltipText.map(tooltip => (
                    <Text
                      key={tooltip.id}
                      style={[styles.text, {lineHeight: 20}]}
                    >
                      {tooltip.description}
                    </Text>
                  ))}
                </LinearGradient>
              </View>
            )}
          </View>
        </ImageBackground>
      </ScrollView>
    </ImageBackground>
  );
};

export default Scan;
