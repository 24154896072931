import {
  View,
  Text,
  Image,
  TouchableOpacity,
  TextInput,
  Dimensions
} from 'react-native';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {ClearSearchProduct} from '../../actions/productAction';
import styles from './styles';
import {LinearGradient} from 'expo-linear-gradient';
import {AddImageButton} from './product';
import {fontFamily} from '../../helpers/fontFamily';
import { Unmounted } from '../../actions/menuActions';

let dimensions = Dimensions.get('window');
let imageHeight = Math.round((dimensions.width * 6) / 26);

const Count = ({
  steps,
  navigation,
  product,
  saveProductInfo,
  pickImage,
  recevingNotes,
  setReceivingNotes,
  newCount,
  setNewCount,
  transformCount,
  previousQOH,
  saveQuantity,
  receivingNotesRef,
  barcode,
  setBarcode,
  scanBarcode,
  navigateForward,
  navigateBack,
  barcodeRef,
  isInvalidBarcode,
  newQOH,
  quantityMessage,
  scanMessage
}) => {
  const dispatch = useDispatch()
  const [imageDimensions, setImageDimensions] = useState(imageHeight);
  const [paddingHorizontal, setPaddingHorizontal] = useState(
    window.innerWidth > 768 ? '30%' : '5%'
  );
  const [qty, setQty]= useState(false)
  const handleDimensions = useCallback(() => {
    setPaddingHorizontal(window.innerWidth > 768 ? '30%' : '5%');
  }, []);
  const updateSize = useCallback(function () {
    setImageDimensions(Math.round((Dimensions.get('window').width * 6) / 20));
  });

  useEffect(() => {
    window.addEventListener('resize', handleDimensions);
    window.addEventListener('resize', updateSize);
    return () => {
      window.removeEventListener('resize', handleDimensions);
      window.removeEventListener('resize', updateSize);
      dispatch(ClearSearchProduct());
      dispatch(Unmounted(false))
    };
  }, []);

  const strCount = transformCount(newCount);
  const fontSize = strCount.length <= 3 ? 130 : strCount.length <= 4 ? 100 : 70;
  return (
    <LinearGradient
      colors={[
        '#000',
        'rgba(37, 60, 87,1)',
        'rgba(54, 84, 110,1)',
        'rgba(37, 60, 87,1)',
        '#000'
      ]}
      start={[-0.5, -1]}
      end={[1, 1]}
      locations={[0, 0.2, 0.5]}
      style={{
        backgroundColor: 'rgba(92, 119, 143,1)'
      }}
    >
      <View style={styles.container}>
        <View
          style={[
            styles.productMiddleContainer,
            {justifyContent: 'center', alignItems: 'flex-end'}
          ]}
        >
          {product.images && product.images.length > 0 ? (
            <View>
              <Image
                style={[
                  styles.image,
                  {
                    height: imageDimensions,
                    width: imageDimensions
                  }
                ]}
                source={{
                  uri: product.images[product.images.length - 1].image
                }}
              />
              <TouchableOpacity
                onPress={pickImage}
                style={{
                  alignSelf: 'center',
                  paddingTop: 5,
                  marginBottom: -20
                }}
              >
                <Text
                  style={{
                    color: 'white',
                    fontFamily: fontFamily.font600
                  }}
                >
                  Edit Image
                </Text>
              </TouchableOpacity>
            </View>
          ) : (
            <AddImageButton onPress={pickImage} />
          )}
          <TextInput
            ref={receivingNotesRef}
            onBlur={saveProductInfo}
            placeholder="Receiving Notes"
            placeholderTextColor={'#656161'}
            multiline={true}
            style={styles.receivingNotesInput}
            value={recevingNotes}
            onChangeText={setReceivingNotes}
          />
        </View>
        <View style={[styles.productBottomContainer]}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingHorizontal,
              marginVertical: 5
            }}
          >
            <Text
              style={{
                fontSize: '16px',
                color: '#ffffff',
                fontFamily: fontFamily.font400
              }}
            >
              {product?.basicinfo?.name || ''}
            </Text>
            <Text
              style={{
                fontSize: '16px',
                color: '#ffffff',
                fontFamily: fontFamily.font400
              }}
            >
              {product?.skus[0]?.sku || ''}
            </Text>
          </View>
          <View
            style={{
              backgroundColor: 'rgba(157,176,193,0.07)',
              alignItems: 'center',
              borderRadius: '7px',
              padding: 5,
              paddingVertical: 20,
              marginHorizontal: paddingHorizontal
            }}
          >
            <Text
              style={{
                fontFamily: fontFamily.font300,
                fontSize: '22px',
                color: '#ffffff'
              }}
            >
              {scanMessage}
            </Text>
            <TextInput
              ref={barcodeRef}
              autoFocus={true}
              onFocus={()=>{
                setQty(true)
              }}
              value={barcode}
              onChangeText={setBarcode}
              style={{
                outlineWidth: 0,
                marginVertical: 20,
                padding: 5,
                backgroundColor: '#f4efef',
                borderRadius: '7px',
                fontSize: '16px',
                fontFamily: fontFamily.font700,
                color: 'rgba(23,54,75,0.5)',
                maxWidth: '459px',
                width: '100%',
                ...(isInvalidBarcode
                  ? {
                    shadowColor: 'red',
                    shadowOpacity: 0.9,
                    shadowRadius: 16.0,
                    elevation: 24
                  }
                  : {})
                }}
                onKeyPress={(e)=>{
                  if(e.key === 'Enter') {
                    scanBarcode()
                  }
                }}
                placeholder="Ready for product scan"
                onSubmitEditing={scanBarcode}
                blurOnSubmit={false}
                testID='ReadyForProductScan'
            />
          </View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingHorizontal,
              marginVertical: 5
            }}
          >
            <Text
              style={{
                fontSize: '16px',
                color: '#ffffff',
                fontFamily: fontFamily.font400
              }}
            >
              Previous QOH: {previousQOH.current}
            </Text>
            <Text
              style={{
                fontSize: '16px',
                color: '#ffffff',
                fontFamily: fontFamily.font400
              }}
            >
              New QOH: {newQOH}
            </Text>
          </View>
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            flex: 1,
            paddingHorizontal,
            marginHorizontal: '2%'
          }}
        >
          <View
            style={{
              maxWidth: '30%',
              marginVertical: 5,
              justifyContent: 'space-around'
            }}
          >
            <TouchableOpacity
              testID='incrementButton'
              accessible={false}
              onPress={() => {
                setNewCount(newCount + 1);
              }}
            >
              <i
                class="icon-up-open"
                style={{
                  color: 'rgba(173,185,196,0.23921568627450981)',
                  fontSize: '66px'
                }}
              />
            </TouchableOpacity>
            <TouchableOpacity
              testID='decrementButton'
              accessible={false}
              onPress={() => {
                if (newCount > 1) setNewCount(newCount - 1);
              }}
            >
              <i
                class="icon-down-open"
                style={{
                  color: 'rgba(173,185,196,0.23921568627450981)',
                  fontSize: '66px'
                }}
              />
            </TouchableOpacity>
          </View>
          <View
            style={{
              maxWidth: '70%',
              marginLeft: '0%',
              paddingLeft: 10,
              alignItems: 'flex-end',
              justifyContent: 'center'
            }}
          >
            <Text
              style={{
                fontSize,
                color: 'rgba(244, 239, 239,0.5)',
                fontFamily: fontFamily.font700,
                textAlign: 'right',
                background:
                  '-webkit-linear-gradient(\n90deg\n, #4b657d, #ffffff 80%)',
                WebkitTextFillColor: 'transparent',
                webkitBackgroundClip: 'text'
              }}
            >
              {strCount}
            </Text>
          </View>
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal,
            paddingTop: '20px'
          }}
        >
          <ActionButton label="Cancel" onPress={navigateBack} />
          <ActionButton
            label="Save"
            id = 'SaveButton'
            onPress={() => {
              saveQuantity();
              if (steps.includes('location')) {
                navigateForward();
              } else {
                navigateBack();
              }
            }}
          />
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            paddingHorizontal,
            paddingTop: '10px'
          }}
        >
          <Text
            style={{
              textAlign: 'center',
              fontSize: '14px',
              color: '#ffffff',
              fontFamily: fontFamily.font600
            }}
          >
            {quantityMessage}
          </Text>
        </View>
      </View>
    </LinearGradient>
  );
};

const ActionButton = ({label,id = '', onPress = () => {}}) => {
  return (
    <TouchableOpacity
      testID= {id}
      onPress={onPress}
      style={{
        paddingHorizontal: '10px',
        height: '36px',
        borderRadius: '10px',
        backgroundColor: 'rgba(157,176,193,0.1)',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Text
        style={{
          fontSize: '22px',
          color: 'rgba(93, 120, 144,0.9)',
          fontFamily: fontFamily.font700
        }}
      >
        {label}
      </Text>
    </TouchableOpacity>
  );
};
export default Count;
