import React from 'react';
import {View,StyleSheet, Switch, Text, TouchableOpacity} from 'react-native';
import {LinearGradient} from 'expo-linear-gradient';
import ToolTip from './toolTip';
import PaperSettingsContainer from './paperSettingsContainer';
import AsyncStorage from '@react-native-async-storage/async-storage';
import styles from '../../style/directPrinting';
const SettingComponent = ({
  activeTollTip,
  isPrinter,
  activeTollTipValue,
  testId,
  handleTollTip,
  switchLabel,
  switchValue,
  switchToggle,
  switchId,
  switchDisabled,
  label,
  ToolTipMessage,
  ToolTipId,
  top,
  left,
  removeItem,
  width,
  zIndex,
  type,
  pdfSize,
  handleEdit,
  isReceiver,
  isShipping
}) => {
  return (
    <View
      style={[switchValue ? style.containerBox : style.container, {zIndex: zIndex}]}
      >
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '15px'
        }}>
        <TouchableOpacity
          testID={testId}
          onPress={() => handleTollTip(activeTollTipValue)}
          style={{width: 20}}>
          <i className='icon-info-circled' style={styles.iconInfo}></i>
        </TouchableOpacity>
        <Switch
          testID={switchId}
          disabled={switchDisabled}
          onValueChange={e => switchToggle(e, switchLabel)}
          offColor="#5c6e79"
          onColor="#65baea"
          offHandleColor="#969696"
          onHandleColor="#fbfdfe"
          handleDiameter={22}
          uncheckedIcon={false}
          checkedIcon={false}
          height={20}
          width={40}
          nativeID="test"
          value={switchValue}
        />
        <Text style={{color: 'white', fontSize: '16px', fontWeight: 300}}>
          {label}
        </Text>
      </View>
      {switchValue && <>
      <PaperSettingsContainer 
        isPrinter={isPrinter} 
        removeItem={removeItem}
        switchLabel={type} 
        pdfSize={pdfSize}
        handleEdit={handleEdit}
        isShipping={isShipping}
        isReceiver={isReceiver}
      />
      </>
      }
      {activeTollTip === activeTollTipValue && (
        <ToolTip
          message={ToolTipMessage}
          testID={ToolTipId}
          windowWidth={width}
          left={left}
          top={top}
        />
      )}
    </View>
  );
};

const style = StyleSheet.create({
  containerBox: {
    display: 'flex',
    paddingVertical: 10,
    marginVertical: 15,
    flexDirection: 'column',
    position: 'relative',
    backgroundColor: 'rgba(104, 135, 156, 0.5)',
    borderRadius: '25px',
    boxShadow: 'rgb(80, 112, 139) 1px 1px 10px',
  },
  container: {
    display: 'flex',
    paddingVertical: 10,
    marginVertical: 15,
    flexDirection: 'column',
    position: 'relative',
  }

})
export default SettingComponent;
