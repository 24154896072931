import React, {Component} from 'react';
import {Text, TextInput, TouchableOpacity, View} from 'react-native';
import i18n from 'i18n-js';
import styles from '../../style/orderdetail';
import globalStyles from '../../style/global';
import moment from 'moment';
import DateTimePicker from 'react-datetime-picker';
import CommonStyles from '../../style/commonStyles';

class InformationDetailMobileView extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    let basicinfo = this.props && this.props.basicinfo;
    return (
      <>
        <View
          style={[CommonStyles.orderInfoStyle, {zIndex: 9, position: 'relative'}]}
        >
          <View>
            <Text
              style={[globalStyles.py_10, CommonStyles.InfoDetailTextStyle]}
            >
              {i18n.t('order.order_number')}
            </Text>
            <TextInput
              testID='orderNo'
              editable={this.props.order_no}
              style={[styles.roundedBox, CommonStyles.infoDetailInputStyle]}
              onChangeText={text => {
                this.props.show === true
                  ? this.props.permissionCheck()
                  : this.props.handleChange('increment_id', text);
              }}
              value={basicinfo.increment_id}
            />
          </View>
          <View>
            <Text
              style={[globalStyles.py_10, CommonStyles.InfoDetailTextStyle]}
            >
              {i18n.t('order.scanning_completed')}
            </Text>
            <TextInput
              editable={this.props.scanning_com}
              testID="scanningCom"
              style={[styles.roundedBox, globalStyles.bgWhite, CommonStyles.infoDetailInputStyle]}
              onChangeText={text => {
                this.props.show === true
                  ? this.props.permissionCheck()
                  : this.props.handleChange('scan_start_time', text);
              }}
              value={moment(basicinfo.scan_start_time).format(
                'MMMM Do YYYY, h:mm:ss a'
              )}
            />
          </View>
          <View>
            <Text
              style={[globalStyles.py_10, CommonStyles.InfoDetailTextStyle]}
            >
              {i18n.t('order.order_date')}
            </Text>
            <View
              style={[
                styles.roundedBox,
                styles.inputBoxWidth,
                globalStyles.bgWhite,
                CommonStyles.roundedBox
              ]}
            >
              <DateTimePicker
                disabled={this.props.order_date}
                testID="orderDate"
                onChange={date => {
                  this.props.show === true
                    ? this.props.permissionCheck()
                    : this.props.setDate(date);
                }}
                value={this.props.pickedDate}
                amPmAriaLabel={'Select AM/PM'}
                format="MM-dd-yyyy h:mm aa"
              />
            </View>
          </View>
        </View>
        <View
          style={CommonStyles.orderInfoStyle}
        >
          <View
            style={CommonStyles.infoDetailFlex}
          >
            <TouchableOpacity testID='contentLess' onPress={() => this.props.contentLess()}>
              <View style={{flexDirection: 'row'}}>
                <Text
                  style={CommonStyles.InfoDetailTextStyleSecond}
                >
                  {this.props.lessMore ? 'Less' : 'More'}
                </Text>
                {this.props.lessMore ? (
                  <i
                    className="icon-up-open"
                    style={{color: 'white', fontSize: '23px'}}
                  ></i>
                ) : (
                  <i
                    className="icon-down-open"
                    style={{color: 'white', fontSize: '23px'}}
                  ></i>
                )}
              </View>
            </TouchableOpacity>
          </View>
          {this.props.lessMore && (
            <View>
              <View>
                <Text
                  style={[globalStyles.py_10, CommonStyles.InfoDetailTextStyle, {marginTop: 0}]}
                >
                  {i18n.t('order.tracking_number')}
                </Text>
                <TextInput
                  testID='trackingNumber'
                  editable={this.props.tracking_number}
                  style={[
                    styles.roundedBox,
                    globalStyles.bgWhite,
                    CommonStyles.infoDetailInputStyle
                  ]}
                  onChangeText={text => {
                    this.props.show === true
                      ? this.props.permissionCheck()
                      : this.props.handleChange('tracking_num', text);
                  }}
                  value={basicinfo.tracking_num}
                />
              </View>
              <View>
                <Text
                  style={[globalStyles.py_10, CommonStyles.InfoDetailTextStyle, {marginTop: 0}]}
                >
                  {i18n.t('order.customer_order_data1')}
                </Text>
                <TextInput
                  testID='custOrderData1'
                  editable={this.props.cust_order_data1}
                  style={[
                    styles.roundedBox,
                    globalStyles.bgWhite,
                    CommonStyles.infoDetailInputStyle
                  ]}
                  onChangeText={text => {
                    this.props.show === true
                      ? this.props.permissionCheck()
                      : this.props.handleChange('custom_field_one', text);
                  }}
                  value={basicinfo.custom_field_one}
                />
              </View>
              <View>
                <Text
                  style={[globalStyles.py_10, CommonStyles.InfoDetailTextStyle, {marginTop: 0}]}
                >
                  {i18n.t('order.customer_order_data2')}
                </Text>
                <TextInput
                  testID='custOrderData2'
                  editable={this.props.cust_order_data2}
                  style={[
                    styles.roundedBox,
                    globalStyles.bgWhite,
                    CommonStyles.infoDetailInputStyle
                  ]}
                  onChangeText={text => {
                    this.props.show === true
                      ? this.props.permissionCheck()
                      : this.props.handleChange('custom_field_two', text);
                  }}
                  value={basicinfo.custom_field_two}
                />
              </View>
            </View>
          )}
        </View>
        <View
          style={CommonStyles.orderInfoStyle}
        >
          <View>
            <Text
              style={[globalStyles.py_10, CommonStyles.InfoDetailTextStyle, {textAlign: 'center'}]}
            >
              Customer Details
            </Text>
          </View>
          <View>
            <Text
              style={[globalStyles.py_10, CommonStyles.InfoDetailTextStyle, {marginTop: 0}]}
            >
              {i18n.t('order.first_name')}
            </Text>
            <TextInput
              testID='firstName'
              editable={this.props.first_name}
              style={[styles.roundedBox, globalStyles.bgWhite, CommonStyles.infoDetailInputStyle]}
              onChangeText={text => {
                this.props.show === true
                  ? this.props.permissionCheck()
                  : this.props.handleChange('firstname', text);
              }}
              value={basicinfo.firstname}
            />
          </View>
          <View>
            <Text
              style={[globalStyles.py_10, CommonStyles.InfoDetailTextStyle, {marginTop: 0}]}
            >
              {i18n.t('order.last_name')}
            </Text>
            <TextInput
              testID='lastName'
              editable={this.props.last_name}
              style={[styles.roundedBox, globalStyles.bgWhite, CommonStyles.infoDetailInputStyle]}
              onChangeText={text => {
                this.props.show === true
                  ? this.props.permissionCheck()
                  : this.props.handleChange('lastname', text);
              }}
              value={basicinfo.lastname}
            />
          </View>

          <View>
            <Text
              style={[globalStyles.py_10, CommonStyles.InfoDetailTextStyle, {marginTop: 0}]}
            >
              {i18n.t('order.email')}
            </Text>
            <TextInput
              testID='emailCust'
              editable={this.props.email_cust}
              style={[styles.roundedBox, globalStyles.bgWhite, CommonStyles.infoDetailInputStyle]}
              onChangeText={text => {
                this.props.show === true
                  ? this.props.permissionCheck()
                  : this.props.handleChange('email', text);
              }}
              value={basicinfo.email}
            />
          </View>
          <View>
            <Text
              style={[globalStyles.py_10, CommonStyles.InfoDetailTextStyle, {marginTop: 0}]}
            >
              {i18n.t('order.company')}
            </Text>
            <TextInput
              testID='companyCust'
              editable={this.props.company_cust}
              style={[styles.roundedBox, globalStyles.bgWhite, CommonStyles.infoDetailInputStyle]}
              onChangeText={text => {
                this.props.show === true
                  ? this.props.permissionCheck()
                  : this.props.handleChange('company', text);
              }}
              value={basicinfo.company}
            />
          </View>
          <View>
            <Text
              style={[globalStyles.py_10, CommonStyles.InfoDetailTextStyle, {marginTop: 0}]}
            >
              {i18n.t('order.city')}
            </Text>
            <TextInput
              testID='cityCust'
              editable={this.props.city_cust}
              style={[styles.roundedBox, globalStyles.bgWhite, CommonStyles.infoDetailInputStyle]}
              onChangeText={text => {
                this.props.show === true
                  ? this.props.permissionCheck()
                  : this.props.handleChange('city', text);
              }}
              value={basicinfo.city}
            />
          </View>
          <View>
            <Text
              style={[globalStyles.py_10, CommonStyles.InfoDetailTextStyle, {marginTop: 0}]}
            >
              {i18n.t('order.state')}
            </Text>
            <TextInput
              testID='stateCust'
              editable={this.props.state_cust}
              style={[styles.roundedBox, globalStyles.bgWhite, CommonStyles.infoDetailInputStyle]}
              onChangeText={text => {
                this.props.show === true
                  ? this.props.permissionCheck()
                  : this.props.handleChange('state', text);
              }}
              value={basicinfo.state}
            />
          </View>
          <View>
            <Text
              style={[globalStyles.py_10, CommonStyles.InfoDetailTextStyle, {marginTop: 0}]}
            >
              {i18n.t('order.address_1')}
            </Text>
            <TextInput
              testID='address1'
              editable={this.props.address1}
              style={[styles.roundedBox, globalStyles.bgWhite, CommonStyles.infoDetailInputStyle]}
              onChangeText={text => {
                this.props.show === true
                  ? this.props.permissionCheck()
                  : this.props.handleChange('address_1', text);
              }}
              value={basicinfo.address_1}
            />
          </View>
          <View>
            <Text
              style={[globalStyles.py_10, CommonStyles.InfoDetailTextStyle, {marginTop: 0}]}
            >
              {i18n.t('order.address_2')}
            </Text>
            <TextInput
              testID='address2'
              editable={this.props.address2}
              style={[styles.roundedBox, globalStyles.bgWhite, CommonStyles.infoDetailInputStyle]}
              onChangeText={text => {
                this.props.show === true
                  ? this.props.permissionCheck()
                  : this.props.handleChange('address_2', text);
              }}
              value={basicinfo.address_2}
            />
          </View>
          <View>
            <Text
              style={[globalStyles.py_10, CommonStyles.InfoDetailTextStyle, {marginTop: 0}]}
            >
              {i18n.t('order.postal_code')}
            </Text>
            <TextInput
              testID='postalCode'
              editable={this.props.postal_code}
              style={[styles.roundedBox, globalStyles.bgWhite, CommonStyles.infoDetailInputStyle]}
              onChangeText={text => {
                this.props.show === true
                  ? this.props.permissionCheck()
                  : this.props.handleChange('postcode', text);
              }}
              value={basicinfo.postcode}
            />
          </View>
          <View>
            <Text
              style={[globalStyles.py_10, CommonStyles.InfoDetailTextStyle, {marginTop: 0}]}
            >
              {i18n.t('order.country')}
            </Text>
            <TextInput
              testID='countryCust'
              editable={this.props.country_cust}
              style={[styles.roundedBox, globalStyles.bgWhite, CommonStyles.infoDetailInputStyle]}
              onChangeText={text => {
                this.props.show === true
                  ? this.props.permissionCheck()
                  : this.props.handleChange('country', text);
              }}
              value={basicinfo.country}
            />
          </View>
        </View>
      </>
    );
  }
}
export default InformationDetailMobileView;
