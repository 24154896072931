import React from "react";
import { Text, TouchableOpacity, Image } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import styles from "../style/scanpack";
import { fontFamily } from "../helpers/fontFamily";
const DisplayOrderNumber = ({
  orderDetail,
  incrementId,
  actionBarShow,
  setActionBarState,
  downArrow,
  upArrow,
  id,
  copyOrderNumberToClipboard,
}) => {
  return (
    <LinearGradient
      start={{ x: 0, y: 1 }}
      end={{ x: 0, y: 0 }}
      colors={["#000000", "#00000006", "#00000006", "#000000"]}
      style={{
        position: "relative",
        backgroundColor: "#292929",
      }}
    >
      <TouchableOpacity
        testID={id}
        onPress={() => {
          orderDetail();
        }}
        delayLongPress={1000}
        style={{ marginRight: 45, marginLeft: 45 }}
      >
        <Text
          numberOfLines={1}
          ellipsizeMode="tail"
          style={[
            styles.nowScanningTextView,
            {
              textAlign: "center",
              margin: 8,
              fontFamily: fontFamily.font700,
              color: "#8e8b8b",
            },
          ]}
        >
          Order {incrementId}
          <TouchableOpacity
            testID='copyOrderNumber'
            onPress={copyOrderNumberToClipboard}
          >
            <i
              className="icon-docs"
              style={{
                color: "#8e8b8b",
                fontSize: "20px",
                marginLeft: "10px",
              }}
            ></i>
          </TouchableOpacity>
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        testID={"actionBarShowFunMobile"}
        onPress={setActionBarState}
        style={{ position: "absolute", top: 10, right: 10 }}
      >
        {actionBarShow ? (
          <Image
            style={{ width: 20, height: 20 }}
            source={upArrow ? upArrow : ""}
          />
        ) : (
          <Image
            style={{ width: 20, height: 20 }}
            source={downArrow ? downArrow : ""}
          />
        )}
      </TouchableOpacity>
    </LinearGradient>
  );
};
export default DisplayOrderNumber;