import AsyncStorage from '@react-native-async-storage/async-storage';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFocusEffect } from '@react-navigation/core';
import { GetProductDetail, GetSearchProduct } from '../../actions/productAction';
import scanFailSound from '../../../assets/sounds/scan_fail_shorter.mp3';
import { processBarCode } from '../scanpack/scanBarcodeFunctions';
import { GetBothSettings } from '../../actions/userAction';

const failSound = new Audio(scanFailSound);
const tooltipText = [
  {
    id: 'header-info-1',
    description:
      'The Workflow setting allows you to choose which product fields will be updated while recounting inventory so you can update many products quickly. Here is a description of each workflow:'
  },
  {
    id: 'tooltip-recount-1',
    description:
      'Enter new Quantity on Hand\nAfter scanning the product you’ll type in the new QOH (quantity on hand) for the item. The previous QOH will be replaced by the new count you enter.'
  },
  {
    id: 'tooltip-recount-2',
    description:
      'Enter Location\nAfter scanning the product you can type or scan a location for the item. This allows you to quickly update the location for many items.'
  },
  {
    id: 'tooltip-recount-3',
    description:
      'Enter new Quantity on Hand, Then Enter Location\nThis option combines the first two options. First, you’ll enter the new QOH for an item and then the location can be updated. If the location has not changed to can press Enter to keep the previously saved location. '
  },
  {
    id: 'tooltip-recount-4',
    description:
      'Scan Each Piece to Recount\nThis option allows you to scan each piece and use the scanner to count the inventory. When you are done scanning every piece you’ll be able to save the total which will overwrite the previous QOH.'
  },
  {
    id: 'tooltip-recount-5',
    description:
      'Scan Each Piece to Recount, Then Enter Location\nThe option combines the two options above. First the scanner is used to count each piece and save the new QOH and then the location of the item can be updated.'
  }
];

const useRecountScan = ({ navigation }) => {
  const dispatch = useDispatch();
  const store = useSelector(state => state.product);
  const settingcheck = useSelector(
    state => state?.user?.bothSettings?.data?.data?.scanpack_setting
  );
  const isUnMounted = useSelector(state => state.menu.isUnMounted);
  const [isWorkflowContainerVisible, setWorkflowContainerVisible] =
    useState(false);
  const [selectedWorkFlow, setSelectedWorkflow] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const searchRef = useRef();
  const [isInvalidBarcode, setIsInvalidBarcode] = useState(false);
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  useEffect(() => {
    dispatch(GetBothSettings());
    let mounted = true;
    (async () => {
      const recountWorkflow = await AsyncStorage.getItem('recount_workflow');
      if (recountWorkflow)
        mounted && setSelectedWorkflow(JSON.parse(recountWorkflow));
      else mounted && setSelectedWorkflow(workflowOptions[0]);
    })();
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (searchTerm) {
      setIsInvalidBarcode(false);
      if (store.searchProduct) {
        if (store.searchProduct.length === 1) {
          dispatch(GetProductDetail(store.searchProduct[0].id));
        } else if (store.searchProduct.length > 1) {
          navigation.navigate('ProductListRecount', { selectedWorkFlow });
        } else {
          setIsInvalidBarcode(true);
          setSearchTerm('');
          failSound.play();
        }
      } else {
        setIsInvalidBarcode(true);
        setSearchTerm('');
        failSound.play();
      }
    }
  }, [store.searchProduct]);

  useEffect(() => {
    if (searchTerm)
      if (store.product) {
        setSearchTerm('');
        navigation.navigate(selectedWorkFlow.screen, {
          steps: selectedWorkFlow.steps
        });
      }
  }, [store.product]);

  useEffect(() => {
    if (selectedWorkFlow) {
      AsyncStorage.setItem(
        'recount_workflow',
        JSON.stringify(selectedWorkFlow)
      );
    }
  }, [selectedWorkFlow]);

  useFocusEffect(() => {
    searchRef.current.focus();
  });

  const setFocus = () => {
    searchRef.current.focus();
  }
  useEffect(() => {
    setFocus();
  }, [isUnMounted])
  const workflowOptions = useMemo(() => [
    {
      label: 'Enter New Quantity on Hand',
      steps: ['quantity'],
      screen: 'ProductRecount'
    },
    {
      label: 'Enter Location',
      steps: ['location'],
      screen: 'ProductRecount'
    },
    {
      label: 'Enter New Quantity on Hand, Then Enter Location',
      steps: ['quantity', 'location'],
      screen: 'ProductRecount'
    },
    {
      label: 'Scan Each Piece Recount',
      steps: ['count'],
      screen: 'CountRecount'
    },
    {
      label: 'Scan Each Piece Recount, Then Enter Location',
      steps: ['count', 'location'],
      screen: 'CountRecount'
    }
  ]);
  const searchProduct = () => {
    let updateBarcode  
    if (settingcheck?.escape_string_enabled) {
      const { barCodeWithoutSpaces } = processBarCode(searchTerm, settingcheck);
      updateBarcode = barCodeWithoutSpaces
    } 
    else {
      updateBarcode = searchTerm
    }
    if (updateBarcode) {
      let data = {
        search: updateBarcode,
        sort: '',
        order: 'DESC',
        is_kit: 0,
        limit: 20,
        offset: 0
      };
      dispatch(GetSearchProduct(data));
    }
  };

  const toggleTooltip = () => {
    setWorkflowContainerVisible(false);
    setTooltipVisible(!isTooltipVisible);
  };

  return {
    workflowOptions,
    isWorkflowContainerVisible,
    setWorkflowContainerVisible,
    selectedWorkFlow,
    setSelectedWorkflow,
    searchProduct,
    searchTerm,
    setSearchTerm,
    searchRef,
    setFocus,
    isInvalidBarcode,
    toggleTooltip,
    isTooltipVisible,
    setTooltipVisible,
    tooltipText
  };
};
export default useRecountScan;
