import { LinearGradient } from 'expo-linear-gradient';
import React from 'react';
import { Text } from 'react-native';
const DropDownButton = (props) => {
  return (
    <LinearGradient
      start={{x: 0, y: 1}}
      end={{x: 0, y: 0}}
      colors={[
        props.firstColor,props.secondColor
      ]}
      style={{
          position: 'relative',
          paddingHorizontal: 5,
          paddingVertical: 3,
          borderWidth: 3,
          borderColor: props.firstColor,
          borderRadius: 10,
          width: props.width
        }}
    >
      <Text style={{fontFamily:'Poppins_600SemiBold', fontSize: props.fontSize, color: props.textColor}}>
        {props?.label}
      </Text>
    </LinearGradient>
    
  )
}
export default DropDownButton;