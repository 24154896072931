import React, { Component } from 'react';
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Dimensions
} from 'react-native';
import styles from '../../style/scanpack';
import commonStyles from '../../style/commonStyles';
import globalStyles from '../../style/global';
import { LinearGradient } from 'expo-linear-gradient';
import i18n from 'i18n-js';
import DefaultInput from '../../commons/Inputs/defaultInput';
export default class OrderConfirmationPopUp extends Component {
  constructor(props) {
    super(props);
    this.typeScanFocus = React.createRef();
    this.state = {
      windowWidth: window.innerWidth
    };
  }

  componentDidUpdate() {
    setTimeout(()=>{
      this?.typeScanFocus?.current?.focus();
    },1000)
  }
  updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    // this.setState()
  }

  render() {
    // console.log("props here",this.props)
    // console.log("this is orderConfirm popup",(this.props?.orderDetails?.notes_internal !== "" && this.props?.orderDetails?.notes_internal !==null)  || (this.props?.orderDetails?.notes_fromPacker !== "" && this.props?.orderDetails?.notes_fromPacker !== null))
    let show_tags = this?.props?.bothSettings?.data?.data?.scanpack_setting?.show_tags
    let show_customer_notes = this?.props?.bothSettings?.data?.data?.scanpack_setting?.show_customer_notes
    let show_internal_notes = this?.props?.bothSettings?.data?.data?.scanpack_setting?.show_internal_notes
    let confirmation_required =
      this?.props?.bothSettings?.data?.data?.general_setting
        ?.conf_req_on_notes_to_packer === 'always' ||
      this?.props?.bothSettings?.data?.data?.general_setting
        ?.conf_req_on_notes_to_packer === 'optional';
    let note_check =
      (this.props?.orderDetails?.notes_internal !== '' &&
        this.props?.orderDetails?.notes_internal !== null && show_internal_notes) ||
      (this.props?.orderDetails?.notes_fromPacker !== '' &&
        this.props?.orderDetails?.notes_fromPacker !== null) ||
      (this.props?.orderDetails?.notes_toPacker !== null &&
        this.props?.orderDetails?.notes_toPacker !== '') ||
      (this.props?.orderDetails?.customer_comments !== null &&
        this.props?.orderDetails?.customer_comments !== '' && show_customer_notes) ||
      (this.props?.orderDetails?.tags !== '' &&
        this.props?.orderDetails?.tags !== null && show_tags);
    return (
      <>
        {this.state.windowWidth >= 900 ? (
          <View style={[styles.responseView, styles.orderConfirmationNew]} dataSet={{ componentName: 'OrderConfirmationPopUp'}}>
            {confirmation_required && note_check ? (
              <LinearGradient
                 testID='OrderConfirmationPopUp'
                // locations={[0, 0.13, 1]}
                colors={['#fdf359', '#d2c609']}
                style={{...globalStyles.orderConfirmationPopUp, height: this.state.windowWidth < 2100 ? '28rem' : '33rem'}}
              >
                <View>
                  <TouchableOpacity
                    testID='cancelIcon'
                    style={[commonStyles.cancelIcon,{
                      marginLeft: "95%",
                    }]}
                    onPress={this.props?.OrderConfirmationClosePopup}
                  >
                    <i
                      className="icon-cancel"
                      style={{

                      }}
                    ></i>
                  </TouchableOpacity>
                </View>
                <View>
                  <Text
                    style={[
                      styles.scanCountText, commonStyles.orderConfirmationText,
                      {
                        fontSize: 20,
                        lineHeight: 30,
                      }
                    ]}
                  >
                    {i18n.t('orderConfirmationPopup.orderConfirmationText')}
                    {/* Confirmation is required for this note. */}
                  </Text>
                </View>

                <View
                  style={[commonStyles.notesMainStyle,{
                    marginTop: '2%'
                  }]}
                >
                  <Text
                    style={[
                      styles.orderConfirmationNotes,
                      {
                        padding: 5,
                      }]}
                  >
                    {this.props?.orderDetails?.notes_fromPacker}
                  </Text>
                  {this.props?.orderDetails?.notes_fromPacker ? (
                    <View
                      testID='notesFromPacker'
                      style={commonStyles.notes}
                    />
                  ) : (
                    <></>
                  )}
                  <Text
                    style={[
                      styles.orderConfirmationNotes,
                      {
                        padding: 5,
                      }]}
                  >
                    {show_internal_notes && this.props?.orderDetails?.notes_internal}
                  </Text>
                  {this.props?.orderDetails?.notes_internal ? (
                    <View
                      testID='notesInternal'
                      style={commonStyles.notes}
                    />
                  ) : (
                    <></>
                  )}
                  <Text
                    style={[
                      styles.orderConfirmationNotes,
                      {
                        padding: 5,
                      }]}
                  >
                    {this.props?.orderDetails?.notes_toPacker}
                  </Text>
                  {this.props?.orderDetails?.notes_toPacker ? (
                    <View
                      testID='notesToPacker'
                      style={commonStyles.notes}
                    />
                  ) : (
                    <></>
                  )}
                  {/* customer_comments */}
                  <Text
                    style={[
                      styles.orderConfirmationNotes,
                      {
                        padding: 5,
                      }]}
                  >
                    {show_customer_notes && this.props?.orderDetails?.customer_comments}
                  </Text>
                  <Text
                    style={[
                      styles.orderConfirmationNotes,
                      {
                        padding: 5,
                      }]}
                  >
                    {show_tags && this.props?.orderDetails?.tags}
                  </Text>
                </View>
                <View
                  style={commonStyles.flexStyle}
                >
                  <Text
                    style={[commonStyles.textStyle,{fontSize: '20px',lineHeight: '30px'}]}
                  >
                    {i18n.t('orderConfirmationPopup.orderConfirmationTextBedoreInput')}
                  </Text>
                  <View style={[commonStyles.checkInputStyle, globalStyles.checkInputStyle, {
                      background: "rgba(255, 255, 255, 0.4)"
                    }]}>
                    <DefaultInput
                      value={this.props.OrderConfirmationCheckInput}
                      onChangeText={this.props.handleOrderConfirmationCheckInput}
                      autoFocus={true}
                      placeholder={i18n.t('orderConfirmationPopup.orderConfimationEnterScan')}
                      ref={this.typeScanFocus}
                      onSubmitEditing={this.props.OrderConfirmationCheckFun}
                      style={[commonStyles.enterScanStyle, { fontSize: "20px", lineHeight: "30px",}]}
                    />
                  </View>
                </View>
              </LinearGradient>
            ) : (
              <View>{this.props.datcheckHere()}</View>
            )}
          </View>
        ) : (
          <View style={[styles.responseView]}>
            {confirmation_required && note_check ? (
              <LinearGradient
                testID='OrderConfirmationPopUpMobileView'
                colors={['#fdf359', '#d2c609']}
                style={globalStyles.orderConfirmationPopUpMobile}
              >
                <View>
                  <TouchableOpacity
                    testID='cancelICon'
                    style={commonStyles.cancelIcon}
                    onPress={this.props?.OrderConfirmationClosePopup}
                  >
                    <i
                      className="icon-cancel"
                      style={{

                      }}
                    ></i>
                  </TouchableOpacity>
                </View>
                <View>
                  <Text
                    style={[
                      styles.scanCountText, commonStyles.orderConfirmationText, globalStyles.orderConfirmationText
                    ]}
                  >
                    {i18n.t('orderConfirmationPopup.orderConfirmationText')}
                  </Text>
                </View>
                {/* if(this.props.) */}
                {/* {this?.props?.bothSettings?.data?.data?.general_setting?.conf_code_product_instruction == "always" ? */}
                <View
                  style={[commonStyles.notesMainStyle,{
                    marginTop: '4%'
                  }]}
                >
                  {this.props?.orderDetails?.notes_fromPacker ? (
                    <View
                     testID='notesFromPacker'
                      style={commonStyles.responseViewNotes}
                    />
                  ) : (
                    <></>
                  )}
                  <Text
                    style={[
                      styles.orderConfirmationNotes,
                    ]}
                  >
                    {show_internal_notes && this.props?.orderDetails?.notes_internal}
                  </Text>
                  {this.props?.orderDetails?.notes_internal ? (
                    <View
                      testID='notesInternal'
                      style={commonStyles.responseViewNotes}
                    />
                  ) : (
                    <></>
                  )}
                  <Text
                    style={[
                      styles.orderConfirmationNotes,
                    ]}
                  >
                    {this.props?.orderDetails?.notes_toPacker}
                  </Text>
                  {this.props?.orderDetails?.notes_toPacker ? (
                    <View
                      testID='notesToPacker'
                      style={commonStyles.responseViewNotes}
                    />
                  ) : (
                    <></>
                  )}
                  <Text
                    style={[
                      styles.orderConfirmationNotes,
                    ]}
                  >
                    {show_tags && this.props?.orderDetails?.tags}
                  </Text>
                </View>
                <View
                  style={commonStyles.flexStyle}
                >
                  <Text
                    style={[commonStyles.textStyle,{ textAlign: 'center', fontSize: '16px' }]}
                  >
                    {' '}
                    {i18n.t('orderConfirmationPopup.orderConfirmationTextBedoreInput')}
                  </Text>
                  <View 
                  style={[commonStyles.checkInputStyle, globalStyles.checkInputStyleMobile,{
                    background: "rgba(255, 255, 255, 0.4)" }]}>
                    <DefaultInput
                      value={this.props.OrderConfirmationCheckInput}
                      onChangeText={this.props.handleOrderConfirmationCheckInput}
                      autoFocus={true}
                      placeholder={i18n.t('orderConfirmationPopup.orderConfimationEnterScan')}
                      ref={this.typeScanFocus}
                      onSubmitEditing={this.props.OrderConfirmationCheckFun}
                      style={[commonStyles.enterScanStyle,
                        {
                          fontSize: "12px",
                          lineHeight: "18px"
                        }
                      ]}
                    />
                  </View>
                </View>
              </LinearGradient>
            ) : (
              <View>{this.props.datcheckHere()}</View>
            )}
          </View>
        )
        }
      </>
    );
  }
}
