import * as ImagePicker from 'expo-image-picker';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {addImage, UpdateProductInfo} from '../../actions/productAction';
import scanSuccessSound from '../../../assets/sounds/Scann Success_coin_a.mp3';
import scanFailSound from '../../../assets/sounds/scan_fail_shorter.mp3';

const successSound = new Audio(scanSuccessSound);
const failSound = new Audio(scanFailSound);
const quantityMessage = 'Counted value will replace existing QOH.';
const scanMessage = 'Scan each piece to recount';

export const useRecountCount = ({navigation, route}) => {
  const {steps} = route.params;
  const store = useSelector(state => state.product);
  const previousQOH = useRef(
    store.product.inventory_warehouses[0].info.quantity_on_hand
  );
  const barcodeRef = useRef();
  const [newCount, setNewCount] = useState(1);
  const [barcode, setBarcode] = useState('');
  const [isInvalidBarcode, setIsInvalidBarcode] = useState(false);
  const [recevingNotes, setReceivingNotes] = useState(
    store.product.basicinfo.product_receiving_instructions
  );
  const receivingNotesRef = useRef();
  const dispatch = useDispatch();

  const saveQuantity = useCallback(() => {
    if (!newCount) return;
    const product = Object.assign({}, store.product);
    product.inventory_warehouses[0].info.quantity_on_hand = newCount;
    dispatch(UpdateProductInfo(product.basicinfo.id, product));
  }, [newCount]);

  const saveProductInfo = useCallback(() => {
    const product = Object.assign({}, store.product);

    product.basicinfo.product_receiving_instructions = recevingNotes;

    dispatch(UpdateProductInfo(product.basicinfo.id, product));
  }, [recevingNotes]);
  const pickImage = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1
    });

    dispatch(addImage(store.product.basicinfo.id, result.uri));
  };
  const transformCount = count => {
    const strCount = count.toString();
    if (strCount.length >= 3) return strCount.toString();
    const numOfZeroes = 3 - strCount.length;
    return new Array(numOfZeroes).fill(0).join('') + strCount;
  };
  const navigateForward = () => {
    navigation.navigate('ProductRecount', {steps: ['location']});
  };
  const navigateBack = () => {
    navigation.navigate('ScanRecount');
  };
  const scanBarcode = useCallback(() => {
    const product = Object.assign({}, store.product);
    const exists = product.barcodes.find(item => item.barcode === barcode);
    if (exists) {
      setNewCount(newCount + exists.packing_count);
      setIsInvalidBarcode(false);
      successSound.play();
    } else {
      setIsInvalidBarcode(true);
      failSound.play();
    }
    setBarcode('');
  }, [barcode, newCount]);
  useEffect(() => {
    barcodeRef.current.focus();
    successSound.load();
    failSound.load();
  }, []);
  return {
    steps,
    product: store.product,
    saveProductInfo,
    pickImage,
    recevingNotes,
    setReceivingNotes,
    newCount,
    setNewCount,
    transformCount,
    previousQOH,
    saveQuantity,
    receivingNotesRef,
    navigateForward,
    navigateBack,
    barcode,
    setBarcode,
    scanBarcode,
    barcodeRef,
    isInvalidBarcode,
    newQOH: newCount,
    quantityMessage,
    scanMessage
  };
};
export default useRecountCount;
