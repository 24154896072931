import {ORDER_DETAIL_LOADER} from '../constants';

const initialState = {};

const orderLoaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ORDER_DETAIL_LOADER:
      return {...state, loading: action.payload};
    default:
      return state;
  }
};

export default orderLoaderReducer;
