import React, { Component } from 'react';
import {
  View,
  Text,
  TextInput,
  ActivityIndicator,
  Platform,
  ImageBackground,
  Dimensions,
  ScrollView
} from 'react-native';
import { connect } from 'react-redux';
import { GetSearchProduct } from '../../actions/productAction';
import { SetIsProductCreated } from '../../actions/productAction'
import PopUpModel from '../scanpack/pop_up_model';
import ProductSearchList from '../products/product_search_list';
import { fontFamily } from '../../helpers/fontFamily';
import { IS_LOADING } from '../../constants';
import { navigateIfNeeded } from './commonFunction';
import i18n from 'i18n-js';

const height = Dimensions.get('window').height;
let skuNotfound = '';
class ProductSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowwidth: 0,
      data: {
        search: '',
        sort: '',
        app: 'app',
        order: 'DESC',
        limit: 20,
        offset: 0
      },
      productSearchData: [],
      popUpModelShow: false,
      autoFocusState: true,
      loading: false,
      cueOrderStatus: '',
      checkFocus: false,
      lastSearched: ''
    };
    this.inputFocus = React.createRef();
    window.addEventListener('resize', this.update);
  }
  componentDidMount() {
    this.setState({ productSearchData: [] });
    this.inputFocus.current.ref = true;
    this.update();
  }
  update = () => {
    this.setFocus();
    this.setState({ windowwidth: window.innerWidth });
  };

  handleKey = (e) => {
    if (e.key === 'Enter') {
      if (this.state.data.search === '') return;
      this.setState({lastSearched: this.state.data.search})
      this.searchProduct();
    }
  }
  //According to the platform call the function
  getOrder = () => {
    if (Platform.OS === 'ios' || Platform.OS === 'android') {
      this.checkConnection('apiCall');
    } else {
      this.apiCall();
    }
  };

  /////////////Alert DialogBox open
  alertBox = (message, skip, messageTypeSuccess) => {
    if (messageTypeSuccess === true) {
      this.setState({
        popUpModelShow: true,
        message: message,
        messageTypeSuccess: true,
        loading: false
      });
    } else {
      this.setState({ popUpModelShow: true, message: message });
    }
    setTimeout(
      function () {
        this.setState({
          popUpModelShow: false,
          message: '',
          messageTypeSuccess: false
        });
      }.bind(this),
      4000
    );
  };
  //Check the connection for the ios and android
  checkConnection = async (type, input) => {
    const connectionInfo = await NetInfo.fetch();
    if (connectionInfo.isConnected) {
      type === 'callShipmentOrder'
        ? this.callShipmentOrder(input)
        : this.apiCall();
    } else {
      this.setState({ rfoTitle: 'Please check your internet connection' });
    }
    //Call the api to fetch the data from backend
    apiCall = () => {
      this.props.GetOrderList(this.state.dataList);
      orderListSuccess = true;
    };
  };

  componentDidUpdate(pP) {
    const { isProductCreated, productId, isLoading, productSearchList } = this.props;
    if (!isProductCreated) {
      let page = 'ProductSearch';
      this?.inputFocus?.current?.focus();
      this.props.navigation.navigate('ProductDetail', {
        productInfo: productId,
        userData: this.props?.route?.params?.userInfo,
        page: page,
        oncomeBack: () => this.oncomeBack()
      });
      this.props.SetIsProductCreated()
    }
    if (isLoading === false && productSearchList) {
      this.props.setIsLoading(true);
      let productlist = productSearchList;
      if (productlist.length === 1) {
        let page = 'ProductSearch';
        this?.inputFocus?.current?.focus();
        this.props.navigation.navigate('ProductDetail', {
          productInfo: productlist[0].id,
          userData: this.props?.route?.params?.userInfo,
          page: page,
          oncomeBack: () => this.oncomeBack()
        });
      }
      if (productlist?.length > 0) {
        this.setState({ productSearchData: productlist });
        this?.inputFocus?.current?.focus();
      } else {
        if (skuNotfound === '') {
          skuNotfound = this.state.data.search
        }
        let message = i18n.t('product.product_not_found');
        this.alertBox(
          `${message} ${skuNotfound}`,
          true,
          true
        );
      }
      let data = this.state.data;
      data['search'] = '';
      this.setState({ loading: false, data: data });
    }
    this?.inputFocus?.current?.focus();
    navigateIfNeeded(this.props, this.state);
  }

  //////////// For Close Alert
  closeAlert = () => {
    this.setState({ popUpModelShow: false });
  };

  redirect = async () => {
    await AsyncStorage.clear();
    this.props?.navigation?.navigate('SignIn', {
      status: 'Inactive',
      time: new Date()
    });
    this.setState({ userInfo: this.props.userInfo });
  };
  //After the apis call update the status (scanned , order  not present)
  statusFunction = message => {
    let data = this.state.data;
    data['input'] = '';
    this.setState({ rfoTitle: message, data });
  };

  //To set the focus
  setFocus = () => {
    this?.inputFocus?.current?.focus();
  };
  searchProduct = input => {
    this.setState({ loading: true, loaderTitle: 'Loading...' });
    let data = this.state.data;
    skuNotfound = ''
    this.props.GetSearchProduct(data);
  };

  //Redirect to the scanpackItem page with order details
  orderDetails = order => {
    if (order && order?.scan_hash && order?.scan_hash.data) {
      this.setState({ showQuick: false });

      this.props.navigation.navigate('ScanPackItem', {
        data: order?.scan_hash?.data,
        userdata: this?.props?.userInfo
      });
      messageShow = false;
    }
    //Check the connection for mobile device before call the api
  };
  //Handle the search field input
  handleChange = (name, value) => {
    let temp = this.state.data;
    temp['search'] = value;
    this.setState({ data: temp });
  };

  drawerClose = () => {
    this.props.route.params.closeDrawer();
  };
  productSearchBack = () => {
    this.setState({ productSearchData: [] });
  };
  oncomeBack = () => {
    this.setState({ checkFocus: true });
  };
  render() {
    return (
      <>
        {this?.state?.productSearchData &&
          this?.state?.productSearchData?.length < 2 ? (
          <View
            style={{ flex: 1 }}
            onStartShouldSetResponder={() => this.drawerClose()}
          >
            <ImageBackground
              style={{ flex: 1 }}
              source={require('././../../../assets/background.png')}
            >
              <ScrollView contentContainerStyle={{ flex: 1 }}>
                <ImageBackground
                  style={{
                    height: 365,
                    marginTop: 20,
                    alignItems: 'center'
                  }}
                  source={require('././../../../assets/scaning.png')}
                  resizeMode="contain"
                >
                  <Text
                    style={{
                      textAlign: 'center',
                      fontSize: 60,
                      color: '#8da4b8',
                      fontFamily: fontFamily.font400,
                      marginTop: 184
                    }}
                  >
                    Scan
                  </Text>
                  <TextInput
                    name="searchOrder"
                    ref={this.inputFocus}
                    value={this.state.data.search}
                    onChangeText={text => {
                      this.handleChange('searchOrder', text);
                    }}
                    onSubmitEditing={() => {
                      if (this.state.data.search === '') return;
                      this.searchProduct();
                    }}
                    onKeyPress={(e) => {
                      this.handleKey(e)
                    }}
                    style={{
                      backgroundColor: 'black',
                      borderWidth: 2,
                      borderColor: '#7a92a8',
                      width: 250,
                      borderRadius: 5,
                      color: 'white',
                      fontSize: 16,
                      fontFamily: fontFamily.font400,
                      outline: 'none',
                      padding: 5
                    }}
                  />
                  {
                    // Notification Pop Up for messages
                    this.state.popUpModelShow && (
                      <PopUpModel
                        closeAlert={this.closeAlert.bind(this)}
                        message={this.state.message}
                        messageTypeSuccess={
                          this.state.messageTypeSuccess ? true : false
                        }
                      />
                    )
                  }
                  {this.state.loading ? (
                    <View style={{ flex: 1, justifyContent: 'center' }}>
                      {/* Activity Indicator Shown  */}
                      <ActivityIndicator size="large" color="#336599" />
                    </View>
                  ) : (
                    <>
                      {this.state.error ? (
                        <Text style={styles.text}>{this.state.error}</Text>
                      ) : null}
                    </>
                  )}
                  <Text
                    style={{
                      color: '#8da4b8',
                      fontSize: 18,
                      marginTop: 24,
                      fontFamily: fontFamily.font400,
                      textAlign: 'center'
                    }}
                  >
                    Scan a Product or Search by SKU
                  </Text>
                  <View style={{ height: 40, width: '100%' }} />
                </ImageBackground>
              </ScrollView>
            </ImageBackground>
          </View>
        ) : (
          <ProductSearchList
            productSearchList={this.state.productSearchData}
            navigation={this.props.navigation}
            userInfo={this.props.route.params.userInfo}
            productSearchBack={() => this.productSearchBack()}
            oncomeBack={() => this.oncomeBack()}
            lastSearched={this.state.lastSearched}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    productSearchList: state.product.searchProduct,
    productId: state.product.productId,
    isProductCreated: state.product.isProductCreated,
    isLoading: state.product.isLoading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setIsLoading: value =>
      dispatch({
        type: IS_LOADING,
        payload: value
      }),
    GetSearchProduct: data => dispatch(GetSearchProduct(data)),
    SetIsProductCreated: () => dispatch(SetIsProductCreated())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductSearch);
