import {IS_OPEN, IS_SWAP, IS_LIST_MODE, IS_UNMOUNT,OPEN_NOTIFICATION, IS_HAPTICS_MODE, MOUNT_COMPONENT} from '../constants';

export function CheckIsMenuOpen(value) {
  return dispatch => {
    dispatch({
      type: IS_OPEN,
      payload: value
    });
  };
}

export function isHapticsMode(value) {
  return dispatch => {
    dispatch({
      type: IS_HAPTICS_MODE,
      payload: value
    });
  };
}

export function CheckIsListMode(value) {
  return dispatch => {
    dispatch({
      type: IS_LIST_MODE,
      payload: value
    });
  };
}

export function SwapUPC(value) {
  return dispatch => {
    dispatch({
      type: IS_SWAP,
      payload: value
    });
  };
}

export function Unmounted(value) {
  return dispatch => {
    dispatch({
      type: IS_UNMOUNT,
      payload: !value
    });
  };
}

export function ShowNotification(value) {
  return dispatch => {
    dispatch({
      type: OPEN_NOTIFICATION,
      payload: value
    })
  }
}

export function MountComponent(value) {
  return dispatch => {
    dispatch({
      type: MOUNT_COMPONENT,
      payload: value
    });
  };
}
