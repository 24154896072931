import {StyleSheet} from 'react-native';
const styles = StyleSheet.create({
  greenButton: {
    borderRadius: 25,
    paddingVertical: 3,
    paddingHorizontal: 10,
    width: '80px',
    textAlign: 'center'
  },
  subHeading: {
    color: 'white',
    fontWeight: 600,
    fontSize: '16px'
  },
  iconInfo: {
    color: 'rgb(172,187,199)',
    fontSize: '17px',
    height: '18px'
  },
  viewAlignMain: {
    display: 'flex',
    gap: '15px',
    alignItems: 'center',
    flexDirection: 'row'
  },
  tooltipText: {
    color: 'balck',
    fontSize: '18px',
    fontWeight: 300,
    lineHight:"27px",
    alignItems:"center"
  }
});
export default styles;
