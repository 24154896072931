import React, { Component } from 'react';
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Dimensions
} from 'react-native';
import styles from '../../style/scanpack';
import globalStyles from '../../style/global';
import CommonStyles from '../../style/commonStyles'
import promptStyle from '../../style/popupmodel'
import { LinearGradient } from 'expo-linear-gradient';
import { fontFamily } from '../../helpers/fontFamily';
import './addBar.css';
import i18n from 'i18n-js';
import StyledInput from '../../commons/Inputs/stylesInput';

export default class SerialRecord extends Component {
  constructor(props) {
    super(props);
    this.typeScanFocus = React.createRef();
    this.state = {
      windowWidth: Dimensions.get('window').width
    };
  }

  updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  };
  componentWillUnmount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentDidUpdate() {
    this.typeScanFocus.current.focus();
  }

  render() {
    return (
      <View style={[styles.responseView]} dataSet={{ componentName: 'serialRecord'}}>
        <View style={[this.state.windowWidth >= 900 ? styles.typeScanContainer : "", styles.recordSerialNew, this.state.windowWidth <= 900 ? styles.recordSerialMobile : ""]}>
          <LinearGradient
            testID='serialRecord'
            locations={[0, 1]}
            start={{ x: 0, y: 1 }}
            end={{ x: 0, y: 0 }}
            colors={['#d47d02', '#ffb636']}
            style={[
              styles.typeRecordBox,
              CommonStyles.alertBox,
              {
                top: this.state.windowWidth >= 900 ? '-24%' : '0%',
                right: this.state.windowWidth >= 900 ? "" : "-1%",
                width: this.state.windowWidth >= 900 ? '50%' : '97%',
              }
            ]}
          >
            <View style={globalStyles.flexDirectionRow}>
              <Text
                style={[
                  styles.scanRecordCountText,
                  { fontFamily: fontFamily.font500, fontSize: 20 }
                ]}
              >
                {this.props.serialRecordText}
              </Text>
            </View>
            <View
              style={CommonStyles.boxContainer}
            >
              <StyledInput
                value={this.props.serialRecordInput}
                keyboardType="default"
                onChangeText={this.props.handleSerialRecordInput}
                autoFocus={true}
                ref={this.typeScanFocus}
                // className="borderInput"
                onSubmitEditing={
                  this.props.serialRecordInput !== ''
                    ? this.props.serialRecordFun
                    : ''
                }
                style={[
                  styles.RecordScanCountInput,
                  CommonStyles.inputStyle
                ]}
                inputStyle={CommonStyles.inputStyleOne}
                 useInputStyle= {true}
              />
              <TouchableOpacity
                testID='cancelButton'
                onPress={() => this.props?.closeSerialRecord()}
                style={[styles.scanCountSubmitBtnBox]}
              >
                <Text
                  style={[
                    styles.scanCountSubmitBtn,
                    { fontFamily: fontFamily.font400 }
                  ]}
                >
                  Cancel
                </Text>
              </TouchableOpacity>
              {/*<TouchableOpacity onPress={this.props.serialRecordInput !== "" ? this.props.serialRecordFun :  ""}
                              style={styles.scanCountSubmitBtnBox}
                              >
              <Text style={styles.scanCountSubmitBtn}>
                Submit
              </Text>
            </TouchableOpacity>*/}
            </View>
          </LinearGradient>
        </View>
      </View>
    );
  }
}
