import React from 'react';
import {View, TextInput,Text} from 'react-native';

export default function PaperSettings({
  paperWeight,
  paperHeight,
  dpiValue,
  handleEdit,
  handleChange,
  handleSubmit,
  isShipping,
  isReceiver
}) {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
        alignItems: 'center',
        justifyContent: 'center'

      }}>
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          display: isShipping && 'none'          
        }}>
        <Text
          style= {{color: '#e0dcdc',fontFamily: 'Poppins_300Light', fontSize: 18, fontWeight: 300,marginBottom: 2}}
        >
          Paper size
        </Text>
        <View
         style={{display: 'flex', flexDirection: 'row'}}
        >
        <InputPart 
        label={'W'}
        testId={'width'}
        value={paperWeight}
        handleEdit={() => handleEdit()}
        handleChange={(value)=>{(/^-?\d*$/.test(value)) && handleChange(value, 'paperWeight')}}
        handleSubmit={()=>{handleSubmit()}}
        isReceiver={isReceiver}
        />
        <InputPart 
        label={'H'}
        testId={'height'}
        value={paperHeight}
        handleEdit={() => handleEdit()}
        handleChange={(value)=>{handleChange(value, 'paperHeight')}}
        handleSubmit={()=>{handleSubmit()}}
        isReceiver={isReceiver}
        />
        </View>
      </View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
      <Text
       style= {{color: '#e0dcdc',fontFamily: 'Poppins_300Light', fontSize: 18, fontWeight: 300,marginBottom: 2, display:'none'}}
      >
          DPI
      </Text>
      <TextInput
        style={{
          width: 48,
          height: 29,
          borderRadius: 10,
          paddingHorizontal:10,
          marginLeft: 3,
          marginRight: 1,
          backgroundColor: '#e3dcdc',
          keyboardType: 'numeric',
          display: 'none'
        }}
        value={dpiValue}
        onChangeText={(text)=>{
          handleChange(text, 'dpiValue')
        }}
        onSubmitEditing={()=>{handleSubmit()}}
      />
      </View>
    </View>
  );
}

const InputPart = ({label, value, handleEdit, handleChange, handleSubmit, testId, isReceiver }) => {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}>
      <Text
         style= {{color: '#e3dcdc',fontFamily: 'Poppins_300Light', fontSize: 18, fontWeight: 300}}
        >
        {label}
      </Text>
      <TextInput
        testID={testId}
        style={{
          width: 48,
          height: 29,
          borderRadius: 10,
          paddingHorizontal:10,
          marginLeft: 3,
          marginRight: 1,
          backgroundColor: '#e3dcdc',
          color: !isReceiver && 'gray'
        }}
        keyboardType='numeric'
        editable={isReceiver ? true : false}
        onFocus={!isReceiver && handleEdit}
        value={value}
        onChangeText={handleChange}
        onSubmitEditing={handleSubmit}
      />
    </View>
  );
};
