import React from 'react';
import {
    View,
    Text,
    TouchableOpacity
} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import styles from '../../style/scanpack';
const AliasButtons = ({function_one,function_two,message1, message2,}) =>{
    return (
        <View
         style={styles.aliasBoxCommonStyle}
        >
            <TouchableOpacity
            testID='Button1'
            onPress={function_one}
            >
                <LinearGradient
                 locations={[0,1]}
                 colors= {['#7BFAC3','#1CBB77']}
                 style={styles.aliasButtonText}
                >
                    <Text>{message1}</Text>
                </LinearGradient>
            </TouchableOpacity>

            <TouchableOpacity
            testID='Button2'
            onPress={function_two}
            >
                <LinearGradient
                 locations={[0,1]}
                 colors= {['#FFB538','#CF7C08']}
                 style={styles.aliasButtonText}
                >
                  <Text>{message2}</Text>
                </LinearGradient>
            </TouchableOpacity>
        </View>
    )
}
export default AliasButtons;