import React from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  Image,
} from 'react-native';
import placeholder from '../../assets/placeholder.jpg';
import {fontFamily} from '../helpers/fontFamily';
import DisplayQuantityInBox from './displayQunatityInBox';
import { LinearGradient } from 'expo-linear-gradient';




const ScannedKitItem = ({ item, order, activeBoxName, redirectToItemDetail, removeBoxItem }) => {
  return (
        <View key={item?.id}>
          {item?.scanned_qty > 0 && (
            <View
              testID="boxItemList"
              key={item?.id}
              style={{
                flexDirection: 'row',
                position: 'relative',
                backgroundColor: '#292929',
                textAlign: 'left',
                display: 'flex',
                borderRadius: 12,
              }}>
              <View
                style={{
                  width: '100%',
                  minHeight: 125,
                }}>
                <LinearGradient
                  colors={['#646469', '#b6c2d2', '#ddedfc', '#dceafc']}
                  locations={[0, 0.1, 0.44, 1]}
                  style={{
                    flex: 16,
                    padding: 4,
                    borderTopLeftRadius: 12,
                    borderTopEndRadius: 12,
                    justifyContent: 'center',
                  }}>
                  <TouchableOpacity
                    testID="redirectToItemDetail"
                    onPress={() => redirectToItemDetail(item)}
                    delayLongPress={1000}>
                    <Text
                      numberOfLines={2}
                      ellipsizeMode="tail"
                      style={
                        item.name && item.name.length <= 32
                          ? {
                              fontSize: 18,
                              fontFamily: fontFamily.font400,
                              width: '65%',
                              textAlign: 'left',
                              lineHeight: '100%',
                            }
                          : {
                              fontSize: 16,
                              fontFamily: fontFamily.font400,
                              width: '65%',
                              textAlign: 'left',
                              lineHeight: '100%',
                            }
                      }>
                      {item.name}
                    </Text>
                  </TouchableOpacity>
                </LinearGradient>
                {item.sku ? (
                  <View
                    style={{
                      padding: 4,
                      justifyContent: 'center',
                      backgroundColor: '#b6cadd',
                    }}>
                    <Text
                      numberOfLines={1}
                      ellipsizeMode="tail"
                      style={{
                        fontSize: 18,
                        fontFamily: fontFamily.font700,
                        color: '#3f638b',
                        width: '65%',
                      }}>
                      <Text
                        style={{
                          fontSize: 12,
                          fontFamily: fontFamily.font400,
                          fontWeight: 'bold',
                        }}>
                        SKU :{' '}
                      </Text>
                      {item.sku}
                    </Text>
                  </View>
                ) : (
                  <></>
                )}
                <View
                  style={{
                    backgroundColor: '#c1d4e6',
                    paddingLeft: 3,
                    paddingRight: 3,
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                    paddingBottom: 3,
                  }}>
                  {item?.barcodes[0]?.barcode ? (
                    <View
                      style={{
                        backgroundColor: '#d3def4',
                        paddingLeft: 3,
                        paddingRight: 3,
                      }}>
                      <Text
                        style={{
                          fontSize: 18,
                          maxWidth: '75%',
                        }}
                        numberOfLines={1}
                        ellipsizeMode="tail">
                        <Text
                          style={{
                            fontSize: 12,
                            fontFamily: fontFamily.font400,
                            fontWeight: 'bold',
                          }}>
                          UPC :{' '}
                        </Text>
                        {item && item.barcodes.length > 0 && item.barcodes[0].barcode}
                      </Text>
                    </View>
                  ) : (
                    <></>
                  )}
                  <View style={{ maxWidth: '75%', flexDirection: 'row' }}>
                    {item.location !== null &&
                      item.location !== '' &&
                      item.location !== undefined && (
                        <View
                          style={{
                            flex: 1,
                            flexDirection: 'row',
                            alignItems: 'baseline',
                          }}>
                          <Text
                            style={{
                              fontSize: 12,
                              fontFamily: fontFamily.font400,
                            }}>
                            L1 :{' '}
                          </Text>
                          <Text numberOfLines={1} style={{ fontSize: 15, fontFamily: fontFamily.font700 }}>
                            {item.location}
                          </Text>
                        </View>
                      )}
                    {item.custom_product_1 !== null &&
                      item.custom_product_1 !== '' &&
                      item.custom_product_1 !== undefined && (
                        <View
                          style={{
                            flex: 1,
                            flexDirection: 'row',
                            alignItems: 'baseline',
                          }}>
                          <Text style={{ fontSize: 12, fontFamily: fontFamily.font400 }}>C1:</Text>
                          <Text numberOfLines={1} style={{ fontSize: 15, fontFamily: fontFamily.font700 }}>
                            {item.custom_product_1}
                          </Text>
                        </View>
                      )}
                  </View>
                </View>
              </View>
              <View style={{ position: 'absolute', top: 0, right: 0, height: '100%' }}>
                <LinearGradient
                  locations={[0, 0.04, 0.08]}
                  start={{ x: 0.9, y: 1 }}
                  colors={['#3d4851', '#9EA4A8', '#ffffff']}
                  style={{
                    height: 118,
                    width: 100,
                    backgroundColor: '#000000',
                    shadowColor: '#000',
                    shadowOffset: { width: 0, height: 0 },
                    shadowOpacity: 0.58,
                    shadowRadius: 29,
                    elevation: 3,
                    borderTopLeftRadius: 5,
                    borderBottomLeftRadius: 5,
                    marginTop: 5,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Text
                    style={{
                      width: '100%',
                      position: 'absolute',
                      textAlign: 'center',
                      fontSize: 18,
                      fontFamily: fontFamily.font600,
                      backgroundColor: '#fff',
                      opacity: 0.7,
                      bottom: 0,
                      right: 0,
                      left: 0,
                      borderBottomLeftRadius: 5,
                      borderBottomRightRadius: 5,
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      zIndex: 999,
                    }}>
                    {item.scanned_qty}/{item?.total_qty === undefined || item.total_qty < item.qty_remaining + item.scanned_qty ? 
              item.qty_remaining + item.scanned_qty : item.total_qty}
                  </Text>
                  {item?.images[0]?.image ? (
                    <>
                      <Image
                        defaultSource={placeholder}
                        source={{ uri: item?.images[0]?.image }}
                        style={{
                          height: '100%',
                          width: '96%',
                          resizeMode: 'cover',
                          borderRadius: 10,
                        }}
                      />
                      <TouchableOpacity
                        testID="removeBox"
                        dataSet={{ componentName: 'removeBoxItem'}}
                        onPress={() => {
                          removeBoxItem(order?.box_id, order.order_item_id, order.kit_id);
                        }}
                        style={{
                          zIndex: '2',
                          color: 'red',
                          marginLeft: '68px',
                          marginTop: '-80px',
                          position: 'absolute',
                        }}>
                        <i className="icon-cancel" style={{ fontSize: 21 }}></i>
                      </TouchableOpacity>
                      <DisplayQuantityInBox
                        qty={order.item_qty}
                        boxName={
                            activeBoxName
                        }
                      />
                    </>
                  ) : (
                    <>
                      <Image
                        source={placeholder}
                        style={{
                          height: '100%',
                          width: '96%',
                          resizeMode: 'cover',
                          borderRadius: 10,
                        }}
                      />
                      <TouchableOpacity
                        testID="removeBox"
                        onPress={() => {
                          removeBoxItem(order?.box_id, order.order_item_id, order.kit_id);
                        }}
                        style={{
                          zIndex: '2',
                          color: 'red',
                          marginLeft: '68px',
                          marginTop: '-80px',
                          position: 'absolute',
                        }}>
                        <i className="icon-cancel" style={{ fontSize: 21 }}></i>
                      </TouchableOpacity>
                      <DisplayQuantityInBox
                        qty={order.item_qty}
                        boxName={
                          activeBoxName
                        }
                      />
                    </>
                  )}
                </LinearGradient>
              </View>
            </View>
          )}
        </View>
  );
};

export default ScannedKitItem;
