import React, {useEffect, useRef, useState} from 'react';
import {
  View,
  Text,
  Dimensions,
  StyleSheet,
  TouchableOpacity
} from 'react-native';
import globalStyles from '../style/global';
import {LinearGradient} from 'expo-linear-gradient';
import {Foundation} from '@expo/vector-icons';

const ShortcutModal = ({
  onClose,
  toggleModal,
  shortcutKey,
  saveShortcut,
  handleShortcutRetry
}) => {
  const windowWidth = Dimensions.get('window').width;
  const [toggleModal2, setToggleModal2] = useState();

  useEffect(() => {
    setToggleModal2(toggleModal);
  }, [toggleModal]);

  return (
    <>
      {toggleModal2 && (
        <TouchableOpacity style={styles.typeScanContainer} onPress={onClose} testID= 'onClose'>
          <LinearGradient
            locations={[0, 1]}
            colors={['rgb(81, 107, 131)', 'rgb(149, 171, 191)']}
            style={[
              styles.typeScanBox,
              {
                backgroundColor: '#d3dfea',
                paddingHorizontal: 10,
                paddingVertical: 20,
                width: windowWidth >= 900 ? '40%' : '100%'
              }
            ]}
          >
            <View style={([globalStyles.flexDirectionRow], styles.centerDiv)}>
              <Text style={styles.title}>
                {shortcutKey?.length > 0
                  ? shortcutKey?.join('+')
                  : 'Please press shortcut key(s) to set the shortcut'}
              </Text>
            </View>

            {shortcutKey?.length > 0 && (
              <TouchableOpacity
                style={styles.retryContainer}
                onPress={handleShortcutRetry}
              >
                <Foundation name="refresh" size={24} color="white" />
                <Text style={styles.retryText}>Retry</Text>
              </TouchableOpacity>
            )}
            {shortcutKey?.length > 0 && (
              <View style={styles.buttonContainer}>
                <TouchableOpacity
                  testID='saveShortcut'
                  style={styles.greenButton}
                  onPress={() => {
                    saveShortcut();
                    onClose();
                  }}
                >
                  <Text style={styles.buttonText}>Save & Close</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={onClose} style={styles.redButton}>
                  <Text style={styles.buttonText}>Cancel</Text>
                </TouchableOpacity>
              </View>
            )}
          </LinearGradient>
        </TouchableOpacity>
      )}
    </>
  );
};

export default ShortcutModal;

const styles = StyleSheet.create({
  typeScanContainer: {
    position: 'absolute',
    top: 0,
    zIndex: 1,
    backgroundColor: 'rgba(14, 14 , 14 , 0.7)',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    display: 'flex',
    shadowColor: '#fff',
    shadowOffset: {width: 0, height: 0},
    shadowOpacity: 10,
    shadowRadius: 10
  },
  typeScanBox: {
    backgroundColor: '#f9df3b',
    opacity: 1,
    width: '60%',
    height: 200,
    padding: 5,
    borderRadius: 10
    // borderWidth: 2,
    // borderColor: "#fff"
  },
  centerDiv: {
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
    flex: 1
  },
  title: {
    color: 'white',
    fontFamily: 'Poppins_400Regular',
    fontSize: 24
  },
  buttonContainer: {
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 20,
    flexDirection: 'row'
  },
  greenButton: {
    marginRight: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#fff',
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
    height: 30,
    shadowColor: '#2bfac3',
    shadowOffset: {width: 0, height: 0.5},
    shadowRadius: 8,
    elevation: 5,
    shadowOpacity: 0.5
  },
  redButton: {
    marginRight: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#fff',
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
    height: 30,
    shadowColor: '#e853fa',
    shadowOffset: {width: 0, height: 0.5},
    shadowRadius: 8,
    elevation: 5,
    shadowOpacity: 0.5
  },
  buttonText: {
    color: 'white',
    paddingHorizontal: 10,
    fontWeight: 'bold'
  },
  retryContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    paddingVertical: 5
  },
  retryText: {
    color: 'white',
    fontFamily: 'Poppins_400Regular',
    fontSize: 18,
    marginHorizontal: 10
  }
});
